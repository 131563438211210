import { Checkbox, IconButton, Radio } from "@mui/material"
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded"
import { useState } from "react"

function ItemList({
  type,
  place,
  selectedItems,
  setSelectedItems,
  handleDeleteItem
}: {
  type: string
  place: any
  selectedItems: any
  setSelectedItems: any
  handleDeleteItem?: any
}) {
  console.log("ItemList - use places ")
  // BANK - this component can't use 'places' and assume it is working within a form context
  // if so - parent component should pass checkedItems or so
  // const { fields } = useFieldArray({
  //   name: "places" // unique name for your Field Array
  // })

  // const previousSelect = fields.find((p: any) => p.place.id === place.id)
  const previousSelect = false
  const [checked, setChecked] = useState(previousSelect ? true : false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    if (!checked) {
      setSelectedItems([...selectedItems, place])
    } else {
      const filteredItem = selectedItems.filter((item) => item.id !== place.id)
      setSelectedItems(filteredItem)
      if (handleDeleteItem) {
        handleDeleteItem(place)
      }
    }
  }

  return (
    <div className="flex items-center gap-5 mb-3 justify-between">
      <div className="flex flex-1 items-center gap-5">
        <IconButton
          aria-label="delete"
          size="large"
          style={{ background: "var(--fc-lightblue-box)", cursor: "default" }}
        >
          <StarBorderRoundedIcon fontSize="inherit" color="primary" />
        </IconButton>

        <p className="font-bold">{place.name}</p>
      </div>

      <div>
        {type === "accommodation" && (
          <Radio onChange={handleChange} value={place.id} />
        )}

        {type === "place" && (
          <Checkbox
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
            checked={checked}
            onChange={handleChange}
            name={place.id}
          />
        )}
      </div>
    </div>
  )
}

export default ItemList

import { useParams } from "react-router-dom"
import useAxios from "axios-hooks"
import { useAuth } from "wakacje-web/src/auth/AuthContext"
import { ChatMessagesList } from "wakacje-web/src/shared/booking/BookingMessagesWidget"
import { BackButton } from "../../../components/commons/button"
import CompanyInfoMobile from "wakacje-web/src/shared/company/widgets/CompanyInfoMobile"
import axios from "axios"
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import { InquiryMessagePopup } from "wakacje-web/src/components/Messages/InquiryMessagePopup"

const ChatPageInner = ({ chatHash }) => {
  // const router = useRouter()
  const params = useParams()
  const { auth } = useAuth()
  const [loading, setLoading] = useState(false)
  const [booking, setBooking] = useState<any>()
  const [company, setCompany] = useState<any>()
  const [isMobileInfoOpen, setIsMobileInfoOpen] = useState(false)
  // const parts = params.id.split("_")
  const id = params.id.replace("chats/", "")
  const url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/messages/v1/chat/${chatHash}`

  const [{ data }, refresh] = useAxios(url, { useCache: false })

  const handleSaved = () => {
    refresh()
  }
  const refreshData = async () => {
    if (!auth.currentUser) {
      return
    }
    const url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/inquiries/v1/${id}`

    setLoading(true)
    try {
      let res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${auth.currentUser.accessToken}`
        }
      })

      setCompany(res.data.company)
      setBooking(res.data)
    } catch (err) {
      console.error(
        "Error loading Chats"
      )
    }
    setLoading(false)
  }
  useEffect(() => {
    refreshData()
  }, [])

  return (
    <>
      {loading && (
        <div className="text-center">
          <div className="mb-6 text-xl font-bold p-10">Wczytuję wiadomości</div>
          <CircularProgress />
        </div>
      )}

      {!loading && (
        <div className="fixed top-[64px] bottom-0  px-10">
          <div className="">
            <div className="flex-1 h-full flex flex-col">
              <div className="py-7">
                <BackButton destination="inquiries" />
              </div>
              <div>
                <CompanyInfoMobile
                  userChatId={id}
                  company={company}
                  setIsMobileInfoOpen={setIsMobileInfoOpen}
                  product={booking}
                />
              </div>
              <div className="lg:h-[calc(100vh-52px)] lg:max-h-[70vh]">             
              <ChatMessagesList
                userId={booking?.user_id}
                accessToken={auth?.currentUser?.accessToken}
                chatHash={params.id}
                companyId={company?.id}
                mode="company"
                rootUrl={process.env.REACT_APP_WAKACJE_HTTP_API_URL}
                detailsRenderer={undefined}
                booking={undefined}
              />
              </div>
            </div>

            {/* Widget column */}
          </div>
          {isMobileInfoOpen && (
            <InquiryMessagePopup
              open={isMobileInfoOpen}
              setOpenParticipant={() => setIsMobileInfoOpen(false)}
              admin
              data={booking}
            />
          )}
        </div>
      )}
    </>
  )
}

export const ChatPage = () => {
  const params = useParams()
  return (
    <div className="p-8 pt-[64px]">
      <ChatPageInner chatHash={params.id} />
    </div>
  )
}

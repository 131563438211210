import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"

export interface CompanyHomeContextType {
  unreadMessagesCount?: number
}

export const CompanyHomeContext = createContext<CompanyHomeContextType>({
  unreadMessagesCount: 0
})

export const CompanyHomeContextProvider = ({ children }: { children: any }) => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)

  const onEventBusEvent = useCallback((event) => {
    if (event.detail?.payload?.unread_messages_count !== undefined) {
      setUnreadMessagesCount(event.detail?.payload?.unread_messages_count)
    }
    // loadNextPage()
  }, [])

  useEffect(() => {
    document.addEventListener("Home", onEventBusEvent)
  }, [])

  useEffect(() => {
    // Access the user session on the client
    // Auth.currentAuthenticatedUser()
    //   .then(async (user) => {
    //     setCognitoUser(user)
    //     const rehydrated = await _rehydrate()
    //     // const currentUser = await refetchCurrentUser()
    //     setIsInitializationCompleted(true)
    //     refetchCurrentUser()
    //   })
    //   .catch((err) => {
    //     setCurrentUser(null)
    //     setIsInitializationCompleted(true)
    //   })
  }, [])

  const refreshHomeData = useCallback(async () => {
    // let meUrl = process.env.NEXT_PUBLIC_WAKACJE_API_URL + `/v1/home`
    // if (userApp) {
    //   return
    // }
    // try {
    //   var result = await axios({
    //     method: "GET",
    //     url: meUrl,
    //   })
    //   if (result && result.status == 200) {
    //     setHomeData(result.data)
    //   } else {
    //     setHomeData(undefined)
    //   }
    // } catch (err) {
    //   console.error("Error get homedata")
    // }
  }, [])

  const contextValue = {
    unreadMessagesCount,
    a: 1
  }
  return (
    <CompanyHomeContext.Provider value={contextValue}>
      {children}
    </CompanyHomeContext.Provider>
  )
}

export const useCompanyHomeContext = (): CompanyHomeContextType =>
  useContext(CompanyHomeContext) as CompanyHomeContextType

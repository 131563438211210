import {
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select
} from "@mui/material"
import { Tag } from "../../../components/Tag/Tag"

interface TagSelectDropdownProps {
  setValue: any
  value: any
  items: ItemsType[]
  label: string
  labelId: string
  placeholder: string
  fieldIdentifier: string
  trigger?: any
}

type ItemsType = {
  id: string
  name: string
  iconSrc: string
}

const TagSelectDropdown = ({
  setValue,
  value,
  items,
  label,
  labelId,
  placeholder,
  fieldIdentifier,
  trigger
}: TagSelectDropdownProps) => {
  const selectedCategories = value

  const options = items.map((item) => (
    <MenuItem value={item.id} key={item.id}>
      <Checkbox checked={selectedCategories?.indexOf(item.id) > -1} />
      <ListItemText primary={item.name} />
    </MenuItem>
  ))

  const handleRemoveCategory = (selectedCategory) => {
    const newCategoriesArr = selectedCategories.filter(
      (c) => c !== selectedCategory
    )
    setValue(fieldIdentifier, newCategoriesArr)
    trigger && trigger(fieldIdentifier)
  }

  const handleChange = (event) => {
    setValue(fieldIdentifier, event.target.value)
    trigger && trigger(fieldIdentifier)
  }

  return (
    <>
      {selectedCategories && selectedCategories.length > 0 && (
        <div className="flex gap-3 mb-6 flex-wrap">
          {selectedCategories.map((selectedCategory, index) => (
            <Tag
              key={index}
              label={items.find((item) => item.id === selectedCategory)?.name}
              onClick={() => handleRemoveCategory(selectedCategory)}
              iconSrc={
                items.find((item) => item.id === selectedCategory)?.iconSrc
              }
            />
          ))}
        </div>
      )}

      <FormControl sx={{ minWidth: 120 }} fullWidth>
        <InputLabel id={labelId} htmlFor="grouped-native-select">
          {label}
        </InputLabel>
        <Select
          fullWidth
          labelId={labelId}
          multiple
          label={label}
          onChange={handleChange}
          value={selectedCategories || []}
          renderValue={() => {
            return placeholder
          }}
        >
          {options}
        </Select>
      </FormControl>
    </>
  )
}

export default TagSelectDropdown

import {
  DescriptionList,
  InputField,
  InputCheck
} from "../../../../components/FormCommons"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import { useIntl } from "react-intl"
import { Controller } from "react-hook-form"
import { format } from "date-fns"

export const ProductSectionSuperAdminForm = ({
  register,
  control,
  watch
}: any) => {
  const admin_approved_at = watch("admin_approved_at")
  console.log("admin_approved_at", admin_approved_at)
  return (
    <>
      <Controller
        name={"admin_approved_at"}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          var val =
            value != null && value.length > 10 ? value.substr(0, 10) : value
          return (
            <InputField
              type="date"
              onChange={onChange}
              value={val}
              InputLabelProps={{ shrink: true }}
              label="Data akceptacji"
              id={`admin_approved_at`}
            />
          )
        }}
      />
      <InputCheck
        control={control}
        label="Pokaż na stronie głównej"
        name="admin_show_on_home_page"
      />
      <InputCheck
        control={control}
        label="Promuj na stronie głównej"
        name="admin_promote_on_home_page"
      />
      <InputField
        {...register("admin_comment")}
        InputLabelProps={{ shrink: true }}
        label="Uwagi administratora"
        id={`admin_comment`}
      />
    </>
  )
}

export const ProductSectionSuperAdminPreview = ({ values }: any) => {
  const intl = useIntl()
  return (
    <div>
      <DescriptionList
        data={{
          "Data Akceptacji Admina": values.admin_approved_at
            ? intl.formatDate(values.admin_approved_at)
            : "",
          "Pokaż na stronie głównej": values.admin_show_on_home_page
            ? "Tak"
            : "Nie",
          "Promuj na stronie głównej": values.admin_promote_on_home_page
            ? "Tak"
            : "Nie",
          "Uwagi administratora": values.admin_comment
        }}
      />
    </div>
  )
}

export const ProductSectionSuperAdminView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var productData: any = {
      id: data.id,
      admin_approved_at: data.admin_approved_at,
      admin_show_on_home_page: data.admin_show_on_home_page,
      admin_promote_on_home_page: data.admin_promote_on_home_page,
      admin_comment: data.admin_comment
    }
    console.log("new product data", data)
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  //   const val
  const val = {
    ...values
    // admin_approved_at: values.admin_approved_at
    //   ? parseISO(values.admin_approved_at)
    //   : undefined,
  }
  return (
    <EditableFormSection
      title="Opcje administratora (Oferta)"
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      styles={"shadow rounded-lg"}
    >
      {(builderProps: any) => {
        console.log("Builder props", builderProps)
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionSuperAdminForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionSuperAdminPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import axios from 'axios'
import { ProductUtils } from '../../../utils/ProductUtils'
import { isAfter } from 'date-fns'
import { usePaymentContext } from '../../payments/PaymentsContext'
import CheckIcon from '@mui/icons-material/Check'
import clsx from 'clsx'
import { useState } from 'react'
import { useAuth } from '../../../auth/AuthContext'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useProductUtils } from '../../../hooks/useProductUtils'
import { useCurrency } from '../../../hooks/useCurrency'
export const BookingPaymentCard = ({
  label,
  value,
  dueDate,
  showPay,
  booking,
  paymentType,
  paidAt
}: {
  label: string
  value: any
  dueDate: any
  showPay?: boolean
  booking?: any
  paymentType: string
  paidAt?: string
}) => {
  const isOverdue = true
  // isAfter(new Date(), ProductUtils.parseDateYYYYMMDD(dueDate)) && !paidAt
  const [isSaving, setIsSaving] = useState(false)
  const paymentContext = usePaymentContext()
  const { auth, user } = useAuth()
  const { t } = useTranslation('common')
  var isPaid = !!paidAt

  const { formatPriceInCents } = useProductUtils()
  const { selectedCurrency } = useCurrency()

  console.log('Booking paymnet', label, dueDate)

  const handleStartPayment = async () => {
    const redirectHost = window.location.protocol + '//' + window.location.host
    var url =
      process.env.NEXT_PUBLIC_WAKACJE_HTTP_API_URL +
      `/bookings/v2.0/bff/user/pay-booking/${booking.id}`

    console.log('handleStartPayment', booking, paymentContext)
    const request = {
      booking_id: booking.id,
      redirect_host: redirectHost,
      payment_type: paymentType,
      selected_currency: selectedCurrency.currency
    }
    var options: any = {}
    if (auth.currentUser) {
      options.headers = {
        Authorization: `Bearer ${auth.currentUser.accessToken}`
      }
    }
    setIsSaving(true)
    try {
      var res = await axios.post(url, request, options)
      setIsSaving(false)
      if (res.status === 200) {
        console.log('Go payment', res.data)
        if (res.data.success && res.data.data) {
          const payment = res.data.data.payment
          if (payment.url) {
            var w = window.open(payment.url, '_self')
          }
        }
      }
    } catch (err) {
      setIsSaving(false)
    }
  }
  console.log('paidAt', paidAt)
  // showPay = true

  var circleBgColor = '#F3F7FE'
  // isOverdue
  // ? " bg-[#FFE4E3] text-[#FD4943] "
  // : " bg-[#F3F7FE]   text-fc-primary "
  return (
    <div className='flex flex-row my-4 w-full'>
      <div
        className={`w-[48px] h-[48px] rounded-full mr-4 
      ${clsx({
        'bg-[#FFE4E3] text-[#FD4943] ': isOverdue && !isPaid,
        'bg-[#EFF6E7] text=[#5CAA0B]': isPaid
      })}
      
      
      text-center 
      text-2xl font-bold
      flex items-center justify-center
        `}
      >
        <div>
          {isPaid ? (
            <>
              <CheckIcon />
            </>
          ) : (
            <>{isOverdue ? '!' : '$'}</>
          )}
        </div>
      </div>

      <div>
        <div>
          <strong>{label}:</strong>{' '}
          {formatPriceInCents(value, booking?.currency)}{' '}
          {ProductUtils.getCurrencyCode(selectedCurrency.currency)}
        </div>
        {!isPaid && (
          <div className='text-gray-500'>
            {t('account.date_of_payment', 'Termin płatności')}:{' '}
            {ProductUtils.formatDate(dueDate)}
          </div>
        )}
        {paidAt && (
          <div className='text-gray-500'>
            {t('account.paid', 'Opłacono')}:{' '}
            {ProductUtils.formatIsoToDDMMYYY(paidAt)}
          </div>
        )}
      </div>

      {showPay && !isPaid && (
        <div className='ml-2 px-4 text-center'>
          <div
            style={{
              background: 'var(--fc-color-1-red)'
            }}
            className='rounded cursor-pointer text-white font-bold text-sm px-4 py-2'
            onClick={handleStartPayment}
          >
            {isSaving ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <div>{t('account.pay_up', 'Opłać')}</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

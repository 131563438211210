import { Button, Typography } from "@mui/material"
import styled from "@emotion/styled"
import Images from "../../../assets"

let BoxDiv = styled.div({
  backgroundColor: "rgba(12, 72, 215, 0.02)",
  height: 0,
  paddingTop: "20%",
  padding: "20%",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
})
let ImgDiv = styled.div({
  display: "flex",
  justifyContent: "center",
})
let ContentDiv = styled.div({
  display: "grid",
  justifyContent: "center",
  paddingTop: "4%",
})
export default function AddFirstProductCard({ onClick }: { onClick: any }) {
  return (
    <BoxDiv>
      <ImgDiv>
        <img src={Images.EventImg} alt="" />
      </ImgDiv>
      <ContentDiv>
        <Typography variant="h6" component="h1" sx={{ fontWeight: "bold" }}>
          Dodaj swoje pierwsze wydarzenie
        </Typography>
        <Button
          className="mx-20 my-10 rounded-lg "
          variant="contained"
          type="submit"
          size="large"
          onClick={onClick}
        >
          Utwórz nowe wydarzenie
        </Button>
      </ContentDiv>
    </BoxDiv>
  )
}

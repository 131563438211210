import {useSeoCategoryApi} from "./useSeoCategoryApi";
import {useCallback, useEffect, useReducer, useState} from "react";
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button
} from "@mui/material"
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useNavigate} from "react-router-dom";
import {useArticleApi} from "./useArticlesApi";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import {array} from "yup";

function sortNodesByPosition(arr) {
  arr.sort((a, b) => {
    if (a.position_in_tree === null && b.position_in_tree === null) {
      // If both have null position_in_tree, compare by id
      return a.id - b.id;
    } else if (a.position_in_tree === null) {
      // If a has null position_in_tree, it comes after b
      return 1;
    } else if (b.position_in_tree === null) {
      // If b has null position_in_tree, it comes after a
      return -1;
    } else {
      // Compare by position_in_tree
      return a.position_in_tree - b.position_in_tree;
    }
  });

  return arr;
}

const EditButton = ({id}: {id: string}) => {
    const navigate = useNavigate();
    return (

        <Button
            className="border-2"
            startIcon={<DriveFileRenameOutlineIcon/>}
            size='small'
            variant='text'
            onClick={() => navigate('/super/articles/' + id)}
        >edytuj</Button>
    )
}

const SEONode = ({tree, parent = null}: {tree: array, parent?: number}) => {
  const {updateArticle} = useArticleApi()

    const changePositionInTree = (moveUp, itemId) => {
      if (true) {
        return
      }
      const firstArticle = tree.find((item) => item.id === itemId);
      const firstArticlePosition: number = firstArticle.position_vs_siblings;
      if (moveUp) {
        const secondArticle = tree.find((item) => item.positon_vs_siblings === firstArticlePosition - 1)
        const secondArticlePosition = secondArticle.position_vs_siblings;
        const firstArticleUpdated = {
          ...firstArticle,
          position_vs_siblings: firstArticlePosition - 1,
        };
        const secondArticleUpdated = {
          ...secondArticle,
          position_vs_siblings: secondArticlePosition + 1,
        };
        updateArticle(firstArticleUpdated)
        updateArticle(secondArticleUpdated)
      } else {
        const secondArticle = tree.find((item) => item.positon_vs_siblings === firstArticlePosition + 1);
          const firstArticleUpdated = {
            ...firstArticle,
            position_vs_siblings: firstArticlePosition + 1,
          }
        if (secondArticle) {
          const secondArticlePosition = secondArticle.position_vs_siblings;
          const secondArticleUpdated = {
            ...secondArticle,
            position_vs_siblings: secondArticlePosition - 1,
          };
          updateArticle(secondArticleUpdated)
        }
        updateArticle(firstArticleUpdated)
      }
      // if moveUp is true we want to change its index position vs siblings to smaller
      //otherwise we want to change its index position to bigger
      // we have to update 2 records in database each time we make a change
    };
    const getBranchByParent = (parentId, tree) => {
        return tree.filter((item) => {
            return item.parent_id === parentId;
        });
    }

    let branch = getBranchByParent(parent, tree);
     branch = sortNodesByPosition(branch)


    // branch.length always represents the length of a single level of a tree
    //get property position_vs_siblings to check what kind of icon to show - expand or close

    return (
      <>
        {branch.length > 0 &&
          branch?.map((item) => (
            <FormGroup className="my-3 ml-10" key={item.id}>
              <div className="flex items-center">
                {/* {item.position_vs_siblings === 0 && ( */}
                  {true && (
                  <div>
                    <motion.div
                      initial={{ rotate: 180 }}
                      animate={{ rotate: 0 }}
                    >
                      <IconButton  onClick={() => {changePositionInTree(false, item.id)}}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </motion.div>
                  </div>
                )}
                {item.position_vs_siblings > 0 &&
                  item.position_vs_siblings < branch.length - 1 && (
                    <div>
                      <motion.div
                        initial={{ rotate: 180 }}
                        animate={{ rotate: 180 }}
                      >
                        <IconButton  onClick={() => {changePositionInTree(true, item.id)}}>
                          <ExpandMoreIcon />
                        </IconButton>
                      </motion.div>
                      <motion.div
                        initial={{ rotate: 180 }}
                        animate={{ rotate: 0 }}
                      >
                        <IconButton  onClick={() => {changePositionInTree(false, item.id)}}>
                          <ExpandMoreIcon />
                        </IconButton>
                      </motion.div>
                    </div>
                  )}
                {item.position_vs_siblings === branch.length - 1 && (
                  <div>
                    <motion.div
                      initial={{ rotate: 180 }}
                      animate={{ rotate: 180 }}
                    >
                      <IconButton  onClick={() => {changePositionInTree(true, item.id)}}>
                        <ExpandMoreIcon />
                      </IconButton>
                    </motion.div>
                  </div>
                )}
                <div>{item.title}</div>
                <EditButton id={item.id} />
              </div>
              {getBranchByParent(item.id, tree).length > 0 && (
                <SEONode tree={tree} parent={item.id} />
              )}
            </FormGroup>
          ))}
      </>
    );
}

const SEOTree = ({queryParams, initialSort}: any) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState(false);

    const { listArticles } = useArticleApi()
    queryParams.type = 'seo';


    // const data = [
    //     {id: 1, title: "ok", parent_id: null},
    //     {id: 2, title: "child", parent_id: 1},
    //     {id: 3, title: "grand child", parent_id: 2},
    //     {id: 4, title: "ok2", parent_id: null},
    //     {id: 5, title: "child2", parent_id: 1},
    // ];
    useEffect(() => {
        loadSeo()
    }, [state]);
    const loadSeo = useCallback(async () => {
        // const seoData= listSeoCategories()?.data;
        setLoading(true)
        try {

            const result = await listArticles(queryParams, false);
            const resultSortedById = result.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

            setData(resultSortedById)
        } catch (err) {
            console.error("Erro ", err)
            setLoading(false)
        }


    }, [state])

    // console.log('data xxx: ', data);
    // console.log('data len: ', data.length);
    // const getDump = (d: any) => {
    //     let s = '';
    //     if (d.id) { s += ' id: ' + d.id; }
    //     if (d.title) { s += ' title: ' + d.title; }
    //     return s;
    // }


    return (
        <div className='flex flex-col my-10'>
            <SEONode tree={data} parent={null}/>
    </div>
    )
}

export default SEOTree
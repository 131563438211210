import axios from "axios"
import useAxios from "axios-hooks"

export const usePlacesApi = () => {
  const createPlace = async (data: any) => {
    const command = {
      type: "Place.Create",
      aggregateId: data.id,
      payload: {
        ...data
      }
    }
    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/places/${data.id}`
    var res = await axios.post(url, command)
    return res.data
  }

  const updatePlace = async (data: any) => {
    const command = {
      type: "Place.Update",
      aggregateId: data.id,
      payload: {
        ...data
      }
    }

    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/places/${data.id}`
    var res = await axios.post(url, command)
    return res.data
  }

  const deletePlace = async (id: string) => {
    const command = {
      type: "Place.Delete",
      aggregateId: id,
      payload: {}
    }

    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/places/${id}`
    var res = await axios.post(url, command)
    return res.data
  }

  const runCommand = async (command: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/v1/places/${command.aggregateId}`
    var res = await axios.post(url, { command })
    return res.data
  }

  const listPlaces = async (query = {}, pagination: any) => {
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + `/v1/places?${searchParams}`
    var res = await axios.get(url)
    return res.data
  }
  return {
    createPlace,
    updatePlace,
    deletePlace,
    listPlaces,
    runCommand
  }
}

export const usePlace = (id: string) => {
  var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/places/${id}`
  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch, error }
}

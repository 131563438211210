import React, { useEffect, useState } from "react"
import Popover from "@mui/material/Popover"
import TabsUnstyled from "@mui/base/TabsUnstyled"
import TabsListUnstyled from "@mui/base/TabsListUnstyled"
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled"
import TabUnstyled from "@mui/base/TabUnstyled"
import ItemPhoto from "./itemPhoto"
import { photoLiblaryProps } from "../../pages/sandbox/SandboxTesting"
import SelectPhotoCrop from "./SelectPhotoCrop"
import Dropzone from "react-dropzone"
import { useTranslation } from "react-i18next"

export const photoLiblary: photoLiblaryProps[] = [
  { url: "/assets/gallery/1.jpeg" },
  { url: "/assets/gallery/2.jpeg" },
  { url: "/assets/gallery/3.jpeg" },
  { url: "/assets/gallery/4.jpeg" },
  { url: "/assets/gallery/5.jpeg" },
  { url: "/assets/gallery/6.jpeg" },
  { url: "/assets/gallery/7.jpeg" },
  { url: "/assets/gallery/8.jpeg" },
  { url: "/assets/gallery/9.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" },
  { url: "/assets/gallery/10.jpeg" }
]
export interface OpenCropProps {
  open?: boolean | false
  url?: string | ""
  file?: any
}

export const SelectPhotoGalleryPopup = ({
  anchorEl,
  open,
  onClosePopup,
  anchorOrigin,
  transformOrigin,
  onPhotoUploaded
}: {
  anchorEl: HTMLElement
  open: boolean
  onClosePopup: React.Dispatch<any>
  anchorOrigin?: {
    vertical: "top" | "center" | "bottom" | number
    horizontal: "left" | "center" | "right" | number
  }
  transformOrigin?: {
    vertical: "top" | "center" | "bottom" | number
    horizontal: "left" | "center" | "right" | number
  }
  onPhotoUploaded?: any
}) => {
  const [valueInputFile, setValueInputFile] = useState("Upuść zdjęcie")
  const [openCrop, setOpenCrop] = useState<OpenCropProps>({} as OpenCropProps)
  const [currentTab, setCurrentTab] = useState(0)
  const [selectedFile, setSelectedFile] = useState<any>()
  const { t } = useTranslation()

  useEffect(() => {
    console.log("Open changed", open)
    setCurrentTab(0)
    setOpenCrop(undefined)
  }, [open])

  const handleCropped = async (croppedFile) => {
    console.log("handleCropped", croppedFile)
    onPhotoUploaded([croppedFile])
    setOpenCrop(undefined)
  }
  const handleDrop = async (event: any) => {
    console.log("Photo selected", event)
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      console.log("res", fileReader.result)
      setOpenCrop({
        url: fileReader.result as any,
        file: event[0]
      })
      // this.setState({src: fileReader.result })
    }
    fileReader.readAsDataURL(event[0])

    // onPhotoUploaded(event)
  }
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      // anchorOrigin={{
      //   vertical: anchorOrigin.vertical,
      //   horizontal: anchorOrigin.horizontal
      // }}
      // transformOrigin={{
      //   vertical: transformOrigin.vertical,
      //   horizontal: transformOrigin.horizontal
      // }}
      className="flex justify-center items-center"
      sx={{
        ".MuiPopover-paper": {
          left: "auto !important",
          top: "auto !important"
        }
      }}
    >
      <div className="w-full h-full py-5 pb-4">
        <div className="w-full h-full overflow-hidden">
          <p className="w-[80%] mx-auto font-lato">
            Wybierz grafikę z dostępnej dla Ciebie biblioteki lub dodaj własne
            zdjęcie
          </p>

          {!openCrop && (
            <>
              <TabsUnstyled
                defaultValue={currentTab}
                value={currentTab}
                className="mt-8"
              >
                <TabsListUnstyled className="border-b-[1px] border-[#BABABA]">
                  <div className="w-[80%] mx-auto font-lato">
                    <TabUnstyled
                      onClick={() => setCurrentTab(0)}
                      className={
                        currentTab === 0 ? "tabs-active" : "tabs-unactive"
                      }
                    >
                      Dodaj plik
                    </TabUnstyled>
                    <TabUnstyled
                      onClick={() => setCurrentTab(1)}
                      className={
                        currentTab === 1 ? "tabs-active" : "tabs-unactive"
                      }
                    >
                      Biblioteka
                    </TabUnstyled>
                  </div>
                </TabsListUnstyled>
                <div className="w-[80%] mx-auto h-[285px] py-4 overflow-y-scroll hidden-scrollbar mt-[4px]">
                  <TabPanelUnstyled value={0} className="h-full">
                    <form className="h-full">
                      <label
                        htmlFor="files"
                        className="w-full h-[242px] flex justify-center items-center border-[1px] border-dashed border-fc-gray-4 text-fc-gray-4 cursor-pointer"
                      >
                        <Dropzone
                          onDrop={async (acceptedFiles: any) =>
                            handleDrop(acceptedFiles)
                          }
                        >
                          {({ getRootProps, getInputProps }: any) => {
                            return (
                              <div
                                {...getRootProps()}
                                style={{ width: "100%", height: "100%" }}
                                className="w-full h-full flex align-center justify-center"
                              >
                                <input {...getInputProps()} />
                                <div>Upuść zdjęcie</div>
                              </div>
                            )
                          }}
                        </Dropzone>
                        {/*       
                      <input
                        name="Image"
                        id="files"
                        type="file"
                        className="custom-file-input w-full absolute text-transparent outline-none h-[50%]"
                        autoFocus={false}
                        onChange={(e) =>
                          setValueInputFile(e.currentTarget.value)
                        }
                      />
                      {valueInputFile === "" ? "Upuść zdjęcie" : valueInputFile} */}
                      </label>
                    </form>
                  </TabPanelUnstyled>
                  <TabPanelUnstyled
                    value={1}
                    className="columns-3 md:columns-4 lg:columns-5 space-y-3 md:space-y-4"
                    // className="grid grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-4 relative"
                  >
                    {photoLiblary?.map((photo) => (
                      <ItemPhoto
                        key={photo.url}
                        url={photo.url}
                        onPhotoSelected={setOpenCrop}
                      />
                    ))}
                  </TabPanelUnstyled>
                </div>
              </TabsUnstyled>
            </>
          )}
          {openCrop && (
            <SelectPhotoCrop
              openCrop={openCrop}
              removePhotoSelected={setOpenCrop}
              cropRatio={16 / 9}
              onCancel={(e: any) => setOpenCrop(undefined)}
              onCropped={handleCropped}
            />
          )}
          <div className="w-full h-[50px] shadow-[0_-5px_15px_-5px_#E7E7E7;] pt-3">
            <div className="w-[80%] h-full mx-auto flex justify-between items-center">
              <p
                className="text-fc-accent-3 cursor-pointer"
                onClick={() => onClosePopup(null)}
              >
                {t("back", "Powrót")}
              </p>
              <button className="text-white px-5 py-1 bg-fc-accent-3 rounded-lg">
                {t("select", "Wybierz")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popover>
  )
}

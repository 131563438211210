import axios from "axios"
import useAxios from "axios-hooks"
import { useState } from "react"

export const usePosterCommand = (): [Function, boolean] => {
  const [isRunning, setIsRunning] = useState(false)

  const runCommand = async (command) => {
    setIsRunning(true)
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/posters/v1.0/poster-orders/${command.aggregateId}`
    var res = await axios.post(url, { command })
    setIsRunning(false)
    return res.data
  }

  return [runCommand, isRunning]
}
export const usePosterOrder = (id: string) => {
  var url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL +
    `/posters/v1.0/poster-orders/${id}`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch }
}

export const usePosterOrdersApi = () => {
  const list = async (query = {}, pagination: any) => {
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/posters/v1.0/poster-orders/?${searchParams}`
    var res = await axios.get(url)
    return res.data
  }

  return { list }
}

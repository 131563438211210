import { IconButton } from "@mui/material"
import React, { useState } from "react"
import { useFileUploadedContext } from "../../contexts/FileUploadedContext"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { MojeButton } from "../../components/MojeButton"
import Animate from "../../components/Animate"

function SideAttachedFile() {
  const { fileUploaded } = useFileUploadedContext()
  const [seeMore, setSeeMore] = useState(false)
  let fileLists = seeMore ? fileUploaded : fileUploaded.slice(0, 3)

  return (
    <>
      <div className="my-8 flex justify-between">
        <p className="font-bold">Multimedia i pliki</p>
        <p className="text-[color:var(--fc-color-accent-4)]">
          {fileUploaded.length}
        </p>
      </div>
      {fileLists.map((uf, i) => {
        return (
          <Animate type="fade-in" key={i}>
            <div className="flex flex-row items-center justify-between mb-3">
              <div className="flex gap-2 items-center">
                <IconButton color="primary" style={{ background: "#f3f6fd" }}>
                  <DescriptionOutlinedIcon color="primary" />
                </IconButton>
                <div>
                  <p className="font-bold w-[200px] text-sm text-[color:var(--fc-color-accent-4)] truncate">
                    {uf.name}
                  </p>
                </div>
              </div>

              <div className="flex gap-3">
                <IconButton color="primary">
                  <FileDownloadOutlinedIcon color="primary" />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={() =>
                    window.open(`https://${uf.bucket}/${uf.key}`, "_blank")
                  }
                >
                  <VisibilityOutlinedIcon color="primary" />
                </IconButton>
              </div>
            </div>
          </Animate>
        )
      })}

      {fileUploaded.length > 3 && (
        <MojeButton
          variant="text"
          onClick={() => {
            setSeeMore(!seeMore)
          }}
        >
          {seeMore ? "Zobacz mniej" : "Zobacz więcej"}
        </MojeButton>
      )}
    </>
  )
}

export default SideAttachedFile

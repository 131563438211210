import { Routes, Route } from "react-router-dom"
import { Box } from "@mui/system"
import { ApiTable } from "../../../components/table/ApiTable"
import { FormattedDate, FormattedTime } from "react-intl"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"
import axios from "axios"

const listLeads = async (data) => {
  var url = process.env.REACT_APP_WAKACJE_API_URL + `/super/v1.0/leads`
  data = data ?? {}
  data.view = "super"
  if (data) {
    url += "?" + new URLSearchParams(data).toString()
  }
  var res = await axios.get(url)
  return res.data
}

const SuperLeadsTable = () => {
  const { setSearchTextDebounced, queryParams } = useSearchText()

  const columns = [
    {
      header: "Utworzony",
      cell: (row: any) => (
        <div>
          <FormattedDate value={row.created_at} />{" "}
          <FormattedTime value={row.created_at} />
        </div>
      ),
      align: "right",
      field: "created_at"
    },
    {
      header: "Email",
      field: "email",
      cell: (row: any) => <div>{row.email}</div>
    },
    {
      header: "Telefon",
      field: "phone",
      cell: (row: any) => <div>{row.phone}</div>
    },
    {
      header: "Nazwa",
      field: "name",
      cell: (row: any) => <div>{row.name}</div>
    },
    {
      header: "Wiadomosc",
      field: "message",
      cell: (row: any) => <div>{row.message}</div>
    },
    {
      header: "Campaign",
      field: "campaign",
      cell: (row: any) => <div>{row.campaign}</div>
    }
  ]

  return (
    <>
      <Toolbar
        setSearchTextDebounced={setSearchTextDebounced}
        api={listLeads}
      />
      <ApiTable
        columns={columns}
        listApi={listLeads}
        showExpandColumn
        //   rowLinkBuilder={(row: any) => `/super/bookings/${row.id}`}
        queryParams={queryParams}
      ></ApiTable>
    </>
  )
}

export const SuperAdminLeads = () => {
  return (
    <Box width="100%">
      <Routes>
        <Route index element={<SuperLeadsTable />} />
      </Routes>
    </Box>
  )
}

import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Typography } from "@mui/material"
import { LoginByEmailForm } from "./LoginByEmailForm"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { ResetPasswordForm } from "wakacje-web/src/components/Registration/ResetPasswordForm"

export const LoginForm = (props: any) => {
  const [loginMode, setLoginMode] = useState(
    props.register ? "signup" : "signin"
  )
  const navigate = useNavigate()

  const handleClickResetPassword = () => {
    setLoginMode("resetPassword")
  }

  return (
    <Box
      component="section"
      className="w-[90%]  md:w-[440px]"
      marginX="auto"
      pt={4}
    >
      <Stack>
        <Box>
          <Typography
            variant="h4"
            component="h1"
            fontWeight="bold"
            textAlign="center"
            gutterBottom
          >
            {loginMode === "signin" && "Logowanie"}
            {loginMode === "resetPassword" && <span>Przypomnij hasło</span>}
          </Typography>
        </Box>
        {/* <Box>Monkey's Time</Box> */}
        {
          loginMode === "resetPassword" && <ResetPasswordForm />
          // <ForgotPasswordForm
          //   onGotoLogin={() => {
          //     setLoginMode("signin")
          //   }}
          //   loginMode={loginMode}
          // />
        }
        {/* {loginMode == "NEW_PASSWORD_REQUIRED" && (
              <ResetTemporaryPasswordForm tmpUser={tmpUser} />
        )} */}
        {loginMode === "confirmation" && <div>Potwierdź e-mail</div>}
        {/* {loginMode === "confirmation" && (
          <ConfirmationForm
            onConfirmed={() => {
              setLoginMode("signin")
            }}
          />
        )}*/}
        {(loginMode === "singup" || loginMode === "signin") && (
          <LoginByEmailForm
            loginMode={loginMode}
            onClickResetPassword={handleClickResetPassword}
          />
        )}
        {loginMode !== "signup" && (
          <>
            <Box sx={{ borderBottom: "1px solid #C4C4C4", marginY: 2 }} />
            <Typography
              fontSize="1.25rem"
              fontWeight="bold"
              textAlign="center"
              gutterBottom
            >
              Nie masz konta?
            </Typography>
            <Typography textAlign="center" gutterBottom>
              Zarejestruj się w serwisie aby fajnie spędzać czas
            </Typography>
            <Button
              sx={{
                fontWeight: "normal",
                marginBottom: 1
              }}
              onClick={() => navigate("/rejestracja")}
            >
              Załóż konto
            </Button>
          </>
        )}
      </Stack>
    </Box>
  )
}

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@mui/material"
import Heading from "wakacje-web/src/shared/components/Heading"
import ItemSearch from "./ItemSearch"
import ItemLists from "./ItemLists"
import { useEffect, useState } from "react"
import { AddPlaceForm } from "../../../places/AddPlace"
import Animate from "wakacje-web/src/components/Animate"
import { useTranslation } from "react-i18next"

export function PlaceOrAccommodationModal({
  type,
  open,
  handleClose,
  disabledItems,
  onItemsSelected,
  handleDeleteItem
}) {
  const { t } = useTranslation()

  const [search, setSearch] = useState(undefined)
  const [showForm, setShowForm] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  const isAccommodation = type === "accommodation"

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 580,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    height: "500px"
  }

  useEffect(() => {
    setShowForm(false)
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {isAccommodation
          ? t("ui.attractions.holiday_lettings_base", "Baza noclegów")
          : t("ui.attractions.attractions_base", "Baza atrakcji")}
      </DialogTitle>
      <DialogContent>
        {/* <Box sx={style}> */}

        {!showForm && (
          <>
            <ItemSearch setSearch={setSearch} />
            <div className="flex justify-end mt-5">
              <Button
                className="mx-4"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => setShowForm(true)}
              >
                {t("ui.attractions.add_new", "Dodaj nową")}
              </Button>
            </div>
          </>
        )}

        {showForm && (
          <div className="">
            <Animate type="fade-in">
              <div className="">
                <AddPlaceForm
                  type={isAccommodation ? "accommodation" : "place"}
                  submitRedirect={false}
                  setShowForm={setShowForm}
                />
              </div>
            </Animate>
          </div>
        )}

        {!showForm && (
          <div className="  mt-6">
            <ItemLists
              type={type}
              search={search}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              handleDeleteItem={handleDeleteItem}
            />
          </div>
        )}

        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        {!showForm && (
          <div className="flex flex-row mt-10 gap-10 justify-end p-2">
            <div>
              <Button
                onClick={() => {
                  handleClose()
                  setSelectedItems([])
                }}
                variant="text"
                color="primary"
              >
                {t("ui.attractions.close_button", "Anuluj")}
              </Button>
            </div>

            <div>
              <Button
                onClick={() => {
                  onItemsSelected([...selectedItems])
                  setSelectedItems([])
                  handleClose()
                }}
                variant="contained"
                color="primary"
                size="large"
              >
                {t("ui.attractions.select_button", "Wybierz")}
              </Button>
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default PlaceOrAccommodationModal

import { useProductApi } from "./api/useProductApi"
import { TripsTable } from "./TripsTable"
import { Box } from "@mui/system"

export default function FutureTripsIndex() {
  const { listTrips } = useProductApi()

  return (
    <Box>
      {/* Uncompleted event */}
      {/* <InfoCard
        className="m-8"
        variant="blue"
        title="Niewypelnione wydarzenie"
        actionLabel="Dokoncz"
        onClick={undefined}
      >
        <Typography variant="h6" component="p" fontWeight="bold" fontSize={18}>
          Aktywnie na Kaszubach
        </Typography>
      </InfoCard> */}
      <Box sx={{ padding: [2, null, null, 4] }}>
        <TripsTable listApi={listTrips} showProduct />
      </Box>
    </Box>
  )
}

import {
  Button,
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
  CircularProgress
} from "@mui/material"
import { OverridableStringUnion } from "@mui/types"
export const MojeButton = ({
  disabled,
  isSaving,
  type,
  variant,
  color,
  fullWidth,
  label,
  children,
  onClick,
  className,
  textSize,
  style,
  endIcon
}: {
  disabled?: boolean
  isSaving?: boolean
  type?: "submit" | "reset" | "button" | undefined
  variant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >
  fullWidth?: boolean
  label?: string
  children?: React.ReactNode
  onClick?: any
  className?: string
  textSize?: string
  style?: React.CSSProperties
  endIcon?: React.ReactNode
}) => {
  return (
    <Button
      disabled={disabled}
      type={type}
      variant={variant}
      color={color}
      fullWidth
      onClick={onClick}
      style={style}
      className={className}
      endIcon={endIcon}
    >
      <div className="relative">
        <div
          className={` ${textSize ? textSize : ""} ${
            isSaving ? " opacity-0 " : ""
          }`}
        >
          {label}
          {children}
        </div>

        {isSaving && (
          <div
            className={`absolute left-0 right-0 top-0 bottom-0 flex flex-row items-center justify-center text-white`}
          >
            <CircularProgress size={20} color="inherit" />
          </div>
        )}
      </div>
    </Button>
  )
}

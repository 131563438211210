import { useNavigate } from "react-router-dom"
import { useCompanyContext } from "../../contexts/company-context"

// current - bold border
// completed - blue
// not enabled - gray
const OnboardingStep = ({
  title,
  description,
  img,
  current,
  index,
  completed,
  onClick,
  enabled
}: {
  title: string
  description: string
  img: string
  current: boolean
  index: number
  completed: boolean
  onClick: any
  enabled: boolean
}) => {
  return (
    <div
      onClick={enabled ? onClick : undefined}
      className={`rounded-2xl 
    border-2 
    ${!enabled ? " opacity-50 " : " "}
    ${completed && !current ? " border-[#E7E7E7] " : " "}
    ${current ? " border-[#0c48d7] " : " "}
    ${completed ? " bg-[#FAFDFF] " : "  "}
    ${current ? " bg-white " : "  "}
    ${enabled && !current ? " bg-[#F6F6F6] " : "  "}
    
    cursor-pointer
    flex flex-col items-center justify-center
    p-8
    relative
    `}
    >
      <div className="absolute top-[-1rem] m-auto">
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center
            ${
              current || completed
                ? " bg-[#0c48d7] text-white "
                : " bg-[#E7E7E7] "
            }
        `}
        >
          <div className="font-medium">{index}</div>
        </div>
      </div>
      <div className="text-lg mb-8 text-center">{title}</div>
      <img src={img} alt="Illustration" className="w-[272px] h-[220px] mb-8" />
      <div className="text-lg font-medium text-center">{description}</div>

      <div className="absolute bottom-[-1rem] m-auto">
        <div
          className={`px-8 h-8 rounded-lg 
        ${current || completed ? " bg-[#0c48d7] text-white " : " bg-[#E7E7E7] "}
         flex items-center justify-center`}
        >
          <div className="font-medium">
            {completed ? "Ukończone" : "Odblokuj"}
          </div>
        </div>
      </div>
    </div>
  )
}
export const OnboardingIndex = () => {
  const companyContext = useCompanyContext()
  const navigate = useNavigate()
  console.log(
    "OnboardingIndex",
    companyContext.company,
    companyContext.onboardingStatus
  )
  const steps = [
    {
      title: "Uzupełnij dane personalne",
      img: "/assets/illustrations/step1.svg",
      description: "O organizatorze",
      onClick: () => {
        navigate("/company/register")
      },
      completed: !!companyContext.onboardingStatus.companyCompleted,
      enabled: true
    },
    {
      title: "Wybierz pakiet materiałów graficznych",
      img: "/assets/illustrations/step2.svg",
      description: "Plakaty",
      onClick: () => {
        navigate("/plakaty/dodaj")
      },
      completed: !!companyContext.onboardingStatus.posterCompleted,
      enabled: !!companyContext.onboardingStatus.companyCompleted
    },
    {
      title: "Dodaj swoją ofertę",
      img: "/assets/illustrations/step3.svg",
      description: "Oferta",
      onClick: () => {
        navigate("/products?dodaj=true")
      },
      completed: !!companyContext.onboardingStatus.offerCompleted,
      enabled: !!companyContext.onboardingStatus.companyCompleted
    }
  ]

  var currentStep = companyContext.onboardingProgress
  return (
    <div className="container max-w-screen-lg m-auto px-8">
      <div className="text-center text-sm font-light mb-8 text-lato pt-8">
        Cześć! Cieszymy się, że jesteś ze nami!
      </div>
      <div className="text-center text-sm font-ligh mb-8 max-w-screen-md mx-auto">
        Dlatego zaczynajmy! Przed sobą masz 3 poziomy wtajemniczenia: O
        organizatorze, Plakaty oraz Oferta. Po ich poprawnym wypełnieniu
        otrzymasz dostęp do pełnego panelu zarządzania. Proszę zwróć uwagę czy
        wszystkie pola zostały uzupełnione, ponieważ niektóre z Twoich danych
        będą automatycznie zapisywane do kolejnych formularzy.
      </div>

      <div className="grid grid-cols-3 gap-4">
        {steps.map((step, index) => (
          <OnboardingStep
            onClick={step.onClick}
            index={index + 1}
            current={currentStep === index}
            completed={step.completed}
            enabled={Boolean(step.enabled)}
            title={step.title}
            description={step.description}
            img={step.img}
            key={step.title}
          />
        ))}
      </div>
    </div>
  )
}

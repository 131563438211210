import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Typography from "@mui/material/Typography"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

import { BackButton } from "../../../components/commons/button"
import { UidUtils } from "../../../utils/uid-utils"
import { useProductApi } from "./api/useProductApi"
import { ProductGeneralInfoPreview } from "../products/forms/ProductGeneralInfoPreview"
import {
  ProductTypes,
  ProductUtils,
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP,
  PRODUCT_TYPE_GROUPS
} from "wakacje-web/src/utils/ProductUtils"
import { ProductSectionFilesPreview } from "../products/ProductSectionFiles"

import { NewSchoolTripPageDetails } from "../products/forms/NewSchoolTripPageDetails"

import { NewProductPublishPage } from "../products/forms/NewProductPublishPage"
import { NewSchoolTripPageSchedule } from "../products/forms/NewSchoolTripPageSchedule"
import { NewSchoolTripPagePricing } from "../products/forms/NewSchoolTripPagePricing"
import { NewProductPageSchedule } from "../products/forms/NewProductPageSchedule"
import { useCompanyContext } from "../../../contexts/company-context"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { NewProductPage1 } from "../products/forms/NewProductPage1"
import NewGroupsAccommodationsPageDetails from "../groups/NewGroupsAccommodationsPageDetails"

const getStepsForType = (type: any) => {
  //step1
  var steps: [string, React.ElementType, React.ElementType?][] = [
    ["Opis", NewProductPage1, ProductGeneralInfoPreview]
  ]

  //step2
  steps.push([
    "Plan wydarzenia",
    NewProductPageSchedule,
    ProductSectionFilesPreview
  ])

  if (type === PRODUCT_TYPE_DAYCAMP.value || type === PRODUCT_TYPE_CAMP.value) {
    steps.push(["Cennik", NewSchoolTripPagePricing, ProductSectionFilesPreview])
  }
  //step3
  steps.push(["Podsumowanie", NewProductPublishPage])

  //School trip
  if (type === PRODUCT_TYPE_SCHOOL_TRIP.value) {
    return [
      ["Opis", NewSchoolTripPageDetails, ProductSectionFilesPreview],
      [
        "Plan Wydarzenia",
        NewSchoolTripPageSchedule,
        ProductSectionFilesPreview
      ],
      ["Cennik", NewSchoolTripPagePricing, ProductSectionFilesPreview],
      ["Podsumowanie", NewProductPublishPage, ProductSectionFilesPreview]
    ]
  }
  if (type === PRODUCT_TYPE_GROUPS.value) {
    return [
      [
        "Noclegi dla grup",
        NewGroupsAccommodationsPageDetails,
        ProductSectionFilesPreview
      ],
      ["Podsumowanie", NewProductPublishPage, ProductSectionFilesPreview]
    ]
  }
  return steps
}

const calculateInitialValues = (productType, merchantContext) => {
  const res: any = {
    type: productType,
    company_id: merchantContext.company?.id,
    country_code: "PL",
    country_is_poland: "yes",
    country: "PL"
  }
  if (productType === ProductTypes.DAYCAMP) {
    res.transport_type = "own"
  }
  return res
}
export function AddProduct({
  superMode,
  type
}: {
  superMode?: any
  type?: string
}) {
  const [isUpdateForm, setIsUpdateForm] = useState(false) // For prevent create new product when user click back button and resubmit
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchParams, _setSearchParams] = useSearchParams()

  const { t } = useTranslation()
  console.log("Add Product, type: ", type)

  const [productType, setProductType] = useState<any>(
    type ?? searchParams.get("type")
  )

  const merchantContext = useCompanyContext()
  const [stepsData, setStepsData] = useState<any>(
    calculateInitialValues(productType, merchantContext)
  )

  const [activeStep, setActiveStep] = useState(0)
  const jumpToQuery = useRef<string | null>(null)

  const steps = getStepsForType(productType)
  const ActiveStepComponent = steps[activeStep][1]
  const [newProductId] = useState(UidUtils.generateUid("PR"))
  const { createProduct, updateProduct } = useProductApi()

  const [productId, setProductId] = useState<any>()

  console.log("stepsData", stepsData)

  console.log("active step", activeStep)

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true)

    console.log("AddProduct.handleSubmit")
    console.log("values:", values)
    console.log("active step: ", activeStep)
    console.log("productID: ", productId)

    var productData = {
      id: newProductId,
      category: { id: productType },
      ...values,
      company_id: merchantContext.company.id
    }
    console.log("productData", productData)
    console.log("handleSubmit", activeStep, values)

    if (!merchantContext.company) {
      throw new Error("No company")
    }

    var newStepsData = {
      ...stepsData,
      ...values,
      id: productId
    }

    // Create step
    if (productId) {
      productData = { ...productData, id: productId }
      console.log("handleSubmit activeStep", activeStep)
      if (activeStep === 2 && productType !== PRODUCT_TYPE_SCHOOL_TRIP.value) {
        console.log("Update trips")

        // Page 3 has also prices - so first save pricing
        var updateProductResult = await updateProduct(productData)
        console.log("updateProductResult", updateProductResult)
        if (updateProductResult.product) {
          newStepsData = { ...newStepsData, ...updateProductResult.product }
        }
        if (productData.trips && productData.trips.length > 0) {
          // Now save trips
          const command = {
            type: "Product.AddTrips",
            aggregateId: newProductId,
            payload: {
              trips: productData.trips
            }
          }
          updateProductResult = await updateProduct({
            command,
            id: newProductId
          })
          console.log("updateProductResult", updateProductResult)
        }
        if (updateProductResult.product) {
          newStepsData = { ...newStepsData, ...updateProductResult.product }
        }
      } else {
        try {
          productData = { ...productData, id: productId }
          try {
            var updateProductResult = await updateProduct(productData)
            console.log("updateProductResult", updateProductResult)
            if (updateProductResult.product) {
              newStepsData = { ...newStepsData, ...updateProductResult.product }
            }
            setIsSubmitting(false)
          } catch (error) {
            console.log("error", error)
          }
        } catch (error) {
          console.log("error", error)
        }
      }
    } else {
      try {
        var createProductResult = await createProduct({
          ...productData
        })
        console.log("createProductResult", createProductResult)
        if (createProductResult.product) {
          console.log(
            "Merge server response with local data",
            newStepsData,
            createProductResult.product
          )
          newStepsData = { ...newStepsData, ...createProductResult.product }
        }
        setProductId(newProductId)
      } catch (error) {
        console.log("error", error)
      }
    }

    setIsSubmitting(false)

    setStepsData(newStepsData)

    setActiveStep((step) => Math.min(steps.length - 1, step + 1))
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    if (jumpToQuery.current) {
      const element = document.querySelector(jumpToQuery.current)
      if (element) {
        element.scrollIntoView()
        jumpToQuery.current = null
      }
    }
  })

  const handleRefetch = async () => {
    console.log("AddProduct.handleRefetch")
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products/${productId}`
    var res = await axios.get(url)
    if (res.status === 200) {
      console.log("get product", res.data)
      setStepsData(res.data)
    }
  }

  const getProductTypeName = () => {
    if (productType === "noclegi-dla-grup") {
      return t("ui.groups.groups", "Noclegi dla grup")
    }

    return productType ? ProductUtils.getTypeName(productType) : "Dodaj ofertę"
  }

  return (
    <div className="p-4 flex-auto max-w-screen-xl mx-auto">
      <BackButton destination={type} />
      <div className="max-w-screen-md mx-auto my-8">
        <Typography variant="h6" component="h1" fontWeight="normal">
          {t("products.add_offer", "Dodaj ofertę")}
        </Typography>

        <Typography variant="h4" component="h1" fontWeight="bold">
          {getProductTypeName()}
        </Typography>
        {productType !== PRODUCT_TYPE_GROUPS.value && (
          <div className="my-4">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label[0]}>
                  <StepLabel>{label[0]}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        )}
        {activeStep === steps.length - 1 ? (
          <>
            <ActiveStepComponent
              values={stepsData}
              jumpTo={(step: number, elementQuery: string) => {
                setActiveStep(step)
                jumpToQuery.current = elementQuery
              }}
              onSave={handleSubmit}
              isSubmitting={isSubmitting}
              refetch={handleRefetch}
            />
          </>
        ) : (
          <div>
            <ActiveStepComponent
              values={stepsData}
              onSubmit={handleSubmit}
              onCancel={() => {
                setIsUpdateForm(true)
                setActiveStep((step) => Math.max(0, step - 1))
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              }}
              isSubmitting={isSubmitting}
            />
          </div>
        )}
      </div>
    </div>
  )
}

import axios from "axios"
import { Button } from "@mui/material"
import { useState } from "react"
import { InputField } from "../../components/FormCommons"
import { useSnackbar } from "notistack"
import { MojeButton } from "wakacje-web/src/components/MojeButton"

const UserAttributeRow = ({ label, value }) => (
  <div className="flex flex-row">
    <div className="w-32">{label}</div>
    <div className="font-bold">{value}</div>
  </div>
)

export const SuperUserPage = ({ user }: { user: any }) => {
  const [newPassword, setNewPassword] = useState<any>()
  const [isSavingPassword, setIsSavingPassword] = useState(false)
  const [isConfirmingEmail, setIsConfirmingEmail] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleConfirmEmail = async () => {
    const command = {
      type: "User.ConfirmEmail",
      aggregateId: user.id,
      payload: {}
    }
    try {
      setIsConfirmingEmail(true)
      var res = await runCommand(command)

      // console.log("Save password res", res)
      if (res.data.success) {
        setIsConfirmingEmail(false)
        enqueueSnackbar("Email potwierdzony", {
          variant: "success"
        })
      }
    } catch (err) {
      setIsConfirmingEmail(false)
      enqueueSnackbar("Problem ze potwierdzeniem maila")
    }
  }
  const handleChangePassword = async () => {
    const command = {
      type: "User.ChangePassword",
      aggregateId: user.id,
      payload: {
        password: newPassword
      }
    }
    try {
      setIsSavingPassword(true)
      var res = await runCommand(command)

      // console.log("Save password res", res)
      if (res.data.success) {
        setIsSavingPassword(false)
        enqueueSnackbar("Hasło zostało zmienione", {
          variant: "success"
        })
      }
    } catch (err) {
      setIsSavingPassword(false)
      enqueueSnackbar("Problem ze zmianą hasła", { variant: "error" })
    }
  }
  const handleMakeSuperAdmin = async () => {
    const command = {
      type: "User.ChangeAdminStatus",
      aggregateId: user.id,
      payload: {
        value: true
      }
    }
    await runCommand(command)
  }
  const runCommand = async (command) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      "/v1/super/users/" +
      command.aggregateId

    var res = await axios.post(url, command)
    return res
  }

  return (
    <div className="bg-white p-8">
      <div className="my-4 font-bold text-lg">Nazwa użytkownika</div>
      <div className="font-bold "> {user.name}</div>

      <div className="my-4 font-bold text-lg">Atrybuty</div>
      <div>
        <UserAttributeRow label="email" value={user.email} />
        <UserAttributeRow label="Telefon" value={user.phone} />
        <UserAttributeRow label="Imie" value={user.first_name} />
        <UserAttributeRow label="Nazwisko" value={user.last_name} />
        <UserAttributeRow label="Zdjecie" value={user.profile_photo_url} />
        <UserAttributeRow label="Rodzaj" value={user.type} />
      </div>
      <div className="border-b border-gray-200 my-4" />
      <div>Nowe hasło (min 8 znaków):</div>
      <div className="flex flex-row">
        <InputField
          value={newPassword}
          onChange={(event) => setNewPassword(event.currentTarget.value)}
        ></InputField>
      </div>
      <MojeButton
        onClick={handleChangePassword}
        variant="outlined"
        disabled={!newPassword || newPassword.length < 8}
        isSaving={isSavingPassword}
      >
        Zmień hasło
      </MojeButton>
      <MojeButton
        onClick={handleConfirmEmail}
        variant="outlined"
        isSaving={isConfirmingEmail}
      >
        Potwierdź email
      </MojeButton>
      <div className="border-b border-gray-200 my-4" />
      {/* <div>
        <Button onClick={handleMakeSuperAdmin} variant="outlined">
          Zrób administratorem
        </Button>
      </div> */}
    </div>
  )
}

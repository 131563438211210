import { useFileUploadedContext } from "../../contexts/FileUploadedContext"
import { ProductTypes } from "../../utils/ProductUtils"
import BookingSideAttachedFile from "./SideAttachedFile"
import { BookingUtils } from "./BookingUtils"
import { BookingPaymentCard } from "./widgets/BookingPaymentCard"
import CompanySideWidget from "../company/widgets/CompanySideWidget"
import { SideWidgetUserSummary } from "./SideWidgetUserSummary"
import { divide } from "lodash"
import ParticipantWidget from "./widgets/ParticipantWidget"
import PaymentWidget from "./widgets/PaymentWidget"

export const BookingSideSummaryWidget = ({
  booking,
  showParticipants,
  showPrices,
  actionsComponent,
  onSaved,
  mode,
  company,
  onCreateOffer
}: {
  showParticipants?: boolean
  showPrices?: boolean
  booking: any
  actionsComponent?: any
  onSaved: Function
  mode: "user" | "company"
  company?: any
  onCreateOffer?: Function
}) => {
  if (!booking) {
    return <span />
  }
  const showPay = mode === "user" && BookingUtils.canBePaid(booking)
  const { fileUploaded } = useFileUploadedContext()
  let isSchoolTrip = booking?.product?.type === ProductTypes.SCHOOL_TRIP
  console.log("Booking summary", isSchoolTrip, booking)

  return (
    <div className="w-full">
      {company && mode === "user" && (
        <CompanySideWidget company={company} booking={booking} />
      )}
      {booking?.user && mode === "company" && (
        <SideWidgetUserSummary
          user={booking.user}
          booking={booking}
          onCreateOffer={onCreateOffer}
        />
      )}

      {showParticipants && booking.participants && (
        <ParticipantWidget booking={booking} />
      )}

      {showPrices && <PaymentWidget booking={booking} />}
      <div>Tryb płatności: {booking.payment_mode}</div>
      {!!booking.advance_payment_price && (
        <BookingPaymentCard
          label="Zaliczka"
          value={booking.advance_payment_price}
          dueDate={booking.advance_payment_due_date}
          booking={booking}
          paymentType={"BOOKING_DEPOSIT"}
          showPay={showPay}
        />
      )}
      {showPrices && (
        <>
          {!!booking.total_price && (
            <BookingPaymentCard
              label="Pełna kwota"
              value={booking.total_price}
              dueDate={booking.payment_due_date}
              booking={booking}
              paymentType={"BOOKING_PAYMENT"}
              showPay={showPay}
            />
          )}

          {actionsComponent && (
            <div className="mb-4">{actionsComponent({ booking, onSaved })}</div>
          )}
        </>
      )}

      {fileUploaded && <BookingSideAttachedFile />}
    </div>
  )
}

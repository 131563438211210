import { FormControl, MenuItem, NativeSelect, Select } from "@mui/material"
import { SearchBox } from "../../../components/table/SearchBox"
import { SuperDataExportButton } from "../../super/SuperDataExport"
import { useEffect, useState } from "react"
import { ProductTypes } from "wakacje-web/src/utils/ProductUtils"
import { useCompanyContext } from "../../../contexts/company-context"
import axios from "axios"


type ProductReservation = { [key: string]: string };

export function BookingsToolbar({
  setSearchTextDebounced,
  api,
  children,
  queryParams,
  selectedProduct,
  setSelectedProduct,
  selectedType,
  setSelectedType
}: {
  setSearchTextDebounced: any
  api: any
  children?: any
  queryParams?: any
  selectedProduct?: any
  setSelectedProduct?: any
  selectedType?: any
  setSelectedType?: any
}) {

  const companyContext = useCompanyContext()
  const [productsWithReservations, setProductsWithReservations] = useState<ProductReservation>({})



  useEffect(() => {

    const getProducts = async () => {
      const reservations = await api({})
      const filteredProducts = {}
      if(reservations && reservations.length >0) {
        reservations.forEach(el =>{
          if(!filteredProducts[el.product_id]){
            filteredProducts[el.product_id] = el.product_name
          }
        })
        setProductsWithReservations(filteredProducts)
      }


    }
    getProducts()
  }, [companyContext])

  console.log("Bookings products selectedProduct:", selectedProduct)
  return (
    <div
      className="flex flex-col md:flex-row justify-between items-center w-full mb-2 mt-4
    "
    >
      <div className="flex flex-col md:flex-row justify-start items-center w-full mb-2 mt-4 gap-2">
        <div>Oferta:</div>

        <Select
          
          size="small"
          value={selectedProduct}
          onChange={event => setSelectedProduct(event.target.value)}
          sx={{
            width: 180
            // '& .MuiMenu-paper': {
            //   maxWidth: 'fit-content',

            // }
          }}
          MenuProps={{
            sx: {
              maxWidth: "400px",
              overflow: "visible"
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
        >
          <MenuItem value="all" selected={selectedProduct === "all"}>
            Wszystkie
          </MenuItem>
          {productsWithReservations &&
            Object.entries(productsWithReservations).map((prod)=> {
              return (
                <MenuItem
                  key={prod[0]}
                  value={prod[0]}
                  selected={selectedProduct === prod[0]}
                >
                  {prod[1]}
                </MenuItem>
              )
            })}
        </Select>

        <div className="md:whitespace-nowrap">Rodzaj oferty:</div>
        <Select
          
          size="small"
          value={selectedType}
          onChange={event => setSelectedType(event.target.value)}
          sx={{
            width: 180,
            "& .MuiMenu-paper": {
              maxWidth: "200px"
            }
          }}
          MenuProps={{
            sx: {
              maxWidth: "200px",
              overflow: "visible"
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
        >
          <MenuItem value={"all"} selected={selectedType === "all"}>
            Wszystkie
          </MenuItem>
          <MenuItem
            value={ProductTypes.CAMP}
            selected={selectedType === ProductTypes.CAMP}
          >
            kolonie i obozy
          </MenuItem>
          <MenuItem
            value={ProductTypes.DAYCAMP}
            selected={selectedType === ProductTypes.DAYCAMP}
          >
            półkolonie
          </MenuItem>
          <MenuItem
            value={ProductTypes.SCHOOL_TRIP}
            selected={selectedType === ProductTypes.SCHOOL_TRIP}
          >
            wycieczki szkolne
          </MenuItem>
        </Select>
      </div>
      <div className="flex flex-col md:flex-row justify-end items-center w-full mb-2 mt-4 gap-1">
        <SearchBox setSearchTextDebounced={setSearchTextDebounced} />
        <SuperDataExportButton api={api} queryParams={queryParams} />
      </div>
    </div>
  )
}

export default BookingsToolbar

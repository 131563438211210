function PrintIcon() {
  return (
    <svg
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.500977" width="52" height="52" rx="8" fill="#0C48D7" />
      <g clipPath="url(#clip0_7509_246929)">
        <path
          d="M34.5334 21.8343H33.2667V15.501H18.0667V21.8343H16.8C14.6973 21.8343 13 23.5317 13 25.6343V33.2344H18.0667V38.301H33.2667V33.2344H38.3334V25.6343C38.3334 23.5317 36.6361 21.8343 34.5334 21.8343ZM20.6 18.0343H30.7334V21.8343H20.6V18.0343ZM30.7334 33.2344V35.7677H20.6V30.701H30.7334V33.2344ZM33.2667 30.701V28.1677H18.0667V30.701H15.5333V25.6343C15.5333 24.9377 16.1033 24.3677 16.8 24.3677H34.5334C35.2301 24.3677 35.8001 24.9377 35.8001 25.6343V30.701H33.2667Z"
          fill="white"
        />
        <path
          d="M33.2667 27.5348C33.9662 27.5348 34.5333 26.9677 34.5333 26.2681C34.5333 25.5686 33.9662 25.0015 33.2667 25.0015C32.5671 25.0015 32 25.5686 32 26.2681C32 26.9677 32.5671 27.5348 33.2667 27.5348Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7509_246929">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(10 10.501)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PrintIcon

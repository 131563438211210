export const FormErrors = ({ errors }: any) => {
  return errors && Object.keys(errors).length ? (
    <div>
      {/* <div>Formularz zawiera błędy</div> */}
      <ul className="text-red-500 font-sm">
        {Object.values(errors).map((err: any, index) => (
          <li key={index}>{err.message}</li>
        ))}
      </ul>
    </div>
  ) : (
    <div />
  )
}

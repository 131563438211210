import {Box, Typography} from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import {
	iconSuccessBox,
	paymentContainer,
	paymentInfoBlock, paymentInfoMainContainer, paymentInfoSecondText,
	paymentInfoText,
	paymentInfoWrapper,
	paymentSuccessIcon,
	priceContainer,
	priceHead,
	priceInfo, priceText, priceWrapper,
} from './paymentSectionStyle';

export const PaymentSection = ()=>{


	return (
			<Box sx={paymentContainer}>

				<Box sx={priceWrapper}>
					<Box sx={priceContainer}>
						<Typography sx={priceHead} component='span'>
							1 200 <span>zł</span>
						</Typography>

						<Typography sx={priceInfo} component='span'>
							Pełny koszt
						</Typography>
					</Box>

					<Typography sx={priceText} component='span'>
						Termin płatności: 07.06.2021
					</Typography>
				</Box>


				<Box sx={paymentInfoMainContainer}>

					<Box sx={paymentInfoWrapper}>

						<Box sx={iconSuccessBox}>
							<DoneOutlinedIcon sx={paymentSuccessIcon}/>
						</Box>

						<Box sx={paymentInfoBlock}>
							<Typography sx={paymentInfoText} component='span'><span>Zaliczka:</span> 800,00 zł,</Typography>
							<Typography sx={paymentInfoSecondText} component='span'>Opłacono: 07.06.2021</Typography>
						</Box>

					</Box>

					<Box sx={paymentInfoWrapper}>

						<Box sx={iconSuccessBox}>
							<DoneOutlinedIcon sx={paymentSuccessIcon}/>
						</Box>

						<Box sx={paymentInfoBlock}>
							<Typography sx={paymentInfoText} component='span'><span>Pozostała kwota:</span> 800,00 zł,</Typography>
							<Typography sx={paymentInfoSecondText} component='span'>Termin płatności: 08.06.2021</Typography>
						</Box>
					</Box>
				</Box>


			</Box>
	)
} ;
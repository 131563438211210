import React, {useState,createContext} from 'react';

export type ModalContextProviderProps = {
  children: React.ReactNode;
}

export const ModalPackageContext = createContext(null);

export const ModalPackageContextProvider = ({children}: ModalContextProviderProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = (): void => {
    setOpen(false);
  }

  return(
    <ModalPackageContext.Provider value={{open, setOpen, handleClose}}>
      {children}
    </ModalPackageContext.Provider>
  )
}
import { useTheme } from "@mui/material/styles"
import Typography, { TypographyProps } from "@mui/material/Typography"

export function PageTitle(props: TypographyProps<"h1">) {
  const theme = useTheme()
  return (
    <Typography
      variant="h4"
      component="h1"
      sx={{
        fontWeight: "bold",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.5rem",
        },
      }}
      {...props}
    >
      {props.children}
    </Typography>
  )
}

export function Title32Bold({ children }: { children: any }) {
  return (
    <Typography
      variant="h4"
      component="h1"
      sx={{
        fontWeight: 700,
        fontSize: 32,
        lineHeight: 1,
      }}
    >
      {children}
    </Typography>
  )
}

const Typo = {
  Title32Bold,
}
export default Typo

import React from "react"
import { IconButton } from "@mui/material"
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined"
import { TrashIcon } from "../../components/Icon/TrashIcon"

function AttachFileList({ uf, handleRemoveAttachment, idx }) {
  return (
    <div className="flex flex-row items-center justify-between mb-3">
      <div className="flex gap-2 items-center">
        <IconButton color="primary" style={{ background: "#f3f6fd" }}>
          <DescriptionOutlinedIcon color="primary" />
        </IconButton>
        <div>
          <p className="font-bold text-sm">{uf.name}</p>
          {/* <p className="text-[#7E7E7E] text-xs">
                            11:32, 07.06.2021
                          </p> */}
        </div>
      </div>

      <div>
        <IconButton
          // className="mr-4"
          onClick={() => {
            handleRemoveAttachment(uf.id)
          }}
        >
          <TrashIcon />
        </IconButton>

        {/* <Checkbox
          checked={showTrash}
          onClick={() => setShowTrash(!showTrash)}
        /> */}
      </div>
    </div>
  )
}

export default AttachFileList

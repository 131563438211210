import { yupResolver } from "@hookform/resolvers/yup"
import { Divider, Grid, InputAdornment } from "@mui/material"
import {
  FieldPath,
  RegisterOptions,
  useForm,
  get,
  FormProvider,
  Controller
} from "react-hook-form"
import { Heading3, InputField } from "../../../../components/FormCommons"
import { NewProductWizardButtonsBar } from "./NewProductWizardButtonsBar"
import * as Yup from "yup"
import { ProductTypes } from "wakacje-web/src/utils/ProductUtils"
import { NewProductPageDates } from "./NewProductPageDates"
import { PriceCentsInput } from "../../../../components/ui/PriceInput"
import { useTranslation } from "react-i18next"

const productThirdPageSchema = Yup.object({
  price_includes_text: Yup.string().required("Cena zawiera jest wymagany")
})
const productThirdPageSchemaSchoolTrip = Yup.object({
  min_price: Yup.number().required("Cena jest wymagana"),
  price_includes_text: Yup.string().required("Cena zawiera jest wymagany")
})

export const NewSchoolTripPagePricing = ({
  values,
  onCancel,
  onSubmit,
  isSubmitting
}: any) => {
  const { t } = useTranslation()

  var defaultValues = values
    ? {
        ...values
      }
    : {}
  const type = values.type

  const formMethods = useForm<any>({
    mode: "onTouched",
    resolver: yupResolver(
      type === ProductTypes.SCHOOL_TRIP
        ? productThirdPageSchemaSchoolTrip
        : productThirdPageSchema
    ),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { errors, isValid }
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  function onFormSubmit(data: any) {
    onSubmit(data)
  }

  function handleScroll(e) {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <section>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <div>
            <Heading3>Cennik</Heading3>
          </div>

          {type === ProductTypes.SCHOOL_TRIP && (
            <div className="w-64">
              <Controller
                render={({ field }) => (
                  <PriceCentsInput
                    value={field.value}
                    onChange={field.onChange}
                    type="number"
                    label={"Cena/ 1 osobę*"}
                    error={errors?.min_price?.message}
                  />
                )}
                control={formMethods.control}
                name="min_price"
              />
            </div>
          )}
          <InputField
            multiline
            minRows={4}
            label={"Opis i informacje o zniżkach"}
            {...register("price_description", { required: false })}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputField
                multiline
                minRows={4}
                label={"Cena zawiera*"}
                {...register("price_includes_text")}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                multiline
                minRows={4}
                label={"Cena nie zawiera"}
                {...register("price_not_includes_text")}
              />
            </Grid>
          </Grid>
          {type === ProductTypes.CAMP && (
            <>
              <Divider sx={{ marginTop: "1rem" }} />

              <div className="my-8">
                <Heading3>{t("ui.discounts", "Zniżki")}</Heading3>
              </div>
              <div className="flex flex-row mb-1 items-center">
                <div className="flex-1">
                  <InputField
                    label={t("ui.discount_name", "Nazwa zniżki")}
                    {...register(`product_payments_discounts_name_1`)}
                  />
                </div>
                <div className="w-64 ml-3">
                  <InputField
                    type="number"
                    label={t("ui.price_per_person", "Cena / 1 osobę")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                      onWheel: handleScroll,
                      onTouchMove: handleScroll
                    }}
                    {...register(`product_payments_discounts_value_1`)}
                  />
                </div>
              </div>

              <div className="flex flex-row mb-1 items-center">
                <div className="flex-1">
                  <InputField
                    label={t("ui.discount_name", "Nazwa zniżki")}
                    {...register(`product_payments_discounts_name_2`)}
                  />
                </div>
                <div className="w-64 ml-3">
                  <InputField
                    type="number"
                    label={t("ui.price_per_person", "Cena / 1 osobę")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                      onWheel: handleScroll,
                      onTouchMove: handleScroll
                    }}
                    {...register(`product_payments_discounts_value_2`)}
                  />
                </div>
              </div>
            </>
          )}

          {/* {type === ProductTypes.CAMP && (
            <>
              <NewProductPageDates
                values={values}
                onCancel={onCancel}
                onSubmit={onSubmit}
              />
            </>
          )} */}
          <NewProductWizardButtonsBar
            onCancel={onCancel}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </section>
  )
}

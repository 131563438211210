import FolderIcon from "@mui/icons-material/Folder"
import IconButton from "@mui/material/IconButton"
import { useState } from "react"
import { SelectPhotoGalleryPopup } from "../../components/gallery-upload/SelectPhotoGalleryPopup"

interface ISanboxTestingProps {}

export interface photoLiblaryProps {
  url: string
}

const SanboxTesting: React.FunctionComponent<ISanboxTestingProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [valueTabs, setValueTabs] = useState(1)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const ChangeTabs = (e: React.FormEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setValueTabs(parseInt(e.currentTarget.dataset.tabs))
  }

  return (
    <div className="mt-10">
      <div className="w-24 h-20 bg-[#C4C4C4] ml-10 md:ml-20 flex justify-center items-center">
        <IconButton onClick={handleClick}>
          <FolderIcon />
        </IconButton>
        <SelectPhotoGalleryPopup
          anchorEl={anchorEl}
          open={open}
          onClosePopup={setAnchorEl}
        />
      </div>
    </div>
  )
}

export default SanboxTesting

import { ApiTable } from "../../../components/table/ApiTable"
import { useArticleApi } from "./useArticlesApi"
import { FormattedDate } from "react-intl"

const columns = [
  {
    header: "Rodzaj",
    cell: (row: any) => row.type,
    field: "type"
  },
  {
    header: "Tytuł",
    cell: (row: any) => row.title,
    field: "title"
  },
  {
    header: "Opublikowany",
    cell: (row: any) => (
      <div>
        {row.published_at ? <FormattedDate value={row.published_at} /> : "-"}
      </div>
    ),
    field: "published_at"
  },
  {
    header: "Autor",
    cell: (row: any) => <div>{row.author_name}</div>,
    field: "author_name"
  },
  {
    header: "Kategoria Seo",
    cell: (row: any) => (
      <div>
        {row.category && (
          <>
            {row.category.category}/{row.category.subcategory}
          </>
        )}
      </div>
    ),
    field: "category_name"
  },
  {
    header: "Usunięty",
    cell: (row: any) => (
      <div className="text-red-500">{row.deleted_at ? "Usunięty" : ""}</div>
    ),
    field: "deleted_at"
  }
]

export const ArticlesTable = ({ queryParams, initialSort }: any) => {
  const { listArticles } = useArticleApi()

  return (
    <ApiTable
      columns={columns}
      listApi={listArticles}
      showExpandColumn
      queryParams={queryParams}
      rowLinkBuilder={(row: any) => `/super/articles/${row.id}`}
      initialSort={initialSort}
    />
  )
}

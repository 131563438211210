import { useForm, FormProvider } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

import FormSectionTerm from "./FormSectionTerm"

import FormSectionParticipant from "./FormSectionParticipant"
import FormSectionTransportation from "./FormSectionTransportation"
import FormSectionAccommodation from "./FormSectionAccommodation"
import FormSectionPlaces from "./FormSectionPlaces"
import FormSectionPrice from "./FormSectionPrice"
import { MojeButton } from "wakacje-web/src/components/MojeButton"
import { FormErrors } from "../../../../components/FormErrors"
import { useState } from "react"
import { UidUtils } from "../../../../utils/uid-utils"
import { useOfferCommand } from "./api/useOfferCommand"

export const SectionContainer = ({ children }) => {
  return <div className="mb-10">{children}</div>
}

const createOfferSchema = yup.object({
  // product_id: yup.string().required(),
  // user_id: yup.string().required(),
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  departure_date: yup.string().required(),
  departure_time: yup.number().required(),
  departure_address: yup.string().required(),
  departure_address_description: yup.string().required(),
  return_date: yup.string().required(),
  return_time: yup.number().required(),
  return_address: yup.string().required(),
  return_address_description: yup.string().required(),
  participants_count: yup.number().required(),
  base_price_per_person: yup.number().required(),
  free_pax_count: yup.number().required(),
  min_age: yup.number().required(),
  max_age: yup.number().required(),
  transport_type: yup.string().required(),
  transport_vehicle: yup.mixed().when("transport_type", {
    is: "provided",
    then: yup.string().required()
  }),
  transport_price_per_pax: yup.mixed().when("transport_type", {
    is: "provided",
    then: yup.number().required()
  }),
  accommodation_place_id: yup.string(),
  accommodation_price_include: yup.string().required(),
  accommodation_price_per_person: yup
    .number()
    .when("accommodation_price_include", {
      is: "additional",
      then: yup
        .number()
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null))
        .required()
    }),
  places: yup.array(),
  payment_due_date: yup.string().required()
})

export const CreateOfferForm = ({ booking }) => {
  console.log("Booking", booking)
  const [offerId, setOfferId] = useState(
    UidUtils.generateUid("quot_").toLowerCase()
  )
  const { runCommand, isSaving } = useOfferCommand()

  const defaultValues = {}
  const formMethods = useForm<any>({
    resolver: yupResolver(createOfferSchema),
    mode: "all",
    defaultValues
  })

  const {
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = formMethods

  // console.log("watch", watch())

  const onSubmit = async (data: any) => {
    console.log("submit2", data)

    if (!booking) {
      console.error("No booking")
      return
    }

    const command = {
      aggregate_id: offerId,
      type: "Booking.Create",
      payload: {
        ...data,
        product_id: booking.product_id,
        company_id: booking.company_id,
        user_id: booking.user_id,
        inquiry_id: booking.id,
        type: "quotation"
      }
    }

    console.log("command", command)
    var res = await runCommand(command)

    console.log("Result ", res)
  }

  return (
    <div className="h-auto">
      <div className="mb-4">Utwórz ofertę</div>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormSectionTerm />
          <FormSectionParticipant />
          <FormSectionTransportation />
          <FormSectionAccommodation />
          <FormSectionPlaces />
          <FormSectionPrice />

          <div className="px-6">
            <FormErrors errors={errors}></FormErrors>
          </div>

          <div className="flex flex-row mt-20 pb-20">
            <div>
              <MojeButton
                label="Powrót"
                onClick={() => console.log("powrót")}
                variant="text"
                color="primary"
              />
            </div>
            <div className="flex-1" />
            <div>
              <MojeButton
                disabled={!isValid}
                label="Zapisz i zakończ"
                type="submit"
                variant="contained"
                color="primary"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

import { Button, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

export const NewProductWizardButtonsBar = ({
  onCancel,
  firstStep,
  finalStep,
  isValid,
  isSubmitting,
  onSubmit
}: {
  onCancel: any
  firstStep?: boolean
  finalStep?: boolean
  isValid?: boolean
  isSubmitting?: boolean
  onSubmit?: any
}) => {
  const { t } = useTranslation()
  return (
    <div className="text-right flex flex-row mt-8">
      {!firstStep && !finalStep && (
        <Button className="mx-4" onClick={onCancel} size="large">
          {t("back", "Powrót")}
        </Button>
      )}

      <div className="flex-1" />
      <Button
        size="large"
        className="mx-4"
        variant="contained"
        type="submit"
        disabled={finalStep ? false : !isValid || isSubmitting}
        onClick={onSubmit}
      >
        {isSubmitting ? (
          <CircularProgress size={24} />
        ) : finalStep ? (
          t("save_and_finish", "Zapisz i zakończ")
        ) : (
          t("contiune", "Dalej")
        )}
      </Button>
    </div>
  )
}

import { useTranslation } from "next-i18next"
import React, { useState } from "react"
import { ProductUtils } from "../../utils/ProductUtils"
import Animate from "../Animate"
import axios from "axios"

function PhoneNumber({ phone, companyId }) {
  const { t } = useTranslation("common")
  const [showPhone, setShowPhone] = useState(false)

  const handleShowPhone = async (phoneValue) => {
    setShowPhone(true)
    const url = process.env.NEXT_PUBLIC_WAKACJE_API_URL + `/v1.1/public/lce`
    try {
      await axios.post(url, {
        event_type: "PhoneNumber.Revealed",
        payload: {
          company_id: companyId,
          phone: phoneValue
        },
        aggregate_type: "CompanyStats",
        aggregate_id: companyId
      })
    } catch (err) {
      console.error("Error show phone")
    }
  }

  return (
    <div>
      {showPhone ? (
        <Animate type="fade-in" duration={0.5}>
          <a
            className="inline md:hidden"
            href={`tel:${ProductUtils.formatPhone(phone)}`}
          >
            {ProductUtils.formatPhone(phone)}
          </a>
          <span className="hidden md:inline">
            {ProductUtils.formatPhone(phone)}
          </span>
        </Animate>
      ) : (
        ProductUtils.formatPhone(phone).substring(0, 5) + "..."
      )}{" "}
      {!showPhone && (
        <span
          onClick={() => handleShowPhone(phone)}
          className="text-[#463bd8] cursor-pointer hover:underline"
        >
          {t("product.show_phone_number", "pokaż numer")}
        </span>
      )}
    </div>
  )
}

export default PhoneNumber

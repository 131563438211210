import Link from "next/link"
import { MojeButton } from "../../components/MojeButton"
import PhoneNumber from "../../components/PhoneNumber"
import { useAuth } from "../../auth/AuthContext"
import { MouseEvent } from "react"
import { useLoginPopupContext } from "../../contexts/LoginPopupContext"
import { useTranslation } from "next-i18next"

export const _ContactCard = ({ title, children }) => {
  return (
    <div className="mr-16">
      <div className="my-2 text-base font-bold text-fc-black">{title}</div>
      <div>{children}</div>
    </div>
  )
}
export const _ContactCardWithCount = ({ title, count, children }) => {
  return (
    <div className="mr-16">
      <div className="=text-base my-2 flex items-center font-bold text-fc-black">
        {title}
        <div className="ml-auto flex items-center text-fc-gray-4">
          <span>{count}</span>
          <div className="ml-2 h-[14px]">
            <img
              src="/svg/chevron-black-right.svg"
              className="w-full"
              width="14"
              height="14"
            />
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  )
}
export const ContactRow = ({ icon, children }) => {
  return (
    <div className="items-flex-start mb-3 flex flex-row text-base text-fc-black">
      <img className="mr-3 h-7 w-7" src={icon} />
      <div>{children}</div>
    </div>
  )
}
export const HomeSectionContact = ({ company, isChatWithCompany }: any) => {
  const { user } = useAuth()
  const { openPopupLogin } = useLoginPopupContext()
  const { t } = useTranslation("common")

  const addressParts = []
  if (company.address_string) {
    addressParts.push(company.address_string)
  }
  if (company.city) {
    addressParts.push(company.city)
  }
  if (company.postcode) {
    addressParts.push(company.postcode)
  }

  const askCompanyUrl = `/profil/wiadomosci/cmp_${company.id}`

  const handleProfile = (e: MouseEvent) => {
    if (!user?.id) {
      e.preventDefault()
      e.stopPropagation()
      openPopupLogin({ redirectUrl: askCompanyUrl })
    }
  }

  const hasFindUs = company?.www || company?.facebook || company?.instagram

  return (
    <div
      className={`${!isChatWithCompany && "rounded-[8px] border border-[#E9E9E9] p-5"
        } w-auto  border-[#e9e9e9] md:w-[385px]`}
    >
      {!isChatWithCompany && (
        <p className="mb-5 text-[24px] font-bold text-deep-blue-500">{t("general.contact_us", "Kontakt")}</p>
      )}
      <div className="">
        <_ContactCard title={t("general.contact_details", "Dane kontaktowe")}>
          {company.phone && (
            <ContactRow icon="/svg/phone.svg">
              <PhoneNumber phone={company.phone} companyId={company.id} />
            </ContactRow>
          )}
          {addressParts && addressParts.length > 0 && (
            <ContactRow icon="/svg/map-marker-black.svg">
              <span>{addressParts.join(", ")}</span>
            </ContactRow>
          )}
        </_ContactCard>

        {hasFindUs && (
          <div className="my-8">
            <_ContactCard title="Znajdź nas!">
              <div className="flex">
                {company?.www && (
                  <a
                    href={company.www}
                    className="mr-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/svg/www.svg" width="37" height="37" />
                  </a>
                )}

                {company?.facebook && (
                  <a
                    href={company.facebook}
                    className="mr-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/svg/fb.svg" width="37" height="37" />
                  </a>
                )}

                {company?.instagram && (
                  <a
                    href={company.instagram}
                    className="mr-3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/svg/inst.svg" width="37" height="37" />
                  </a>
                )}
              </div>
            </_ContactCard>
          </div>
        )}
      </div>

      {/* {!isChatWithCompany && (

        <div>
          <div className="w-[215px]">
            <a href={askCompanyUrl} onClick={handleProfile}>
              <MojeButton
                className="rounded-full"
                color="primary"
                variant="contained"
              >
                Zapytaj organizatora
              </MojeButton>
            </a>
          </div>
        </div>
      )} */}
    </div>
  )
}

import { createContext, useContext, useEffect, useState } from "react"

export interface FileUploadedContextType {
  messages: any
  fileUploaded: any
  fileUploadedAction: any
}

export const FileUploadedContext = createContext<FileUploadedContextType>(
  {} as FileUploadedContextType
)

export function useFileUploadedContext(): FileUploadedContextType {
  return useContext(FileUploadedContext)
}

export const FileUploadedContextProvider = ({
  children,
  userApp
}: {
  children: any
  userApp?: boolean
}) => {
  const [messages, setMessages] = useState<any>()
  const [fileUploaded, setFileUploaded] = useState<any>()

  useEffect(() => {
    let attachments = []
    if (messages?.length > 0) {
      messages.forEach((m) => {
        if (m.content && m.type === "attachment") {
          m.content.forEach((c) => {
            attachments.push(c)
          })
        }
      })
    }
    setFileUploaded(attachments)
  }, [messages])

  function updateMessages(messages) {
    setMessages(messages)
  }

  const FileUploadedStore: any = {
    messages,
    fileUploaded,
    fileUploadedAction: {
      updateMessages
    }
  }

  return (
    <FileUploadedContext.Provider value={FileUploadedStore}>
      {children}
    </FileUploadedContext.Provider>
  )
}

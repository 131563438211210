export interface IHeaderPackageProps {
  title: string
  buttonText?: string
  handleClick?: (props: any) => void
  mt?: string
}

export function HeaderPackage({
  title,
  buttonText,
  handleClick,
  mt
}: IHeaderPackageProps) {
  return (
    <div
      className="w-full bg-fc-lightblue-light py-4 px-3 flex justify-between items-center font-medium"
      style={{ marginTop: `${mt}` }}
    >
      <div className="text-fc-black">{title}</div>
      <div className="cursor-pointer text-fc-accent-3" onClick={handleClick}>
        {buttonText}
      </div>
    </div>
  )
}

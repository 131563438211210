export const ParentIcon = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7791_242743)">
        <path
          d="M40.8155 29.4075C42.3905 27.9675 43.403 25.92 43.403 23.625C43.403 19.2825 39.8705 15.75 35.528 15.75C31.838 15.75 28.778 18.2925 27.923 21.7125C27.4955 21.465 27.068 21.285 26.5955 21.15C28.463 19.395 29.633 16.9425 29.633 14.1975C29.633 8.93251 25.3355 4.63501 20.0705 4.63501C14.8055 4.63501 10.508 8.93251 10.508 14.1975C10.508 16.9425 11.7005 19.4175 13.568 21.1725C11.1155 21.8475 8.73047 24.3675 8.73047 29.9025V49.9275H25.7855H45.2705V35.5275C45.2705 32.49 43.358 29.97 40.8155 29.4075ZM35.528 19.125C38.003 19.125 40.028 21.15 40.028 23.625C40.028 26.1 38.003 28.125 35.528 28.125C33.053 28.125 31.028 26.1 31.028 23.625C31.028 21.15 33.053 19.125 35.528 19.125ZM20.0705 8.01001C23.4905 8.01001 26.258 10.7775 26.258 14.1975C26.258 17.6175 23.4905 20.385 20.0705 20.385C16.6505 20.385 13.883 17.6175 13.883 14.1975C13.883 10.7775 16.6505 8.01001 20.0705 8.01001ZM25.7855 37.0575V46.5525H12.1055V29.9025C12.1055 26.505 13.2305 24.3225 14.963 24.3225H25.133C26.6855 24.3225 27.9905 26.055 27.9905 28.08V30.6225C26.7305 31.7925 25.7855 33.8175 25.7855 37.0575ZM41.8955 46.5525H29.1605V37.0575C29.1605 34.425 30.0155 32.67 31.2755 32.67H39.7805C40.928 32.67 41.8955 33.975 41.8955 35.5275V46.5525Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_7791_242743">
          <rect width="54" height="54" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { RadioGroup } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import Heading from "wakacje-web/src/shared/components/Heading"
import { LoadingSpinner } from "../../../../../components/LoadingSpinner"
import { useCompanyContext } from "../../../../../contexts/company-context"
import { usePlacesApi } from "../../../places/usePlacesApi"
import ItemList from "./ItemList"
import { useTranslation } from "react-i18next"

function ItemLists({
  type,
  search,
  selectedItems,
  setSelectedItems,
  handleDeleteItem
}) {
  const { t } = useTranslation()
  const api = usePlacesApi()
  const companyContext = useCompanyContext()
  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState(undefined)
  const [filteredPlaces, setFilteredPlaces] = useState([])
  const isPlace = type === "place"
  const isAccommodation = type === "accommodation"

  const queryParams = {
    company_id: companyContext.company?.id,
    type: type
  }

  useEffect(() => {
    loadPage()
  }, [])

  const loadPage = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await api.listPlaces(queryParams, "50")
      setItems(result.data)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.log("Error", err)
    }
  }, [])

  useEffect(() => {
    setFilteredPlaces(
      items?.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      )
    )
  }, [items])

  useEffect(() => {
    let filteredItems = items?.filter((place) => {
      return place.name.toLowerCase().includes(search.toLowerCase())
    })

    setFilteredPlaces(filteredItems)
  }, [search])

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="mt-5">
      <Heading as="h6" size="h6">
        {isPlace
          ? t("ui.attractions.attractions_list", "Lista atrakcji")
          : t("ui.attractions_holiday_lettings_list", "Lista noclegów")}
      </Heading>

      {filteredPlaces?.length === 0 && (
        <div className="mt-5">
          {isPlace
            ? t(
                "ui.attractions.empty_attractions_list",
                "Brak atrakcji na liście"
              )
            : t(
                "ui.attractions.empty_holiday_lettings_list",
                "Brak noclegów na liście"
              )}
        </div>
      )}

      {items && filteredPlaces?.length > 0 && (
        <div className="mt-3">
          {isPlace && (
            <div>
              {filteredPlaces.map((place) => {
                return (
                  <ItemList
                    key={place.id}
                    type={type}
                    place={place}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    handleDeleteItem={handleDeleteItem}
                  />
                )
              })}
            </div>
          )}
          {isAccommodation && (
            <RadioGroup name="accommodation-group">
              {filteredPlaces.map((place) => {
                return (
                  <ItemList
                    key={place.id}
                    type={type}
                    place={place}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                )
              })}
            </RadioGroup>
          )}
        </div>
      )}
    </div>
  )
}

export default ItemLists

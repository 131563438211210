import { MenuItem, Popover } from '@mui/material'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const LangSupportList = [
  { country: 'pl', name: 'Polska' },
  { country: 'us', name: 'USA' }
]

export const CompanyDropdownMenu = ({ control, watch, register }: any) => {
  const { setValue } = useFormContext()
  const currentCountry = watch('country') ?? 'pl'

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <div className='flex items-center cursor-pointer' onClick={handleClick}>
        <div className='w-8 h-8'>
          <img
            src={`/assets/langs/${currentCountry}.png`}
            width={32}
            height={32}
            className='w-full h-full'
            alt={currentCountry}
          />
        </div>
        <p className='uppercase font-lato ml-4'>
          {currentCountry?.toUpperCase() || ''}{' '}
        </p>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { width: '300px', marginTop: '48px' }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'unset'
          }
        }}
      >
        <ul>
          {LangSupportList.map(({ country, name }) => (
            <MenuItem
              sx={{
                '&.MuiMenuItem-root': {
                  paddingX: '1.25rem',
                  paddingY: '1rem'
                }
              }}
              key={country}
              onClick={() => {
                setValue('country', country)
                handleClose()
              }}
            >
              <div className='w-8 h-8'>
                <img
                  src={`/assets/langs/${country}.png`}
                  width={32}
                  height={32}
                  className='w-full h-full'
                  alt={country}
                />
              </div>
              <p className='uppercase font-lato ml-4'>
                {country.toUpperCase()} / {name}{' '}
              </p>
            </MenuItem>
          ))}
        </ul>
      </Popover>
    </div>
  )
}

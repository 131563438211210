import { useSnackbar } from 'notistack'
import { HeaderPackage } from '../../../components/packages/HeaderPackage'
import { PackageInfo } from '../../../components/packages/PackageInfo'
import { PackageItem } from '../../../components/packages/PackageItem'
import { useCompanyContext } from '../../../contexts/company-context'

import { useCompanyApi } from '../events/api/useCompanyApi'
import { PaymentPopup } from 'wakacje-web/src/shared/payments/PaymentPopup'
import { CreateCompanyFirst } from '../../../components/widgets/CreateCompanyFirst'

import {
  PaymentContextProvider,
  usePaymentContext
} from 'wakacje-web/src/shared/payments/PaymentsContext'

import { FCPackage, getPackageName, packageTypes } from './PackageUtils'
import { differenceInDays, formatDistance, parse } from 'date-fns'
import { pl } from 'date-fns/locale'
import { useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useAuth } from 'wakacje-web/src/auth/AuthContext'
import { useRouter } from 'next/router'

export interface IMyPackageProps {}

function MyPackageBlock ({ company }) {
  var name = getPackageName(company?.license_type)
  console.log('MyPackageBlock', company.license_valid_till)
  console.log(
    'data',
    parse(company.license_valid_till, 'yyyy-MM-dd', new Date())
  )
  const licenseValidTillDate = company.license_valid_till
    ? parse(company.license_valid_till, 'yyyy-MM-dd', new Date())
    : undefined
  var remainingTime = company.license_valid_till
    ? formatDistance(
        new Date(),
        parse(company.license_valid_till, 'yyyy-MM-dd', new Date()),
        {
          locale: pl
        }
      )
    : 'czas nieokreślony'

  var diffInDays = licenseValidTillDate
    ? differenceInDays(licenseValidTillDate, new Date())
    : undefined
  console.log('diffInDays', diffInDays)
  if (diffInDays < 0) {
    remainingTime = 'Licencja wygasła ' + remainingTime + ' temu'
  }
  if (company.license_type === 'TRIAL') {
    return <div>Nie masz jeszcze pakietu</div>
  }
  console.log('Remaining', remainingTime)
  return (
    <PackageInfo
      packageName={name}
      isExpired={diffInDays < 0}
      // activeStatus="Dobiega końca"
      remainingTime={remainingTime}
      boughtOn={company.license_valid_from}
      validUntil={company.license_valid_till}
    />
  )
}

const SelectPackagePage = () => {
  const router = useRouter()
  const companyContext = useCompanyContext()
  const paymentContext = usePaymentContext()
  const companyApi = useCompanyApi()
  const { enqueueSnackbar } = useSnackbar()
  const [packageVisible, setPackageVisible] = useState(false)

  const handlePackageSelected = async (pkg: FCPackage) => {
    try {
      const command = {
        type: 'Company.ActivateLicense',
        aggregateId: companyContext.company.id,
        payload: {
          package_code: pkg.code
        }
      }

      var res = await companyApi.runCommand(command)

      enqueueSnackbar('Zamówienie licencji zapisane!', {
        variant: 'success'
      })
      // setNewOrder(res)
      paymentContext.initPayment({
        type: 'COMPANY_PACKAGE_ORDER',
        package_order_id: res.id,
        redirectUrl: `${
          router?.asPath ?? 'https://admin-test.gofunlo.com'
        }/about/pakiety`
        // poster_order_id: res.id,
      })
      // navigate("/plakaty/" + res.id)
    } catch (err) {
      console.error('Error saving', err)
    }
  }
  if (!companyContext.company) {
    return <CreateCompanyFirst title='Ustaw dane firmy' />
  }

  return (
    <div className='pb-5'>
      <div className='mt-4 mb-8 text-2xl font-bold'>Wybierz pakiet</div>
      {/* <div className="mt-4 mb-8 text-xl font-bold text-center">
        oferta promocyjna
      </div>
      <div className="w-[95%] xl:w-[100%] mx-auto">
        <div className="flex flex-col md:flex-row gap-7 justify-center items-center ">
          {promoPackageTypes.map((p, index) => (
            <PromoPackageCard
              key={index}
              product={p}
              onClick={() => handlePackageSelected(p as FCPackage)}
            />
          ))}
        </div>
      </div> */}

      {/* <div className="mt-4 mb-8 text-xl font-bold text-center">
        oferta standardowa
      </div> */}
      <div
        className='w-full flex flex-col md:flex-row mt-6 md:flex-wrap lg:flex-nowrap space-x-0 md:space-x-4 space-y-4 md:space-y-0
        md:justify-center
      '
      >
        {packageTypes
          .filter((pkg: any) => pkg.active)
          .map((pkg: FCPackage) => (
            <PackageItem
              pkg={pkg}
              packageName={pkg.name}
              price={pkg.price}
              offerVisibility={pkg.offerVisibility}
              description={pkg.description}
              features={pkg.features}
              savings={pkg.savings}
              bestChoice={pkg.bestChoice}
              onClick={() => handlePackageSelected(pkg)}
            />
          ))}
      </div>
      {/* <p
        className="cursor-pointer pl-3 pt-3"
        onClick={() => setPackageVisible(false)}
      >
        Wróć
      </p> */}
    </div>
  )
}

const MyPackagePage = () => {
  const companyContext = useCompanyContext()
  const navigate = useNavigate()
  return (
    <div>
      <HeaderPackage
        title='Twój pakiet'
        buttonText='Zmień pakiet'
        mt='10px'
        handleClick={() => {
          navigate('/about/pakiety/wybierz')
        }}
      />
      {companyContext.company && (
        <MyPackageBlock company={companyContext.company} />
      )}
    </div>
  )
}
function MyPackageInner (props: IMyPackageProps) {
  const companyContext = useCompanyContext()
  const paymentContext = usePaymentContext()
  const companyApi = useCompanyApi()
  const { enqueueSnackbar } = useSnackbar()
  const [packageVisible, setPackageVisible] = useState(false)

  const handlePackageSelected = async (pkg: FCPackage) => {
    try {
      const command = {
        type: 'Company.ActivateLicense',
        aggregateId: companyContext.company.id,
        payload: {
          package_code: pkg.code
        }
      }

      var res = await companyApi.runCommand(command)

      enqueueSnackbar('Zamówienie licencji zapisane!', {
        variant: 'success'
      })
      // setNewOrder(res)
      paymentContext.initPayment({
        type: 'COMPANY_PACKAGE_ORDER',
        package_order_id: res.id
        // poster_order_id: res.id,
      })
      // navigate("/plakaty/" + res.id)
    } catch (err) {
      console.error('Error saving', err)
    }
  }
  if (!companyContext.company) {
    return <CreateCompanyFirst title='Ustaw dane firmy' />
  }

  return (
    <div>
      <div className='w-full px-3'>
        <Routes>
          <Route path='wybierz' element={<SelectPackagePage />} />
          <Route path='*' element={<MyPackagePage />} />
        </Routes>
      </div>
    </div>
  )
}

export function MyPackage (props) {
  const { enqueueSnackbar } = useSnackbar()
  const { auth } = useAuth()
  return (
    <PaymentContextProvider
      rootUrl={process.env.REACT_APP_WAKACJE_API_URL}
      accessToken={auth?.currentUser?.accessToken}
      enqueueSnackbar={enqueueSnackbar}
    >
      <MyPackageInner {...props} />
      <PaymentPopup />
    </PaymentContextProvider>
  )
}

import { Divider, Grid, InputAdornment } from "@mui/material"
import {
  DescriptionList,
  InputField,
  Heading3
} from "../../../../components/FormCommons"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import * as Yup from "yup"
import { ProductTypes, ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { Controller } from "react-hook-form"
import { PriceCentsInput } from "../../../../components/ui/PriceInput"
import { useTranslation } from "react-i18next"
import { getLocalizedKey } from "../../../../utils/getLocalizedKey"
import { useProductUtils } from "../../../../hooks/useProductUtils"
import { useCurrency } from "../../../../hooks/useCurrency"

const productSectionPricingSchema = {
  price_includes_text: Yup.string().required("Opis ceny jest wymagany")
}
const productSectionPricingSchemaSchoolTrip = {
  min_price: Yup.number().required("Cena jest wymagana"),
  price_includes_text: Yup.string().required("Opis ceny jest wymagany")
}

export const ProductSectionPricingForm = ({
  register,
  values,
  control,
  errors
}: any) => {
  const { t } = useTranslation()
  const type = values.type

  function handleScroll(e) {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <>
      {type === ProductTypes.SCHOOL_TRIP && (
        <Controller
          render={({ field }) => (
            <PriceCentsInput
              value={field.value}
              onChange={field.onChange}
              label={`Cena/ 1 osobę*`}
              inputProps={{ min: 1 }}
              error={errors?.full_price?.message}
              fullWidth
            />
          )}
          control={control}
          name="min_price"
        />
        // <InputField label={"Cena/ 1 osobę*"} {...register("min_price")} />
      )}

      <InputField
        multiline
        minRows={4}
        label={"Opis i informacje o zniżkach"}
        {...register("price_description", { required: false })}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputField
            multiline
            minRows={4}
            label={"Cena zawiera*"}
            {...register("price_includes_text")}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            multiline
            minRows={4}
            label={"Cena nie zawiera"}
            {...register("price_not_includes_text")}
          />
        </Grid>
      </Grid>

      {type === ProductTypes.CAMP && (
        <div className="mb-8">
          <Divider sx={{ marginTop: "1rem" }} />

          <div className="my-8">
            <Heading3>{t("ui.discounts", "Zniżki")}</Heading3>
          </div>
          <div className="flex flex-row mb-1 items-center">
            <div className="flex-1">
              <InputField
                label={t("ui.discount_name", "Nazwa zniżki")}
                {...register(`product_payments_discounts_name_1`)}
              />
            </div>
            <div className="ml-3">
              <InputField
                type="number"
                label={t("ui.price_per_person", "Cena / 1 osobę")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">zł</InputAdornment>
                  ),
                  onWheel: handleScroll,
                  onTouchMove: handleScroll
                }}
                {...register(`product_payments_discounts_value_1`)}
              />
            </div>
          </div>

          <div className="flex flex-row mb-1 items-center">
            <div className="flex-1">
              <InputField
                label={t("ui.discount_name", "Nazwa zniżki")}
                {...register(`product_payments_discounts_name_2`)}
              />
            </div>
            <div className="ml-3">
              <InputField
                type="number"
                label={t("ui.price_per_person", "Cena / 1 osobę")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">zł</InputAdornment>
                  ),
                  onWheel: handleScroll,
                  onTouchMove: handleScroll
                }}
                {...register(`product_payments_discounts_value_2`)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const ProductSectionPricingPreview = ({ values }: any) => {
  const { t, i18n } = useTranslation()

  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  console.log("ProductSectionPricingPreview", values)
  var fields: any = {}
  if (values.type === ProductTypes.SCHOOL_TRIP) {
    fields["Cena orientacyjna"] = `${formatPriceInCents(
      values.min_price,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
  }

  fields = {
    ...fields,
    ...{
      [t("ui.groups.discount_description", "Opis i informacje o zniżkach")]:
        values.price_description,
      [t("ui.price_includes", "Cena zawiera")]: values.price_includes_text,
      [t("ui.price_not_include", "Cena nie zawiera")]:
        values.price_not_includes_text
    }
  }

  if (values.type === ProductTypes.CAMP) {
    if (
      ((i18n.language === "pl" && values.product_payments_discounts_name_1) ||
        (i18n.language === "en" &&
          values.product_payments_discounts_name_1_en)) &&
      values.product_payments_discounts_value_1
    ) {
      fields[
        `${t("ui.discount", "Zniżka")}: ${
          values[getLocalizedKey("product_payments_discounts_name_1")]
        } ${t("ui.per_person", "/ 1 osobę")}`
      ] = `${formatPriceInCents(
        values.product_payments_discounts_value_1 * 100,
        values.currency
      )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
    }
    if (
      ((i18n.language === "pl" && values.product_payments_discounts_name_2) ||
        (i18n.language === "en" &&
          values.product_payments_discounts_name_2_en)) &&
      values.product_payments_discounts_value_2
    ) {
      fields[
        `${t("ui.discount", "Zniżka")}: ${
          values[getLocalizedKey("product_payments_discounts_name_2")]
        } ${t("ui.per_person", "/ 1 osobę")}`
      ] = `${formatPriceInCents(
        values.product_payments_discounts_value_2 * 100,
        values.currency
      )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
    }
  }

  return (
    <div>
      <DescriptionList data={fields} />
    </div>
  )
}

export const ProductSectionPricingView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    console.log("Save handler ProductSectionPricingView", data)
    const productData: any = {
      id: values.id,
      min_price: data.min_price,
      price_description: data.price_description,
      price_includes_text: data.price_includes_text,
      price_not_includes_text: data.price_not_includes_text,
      product_payments_discounts_name_1: data.product_payments_discounts_name_1,
      product_payments_discounts_value_1:
        data.product_payments_discounts_value_1,
      product_payments_discounts_name_2: data.product_payments_discounts_name_2,
      product_payments_discounts_value_2:
        data.product_payments_discounts_value_2
    }
    console.log("new product data", data)
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  return (
    <EditableFormSection
      title="Cennik"
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={
        values.type === ProductTypes.SCHOOL_TRIP
          ? productSectionPricingSchemaSchoolTrip
          : productSectionPricingSchema
      }
    >
      {(builderProps: any) => {
        console.log("Builder props", builderProps)
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionPricingForm values={values} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionPricingPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import { WakacjeChip, WakacjeChipVariant } from "../components/WakacjeChip"
import { BookingStatus } from "../../shared/wakacje-types"

const getBookingStatusChipVariant = (booking) => {
  if (
    booking.status === BookingStatus.NEW ||
    booking.status === BookingStatus.RESERVED
  ) {
    return WakacjeChipVariant.YELLOW
  }
  if (
    booking.status === BookingStatus.ADVANCE_PAID ||
    booking.status === BookingStatus.FULLY_PAID
  ) {
    return WakacjeChipVariant.GREEN
  }
  if (
    booking.status === BookingStatus.CANCELED ||
    booking.status === BookingStatus.RESIGNED ||
    booking.status === BookingStatus.REJECTED
  ) {
    return WakacjeChipVariant.RED
  }

  if (booking.status === BookingStatus.REQUEST) {
    return WakacjeChipVariant.BLUE
  }
  return WakacjeChipVariant.GREY
}
export const BookingStatusChip = ({ booking }) => {
  const variant = getBookingStatusChipVariant(booking)

  return (
    <WakacjeChip
      label={booking.status_label ?? booking.status}
      variant={variant}
    />
  )
}

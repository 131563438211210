import { useFormContext } from "react-hook-form"
import { InputField, SectionTitle } from "../../../../components/FormCommons"
import { SectionContainer } from "./CreateOfferForm"

const PriceField = ({ label, value }: { label: string; value: number }) => {
  return (
    <div className="flex gap-5 items-center mb-5">
      <p className="text-gray-500 text-sm w-[100px]">{label}</p>
      <p>{value} zł</p>
    </div>
  )
}

function FormSectionPrice() {
  const { watch, register } = useFormContext()
  const base_price_per_person = watch("base_price_per_person")
  const transport_price_per_pax = watch("transport_price_per_pax")
  const accommodation_price_per_person = watch("accommodation_price_per_person")

  const places = watch("places")

  let attractionPriceSum = places?.reduce(function (prev, current) {
    return current.price ? prev + +current.price : prev
  }, 0)

  let totalPrice = () => {
    let price = 0
    if (!isNaN(base_price_per_person)) {
      price += base_price_per_person
    }
    if (!isNaN(transport_price_per_pax)) {
      price += transport_price_per_pax
    }
    if (!isNaN(accommodation_price_per_person)) {
      price += accommodation_price_per_person
    }
    if (!isNaN(attractionPriceSum)) {
      price += attractionPriceSum
    }
    return price
  }

  return (
    <SectionContainer>
      <div className="mb-5">
        <SectionTitle>Cena</SectionTitle>
      </div>

      <div>
        {!isNaN(base_price_per_person) && (
          <PriceField
            label="Oferta/ 1 os."
            value={watch("base_price_per_person")}
          />
        )}
        {!isNaN(transport_price_per_pax) && transport_price_per_pax > 0 && (
          <PriceField
            label="Dojazd/ 1 os."
            value={watch("transport_price_per_pax")}
          />
        )}

        {!isNaN(accommodation_price_per_person) &&
          accommodation_price_per_person > 0 && (
            <PriceField
              label="Nocleg/ 1 os."
              value={watch("accommodation_price_per_person")}
            />
          )}

        {!isNaN(attractionPriceSum) && attractionPriceSum > 0 && (
          <PriceField label="Atrakcje/ 1 os." value={attractionPriceSum} />
        )}

        <hr className="mb-5" />

        {totalPrice() > 0 && (
          <PriceField label="Całość/ 1 os." value={totalPrice()} />
        )}

        <div className="w-40">
          <InputField
            label="Termin płatności*"
            type="date"
            InputLabelProps={{ shrink: true }}
            {...register("payment_date")}
          />
        </div>
      </div>
    </SectionContainer>
  )
}

export default FormSectionPrice

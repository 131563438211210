import axios from "axios"

export const useSettingsApi = () => {
  const list = async (query = {}, pagination: any) => {
    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/settings`
    var res = await axios.get(url)
    return res.data
  }

  return { list }
}

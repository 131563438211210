import { MenuItem, Popover } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PopoverArrow } from "../popover/PopoverArrow"

const LangThreeLetter = {
  en: "eng",
  pl: "pol"
}

const LangSupportList = [
  { lang: "pl", name: "Polski" },
  { lang: "en", name: "English" }
]

export const LanguageDropdownMenu = () => {
  const { i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const lang = i18n.language?.split("-")[0]

  return (
    <div>
      <div className="flex items-center cursor-pointer" onClick={handleClick}>
        <div className="w-8 h-8">
          <img
            src={`/assets/langs/${lang}.png`}
            width={32}
            height={32}
            className="w-full h-full"
            alt={lang}
          />
        </div>
        <p className="uppercase font-lato ml-4">
          {LangThreeLetter[lang] || ""}
        </p>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { width: "300px", marginTop: "48px" }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          "& .MuiPaper-root": {
            overflow: "unset"
          }
        }}
      >
        <PopoverArrow />
        <ul>
          {LangSupportList.map(({ lang, name }) => (
            <MenuItem
              sx={{
                "&.MuiMenuItem-root": {
                  paddingX: "1.25rem",
                  paddingY: "1rem"
                }
              }}
              key={lang}
              onClick={() => {
                i18n.changeLanguage(lang)
                handleClose()
              }}
            >
              <div className="w-8 h-8">
                <img
                  src={`/assets/langs/${lang}.png`}
                  width={32}
                  height={32}
                  className="w-full h-full"
                  alt={lang}
                />
              </div>
              <p className="uppercase font-lato ml-4">
                {LangThreeLetter[lang] || ""} / {name}
              </p>
            </MenuItem>
          ))}
        </ul>
      </Popover>
    </div>
  )
}

import styled from "styled-components"

export const PopoverArrow = styled.div`
  position: absolute;
  top: -16px;
  right: 0;
  width: 16px;
  height: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 50%) rotate(-45deg);
  clip-path: polygon(
    -8px -8px,
    calc(100% + 8px) -8px,
    calc(100% + 8px) calc(100% + 8px)
  );
`

// src/contexts/ExchangeRatesContext.js
import React, { createContext, useState, useEffect } from "react"

export const ExchangeRatesContext = createContext(null)

export const ExchangeRatesProvider = ({ children }) => {
  const [exchangeRates, setExchangeRates] = useState(null)

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          "https://ycwe1mkvi6.execute-api.eu-central-1.amazonaws.com/dev/currencies/v1.0"
        )
        const data = await response.json()

        const rates = {}
        data.forEach((item) => {
          rates[item.currency] = item.dollars_exchange
        })

        setExchangeRates(rates)
      } catch (error) {
        console.error("Error fetching exchange rates:", error)
      }
    }

    fetchExchangeRates()
  }, [])

  return (
    <ExchangeRatesContext.Provider value={exchangeRates}>
      {children}
    </ExchangeRatesContext.Provider>
  )
}

export const LogoutIcon = () => {
  return (
    <svg
      className="fill-current"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6336_3182)">
        <path d="M3.95996 19.25H11.46C11.7914 19.2496 12.1091 19.1178 12.3434 18.8835C12.5778 18.6491 12.7096 18.3314 12.71 18V16.125H11.46V18H3.95996V3H11.46V4.875H12.71V3C12.7096 2.6686 12.5778 2.35087 12.3434 2.11654C12.1091 1.8822 11.7914 1.75038 11.46 1.75H3.95996C3.62856 1.75038 3.31084 1.8822 3.0765 2.11654C2.84216 2.35087 2.71034 2.6686 2.70996 3V18C2.71034 18.3314 2.84216 18.6491 3.0765 18.8835C3.31084 19.1178 3.62856 19.2496 3.95996 19.25Z" />
        <path d="M13.0762 13.3663L15.3175 11.125H6.45996V9.875H15.3175L13.0762 7.63375L13.96 6.75L17.71 10.5L13.96 14.25L13.0762 13.3663Z" />
      </g>
      <defs>
        <clipPath id="clip0_6336_3182">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.209961 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

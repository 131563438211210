import { Box } from "@mui/system"
import {
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_GROUPS,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"
import { ProductSectionSuperAdminView } from "./forms/ProductSectionSuperAdmin"
import { useAuthContext } from "../../../contexts/auth-context"
import { ProductJoinedSectionsCamp } from "./forms/ProductJoinedSectionsCamp"
import { ProductJoinedSectionsDaycamp } from "./forms/ProductJoinedSectionsDaycamp"
import { ProductJoinedSectionsSchoolTrip } from "./forms/ProductJoinedSectionsSchoolTrip"
import ProductJoinedSectionsGroupsAccommodation from "../groups/ProductJoinedSectionsGroupsAccommodation"

export const ProductSummaryPageJoinedForms = ({
  product,
  refetch
}: {
  product: any
  refetch: Function
}) => {
  const authContext = useAuthContext()

  console.log("[ProductSummary]: product", product)

  return (
    <Box
      sx={{
        display: "flex",
        gap: "3rem",
        justifyContent: "flex-start"
      }}
    >
      {product.type === PRODUCT_TYPE_CAMP.value && (
        <div className="flex-1">
          <ProductJoinedSectionsCamp values={product} refetch={refetch} />
        </div>
      )}
      {product.type === PRODUCT_TYPE_DAYCAMP.value && (
        <div className="flex-1">
          <ProductJoinedSectionsDaycamp values={product} refetch={refetch} />
        </div>
      )}
      {product.type === PRODUCT_TYPE_SCHOOL_TRIP.value && (
        <div className="flex-1">
          <ProductJoinedSectionsSchoolTrip values={product} refetch={refetch} />
        </div>
      )}
      {product.type === PRODUCT_TYPE_GROUPS.value && (
        <div className="flex-1">
          <ProductJoinedSectionsGroupsAccommodation
            values={product}
            refetch={refetch}
          />
        </div>
      )}
      {authContext.isSuperAdmin && (
        <Box width={300}>
          <ProductSectionSuperAdminView values={product} refetch={refetch} />
        </Box>
      )}
    </Box>
  )
}

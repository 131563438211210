import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { WakacjeChip, WakacjeChipVariant } from "../WakacjeChip"

export const ProductTypeChip = ({ type }: { type: string }) => {
  var variants: any = {
    kolonie: WakacjeChipVariant.RED,
    polkolonie: WakacjeChipVariant.GREEN,
    "wycieczka-szkolna": WakacjeChipVariant.BLUE,
    "zielona-szkola": WakacjeChipVariant.BLUE,
    "noclegi-dla-grup": WakacjeChipVariant.BLUE
  }
  return (
    <WakacjeChip
      variant={variants[type]}
      label={ProductUtils.getTypeName(type)}
    />
  )
}

import { Box } from "@mui/system"
import { ApiTable } from "../../../components/table/ApiTable"
import axios from "axios"
import { FormattedDate, FormattedTime } from "react-intl"
import { BookingStatusChip } from "wakacje-web/"

import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"
import { ProductTypeChip } from "../../../components/events/ProductTypeChip"

const SuperMessagesTable = ({ queryParams }) => {
  const list = async (queryParams: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL + "/messages/v1/admin/chats"
    if (queryParams) {
      url += "?" + new URLSearchParams(queryParams).toString()
    }
    var res = await axios.get(url)
    return res.data
  }
  const columns = [
    {
      header: "Data dodania",
      cell: (row: any) => {
        var isRead = !!row.read_at
        return (
          <div className={`${!isRead ? " font-bold " : ""}`}>
            {row.sent_at ? (
              <>
                <FormattedDate value={row.sent_at} />{" "}
                <FormattedTime value={row.sent_at} />
              </>
            ) : (
              <span />
            )}
          </div>
        )
      }
    },
    {
      header: "Imię i nazwisko",
      field: "user",
      cell: (row: any) => (
        <div className="text-sm">
          <div>{row.user_name ?? "-"}</div>
          <div>{row.user_email ?? "-"}</div>
          <div>{row.user_phone ?? "-"}</div>
        </div>
      )
    },
    {
      header: "Firma",
      field: "company",
      cell: (row: any) => (
          <div className="text-sm">{row.company_name ?? "-"}</div>
      )
    },
    {
      header: "Nazwa wydarzenia",
      field: "company",
      cell: (row: any) => (
        <div>
          <div className="text-sm font-bold">{row.product_name}</div>
          {row.booking_start_date && (
            <div className="text-sm font-bold">
              {row.booking_start_date} - {row.booking_end_date}
            </div>
          )}
        </div>
      )
    },
    {
      header: "Typ",
      field: "type",
      cell: (row: any) => (
        <div className="text-sm">
          <ProductTypeChip type={row.product_type ?? "brak"} />
        </div>
      )
    },

    // {
    //   header: "Rodzaj",
    //   cell: (row: any) => (
    //     <div>

    //     </div>
    //   ),
    // },
    {
      header: "Treść",
      cell: (row: any) => (
        <div className="text-sm max-w-[240px] font-bold">
          {row.type === "MESSAGE" && row.direction === "TO_USER" && <><span>{row.company_name ? (row.company_name + ": ") : ""}</span><span>{row.content_text}</span></>}
          {row.type === "MESSAGE" && row.direction === "FROM_USER" && <><span>{row.user_name ? (row.user_name + ": ") : ""}</span><span>{row.content_text}</span></>}
          {row.type === "BOOKING_DEPOSIT_PAID" && (
            <span className="font-bold">Depozyt opłacony</span>
          )}
          {row.type === "BOOKING_FULLY_PAID" && (
            <span className="font-bold">Całość opłacona</span>
          )}
          {row.type === "BOOKING_CONFIRMED" && (
            <span className="font-bold">Rezygnacja</span>
          )}
          {row.type === "NEW_BOOKING" && (
            <span className="font-bold">Nowa rezerwacja</span>
          )}
        </div>
      )
    },
    {
      header: "Nr rezerwacji",
      cell: (row: any) => (
        <div className="flex flex-col text-sm">
          {row.chat_hash && row.chat_hash.indexOf("book_") === 0 && (
            <>
              <a
                href={`/bookings/${row.chat_hash}`}
                target="_blank"
                rel="noreferrer"
                className="underline text-sm"
              >
                {row.chat_hash}
              </a>
              {row.booking_status && (
                <div className="mt-1">
                  <BookingStatusChip
                    booking={{
                      status: row.booking_status
                    }}
                  />
                </div>
              )}
            </>
          )}

          {row.chat_hash && row.chat_hash.indexOf("book_") === -1 && (
            <div>Zapytanie</div>
          )}
        </div>
      )
    }

    // {
    //   header: "Id",
    //   field: "id",
    // },
  ]
  return (
    <ApiTable
      columns={columns}
      listApi={list}
      showExpandColumn
      // rowLinkBuilder={(row: any) => `/super/chats`}
    ></ApiTable>
  )
}

export function SuperAdminMessages() {
  const { setSearchTextDebounced, queryParams } = useSearchText()

  return (
    <div className="mt-10">
      <Box width="100%">
        <Toolbar setSearchTextDebounced={setSearchTextDebounced} api={[]} />
        <SuperMessagesTable queryParams={queryParams} />
      </Box>
    </div>
  )
}

import { SchoolTripBookingsTable } from "./SchoolTripBookingsTable"

export const SchoolTripBookingsPage = ({
  product,
  onSaved,
  refetch
}: {
  product: any
  onSaved: Function
  refetch: any
}) => {
  console.log("SchoolTripBookingsPage")
  return (
    <div className="w-full bg-red">
      <SchoolTripBookingsTable data={product.bookings} product={product} />
    </div>
  )
}

import { FcTabPanel, FcTabsHeader } from "../../../components/FcTabs/FcTabs"
import { SuperAdminKids } from "./SuperAdminKids"

import { useTabs } from "../useTabs"
import { SuperAdminUsers } from "./SuperAdminUsers"

export const SuperAdminAccounts = () => {
  const { selectedTab, handleTabChange } = useTabs()

  return (
    <>
      <h1 className="text-[32px] font-bold">Konta</h1>
      <FcTabsHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabs={["Firmy", "Użytkownicy", "Dzieci"]}
      />

      <div className="w-full">
        <FcTabPanel selectedTab={selectedTab} index={0}>
          <SuperAdminUsers accountType="MERCHANT" />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={1}>
          <SuperAdminUsers accountType="USER" />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={2}>
          <SuperAdminKids />
        </FcTabPanel>
      </div>
    </>
  )
}

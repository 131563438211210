import * as yup from "yup"
import { DescriptionList } from "../../../../components/FormCommons"
import { useFormContext, useFormState } from "react-hook-form"
import { useEffect, useState } from "react"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useCompanyApi } from "../../events/api/useCompanyApi"
import { MojeTextInput } from "../../../../components/ui/MojeTextInput"

export const companyAdminContactDetailsSchema = {
  admin_first_name: yup.string().required("Imię administratora jest wymagane"),
  admin_last_name: yup
    .string()
    .required("Nazwisko administratora jest wymagane"),
  admin_title: yup.string(),
  admin_phone: yup.string().required("Numer telefonu jest wymagany"),
  admin_email: yup
    .string()
    .email("Błędny format email")
    .required("Adres email jest wymagany"),
}

export const CompanySectionAdminContactDetailsForm = () => {
  const { register } = useFormContext()
  const { errors } = useFormState()
  return (
    <>
      <MojeTextInput
        label="*Imię"
        {...register("admin_first_name")}
        error={errors.admin_first_name?.message}
        fullWidth
      />
      <MojeTextInput
        label="*Nazwisko"
        {...register("admin_last_name")}
        fullWidth
        error={errors.admin_last_name?.message}
      />
      <MojeTextInput
        label="Stanowisko"
        {...register("admin_title")}
        fullWidth
        error={errors.admin_title?.message}
      />
      <MojeTextInput
        label="*Numer telefonu"
        {...register("admin_phone")}
        fullWidth
        error={errors.admin_phone?.message}
      />
      <MojeTextInput
        label="*Adres e-mail"
        {...register("admin_email")}
        fullWidth
        error={errors.admin_email?.message}
      />
    </>
  )
}

export const CompanySectionAdminContactDetailsPreview = ({ values }: any) => {
  return (
    <div>
      <DescriptionList
        data={{
          Imię: values.admin_first_name,
          Nazwisko: values.admin_last_name,
          Stanowisko: values.admin_title,
          "Numer telefonu": values.admin_phone,
          "Adres e-mail": values.admin_email,
        }}
      />
    </div>
  )
}

export const CompanySectionAdminContactDetails = ({
  values,
  refetch,
  onSave,
}: {
  onSave?: Function
  values: any
  refetch: Function
}) => {
  const { updateCompany } = useCompanyApi()
  const [val, setValue] = useState(values)

  const saveHandler = async (data: any) => {
    var updateData: any = {
      id: values.id,
      admin_first_name: data.admin_first_name,
      admin_last_name: data.admin_last_name,
      admin_title: data.admin_title,
      admin_phone: data.admin_phone,
      admin_email: data.admin_email,
    }
    if (onSave) {
      return onSave(updateData)
    }
    return updateCompany(updateData)
  }
  const handleRefresh = () => {
    refetch()
  }
  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const update = (data: object) => {
    setValue(data)
  }

  return (
    <EditableFormSection
      title="Dane kontaktowe - dla administratora"
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionAdminContactDetailsForm
                values={val}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <CompanySectionAdminContactDetailsPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

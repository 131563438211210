export const useCategories = (t) => {
  return [
    {
      id: "artystyczne.fotografia",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.photography", "Fotografia"),
      created_at: "2022-04-13T06:06:24.225Z",
      archived_at: null
    },
    {
      id: "artystyczne.makijaz",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.makeup", "Makijaż"),
      created_at: "2022-04-13T06:06:22.956Z",
      archived_at: null
    },
    {
      id: "artystyczne.muzyczne",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.music", "Muzyczne"),
      created_at: "2022-04-14T03:56:02.918Z",
      archived_at: null
    },
    {
      id: "artystyczne.malowanie",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.painting", "Plastyczne"),
      created_at: "2022-04-13T06:06:20.420Z",
      archived_at: null
    },
    {
      id: "artystyczne.szycie",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.sewing", "Szycie"),
      created_at: "2022-04-13T06:06:21.693Z",
      archived_at: null
    },
    {
      id: "artystyczne.teatralne",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.theatre", "Teatralne"),
      created_at: "2022-04-13T06:06:25.491Z",
      archived_at: null
    },
    {
      id: "jezykowe.angielski",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.english", "Angielski"),
      created_at: "2022-04-13T06:05:53.700Z",
      archived_at: null
    },
    {
      id: "jezykowe.francuski",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.french", "Francuski"),
      created_at: "2022-04-13T06:05:56.220Z",
      archived_at: null
    },
    {
      id: "jezykowe.hiszpański",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.spanish", "Hiszpański"),
      created_at: "2022-04-13T06:05:54.961Z",
      archived_at: null
    },
    {
      id: "jezykowe.inny",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.others", "Inne"),
      created_at: "2022-04-13T06:05:58.756Z",
      archived_at: null
    },
    {
      id: "jezykowe.niemiecki",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.german", "Niemiecki"),
      created_at: "2022-04-13T06:05:57.487Z",
      archived_at: null
    },
    {
      id: "komputerowe.e-sport",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.e-sport", "E-sport"),
      created_at: "2022-04-13T06:06:26.769Z",
      archived_at: null
    },
    {
      id: "komputerowe.influencer",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.influencer", "Influencer"),
      created_at: "2022-04-13T06:06:28.043Z",
      archived_at: null
    },
    {
      id: "komputerowe.programowanie",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.programming", "Programowanie"),
      created_at: "2022-04-13T06:06:30.577Z",
      archived_at: null
    },
    {
      id: "komputerowe.roboty-drony",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.robotics_and_drones", "Robotyka i drony"),
      created_at: "2022-04-13T06:06:31.833Z",
      archived_at: null
    },
    {
      id: "komputerowe.youtuber",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.youtuber", "Youtuber"),
      created_at: "2022-04-13T06:06:29.316Z",
      archived_at: null
    },
    {
      id: "lokalizacja.gory",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.mountains", "Góry"),
      created_at: "2022-04-13T06:06:08.987Z",
      archived_at: null
    },
    {
      id: "lokalizacja.jeziora",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.lakes", "Jeziora"),
      created_at: "2022-04-13T06:06:10.253Z",
      archived_at: null
    },
    {
      id: "lokalizacja.las",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.forest", "Las"),
      created_at: "2022-04-13T06:06:14.067Z",
      archived_at: null
    },
    {
      id: "lokalizacja.miasto",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.city", "Miasto"),
      created_at: "2022-04-13T06:06:12.796Z",
      archived_at: null
    },
    {
      id: "lokalizacja.morze",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.sea", "Morze"),
      created_at: "2022-04-13T06:06:07.698Z",
      archived_at: null
    },
    {
      id: "lokalizacja.rzeka",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.river", "Rzeka"),
      created_at: "2022-05-20T09:55:20.087Z",
      archived_at: null
    },
    {
      id: "lokalizacja.wies",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.village", "Wieś"),
      created_at: "2022-04-13T06:06:11.523Z",
      archived_at: null
    },
    {
      id: "pozostale.harcerskie",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.scouting", "Harcerskie"),
      created_at: "2022-04-13T05:58:06.963Z",
      archived_at: null
    },
    {
      id: "pozostale.kulinarne",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.culinary", "Kulinarne"),
      created_at: "2022-05-20T09:38:46.783Z",
      archived_at: null
    },
    {
      id: "pozostale.lego",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.lego", "Lego"),
      created_at: "2022-05-20T09:38:48.165Z",
      archived_at: null
    },
    {
      id: "pozostale.magia-fantasy",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.magic_and_fantasy", "Magia i fantasy"),
      created_at: "2022-09-19T10:56:43.232Z",
      archived_at: null
    },
    {
      id: "pozostale.naukowe",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.scientific", "Naukowe"),
      created_at: "2022-06-07T10:20:49.153Z",
      archived_at: null
    },
    {
      id: "pozostale.ogolnorekreacyjny",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.general_recreational", "Ogólnorekreacyjny"),
      created_at: "2022-04-13T05:58:05.704Z",
      archived_at: null
    },
    {
      id: "pozostale.przygodowe",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.adventurous", "Przygodowe"),
      created_at: "2022-05-20T09:38:45.385Z",
      archived_at: null
    },
    {
      id: "pozostale.wedrowne",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.wandering", "Wędrowne"),
      created_at: "2022-09-19T10:57:01.297Z",
      archived_at: null
    },
    {
      id: "sportowe.akrobatyka",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.acrobatics", "Akrobatyka"),
      created_at: "2022-04-19T13:41:46.321Z",
      archived_at: null
    },
    {
      id: "sportowe.bieganie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.running", "Bieganie"),
      created_at: "2022-09-15T10:22:14.269Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.dogoterapia",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.dog_therapy", "Dogoterapia"),
      created_at: "2022-06-03T07:31:02.554Z",
      archived_at: null
    },
    {
      id: "sportowe.hokej",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.hockey", "Hokej"),
      created_at: "2022-09-15T10:22:21.944Z",
      archived_at: null
    },
    {
      id: "sportowe.jezdzieckie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.equestrian", "Jeździeckie"),
      created_at: "2022-04-13T06:05:48.610Z",
      archived_at: null
    },
    {
      id: "sportowe.joga",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.yoga", "Joga"),
      created_at: "2022-06-03T07:29:57.521Z",
      archived_at: null
    },
    {
      id: "sportowe.kitesurfing",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.kitesurfing", "Kitesurfing"),
      created_at: "2022-04-13T06:05:43.524Z",
      archived_at: null
    },
    {
      id: "sportowe.lyzwiarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.ice_skating", "Łyżwiarstwo"),
      created_at: "2022-09-15T10:22:28.758Z",
      archived_at: null
    },
    {
      id: "sportowe.narciarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.skiing", "Narciarstwo"),
      created_at: "2022-09-15T10:22:37.842Z",
      archived_at: null
    },

    {
      id: "sportowe.nordic-walking",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.nordic_walking", "Nordic Walking"),
      created_at: "2022-09-15T10:22:56.113Z",
      archived_at: null
    },
    {
      id: "sportowe.nurkowanie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.diving", "Nurkowanie"),
      created_at: "2022-06-03T07:29:58.899Z",
      archived_at: null
    },
    {
      id: "sportowe.paintball",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.paintball", "Paintball"),
      created_at: "2022-09-15T10:23:02.258Z",
      archived_at: null
    },
    {
      id: "sportowe.pilkarskie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.football", "Piłkarskie"),
      created_at: "2022-04-13T06:05:46.065Z",
      archived_at: null
    },
    {
      id: "sportowe.plywanie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.swimming", "Pływanie"),
      created_at: "2022-06-03T07:30:00.328Z",
      archived_at: null
    },
    {
      id: "sportowe.rowerowe",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.cycling", "Rowerowe"),
      created_at: "2022-04-13T06:05:52.443Z",
      archived_at: null
    },
    {
      id: "sportowe.saneczkarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.luge", "Saneczkarstwo"),
      created_at: "2022-09-15T10:23:11.503Z",
      archived_at: null
    },
    {
      id: "sportowe.siatkarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.volleyball", "Siatkarstwo"),
      created_at: "2022-09-15T10:23:18.546Z",
      archived_at: null
    },
    {
      id: "sportowe.skitouring",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.skitouring", "Skitouring"),
      created_at: "2022-09-15T10:23:25.812Z",
      archived_at: null
    },
    {
      id: "sportowe.snowboard",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.snowboarding", "Snowboard"),
      created_at: "2022-09-15T10:23:34.229Z",
      archived_at: null
    },
    {
      id: "sportowe.Strzeleckie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.shooting", "Strzeleckie"),
      created_at: "2022-06-03T07:30:01.783Z",
      archived_at: null
    },
    {
      id: "sportowe.survival",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.survival", "Survival"),
      created_at: "2022-04-13T06:05:49.873Z",
      archived_at: null
    },
    {
      id: "sportowe.szachy",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.chess", "Szachy"),
      created_at: "2022-05-20T09:40:28.772Z",
      archived_at: null
    },
    {
      id: "sportowe.sztuki-walki",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.martial_arts", "Sztuki Walki"),
      created_at: "2022-04-19T13:41:52.844Z",
      archived_at: null
    },
    {
      id: "sportowe.taniec",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.dance", "Taniec"),
      created_at: "2022-04-13T06:05:51.186Z",
      archived_at: null
    },
    {
      id: "sportowe.tenisowe",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.tennis", "Tenis"),
      created_at: "2022-04-13T06:05:47.339Z",
      archived_at: null
    },
    {
      id: "sportowe.wedkarskie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.fishing", "Wędkarskie"),
      created_at: "2022-04-13T06:05:40.992Z",
      archived_at: null
    },
    {
      id: "sportowe.windsurfing",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.windsurfing", "Windsurfing"),
      created_at: "2022-04-13T06:05:42.265Z",
      archived_at: null
    },
    {
      id: "sportowe.wspinaczka",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.climbing", "Wspinaczka"),
      created_at: "2022-04-13T06:05:44.788Z",
      archived_at: null
    },
    {
      id: "sportowe.zeglarskie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.sailing", "Żeglarskie"),
      created_at: "2022-04-13T05:58:08.221Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.odwykowe",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t("categories.rehabilitation", "Odwykowe"),
      created_at: "2022-04-13T06:06:15.351Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.wychowawcze",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t("categories.educational", "Wychowawcze"),
      created_at: "2022-04-13T06:06:19.155Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.zaburzenia-emocjonalne",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t(
        "categories.emotional_disorders",
        "Zaburzenia emocjonalne"
      ),
      created_at: "2022-04-13T06:06:17.890Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.zaburzenia-zywienia",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t("categories.eating_disorders", "Zaburzenia żywienia"),
      created_at: "2022-04-13T06:06:16.620Z",
      archived_at: null
    }
  ]
}

export const getDietOptions = (t) => {
  return [
    {
      name: t("product.no_gluten", "Bez glutenu"),
      value: "no_gluten"
    },
    {
      name: t("product.no_eggs", "Bez kurzych jaj"),
      value: "no_eggs"
    },
    {
      name: t("product.no_lactose", "Bez laktozy"),
      value: "no_lactose"
    },
    {
      name: t("product.no_nuts", "Bez orzechów"),
      value: "no_nuts"
    },
    {
      name: t("product.no_fish", "Bez ryb"),
      value: "no_fish"
    },
    {
      name: t("product.vegan", "Dieta Wegańska"),
      value: "vegan"
    },
    {
      name: t("product.vegetarian", "Dieta Wegetariańska"),
      value: "vegetarian"
    }
  ]
}

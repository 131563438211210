import { Controller, useFormState } from "react-hook-form"
import {
  DescriptionList,
  FormHelperText,
  FormSectionSeparator,
  Heading3,
  InputField
} from "../../../components/FormCommons"
import { PriceCentsInput } from "../../../components/ui/PriceInput"
import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useProductApi } from "../events/api/useProductApi"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { useTranslation } from "react-i18next"
import { t } from "i18next"
import { useCurrency } from "../../../hooks/useCurrency"
import { useProductUtils } from "../../../hooks/useProductUtils"

export const ProductSectionPriceForm = ({
  register,
  values,
  control,
  watch
}: any) => {
  const { errors } = useFormState()
  const watchPrepayment = watch("prepayment")
  const watchTouristTax = watch("tourist_tax")
  const watchDependentPrice = watch("dependent_price")
  const watchCancellationFee = watch("cancellation_fee")

  return (
    <div className="mb-8">
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.price", "Cena")}
      </Heading3>
      <div className="mb-4">
        <FormHelperText>
          {t(
            "ui.groups.price_description",
            "Wpisz orientacyjną cenę za osobę dla indywidualnych gości oraz grup. Opisz jeśli oferujesz zniżki dla grup lub zależne od wieku gości."
          )}
        </FormHelperText>
      </div>

      <div className="w-100">
        <div className="flex flex-row items-center gap-[10px]">
          <span className="text-[16px] pr-[5px]">
            {t(
              "ui.groups.for_individual_guests",
              "Dla indywidualnych gości od"
            )}
          </span>
          <Controller
            render={({ field }) => (
              <PriceCentsInput
                value={field.value}
                onChange={field.onChange}
                type="number"
                label={`${t(
                  "ui.groups.enter_price_for_single_person",
                  "Wpisz cenę/1 osobę"
                )}*`}
                error={errors?.individual_min_price?.message}
              />
            )}
            control={control}
            name="individual_min_price"
          />
        </div>
      </div>
      <div className="w-100">
        <div className="flex flex-row items-center gap-[10px]">
          <span className="text-[16px]">
            {t("ui.groups.for_organised_group", "Dla zorganizowanej grupy od")}
          </span>
          <Controller
            render={({ field }) => (
              <PriceCentsInput
                value={field.value}
                onChange={field.onChange}
                type="number"
                label={`${t(
                  "ui.groups.enter_price_for_single_person",
                  "Wpisz cenę/1 osobę"
                )}*`}
                error={errors?.group_min_price?.message}
              />
            )}
            control={control}
            name="group_min_price"
          />
        </div>
      </div>
      <InputField
        multiline
        minRows={4}
        label={t(
          "ui.groups.discount_description",
          "Opis i informacje o zniżkach"
        )}
        {...register("price_description", { required: false })}
      />
      <div className="border-t border-[#E9E9E9] my-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.prepayment", "Przedpłata")}
      </Heading3>
      <div className="mb-4"></div>
      <Controller
        name="prepayment"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <div className="flex flex-row">
              <FormControlLabel
                label={t("ui.groups.yes", "Tak")}
                value="tak"
                control={<Radio value="tak" />}
              />
              <FormControlLabel
                label={t("ui.groups.no", "Nie")}
                value="nie"
                control={<Radio value="nie" />}
              />
            </div>
          </RadioGroup>
        )}
      />
      {watchPrepayment === "tak" && (
        <>
          <div className="mb-4" />
          <FormHelperText>
            {t(
              "ui.groups.prepayment_terms_description",
              "Jeśli oczekujesz przedpłaty, napisz jakie są jej warunki"
            )}
          </FormHelperText>
          <InputField
            label={t("ui.groups.prepayment_terms", "Warunki przedpłaty")}
            multiline
            minRows={3}
            value={watch("prepayment_description")}
            {...register("prepayment_description")}
          />
        </>
      )}
      <div className="border-t border-[#E9E9E9] my-8" />
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.resort_tax", "Opłata klimatyczna")}
      </Heading3>
      <div className="mb-4"></div>
      <Controller
        name="tourist_tax"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <div className="flex flex-row">
              <FormControlLabel
                label={t("ui.groups.yes", "Tak")}
                value="tak"
                control={<Radio value="tak" />}
              />
              <FormControlLabel
                label={t("ui.groups.no", "Nie")}
                value="nie"
                control={<Radio value="nie" />}
              />
            </div>
          </RadioGroup>
        )}
      />
      {watchTouristTax === "tak" && (
        <>
          <div className="mb-4" />
          <FormHelperText>
            {t(
              "ui.groups.resort_tax_terms_description",
              "Jeśli pobierasz opłatę klimatyczną, napisz jakie są jej warunki"
            )}
          </FormHelperText>
          <InputField
            label={t(
              "ui.groups.resort_tax_terms",
              "Warunki opłaty klimatycznej"
            )}
            multiline
            minRows={3}
            value={watch("tourist_tax_description")}
            {...register("tourist_tax_description")}
          />
        </>
      )}
      <div className="border-t border-[#E9E9E9] my-8" />
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.dependent_price", "Cena zależna")}
      </Heading3>
      <div className="mb-4"></div>
      <Controller
        name="dependent_price"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <div className="flex flex-row">
              <FormControlLabel
                label={t("ui.groups.yes", "Tak")}
                value="tak"
                control={<Radio value="tak" />}
              />
              <FormControlLabel
                label={t("ui.groups.no", "Nie")}
                value="nie"
                control={<Radio value="nie" />}
              />
            </div>
          </RadioGroup>
        )}
      />
      {watchDependentPrice === "tak" && (
        <>
          <div className="mb-4" />
          <FormHelperText>
            {t(
              "ui.groups.price_dependent_description",
              "Jeśli cena za osobo-dobę, zależna jest od np. sezonu, opisz to w tym miejscu"
            )}
          </FormHelperText>
          <InputField
            label={t("ui.groups.price_terms", "Warunki cen")}
            multiline
            minRows={3}
            value={watch("dependent_price_description")}
            {...register("dependent_price_description")}
          />
        </>
      )}
      <div className="border-t border-[#E9E9E9] my-8" />
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.resign_price", "Opłata za rezygnację")}
      </Heading3>
      <div className="mb-4"></div>
      <Controller
        name="cancellation_fee"
        control={control}
        render={({
          field,
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <RadioGroup {...field}>
            <div className="flex flex-row">
              <FormControlLabel
                label={t("ui.groups.yes", "Tak")}
                value="tak"
                control={<Radio value="tak" />}
              />
              <FormControlLabel
                label={t("ui.groups.no", "Nie")}
                value="nie"
                control={<Radio value="nie" />}
              />
            </div>
          </RadioGroup>
        )}
      />
      {watchCancellationFee === "tak" && (
        <>
          <div className="mb-4" />
          <FormHelperText>
            {t(
              "ui.groups.resign_price_description",
              "Jeśli pobierasz opłatę za rezygnację, opisz swoje warunki w tym miejscu"
            )}
          </FormHelperText>
          <InputField
            label={t(
              "ui.groups.resign_price_terms",
              "Warunki opłaty za rezygnację"
            )}
            multiline
            minRows={3}
            value={watch("cancellation_fee_description")}
            {...register("cancellation_fee_description")}
          />
        </>
      )}
    </div>
  )
}

export const ProductSectionPrice = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()
  const { t } = useTranslation()

  const saveHandler = async (data: any) => {
    const productData: any = {
      id: values.id
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }

  return (
    <EditableFormSection
      title={t("ui.groups.price_and_payments", "Cena i płatności")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      //validationSchema={schema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionPriceForm values={values} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionPricePreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

export const ProductSectionPricePreview = ({ values }: any) => {
  const { t } = useTranslation()

  const { selectedCurrency } = useCurrency()

  const { formatPriceInCents } = useProductUtils()

  var fields: any = {}

  fields = {
    [t("ui.groups.individual_price_per_person", "Cena indywidualna / 1 osobę")]:
      values.individual_min_price
        ? `${t("ui.groups.from", "od")} ${formatPriceInCents(
            values.individual_min_price,
            values.currency
          )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
        : "-",
    [t("ui.groups.group_price_per_person", "Cena grupowa / 1 osobę")]:
      values.group_min_price
        ? `${t("ui.groups.from", "od")} ${formatPriceInCents(
            values.group_min_price,
            values.currency
          )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
        : "-",
    [t("ui.groups.description_header", "Opis")]: values.price_description ?? "-"
  }
  return (
    <div>
      <DescriptionList data={fields} />
      <div className="mx-4">
        <FormSectionSeparator />
      </div>
      <DescriptionList
        separator
        data={{
          [t("ui.groups.prepayment", "Przedpłata")]:
            values.prepayment === "tak"
              ? t("ui.groups.yes", "Tak")
              : t("ui.groups.no", "Nie"),
          "": values.prepayment === "tak" && values.prepayment_description
        }}
      />
      <DescriptionList
        separator
        data={{
          [t("ui.groups.resort_tax", "Opłata klimatyczna")]:
            values.tourist_tax === "tak"
              ? t("ui.groups.yes", "Tak")
              : t("ui.groups.no", "Nie"),
          "": values.tourist_tax === "tak" && values.tourist_tax_description
        }}
      />
      <DescriptionList
        separator
        data={{
          [t("ui.groups.dependent_price", "Cena zależna")]:
            values.dependent_price === "tak"
              ? t("ui.groups.yes", "Tak")
              : t("ui.groups.no", "Nie"),
          "":
            values.dependent_price === "tak" &&
            values.dependent_price_description
        }}
      />
      <DescriptionList
        data={{
          [t("ui.groups.resign_price", "Opłata za rezygnację")]:
            values.cancellation_fee === "tak"
              ? t("ui.groups.yes", "Tak")
              : t("ui.groups.no", "Nie"),
          "":
            values.cancellation_fee === "tak" &&
            values.cancellation_fee_description
        }}
      />
      <div className="mb-8" />
    </div>
  )
}

import { IconButton, InputAdornment, TextField } from "@mui/material"
import SearchIcon from "../icons/SearchIcon"

export const SearchBoxWithIcon = ({ setSearchTextDebounced }) => {
  return (
    <div className="flex items-center gap-3">
      <div>
        <TextField
          onChange={(ev) => setSearchTextDebounced(ev.target.value)}
          placeholder="Wyszukaj"
          InputProps={{
            style: {
              height: "52px",
              borderRadius: "8px"
            },
            endAdornment: (
              <InputAdornment position="end" className="relative right-2">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => console.log("click")}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
    </div>
  )
}

import { useTranslation } from "react-i18next"
import { useProductApi } from "../../pages/organizer/events/api/useProductApi"
import { TripDataDialogMain } from "./TripDataDialogMain"
import { format } from "date-fns"

/**
 * TODO: For now, I'm substituting full_price and payment_due_date for the base items
 * Since we're not changing them on the client app yet, this is to prevent any issues with
 * displaying and adding prices and dates for currently active camps/colonies.
 */
export const EditTripDataDialog = ({ open, product, onClose }) => {
  const { updateTrip } = useProductApi()
  const { t } = useTranslation()

  const handleSave = async (data: any) => {
    const productData: any = {
      ...product,
      ...data,
      start_date: format(data.start_date, "yyyy-MM-dd"),
      end_date: format(data.end_date, "yyyy-MM-dd")
    }

    productData.payment_due_date = format(data.payment_due_date, "yyyy-MM-dd")

    return updateTrip(product.product_id, productData)
  }

  return (
    <TripDataDialogMain
      open={open}
      product={product}
      onClose={onClose}
      onSubmit={handleSave}
      defaultValues={product}
      headerTitle={t("ui.trips.edit_term", "Edytuj termin")}
    />
  )
}

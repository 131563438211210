import { InputAdornment } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { InputField, SectionTitle } from "../../../../components/FormCommons"
import { AgeSelectDropdown } from "../../../../components/forms/AgeSelectDropdown"
import { SectionContainer } from "./CreateOfferForm"

function FormSectionParticipant() {
  const { register } = useFormContext()
  return (
    <SectionContainer>
      <SectionTitle>Uczestnicy</SectionTitle>

      <div className="flex flex-row gap-5">
        <div className="w-40">
          <InputField
            type="number"
            label="Ilosc dzieci*"
            {...register("participants_count", { valueAsNumber: true })}
          />
        </div>
        <div className="w-40">
          <InputField
            type="number"
            label="Cena/1 os*"
            InputProps={{
              endAdornment: <InputAdornment position="end">zł</InputAdornment>
            }}
            {...register("base_price_per_person", { valueAsNumber: true })}
          />
        </div>
      </div>

      <div className="w-40 mb-5">
        <InputField
          type="number"
          label="Gratis opiekun*"
          {...register("free_pax_count", { valueAsNumber: true })}
        />
      </div>

      <div className="mb-2 font-bold">Wiek</div>

      <div className="flex flex-row gap-5">
        <div className="w-40">
          <AgeSelectDropdown
            label="Od lat*"
            {...register("min_age", { valueAsNumber: true })}
          />
        </div>
        <div className="w-40">
          <AgeSelectDropdown
            label="Do lat*"
            {...register("max_age", { valueAsNumber: true })}
          />
        </div>
      </div>
    </SectionContainer>
  )
}

export default FormSectionParticipant

import { PosterSectionPayment } from "./PosterSectionPayment"
import { TextField } from "@mui/material"
import { MojeButton } from "../../components/ui/MojeButton"
import { PosterPage } from "./PosterEditor"
import { PosterSectionDelivery } from "./PosterSectionDelivery"

// const PlakatPreview = ({ order, template }: any) => {
//   const ratio = 1
//   console.log("PlakatPreview", order, template)
//   return (
//     <PosterPage
//       template={template.poster}
//       scale={ratio}
//       values={order.data}
//       setField={() => {}}
//       editable={false}
//     />
//   )
// }
const PackageDescription = ({ order, template }: any) => {
  const ratio = 1
  return (
    <div className="mb-4 flex flex-row items-center">
      <div className="mr-8 ">
        Szablon: <strong>250,00 zł</strong>
      </div>
      <div className="mr-8 text-sm text-center">
        <div className="bg-gray-200 rounded-lg mb-4">
          <PosterPage
            template={template.poster}
            scale={ratio}
            values={order.data}
            setField={() => {}}
            editable={false}
          />
        </div>
        <div className="font-bold text-sm ">plakat A3</div>
        <div>10 sztuk</div>
      </div>
      <div className="mr-8 text-sm text-center">
        <div className="bg-gray-200 rounded-lg mb-4">
          <div>
            <PosterPage
              template={template.brochureFront}
              scale={0.7}
              values={order.data}
              setField={() => {}}
              editable={false}
            />
          </div>
        </div>
        <div className="font-bold text-sm ">ulotka A5</div>
        <div>50 sztuk</div>
      </div>
    </div>
  )
}

export const NewPosterSummaryPage = ({
  order,
  posterTemplate,
  onUpdate,
  onNext,
  onBack
}: {
  order: any
  posterTemplate: any
  onUpdate: Function
  onNext: Function
  onBack: Function
}) => {
  const isReady = order.delivery_type
  return (
    <div>
      <div className="my-2 text-lg mb-8">
        Poniżej znajdziesz podsumowanie opracowanego projektu.{" "}
      </div>
      <div className="px-16">
        <PackageDescription order={order} template={posterTemplate} />

        <PosterSectionDelivery order={order} onUpdate={onUpdate} />

        <PosterSectionPayment order={order} />
      </div>
      <div className="mt-8 mb-2">
        Jeżeli chcesz zwiększyć ilość materiałów reklamowych, opłać promocyjny
        Pakiet na Fajny Czas i napisz wiadomość poniżej (gwarantujemy rok
        darmowego dostępu do portalu), a my skontaktujemy się z Tobą.
      </div>
      <div className="w-full my-2">
        <TextField
          name="message"
          placeholder="Napisz do nas"
          fullWidth
          onChange={(event) => {
            onUpdate({ special_request: event.target.value })
          }}
        />
      </div>
      {/* <div>
        {!order.delivery_type && <>
          Wybierz 
        </>}
      </div> */}
      <div>
        <div className="flex flex-row">
          <div>
            <MojeButton
              label="Powrót"
              onClick={onBack}
              variant="text"
              color="secondary"
            />
          </div>
          <div className="flex-1" />
          <div>
            <MojeButton
              disabled={!isReady}
              label="Przejdź do płatności"
              onClick={onNext}
              variant="contained"
              color="primary"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const XIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0938 10.527L20.7937 1.82695C21.0937 1.52695 21.0937 1.02695 20.7937 0.726953C20.4937 0.426953 19.9938 0.426953 19.6938 0.726953L10.9937 9.42695L2.29375 0.726953C1.99375 0.426953 1.49375 0.426953 1.19375 0.726953C0.89375 1.02695 0.89375 1.52695 1.19375 1.82695L9.89375 10.527L1.19375 19.227C0.89375 19.527 0.89375 20.027 1.19375 20.327C1.39375 20.427 1.59375 20.527 1.79375 20.527C1.99375 20.527 2.19375 20.427 2.29375 20.327L10.9937 11.627L19.6938 20.327C19.7938 20.427 19.9938 20.527 20.1938 20.527C20.3938 20.527 20.5938 20.427 20.6938 20.327C20.9938 20.027 20.9938 19.527 20.6938 19.227L12.0938 10.527Z"
        fill="black"
      />
    </svg>
  )
}

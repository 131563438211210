
export const pageTitleWrapper:any ={
	display: 'flex',
	flexFlow: 'row',
	justifyContent: 'space-between',
	padding: { xs: "32px 64px", sm: "32px 64px", md: "32px 64px" }
} ;

export const pageTitle:any = {
	fontFamily: 'Mulish',
			fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '30px',
		letterSpacing: '0.01em',
		textAlign: 'left',
		fontSize:{xs:'24px' , sm:'24px' , md:'32px'}
} ;

export const accordionBox: any = {
	padding: {
		xs: '0px 24px',
		sm: '0px 45px',
		md: '0px 94px 0px 71px',
		lg: '0px 64px 0px 71px',
	},
};

export const accordionTitle: any = {

	width: '100%',
	flexShrink: 0,
	fontFamily: 'Lato',
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: 700,
	lineHeight: '23px',
	letterSpacing: '0em',
	textAlign: 'left',
	padding: {
		sm: '0',
	},
};

export const accordionText:any ={
	fontFamily: 'Lato',
	fontSize: '16px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: '25.6px',
	letterSpacing: '0.01em',
	textAlign: 'left',
	flex: 'none',
	order: '0',
	alignSelf: 'stretch',
flexGrow: 0,
} ;

export const accordionContainer:any = {
	boxShadow: 'none',
	'&:last-child': {
		borderRadius: 0,
		boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 12%)',
	},
} ;

import React from "react"
import { Routes, Route, Link, Navigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import EventIcon from "@mui/icons-material/EventOutlined"
import MessageIcon from "@mui/icons-material/ChatOutlined"
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined"
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined"
import { AddProduct } from "./events/AddProduct"
import { MessagesIndex } from "./messages/MessagesIndex"
import { PaymentsIndex } from "./payments/PaymentsIndex"
import { MyCompanyIndex } from "./company/MyCompanyIndex"
import { HelpIndex } from "./help/HelpIndex"
import { ProductsIndex } from "./products/ProductsIndex"
import { PlacesIndex } from "./places/PlacesIndex"
import { useCompanyContext } from "../../contexts/company-context"
import { EventsIndex } from "./events/Events"
import { SideMenu, SideMenuProps } from "../../wakacje-ui/components/SideMenu"
import { PostersIndex } from "../posters/PostersIndex"
import HomeDashboard from "./HomeDashboard"
import { BookingPage } from "../booking/BookingPage"
import { InquiryPage } from "./booking/InquiryPage"
import { ChatPage } from "./messages/ChatPage"
import { useCompanyHomeContext } from "../../contexts/company-home-context"
import { NewSchoolTripPage } from "./school-trips/NewSchoolTripPage"
import { useTranslation } from "react-i18next"
import { BookingsIndex } from "./booking/BookingsIndex"
import { AddIndividualSchoolTripOffer } from "./school-trips/AddIndividualSchoolTripOffer"
import MarketingIndex from "./booking/marketing/MarketingIndex"
import RegulaminIndex from "./booking/regulamin/RegulaminIndex"
import NewGroupsAccommodationsPage from "./groups/NewGroupsAccommodationsPage"

const UnreadMessagesMarker = () => {
  const companyHomeContext = useCompanyHomeContext()

  if (companyHomeContext.unreadMessagesCount) {
    return (
      <div
        className="rounded-full text-white p-[2px] px-[7px] text-[10px]"
        style={{
          background: "var(--fc-color-1-red)"
        }}
      >
        {companyHomeContext.unreadMessagesCount}
      </div>
    )
  }
  return <span />
}

const EndAdornment = ({ label }) => {
  return (
    <div className="bg-[color:var(--fc-color-3)] text-white text-xxs px-2  rounded-lg">
      {label}
    </div>
  )
}

const SideMenuRoutes = (t: any): SideMenuProps["routes"] => [
  {
    label: t("ui.menu.products", "OFERTA"),
    secondary: [
      {
        pathname: "/products/camp",
        label: t("ui.menu.camps", "Kolonie i obozy"),
        icon: <EventIcon />
      },
      {
        pathname: "/products/daycamp",
        label: t("ui.menu.daycamps", "Półkolonie"),
        icon: <EventIcon />
      },
      {
        pathname: "/products/schooltrip",
        label: t("ui.menu.school_trips", "Wycieczki szkolne"),
        icon: <EventIcon />
        // endAdornment: <EndAdornment label={t("ui.menu.new", "nowość")} />
      },
      {
        pathname: "/products/groups",
        label: t("ui.menu.groups", "Noclegi dla grup"),
        icon: <EventIcon />
      },
      {
        pathname: "/places/place",
        label: t("ui.menu.places_and_accomodation", "Atrakcje i noclegi"),
        icon: (
          <img src="/svg/attraction-icon.svg" width={20} height={20} alt="" />
        )
        // endAdornment: <EndAdornment label={t("ui.menu.new", "nowość")} />
      }
    ]
  },
  {
    label: t("ui.menu.management", "ZARZĄDZANIE").toUpperCase(),
    secondary: [
      {
        pathname: "/bookings-list",
        label: t("ui.menu.booking", "Rezerwacje"),
        icon: <EventIcon />
      },
      {
        pathname: "/inquiries",
        label: t("ui.menu.inquiries", "Zapytania"),
        icon: <EventIcon />
      }
      // {
      //   pathname: "/messages",
      //   label: t("ui.menu.messages", "Wiadomości"),
      //   icon: <MessageIcon />
      // }
    ]
  },
  {
    label: t("ui.menu.my_account", "MOJE KONTO"),
    secondary: [
      {
        pathname: "/about/informacje",
        label: t("ui.menu.about_company", "O organizatorze"),
        icon: <PermIdentityOutlinedIcon />
      },

      {
        pathname: "/marketing",
        label: t("ui.menu.marketing", "Marketing"),
        icon: <CampaignOutlinedIcon />
      },

      {
        pathname: "/pomoc",
        label: t("ui.menu.help", "Pomoc"),
        icon: <HelpOutlineOutlinedIcon />
      },

      {
        pathname: "/regulamin",
        label: t("ui.menu.statute", "Regulamin"),
        icon: <GavelOutlinedIcon />
      }
    ]
  }
]

function LinkComponent({
  href,
  children
}: {
  href: string
  children?: React.ReactNode
}) {
  return <Link to={href}>{children}</Link>
}

export function RouteScreen({
  secondaryActive,
  children
}: {
  secondaryActive?: number
  children: React.ReactNode
}) {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const { t } = useTranslation()

  return (
    <>
      {md && (
        <div className="md:w-1/3 xl:w-1/4 bg-[#F9FAFD]">
          <SideMenu
            routes={SideMenuRoutes(t)}
            secondaryActive={secondaryActive}
            linkComponent={LinkComponent}
          />
        </div>
      )}
      <div className="w-full md:w-2/3 xl:w-3/4">{children}</div>
    </>
  )
}

export default function Organizers() {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const merchantContext = useCompanyContext()
  const { t } = useTranslation()

  return (
    <Routes>
      {merchantContext.company && (
        <Route path={"/events/add"} element={<AddProduct />} />
      )}

      {/* <Route path="/products/schooltrip/*">
        <Route
          path="new"
          element={
            <RouteScreen>
              <NewSchoolTripPage />
            </RouteScreen>
          }
        />
        <Route
          path="list"
          element={
            <RouteScreen>
              <SchoolTripsIndex />
            </RouteScreen>
          }
        />
        <Route
          path=":id/*"
          element={
            <RouteScreen>
              <div>school trip details</div>
              <ProductsIndex />
            </RouteScreen>
          }
        />

        <Route index element={<Navigate to="/products/schooltrip/list" />} />
      </Route> */}

      <Route
        path="/products/schooltrip/new"
        element={
          <RouteScreen>
            <NewSchoolTripPage />
          </RouteScreen>
        }
      />
      <Route
        path="/products/groups/new"
        element={
          <RouteScreen>
            <NewGroupsAccommodationsPage />
          </RouteScreen>
        }
      />
      <Route
        path="/products/schooltrip/individual-offer/:id/:chat_hash"
        element={
          <RouteScreen>
            <AddIndividualSchoolTripOffer />
          </RouteScreen>
        }
      />
      <Route
        path="/products/schooltrip/individual-offer/:id/:chat_hash/:trip_id/details"
        element={
          <RouteScreen>
            <AddIndividualSchoolTripOffer isUpdateForm />
          </RouteScreen>
        }
      />
      <Route
        path="/products/:type/*"
        element={
          <RouteScreen>
            <ProductsIndex />
          </RouteScreen>
        }
      />

      <Route
        path="/places/*"
        element={
          <RouteScreen>
            <PlacesIndex />
          </RouteScreen>
        }
      />

      <Route
        path="/bookings-list/*"
        element={
          <RouteScreen>
            <BookingsIndex />
          </RouteScreen>
        }
      />
      <Route
        path="/inquiries"
        element={
          <RouteScreen>
            <InquiryPage />
          </RouteScreen>
        }
      ></Route>
      <Route
        path="*"
        element={
          md ? (
            <Navigate to="/" replace />
          ) : (
            <SideMenu
              routes={SideMenuRoutes(t)}
              linkComponent={LinkComponent}
            />
          )
        }
      />
      <Route
        path="/wydarzenia/*"
        element={
          <RouteScreen>
            <EventsIndex />
          </RouteScreen>
        }
      ></Route>
      <Route
        path="/messages"
        element={
          <RouteScreen>
            <MessagesIndex />
          </RouteScreen>
        }
      />

      <Route path="/payments">
        <Route index element={<Navigate to="paymentForm" />} />
        <Route
          path=":tab/*"
          element={
            <RouteScreen>
              <PaymentsIndex />
            </RouteScreen>
          }
        />
      </Route>

      <Route path="/about">
        <Route index element={<Navigate to="informacje" />} />
        <Route
          path=":tab/*"
          element={
            <RouteScreen>
              <MyCompanyIndex />
            </RouteScreen>
          }
        />
      </Route>

      <Route
        path="/plakaty/*"
        element={
          <RouteScreen>
            <PostersIndex />
          </RouteScreen>
        }
      />
      <Route
        path="/bookings/:id"
        element={
          <RouteScreen>
            <BookingPage />
          </RouteScreen>
        }
      ></Route>
      <Route
        path="/chats/:id"
        element={
          <RouteScreen>
            <ChatPage />
          </RouteScreen>
        }
      ></Route>
      <Route
        path="/marketing"
        element={
          <RouteScreen>
            <MarketingIndex />
          </RouteScreen>
        }
      />
      <Route
        path="/pomoc"
        element={
          <RouteScreen>
            <HelpIndex />
          </RouteScreen>
        }
      />
      <Route
        path="/regulamin"
        element={
          <RouteScreen>
            <RegulaminIndex />
          </RouteScreen>
        }
      />
      <Route
        path="/"
        element={
          <RouteScreen>
            <HomeDashboard />
          </RouteScreen>
        }
      />
    </Routes>
  )
}

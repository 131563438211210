import { Tooltip, useMediaQuery } from "@mui/material"
import { getIn, useFormikContext } from "formik"
import { useEffect, useRef, useState } from "react"
import { CheckCircleIcon } from "../../components/icons/CheckCircleIcon"

const CheckItem = ({ label, check }: any) => {
  return (
    <div className={"flex items-center gap-3"}>
      <CheckCircleIcon
        className={check ? "text-[#21E57B]" : "text-[rgba(255,255,255,0.3)]"}
      />
      <span className={check ? "font-bold" : "font-light"}>{label}</span>
    </div>
  )
}

export const PasswordTooltip = ({ children, setFieldValue }: any) => {
  const { values } = useFormikContext()

  const ref = useRef<HTMLDivElement>(undefined as never)

  const [open, setOpen] = useState(false)

  const matches = useMediaQuery("(min-width:768px)")

  const watchPassword = getIn(values, "password")

  const length = watchPassword.length >= 8
  const upperAndLower =
    /[A-Z]/.test(watchPassword) && /[a-z]/.test(watchPassword)
  const digit = /[0-9]/.test(watchPassword)
  const vaild = /[a-zA-Z]/.test(watchPassword)
  const specialSigns = /[^a-zA-Z0-9@$!%*#?&]/.test(watchPassword)

  useEffect(() => {
    setFieldValue(
      "passwordCorrect",
      length && upperAndLower && digit && vaild && !specialSigns
    )
  }, [length, upperAndLower, digit, vaild, specialSigns])

  useEffect(() => {
    let input
    const handleFocus = () => {
      setOpen(true)
    }
    const handleBlur = () => {
      setOpen(false)
    }
    if (ref.current) {
      input = ref.current.querySelector("input")
      if (input) {
        input.addEventListener("focus", handleFocus)
        input.addEventListener("blur", handleBlur)
      }
    }
    return () => {
      if (input) {
        input.removeEventListener("focus", handleFocus)
        input.removeEventListener("blur", handleBlur)
      }
    }
  }, [ref])

  return (
    <Tooltip
      title={
        <div className="flex flex-col gap-3.5">
          Hasło musi zawierać:
          <CheckItem label="co najmniej 8 znaków" check={length} />
          <CheckItem label="wielką i małą literę" check={upperAndLower} />
          <CheckItem label="cyfrę" check={digit} />
          Opcjonalny:
          <CheckItem label="znaki specjalne: @$!%*#?&" check={!specialSigns} />
        </div>
      }
      arrow
      open={open}
      placement={matches ? "right" : "top"}
      componentsProps={{
        arrow: {
          sx: {
            fontSize: 20,
            color: "#222222"
          }
        },
        tooltip: {
          sx: {
            borderRadius: "8px",
            padding: "16px 16px 24px 16px",
            backgroundColor: "#222222",
            fontSize: "0.875rem"
          }
        }
      }}
    >
      <div className="grid" ref={ref}>
        {children}
      </div>
    </Tooltip>
  )
}

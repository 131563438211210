import {
  WakacjeChip,
  WakacjeChipVariant
} from "../../../components/WakacjeChip"
import { GroupTypeChip } from "./GroupTypeChip"
import { OptionButton } from "../places/OptionButton"

export const groupTableColumns = (
  handleDuplicateOffer,
  handleDeleteOffer,
  mapObjectTypes,
  t
) => {
  return [
    {
      header: t("ui.groups.facility_name", "Nazwa obiektu"),
      cell: (row: any) => (
        <>
          <div className="font-bold text-lg mb-2 leading-tight">{row.name}</div>
          {row.status && (
            <div>
              <WakacjeChip
                label={row.status}
                variant={WakacjeChipVariant.GREEN}
                showStatus={true}
              />
            </div>
          )}
        </>
      ),
      sort: false
    },
    {
      header: t("ui.groups.category", "Kategoria"),
      cell: () => {
        return (
          <div className="whitespace-nowrap">
            <GroupTypeChip />
          </div>
        )
      },
      sort: false
    },
    {
      header: t("ui.groups.facility_type", "Rodzaj obiektu"),
      cell: (row: any) => {
        return (
          <>
            <p className="whitespace-nowrap">
              {row.object_type ? mapObjectTypes(row.object_type) : "-"}
            </p>
          </>
        )
      },
      sort: false
    },
    {
      header: t("ui.groups.location", "Lokalizacja"),
      cell: (row: any) => (
        <>
          <p className="whitespace-nowrap">
            {row.location.street +
              " " +
              row.location.house_number +
              "/" +
              row.location.room_number +
              ", " +
              row.location.city +
              " " +
              row.location.post_code}
          </p>
        </>
      ),
      sort: false
    },
    {
      header: t("ui.school_trips.options", "Opcje"),
      cell: (row: any) => {
        return (
          <div>
            <OptionButton
              row={row}
              options={["editOffer", "duplicate", "remove"]}
              handleDuplicate={handleDuplicateOffer}
              handleDelete={handleDeleteOffer}
              rowType="offer"
            />
          </div>
        )
      }
    }
  ]
}

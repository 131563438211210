export const chatBlockWrapper:any = {
	display: 'flex',
	flexDirection:{xs:'column' , sm:'row'},
	width: '100%',
	justifyContent: 'space-between',
};


export const infoBlock = {
	display:'flex',
	alignItems:'center',

} ;

export const buttonMark = {
	color:'#0C48D7',
	width:'48px',
	height: '48px',
	marginRight:'16px',
	backgroundColor:'rgba(12, 72, 215, 0.05)',
};

export const chatButton = {
	height: '40px',
	margin: {xs: '20px 0', sm: '0 16px'},
	backgroundColor: '#FFFFFF',
	border: '1px solid #042675',
	color: '#042675',
} ;
import React from "react"
import { FormControl, InputLabel, MenuItem, Radio, Select } from "@mui/material"

interface RadioDropdownProps {
  setValue: any
  value: any
  items: ItemsType[]
  label: string
  labelId: string
  fieldIdentifier: string
}

type ItemsType = {
  id: string | number
  name: string
}

const RadioDropdown = ({
  setValue,
  value,
  items,
  label,
  labelId,
  fieldIdentifier
}: RadioDropdownProps) => {
  const selectedCategory = value

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(fieldIdentifier, event.target.value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        value={selectedCategory || ""}
        onChange={handleChange}
        renderValue={(selected) =>
          items.find((item) => item.id === selected)?.name || ""
        }
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Radio
              checked={selectedCategory === item.id}
              value={item.id}
              color="primary"
            />
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default RadioDropdown

import React from "react"
import { SearchBox } from "../../../components/table/SearchBox"
import { SuperDataExportButton } from "../SuperDataExport"

function Toolbar({
  setSearchTextDebounced,
  setSearchValue,
  api,
  children,
  queryParams
}: {
  setSearchTextDebounced: any
  setSearchValue?: any
  api: any
  children?: any
  queryParams?: any
}) {
  return (
    <div className="flex flex-row items-center w-full mb-2 mt-4 gap-4">
      <SearchBox setSearchTextDebounced={setSearchTextDebounced} setSearchValue={setSearchValue} />
      {children}
      <div className="flex-1" />
      {/* <SuperDataExportButton api={api} queryParams={queryParams} /> */}
    </div>
  )
}

export default Toolbar

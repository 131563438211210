import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Modal
} from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material"
import startOfYesterday from "date-fns/startOfYesterday"
import { useReducer, useState } from "react"
import { MojeButton } from "../../components/MojeButton"
import { UidUtils } from "../../utils/UidUtils"
import { useFileUpload } from "../../utils/useFileUpload"
import Heading from "../components/Heading"
import AttachFileList from "./AttachFileList"
import { AnimatePresence, motion } from "framer-motion"
import { XIcon } from "../../components/Icon/XIcon"

type Attachment = {
  status: string
}

type StateType = {
  attachments: Array<Attachment>
}

const Actions = {
  FILE_SELECTED: "FILE_SELECTED"
}

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.FILE_SELECTED:
      return { ...startOfYesterday }
  }
}

export const AttachFilePopup = ({ onClose, open, chatHash, onSend }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))
  const [isSending, setIsSending] = useState(false)
  const [uploading, setUploading] = useState(false)

  const { uploadFile, isUploading } = useFileUpload()
  const [state, dispatch] = useReducer(reducer, {
    attachments: []
  })
  const [uploadedFiles, setUploadedFiles] = useState([])

  const [currentUpload, setCurrentUpload] = useState()

  const handleFileChanged = async event => {
    console.log("handleFileChanged: ", event)

    if (event.target.files.length > 0) {
      const nextFile = event.target.files[0]
      console.log("nextFile", nextFile)
      try {
        setUploading(true)
        const req = {
          type: "chat",
          chat_hash: chatHash
        }
        setCurrentUpload(nextFile)
        var res: any = await uploadFile(req, nextFile)
        console.log("File uploaded: ", res)
        if (res && res.key) {
          const newFile = {
            id: UidUtils.generateUid(),
            key: res.key,
            name: nextFile.name,
            bucket: res.bucket
          }
          console.log("Adding file", newFile)
          setUploadedFiles([
            ...uploadedFiles,
            {
              name: newFile.name,
              id: newFile.id,
              key: newFile.key,
              bucket: newFile.bucket,
              type: "file"
            }
          ])
          setCurrentUpload(undefined)
        }
        setUploading(false)
      } catch (err) {
        setUploading(false)
        console.error("Error uploading file")
      }
    }
  }

  const handleRemoveAttachment = id => {
    const filteredFiles = uploadedFiles.filter(item => item.id !== id)
    setUploadedFiles(filteredFiles)
    //clear file input
    var input: any = document.getElementById("fileDialog")
    input.value = ""
  }

  if (!mobile) {
    return (
      <Dialog fullScreen={mobile} onClose={onClose} open={open}>
        <div className="p-8 w-full md:w-[620px]">
          <Heading as="h3" size="h3" className="mb-12">
            Dodaj załącznik
          </Heading>

          {currentUpload && (
            <div className="flex flex-col items-center mx-5 mb-5">
              <div className="mb-2">Wgrywam plik</div>
              <CircularProgress />
            </div>
          )}

          <div className="pt-2">
            <div className=" font-bold text-lg">Załącznik z komputera</div>

            <div className="mt-3 mb-16">
              <div className="mb-5">
                {uploadedFiles &&
                  uploadedFiles.map((uf: any, i) => {
                    return (
                      <div key={i}>
                        <AttachFileList
                          uf={uf}
                          handleRemoveAttachment={handleRemoveAttachment}
                          idx={i}
                        />
                      </div>
                    )
                  })}
              </div>

              <MojeButton
                label="Dodaj pliki"
                onClick={ev => {
                  document.getElementById("fileDialog").click()
                }}
                fullWidth
                variant="outlined"
                color="menteBrandBlue"
                disabled={uploading}
              />
              <div style={{ display: "none" }}>
                <input
                  type="file"
                  id={`fileDialog`}
                  onChange={event => handleFileChanged(event)}
                />
              </div>
            </div>
            {/* <RegistrationForm redirectUrl={loginPopupContext.redirectUrl} /> */}

            <div className="flex flex-row ">
              <div className="flex-1" />
              <div className="mr-8">
                <MojeButton
                  label="Anuluj"
                  onClick={onClose}
                  variant="text"
                  color="menteBrandBlue"
                  disabled={uploading}
                />
              </div>
              <div className="">
                <MojeButton
                  label="Dodaj"
                  onClick={async () => {
                    setIsSending(true)
                    try {
                      var res = await onSend(uploadedFiles)
                    } catch (error) {}

                    setUploadedFiles([])
                    setIsSending(false)
                  }}
                  variant="contained"
                  isSaving={isSending}
                  disabled={uploading}
                />
              </div>
            </div>
          </div>

          {/* <div className="mt-10">
          <div className=" font-bold text-lg">Załączniki z portalu</div>
          <p>Coming soon...</p>
        </div> */}
        </div>
      </Dialog>
    )
  } else {
    return (
      <AnimatePresence>
        {open && (
          <Modal open={open} className="w-full h-full">
            <motion.div
              initial={{ y: 1000 }}
              animate={{ y: 15 }}
              transition={{ duration: 0.5 }}
              exit={{ y: 1000 }}
              className="fixed 
              flex flex-col justify-between
            bg-white
              bg-opacity-100
              bottom-0 left-0 z-50
              rounded-2xl
              overflow-auto
              w-full h-[70%] 
              py-5 "
            >
              <div className="p-8 w-full md:w-[620px]">
                <Heading as="h3" size="h3" className="mb-12">
                  Dodaj załącznik
                </Heading>

                {currentUpload && (
                  <div className="flex flex-col items-center mx-5 mb-5">
                    <div className="mb-2">Wgrywam plik</div>
                    <CircularProgress />
                  </div>
                )}

                <div className="pt-2">
                  <div className=" font-bold text-lg">
                    Załącznik z komputera
                  </div>

                  <div className="mt-3 mb-16">
                    <div className="mb-5">
                      {uploadedFiles &&
                        uploadedFiles.map((uf: any, i) => {
                          return (
                            <div key={i}>
                              <AttachFileList
                                uf={uf}
                                handleRemoveAttachment={handleRemoveAttachment}
                                idx={i}
                              />
                            </div>
                          )
                        })}
                    </div>

                    <MojeButton
                      label="Dodaj pliki"
                      onClick={ev => {
                        document.getElementById("fileDialog").click()
                      }}
                      fullWidth
                      variant="outlined"
                      color="menteBrandBlue"
                      disabled={uploading}
                    />
                    <div style={{ display: "none" }}>
                      <input
                        type="file"
                        id={`fileDialog`}
                        onChange={event => handleFileChanged(event)}
                      />
                    </div>
                  </div>
                  {/* <RegistrationForm redirectUrl={loginPopupContext.redirectUrl} /> */}

                  <div className="flex flex-row ">
                    <div className="flex-1" />
                    <div className="mr-8">
                      <MojeButton
                        label="Anuluj"
                        onClick={onClose}
                        variant="text"
                        color="menteBrandBlue"
                        disabled={uploading}
                      />
                    </div>
                    <div className="">
                      <MojeButton
                        label="Dodaj"
                        onClick={async () => {
                          setIsSending(true)
                          try {
                            var res = await onSend(uploadedFiles)
                          } catch (error) {}

                          setUploadedFiles([])
                          setIsSending(false)
                        }}
                        variant="contained"
                        isSaving={isSending}
                        disabled={uploading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
    )
  }
}

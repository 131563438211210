import { WakacjeChip, WakacjeChipVariant } from "../components/WakacjeChip"
import { PaymentUtils } from "./PaymentUtils"

export const PaymentStatusChip = ({ payment }) => {
  var variant = WakacjeChipVariant.GREY
  if (payment.status === "VERIFIED") {
    variant = WakacjeChipVariant.GREEN
  } else if (payment.status === "CANCELED") {
    variant = WakacjeChipVariant.RED
  }
  return (
    <WakacjeChip
      label={PaymentUtils.getStatusLabel(payment.status)}
      variant={variant}
    />
  )
}

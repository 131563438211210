import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import { InputAdornment, TextField } from "@mui/material"
import { MojeButton } from "../../../components/MojeButton"
import { Booking } from "../../../client/reservation/BookingContext"

const schema = yup
  .object({
    fullAmount: yup.string().required()
  })
  .required()
function PaymentWidget({ booking }: { booking: Booking }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  })
  const onSubmit = (data) => {
    console.log(data)
  }

  return (
    <div className="flex justify-between items-center pt-[37px]">
      <p className="font-bold pb-[10px]">Płatności</p>

      {/* <button
        onClick={handleClickOpen}
        className="text-fc-accent-3 underline hover:no-underline hover:text-fc-accent-3"
      >
        ZMIEŃ CENĘ
      </button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "760px"
            }
          }
        }}
      >
        <div className="py-[40px] w-[760px]">
          <div
            id="alert-dialog-title"
            className="text-center text-[32px] text-bold"
          >
            Zmień cenę
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div
                className="px-[53px] text-[16px] "
                id="alert-dialog-description"
              >
                <p className="mb-[40px]">
                  Tutaj możesz dokonać zmiany kwoty za wyjazd. Po akceptacji
                  rezerwacji nie będzie można już jej zmienić. Zmiana kwoty nie
                  wpływa na wartość <strong>zaliczki.</strong>
                </p>

                <TextField
                  {...register("fullAmount")}
                  type="number"
                  label="Pełna kwota"
                  variant="outlined"
                  fullWidth
                  size="medium"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">zł</InputAdornment>
                    )
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <div className="flex justify-between w-full px-[70px]">
                <div>
                  <MojeButton
                    type="button"
                    label="Anuluj"
                    onClick={handleClose}
                    variant="text"
                    fullWidth={false}
                  />
                </div>

                <div className="w-[110px]">
                  <MojeButton
                    disabled={!isValid}
                    type="submit"
                    label="Zapisz"
                    onClick={handleClose}
                    variant="contained"
                    fullWidth
                  />
                </div>
              </div>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </div>
  )
}

export default PaymentWidget

import clsx from "clsx"

const DateRangeDay = ({ date, isSelect }) => {
  return (
    <span>
      {isSelect && (
        <div className="absolute w-[26px] h-full inset-0 m-auto bg-[#042675] rounded-full"></div>
      )}
      <span
        className={clsx("relative")}
        style={{ color: isSelect ? "#fff" : "" }}
      >
        {date.getDate()}
      </span>
    </span>
  )
}

export default DateRangeDay

import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Heading3, InputField } from "../FormCommons"
import { FormErrors } from "../FormErrors"
import { Button, CircularProgress } from "@mui/material"
import { useState } from "react"

const paymentsSchema = yup
  .object({
    nip: yup.number().required(),
    company_name: yup.string().required(),
    address: yup.string().required(),
    zip_code: yup.number().required(),
    city: yup.string().required(),
    bank_account_number: yup.string().required(),
    currency: yup.string().required(),
    bank_account_number2: yup.string().required(),
    currency2: yup.string().required(),
    email_for_billing: yup.string().required()
  })
  .required()

export const PaymentForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(paymentsSchema)
  })
  const [isSaving, setIsSaving] = useState(false)
  const onSubmit = (data) => console.log(data)

  const handleCancel = () => {
    console.log("cancel")
  }
  return (
    <div className="w-[550px] pt-[30px]">
      <BlueBackground>
        <Heading3>Dane rozliczeniowe</Heading3>
      </BlueBackground>
      <p className="mt-[20px] text-fc-red text-sm">
        Informujemy, że poniższe dane można uzupełnić tylko jednorazowo.
      </p>
      <p className="text-xs mt-[20px]">
        Prosimy o wpisywanie <span className="font-bold">Nazwy firmy</span>{" "}
        zgodnie z danymi w KRS
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField label="NIP*" {...register("nip")} />
        <InputField label="Nazwa firmy*" {...register("company_name")} />
        <InputField label="Ulica, nr domu i lokalu*" {...register("address")} />
        <div className="flex">
          <div className="w-[144px] mr-[20px]">
            <InputField label="Kod pocztowy*" {...register("zip_code")} />
          </div>
          <div className="flex-1">
            <InputField label="Miasto*" {...register("city")} />
          </div>
        </div>

        <p className="mt-[50px]  text-xs">
          Wpisz numer IBAN{" "}
          <span className="italic font-bold">
            (kod kraju oraz 26-cyfrowy numer rachunku bankowego)
          </span>
        </p>

        <div className="flex">
          <div className="w-[330px] mr-[20px]">
            <InputField
              label="Numer konta bankowego (IBAN)*"
              {...register("bank_account_number")}
            />
          </div>
          <div>
            <InputField label="Waluta*" {...register("currency")} />
          </div>
        </div>

        <div className="flex">
          <div className="w-[330px] mr-[20px]">
            <InputField
              label="Numer konta bankowego (IBAN)*"
              {...register("bank_account_number2")}
            />
          </div>
          <div>
            <InputField label="Waluta*" {...register("currency2")} />
          </div>
        </div>

        <p className="text-xs mt-[30px]">
          Uzupełnij adres mailowy w celu wysłania rozliczeń
        </p>
        <InputField
          label="E-mail do wysyłki rozliczeń*"
          {...register("email_for_billing")}
        />

        <p className="mt-[30px] text-xs">*pola obowiązkowe</p>

        <div className="mt-[20px]">
          <div className="flex flex-row">
            <div className="">
              <Button
                className="mx-4"
                variant="outlined"
                type="button"
                size="large"
                onClick={handleCancel}
              >
                Anuluj
              </Button>
            </div>
            <div className="flex-1" />
            <div className="text-right">
              <Button
                className="mx-4"
                variant="contained"
                type="submit"
                size="large"
                // disabled={!isValid}
              >
                {isSaving ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Zapisz"
                )}
              </Button>
            </div>
          </div>
        </div>

        <FormErrors errors={errors}></FormErrors>
      </form>
    </div>
  )
}

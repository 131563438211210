import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import GlobalStyles from "@mui/material/GlobalStyles"
import { muiTheme } from "./wakacje-ui/mui-theme"

const theme = muiTheme

const globalStyles = {
  html: { color: theme.palette.text.primary },
  // notistack
  ".SnackbarItem-wrappedRoot": {
    "& .SnackbarContent-root.SnackbarItem-variantSuccess": {
      backgroundColor: "#EFF7E7",
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: theme.typography.body1.fontFamily,
      "& .MuiSvgIcon-root": {
        color: "#5CAA0B",
      },
    },
  },

  ".MuiPopover-paper": {
    maxWidth: "784px",
    width: "748px",
  },
}

export default function MuiGlobalTheme({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyles} />
      {children}
    </ThemeProvider>
  )
}

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { InputCheck } from "../../../../components/FormCommons"

import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useCompanyApi } from "../../events/api/useCompanyApi"
import {
  operation_types_mapping,
  operation_types_prefix,
} from "../register/RegisterCompanyFirstStep"

export const CompanySectionServicesForm = ({
  control,
  watch,
  register,
}: any) => {
  console.log("CompanySectionServicesForm", control)
  const val = watch("operation_types")
  console.log("operation_types value ", val, operation_types_mapping)
  const field = register("operation_types")
  console.log("field", field)
  const formContext = useFormContext()
  return (
    <>
      {Object.keys(operation_types_mapping).map((type_key, index) => {
        console.log("key", type_key, val)
        const on = val?.includes(operation_types_mapping[type_key])
        console.log("on", on)
        return (
          <FormGroup className="mb-4" key={index}>
            <FormControlLabel
              control={<Checkbox checked={on} />}
              label={type_key}
              onChange={(ev: any) => {
                console.log("on change", ev.target.checked)
                var newVal = val
                  ? val.filter((v) => v !== operation_types_mapping[type_key])
                  : []
                if (ev.target.checked) {
                  newVal = [...newVal, operation_types_mapping[type_key]]
                }
                formContext.setValue("operation_types", newVal)
              }}
            />
          </FormGroup>
          // <InputCheck
          //   // control={control}
          //   value={on}
          //   onChange={(ev) => {
          //     console.log("on change", ev)
          //   }}
          //   label={type_key}
          //   // key={operation_types_mapping[type_key]}
          //   // name={`${operation_types_prefix}${operation_types_mapping[type_key]}`}
          // />
        )
      })}
    </>
  )
}

export const CompanySectionServicesPreview = ({ values }: any) => {
  return (
    <div>
      <Typography marginX={4}>
        {Object.keys(operation_types_mapping)
          .filter((key) =>
            values.operation_types?.includes(operation_types_mapping[key])
          )
          .join(", ") || "-"}
      </Typography>
    </div>
  )
}

export const CompanySectionServices = ({
  values,
  refetch,
  onSave,
}: {
  onSave?: Function
  values: any
  refetch: Function
}) => {
  const { updateCompany } = useCompanyApi()
  const [val, setValue] = useState(values)

  const saveHandler = async (data: any) => {
    console.log("data", data)
    if (onSave) {
      return onSave(data)
    }
    var productData: any = {
      id: values.id,
      operation_types: data.operation_types,
    }
    return updateCompany(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const update = (data: object) => {
    setValue(data)
  }

  console.log("CompanySectionServices", values)
  return (
    <EditableFormSection
      title="Zakres Działalności"
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionServicesForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <CompanySectionServicesPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

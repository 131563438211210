import { useEffect, useState } from "react"
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams
} from "react-router-dom"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Navigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { PageTitle } from "../../../components/typography"
import { CreateCompanyFirst } from "../../../components/widgets/CreateCompanyFirst"
import MapOutlinedIcon from "@mui/icons-material/MapOutlined"
import SnippetFolderOutlinedIcon from "@mui/icons-material/SnippetFolderOutlined"
import { ProductsTable } from "./ProductsTable"
import { ProductPage } from "./ProductPage"
import { AddProduct } from "../events/AddProduct"
import { useCompanyContext } from "../../../contexts/company-context"
import {
  NewProductTypePopup,
  NewProdutTypeSelector
} from "./NewProductTypePopup"
import AddFirstProductCard from "./AddFirstProductCard"
import { withServiceBlocked } from "../../../components/widgets/ServiceBlocked"
import { useTranslation } from "react-i18next"
import styled from "@emotion/styled"
import { IndividualSchoolTripTable } from "../school-trips/IndividualSchoolTripTable"

const NoProductsYetCard = ({ setIsAddingProduct }) => {
  return (
    <Box maxWidth={1000} margin="auto">
      <AddFirstProductCard
        onClick={() => {
          setIsAddingProduct(true)
        }}
      />
    </Box>
  )
}

const HelperHeader = () => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div>
      <div>
        <div>
          Aby Twoja oferta była widoczna:
          <ul className="list-disc list-outside">
            <li>
              dla <strong>Kolonie i obozy</strong> oraz{" "}
              <strong>Półkolonie</strong> - należy uzupełnić formularz danych
              oraz dodać terminy poszczególnych turnusów,
            </li>
            <li>
              dla <strong>Wycieczka szkolna</strong> oraz{" "}
              <strong>Zielona szkoła</strong>- należy uzupełnić wszystkie dane z
              formularza.
            </li>
          </ul>
          <br />
        </div>
        {/* <div className="flex flex-row">
          <div>Rodzaje ofert </div>
          <div>
            <img
              onClick={() => setExpanded(!expanded)}
              src={
                expanded
                  ? "/assets/svg/chevron-up-black.svg"
                  : "/assets/svg/chevron-down-black.svg"
              }
              className={`ml-2 cursor-pointer w-[20px] m-auto transition-transform ${
                expanded ? " rotate-180 " : ""
              }`}
              alt="Arrow down"
            />
          </div>
        </div> */}
        {expanded && (
          <div>
            <div className="mb-2">
              <strong>Półkolonie </strong>to zajęcia w trakcie dnia, bez
              nocowania. Blisko miejsca zamieszkania dziecka, na które dowozi
              rodzic.
            </div>
            <div className="mb-2">
              <strong>Kolonie</strong> to zorganizowany wyjazd dla dzieci z
              noclegiem. Z dojazdem zorganizowanym lub własnym. Wycieczki
              szkolne u nas rozumiane jako
            </div>
            <div className="mb-2">
              <strong>Jednodniowe</strong> zajęcia dla zorganizowanych grup, bez
              noclegów. Z transportem zorganizowanym lub własnym. Może to być
              np. oferta 2 godzinnego zwiedzania pobliskiego muzeum lub
              całodniowy zorganizowany wyjazd do innego miasta.
            </div>
            <div className="mb-2">
              <strong>Zielone szkoły </strong> – wyjazdy dla zorganizowanych
              grup z noclegiem i wyżywieniem. Z dojazdem własnym lub
              zorganizowanym np. kilka dni dla klasy nad morzem.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export const ProductsIndexTabOfferts = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const merchantContext = useCompanyContext()
  const [isAddingProduct, setIsAddingProduct] = useState<any>(
    !!new URLSearchParams(location.search).get("dodaj")
  )
  const { t } = useTranslation()
  var tabs = [
    {
      path: "products",
      alternativePath: "",
      label: t("ui.school_trips.offers_list", "Lista ofert"),
      icon: <MapOutlinedIcon />
    },
    {
      path: "archive",
      alternativePath: null,
      label: t("ui.school_trips.offers_archive", "Archiwum"),
      icon: <SnippetFolderOutlinedIcon />
    }
  ]
  const type = params.type
  if (type === "schooltrip") {
    tabs.push({
      path: "planned-schooltrips",
      alternativePath: null,
      label: "Zaplanowane wycieczki",
      icon: <SnippetFolderOutlinedIcon />
    })
  }

  const tabIndex = tabs.findIndex(
    (tabData) =>
      tabData["path"] === params["*"] ||
      tabData["alternativePath"] === params["*"]
  )

  let typepl = ""

  const queryParams: any = { company_id: merchantContext.company.id }

  if (type === "camp") {
    queryParams.type = "kolonie"
    typepl = "kolonie"
  }
  if (type === "daycamp") {
    queryParams.type = "polkolonie"
    typepl = "polkolonie"
  }
  if (type === "schooltrip") {
    queryParams.type = "wycieczka-szkolna,zielona-szkola"
  }
  if (type === "groups") {
    queryParams.type = "noclegi-dla-grup"
  }
  if (params["*"] === "archive") {
    queryParams.status = "ARCHIVED"
  } else {
    queryParams.status = "DRAFT,ACTIVE,SUSPENDED"
  }

  console.log("jaki jest type", type)

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Box flex={1}>
          <div>
            <PageTitle gutterBottom>
              <div className="pl-8">
                {type === "camp" && t("ui.camps.camps", "Kolonie i obozy")}
                {type === "daycamp" && t("ui.daycamps.daycamps", "Półkolonie")}
                {type === "groups" && t("ui.groups.groups", "Noclegi dla grup")}
                {type === "schooltrip" &&
                  t("ui.school_trips.school_trips", "Wycieczki szkolne")}
              </div>
            </PageTitle>
          </div>
          <div className="pl-8">
            {type === "camp" && (
              <>
                Dodaj swoją ofertę Koloni I obozów. Nie zapomnij wprowadzić i
                aktywować terminów, aby oferta była widoczna na stronie.
              </>
            )}
            {type === "daycamp" && (
              <>
                Dodaj swoją ofertę Półkolonii. Nie zapomnij wprowadzić i
                aktywować terminów, aby oferta była widoczna na stronie.
              </>
            )}

            {type === "schooltrip" && (
              <>
                Dodaj swoją ofertę wycieczek. Jeśli masz sprawdzoną listę
                noclegów i atrakcji dodaj ją do zakładki poniżej, dzięki czemu
                sprawnie i szybko uzupełnisz oferty.
              </>
            )}
            {type === "groups" && (
              <>
                {t(
                  "ui.groups.description",
                  "Dodaj swoją ofertę Noclegu dla grup. Jeśli masz sprawdzoną listę atrakcji w okolicy dodaj ją do zakładki 'Atrakcje i noclegi', dzięki czemu sprawnie i szybko uzupełnisz oferty."
                )}
              </>
            )}
          </div>
        </Box>
      </Box>
      <div className="p-8">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} display="flex">
          <div className="flex-1">
            {tabIndex >= 0 && (
              <Tabs
                value={tabIndex}
                aria-label="organizatorze tab"
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    onClick={() => navigate(`/products/${type}/${tab.path}`)}
                    label={
                      <div className="flex justify-center items-center">
                        <div className="mr-1">{tab["icon"]}</div>
                        {tab["label"] ?? tab["path"]}
                      </div>
                    }
                    key={tab["path"]}
                  />
                ))}
              </Tabs>
            )}
          </div>
          {tabIndex !== 2 && (
            <Box mr={1}>
              <StyledButton>
                <Button
                  className="mx-4 mb-2"
                  variant="contained"
                  color="primary"
                  size="large"
                  // onClick={() => setIsAddingProduct(true)}
                  onClick={() => {
                    if (type === "schooltrip") {
                      navigate(`/products/schooltrip/new`)
                    } else if (type === "groups") {
                      navigate(`/products/groups/new`)
                    } else {
                      navigate(`/products/${type}/dodaj?type=${typepl}`)
                    }
                  }}
                >
                  {type === "camp" && (
                    <>{t("ui.camps.add_camp", "Dodaj kolonie/obóz")}</>
                  )}
                  {type === "daycamp" && (
                    <>{t("ui.daycamps.add_daycamp", "Dodaj półkolonie")}</>
                  )}
                  {type === "schooltrip" && (
                    <>{t("ui.school_trips.add_trip", "Dodaj wycieczkę")}</>
                  )}
                  {type === "groups" && (
                    <>{t("ui.groups.add_trip", "Dodaj nocleg dla grup")}</>
                  )}
                </Button>
              </StyledButton>
            </Box>
          )}
        </Box>
        {(tabIndex === 0 || tabIndex === 1) && (
          <>
            <ProductsTable
              queryParams={queryParams}
              nothingFoundComponent={() => (
                <NoProductsYetCard setIsAddingProduct={setIsAddingProduct} />
              )}
              prefix={type}
              type={type}
              isArchive={params["*"] === "archive"}
              sortAlphabetically={true}
            />
            {/* <div className="text-center my-4 flex flex-row items-center justify-center">
              <div className="shadow p-8 rounded">
                <div className="text-2xl font-bold my-2">
                  {t("ui.products.add_new_offer", "Dodaj nową ofertę")}
                </div>
                <NewProdutTypeSelector
                    onSelected={handleStartAddingProduct}
                  />
              </div>
            </div> */}
          </>
        )}
        {type === "schooltrip" && tabIndex === 2 && (
          <>
            <IndividualSchoolTripTable
              queryParams={queryParams}
              prefix={type}
              type={type}
              isArchive={params["*"] === "archive"}
              sortAlphabetically={true}
            />
            {/* <div className="text-center my-4 flex flex-row items-center justify-center">
              <div className="shadow p-8 rounded">
                <div className="text-2xl font-bold my-2">
                  {t("ui.products.add_new_offer", "Dodaj nową ofertę")}
                </div>
                <NewProdutTypeSelector
                    onSelected={handleStartAddingProduct}
                  />
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  )
}
export const ProductsIndex = withServiceBlocked(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const merchantContext = useCompanyContext()
  const [isAddingProduct, setIsAddingProduct] = useState<any>(
    !!new URLSearchParams(location.search).get("dodaj")
  )
  const { t } = useTranslation()
  const type = params.type
  useEffect(() => {
    if (!merchantContext.company) {
      merchantContext.refreshHomeData()
    }
  }, [])

  const handleStartAddingProduct = (type: string) => {
    setIsAddingProduct(false)

    if (type && typeof type === "string") {
      navigate(`dodaj?type=${type}`)
    }
  }

  const hasCompany: boolean = !!merchantContext.company

  if (!hasCompany) {
    return <Navigate to="/company/register" />
  }

  if (!type) {
    return <div>Wybierz rodzaj oferty z menu po lewej</div>
  }

  if (!merchantContext.company) {
    return (
      <CreateCompanyFirst title="Przed dodaniem produktów musisz ustawic dane firmy" />
    )
  }
  return (
    <div className="p-8">
      <Routes>
        <Route
          path="dodaj"
          element={
            <AddProduct />
            // merchantContext.company ? (
            //   <AddProduct companyId={merchantContext.company!.id} />
            // ) : (
            //   <>
            //     <Box>Przed dodaniem produktów ustaw dane firmy</Box>
            //     <Button
            //       color="primary"
            //       variant="contained"
            //       onClick={(ev) => {
            //         navigate("/company/register")
            //       }}
            //     >
            //       Ustaw dane firmy
            //     </Button>
            //   </>
            // )
          }
        />
        <Route path="archive" element={<ProductsIndexTabOfferts />}></Route>
        <Route path="products" element={<ProductsIndexTabOfferts />}></Route>
        <Route
          path="planned-schooltrips"
          element={<ProductsIndexTabOfferts />}
        ></Route>
        <Route path=":productId/*" element={<ProductPage />}></Route>
        <Route
          index
          element={
            <Box>
              <ProductsIndexTabOfferts />
            </Box>
          }
        />
      </Routes>
      <NewProductTypePopup
        isOpen={isAddingProduct}
        onClose={handleStartAddingProduct}
      />
    </div>
  )
})

let StyledButton = styled.div(() => {
  return `
  button:disabled{
    background-color: #7E7E7E;
    
  }
`
})

import { Button, TextField } from "@mui/material"
import { Box } from "@mui/system"
import { Formik } from "formik"
import { useState } from "react"
import { MojeButton } from "../../components/ui/MojeButton"

export const ConfirmationForm = (props: any) => {
  const [confirmationResult, setConfirmationResult] = useState<any>(undefined)

  const [loginError, setLoginError] = useState(undefined)
  const [resendCodeStatus, setResendCodeStatus] =
    useState<string | undefined>(undefined)

  const handleConfirm = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    console.log("handleConfirm")
    try {
      setSubmitting(true)
      // const res = await Auth.confirmSignUp(
      //   // values.username.trim(),
      //   props.signupParams.username,
      //   values.confirmation_code.trim()
      // )
      // console.log("Confirmation result", res)
      // if (res === "SUCCESS") {
      //   await Auth.signIn(
      //     props.signupParams.username,
      //     props.signupParams.password
      //   )

      //   authContext.refetchCurrentUser()

      //   const confirmedUser = await Auth.currentAuthenticatedUser()
      //   console.log("confirmedUser", confirmedUser)
      //   // showToastr("Email potwierdzony!", {
      //   //   message: "Witamy w Moje Urodziny",
      //   //   onYes: () => {
      //   //     console.log("On yes")
      //   //   }
      //   // })
      //   onConfirmed()
      // }
    } catch (error) {
      console.log("Confirmation error" + error)
      setConfirmationResult(error)
    }
    setSubmitting(false)
    // console.log('Confirm result: ', res)
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    console.log("handleSubmit", values)
    try {
      setSubmitting(true)
      await handleConfirm(values, { setSubmitting })
      setSubmitting(false)
    } catch (err: any) {
      console.log("confirmation error", err)
      console.log(err)
      setLoginError(err.message)
      setSubmitting(false)
      setTimeout(() => {
        setLoginError(undefined)
      }, 5000)
    }
  }

  const handleRequestCode = () => {
    console.log("handleRequestCode")
    try {
      setResendCodeStatus("SENDING")
      Auth.resendSignUp(props.signupParams.username)
      setResendCodeStatus("SENT")
    } catch (err) {
      console.error("Can't request codes")
      setResendCodeStatus("ERROR")
    }
  }

  const errorMessage = undefined
  console.log("Render ConfirmationForm", props)

  return (
    <div>
      <Formik
        initialValues={{} as any}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitForm
        }) => (
          <form onSubmit={handleSubmit} className="Form">
            <Box style={{ margin: "10px 0" }}>
              Wprowadź kod wysłany na adres email
            </Box>

            <Box style={{ margin: "10px 0" }}>
              <TextField
                type="text"
                className="form-control mps-metro-input"
                id="confirmation_code"
                name="confirmation_code"
                placeholder="Kod potwierdzający"
                required
                onChange={handleChange}
                value={values.confirmation_code}
                fullWidth
              />
            </Box>

            <MojeButton
              color="primary"
              variant="contained"
              onClick={(event: any) => {
                submitForm()
              }}
              fullWidth
              label="Potwierdź"
              isSaving={isSubmitting}
            />

            <Box style={{ margin: "10px 0" }} width="100%">
              <Button
                onClick={(event: any) => handleRequestCode()}
                color="secondary"
                variant="outlined"
                fullWidth
              >
                Wyślij ponownie
              </Button>
              {resendCodeStatus === "SENDING" && (
                <div>Wysyłam kod ponownie</div>
              )}
              {resendCodeStatus === "SENT" && <div>Kod wysłany</div>}
              {resendCodeStatus === "ERROR" && (
                <div>Problem z wysłaniem kodu</div>
              )}
              {/* resendCodeStatus: {resendCodeStatus} */}
            </Box>

            <div style={{ marginTop: 20, textAlign: "right" }}>
              {errorMessage && (
                <span className="alert alert-danger help-block">
                  {errorMessage}
                </span>
              )}
            </div>
            {loginError && (
              <div
                style={{
                  textAlign: "center",
                  padding: 10,
                  height: 30,
                  color: "red"
                }}
              >
                {loginError}
              </div>
            )}

            {/* {confirmationResult?.code == 'ExpiredCodeException' && ( */}

            {/* )} */}
          </form>
        )}
      </Formik>

      {confirmationResult?.message}
    </div>
  )
}

import { Controller } from "react-hook-form"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { InputField } from "../../../components/FormCommons"
import { InputAdornment } from "@mui/material"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { useCurrency } from "../../../hooks/useCurrency"
import { useProductUtils } from "../../../hooks/useProductUtils"

export const CampFeesBase = ({ control, productCurrency }) => {
  const { t } = useTranslation()
  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  const [isValueChanged, setIsValueChanged] = useState(false)

  return (
    <>
      <div className="mb-2 mt-6 font-bold">
        {t("ui.trips.base_price", "Cena bazowa")}
      </div>
      <div className="mt-1 text-sm font-light">
        {t(
          "ui.trips.base_price_description",
          "Cena nie będzie możliwa do późniejszej edycji. Będzie zawsze widoczna dla klienta."
        )}
      </div>

      <div className="flex flex-col md:flex-row gap-2">
        <Controller
          name="full_price"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            if (
              selectedCurrency.currency !== productCurrency &&
              !isValueChanged
            ) {
              value = formatPriceInCents(value, productCurrency) * 100
            }

            const handleValueChange = (event) => {
              setIsValueChanged(true)

              const inputValue = parseFloat(event.target.value)

              if (!isNaN(inputValue)) {
                const multipliedValue = inputValue * 100
                onChange(multipliedValue)
              } else {
                onChange(null)
              }
            }

            return (
              <InputField
                type="number"
                label={t("ui.trips.price_per_person", "Cena / 1 osobę")}
                value={value ? value / 100 : ""}
                onChange={handleValueChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {ProductUtils.getCurrencyCode(selectedCurrency.currency)}
                    </InputAdornment>
                  )
                }}
              />
            )
          }}
        />
        <Controller
          name={"payment_due_date"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            return (
              <InputField
                type="date"
                onChange={onChange}
                value={val}
                InputLabelProps={{ shrink: true }}
                label={t("ui.trips.payment_date_required", "Termin płatności*")}
                name={"payment_due_date"}
              />
            )
          }}
        />
      </div>
    </>
  )
}

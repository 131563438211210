import { format } from "date-fns"
import useLocale from "../../utils/useLocale"

const DateRangePickerHeader = ({ date, onPrevious = null, onNext = null }) => {
  const localization = useLocale()
  return (
    <div className="h-[22px] flex items-center">
      {onPrevious && (
        <div
          className="w-[22px] h-full flex items-center cursor-pointer"
          onClick={onPrevious}
        >
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.44 11.7178L1.33008 6.67139L5.44 1.625"
              stroke="#333333"
              stroke-width="1.74933"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      )}
      <div className="w-full flex-1 flex justify-center items-center">
        <span className="capitalize">
          {format(date, "MMMM yyyy", {
            locale: localization.locale
          })}
        </span>
      </div>
      {onNext && (
        <div
          className="w-[22px] h-full flex items-center justify-end cursor-pointer"
          onClick={onNext}
        >
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.49609 11.7178L5.60601 6.67139L1.49609 1.625"
              stroke="#333333"
              stroke-width="1.74933"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default DateRangePickerHeader

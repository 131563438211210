import { Check, Visibility, VisibilityOff } from "@mui/icons-material"
import { TextField, InputAdornment, TextFieldProps } from "@mui/material"
import { forwardRef, useState } from "react"

type MojeTextInputProps = {
  valid?: any
  errors?: any
  id?: any
  type?: any
  forceValidEndorsement?: any
  touched?: any
  error?: any
  placeholder?: any
  rows?: any
  multiline?: any
  variant?: any
  size?: any
  passwordField?: any
}
export const MojeTextInput = forwardRef<
  HTMLInputElement,
  TextFieldProps & MojeTextInputProps
>((props, ref) => {
  const {
    label,
    variant,
    onChange,
    onBlur,
    value,
    name,
    size,
    required,
    valid,
    margin,
    errors,
    type,
    touched,
    fullWidth,
    id,
    forceValidEndorsement,
    inputRef,
    error,
    placeholder,
    rows,
    multiline,
    passwordField,
    disabled
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev)
  }

  return (
    <TextField
      className="custom-input"
      id={id}
      ref={ref}
      inputRef={inputRef}
      label={label}
      variant={variant}
      size={size}
      margin={margin ?? "normal"}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      required={required}
      color={valid ? "success" : undefined}
      type={passwordField ? (showPassword ? "text" : "password") : type}
      fullWidth={fullWidth}
      placeholder={placeholder ? placeholder : undefined}
      rows={rows ? rows : undefined}
      multiline={multiline ? true : false}
      disabled={disabled}
      InputProps={{
        endAdornment: passwordField ? (
          <InputAdornment position="end">
            {showPassword ? (
              <Visibility
                className="cursor-pointer"
                onClick={handleTogglePassword}
              />
            ) : (
              <VisibilityOff
                className="cursor-pointer"
                onClick={handleTogglePassword}
              />
            )}
          </InputAdornment>
        ) : forceValidEndorsement || (!!errors && !errors[name]) ? (
          <InputAdornment position="end">
            <Check color="success" />
          </InputAdornment>
        ) : undefined
      }}
      error={error ? error : touched && errors && touched[name] && errors[name]}
      helperText={
        error
          ? error
          : touched && errors && touched[name]
          ? errors[name]
          : undefined
      }
    />
  )
})

import { Box } from "@mui/system"

interface WakacjeChipProps {
  label: string
  variant: any
  showStatus?: boolean
}
const _ChipVariants: any = {
  GREEN: {
    textColor: "var(--wakacje-action-color-green);",
    bgColor: "#F7FAF3",
  },
  ORANGE: {
    textColor: "var(--wakacje-action-color-orange);",
    bgColor: "#F7FAF3",
  },
  RED: {
    textColor: "var(--fc-color-1-red);",
    bgColor: "#F7FAF3",
  },
  BLUE: {
    textColor: "var(--wakacje-color-blue);",
    bgColor: "#F7FAF3",
  },
  YELLOW: {
    textColor: "var(--fc-color-3);",
    bgColor: "#F7FAF3",
  },
  DEFAULT: {
    textColor: "black",
    bgColor: "grey",
  },
}

export enum WakacjeChipVariant {
  GREY = "GREY",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  RED = "RED",
  BLUE = "BLUE",
  YELLOW = "YELLOW",
}

export function WakacjeChip({
  variant,
  label,
}: {
  variant?: WakacjeChipVariant
  label: string
}) {
  var v =
    _ChipVariants[variant ?? WakacjeChipVariant.GREY] ?? _ChipVariants.GREY

  return (
    <Box
      px={1}
      py={1}
      borderRadius={50}
      color={v?.textColor}
      bgcolor={v?.bgColor}
      display="inline"
      fontSize={10}
      fontWeight="bold"
      sx={{
        textTransform: "uppercase",
      }}
    >
      {label}
    </Box>
  )
}

import { Routes, Route } from 'react-router-dom'
import { ApiTable } from '../../../components/table/ApiTable'
import { FormattedDate, FormattedTime } from 'react-intl'
import { useProductApi } from '../../organizer/events/api/useProductApi'
import { ProductPage } from '../../organizer/products/ProductPage'

import Toolbar from '../Toolbar/Toolbar'
import { useSearchText } from '../useSearchText'
import { Select } from '@mui/material'

import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { ProductStatus, ProductTypes } from 'wakacje-web/src/utils/ProductUtils'

const SuperProductsTable = props => {
  const { superListProducts } = useProductApi()
  const columns = [
    {
      header: 'Data dodania',
      cell: (row: any) => (
        <div className='text-left'>
          <FormattedDate value={row.created_at} />{' '}
          <FormattedTime value={row.created_at} />
        </div>
      )
    },
    {
      header: 'Nazwa Wydarzenia',
      field: 'name'
    },
    {
      header: 'Nazwa Firmy',
      cell: (row: any) => (
        <div className='text-left'>{row.company?.name ?? 'Brak'}</div>
      )
    },
    {
      header: 'Typ',
      cell: (row: any) => {
        switch (row.type) {
          case 'wycieczka-szkolna':
            return (
              <div className='text-left font-bold'>{'Wycieczka szkolna'}</div>
            )
          case 'polkolonie':
            return <div className='text-left font-bold'>{'Półkolonie'}</div>
          case 'kolonie':
            return (
              <div className='text-left font-bold'>{'Kolonie i obozy'}</div>
            )
          case 'noclegi-dla-grup':
            return (
              <div className='text-left font-bold'>{'Noclegi dla grup'}</div>
            )
          default:
            return <div className='text-left font-bold'></div>
        }
      }
    },
    {
      header: 'Terminy',
      cell: (row: any) => (
        <div className='text-left'>
          <span title='Wszystkie'>{row.all_trips_count} </span>/{' '}
          <span title='Pozostałe (Przyszłe)'>{row.all_trips_count}</span>{' '}
        </div>
      )
    },
    {
      header: 'Status',
      cell: (row: any) => {
        switch (row.status) {
          case 'DRAFT':
            return <div className='text-left'>{'szkic'}</div>
          case 'SUSPENDED':
            return <div className='text-left'>{'status wstrzymany'}</div>
          case 'ACTIVE':
            return <div className='text-left'>{'aktywny'}</div>
          case 'ARCHIVED':
            return <div className='text-left'>{'oferta zarchiwizowana'}</div>
          default:
            return <div className='text-left'></div>
        }
      }
    },

    {
      header: 'Akcept',
      cell: (row: any) => (
        <div className='text-left'>
          {row.admin_approved_at && (
            <>
              <FormattedDate value={row.admin_approved_at} />{' '}
              <FormattedTime value={row.admin_approved_at} />
            </>
          )}
        </div>
      )
    },
    {
      header: 'Home Page',
      cell: (row: any) => (
        <div className='text-left'>
          {row.admin_show_on_home_page ? 'Tak' : ''}
        </div>
      )
    },
    {
      header: 'Promo',
      cell: (row: any) => (
        <div className='text-left'>
          {row.admin_promote_on_home_page ? 'Tak' : ''}
        </div>
      )
    },
    {
      header: 'Uwagi Admin',
      cell: (row: any) => <div className='text-left'>{row.admin_comment}</div>,
      width: 200
    }
  ]
  return (
    <ApiTable
      {...props}
      columns={columns}
      listApi={superListProducts}
      showExpandColumn
      rowLinkBuilder={(row: any) => {
        if (row.type === 'kolonie') {
          return `/products/camp/${row.id}?admin=true`
        }

        if (row.type === 'polkolonie') {
          return `/products/daycamp/${row.id}?admin=true`
        }

        if (row.type === 'wycieczka-szkolna') {
          return `/products/school-trips/${row.id}?admin=true`
        }
      }}
      queryParams={props.queryParams}
    ></ApiTable>
  )
}

export const SuperAdminOffers = () => {
  const { superListProducts } = useProductApi()
  const [type, setType] = useState('all')
  const [status, setStatus] = useState('all')
  const [searchValue, setSearchValue] = useState('')
  const { setSearchTextDebounced, queryParams } = useSearchText()

  const mergedQueryParams = { ...queryParams }
  // const mergedQueryParams: {[k: string]: any} = {};

  if (type && type !== 'all') {
    mergedQueryParams.type = type
  }
  if (status && status !== 'all') {
    mergedQueryParams.status = status
  }

  return (
    <>
      <Toolbar
        setSearchTextDebounced={setSearchTextDebounced}
        setSearchValue={setSearchValue}
        api={superListProducts}
        queryParams={mergedQueryParams}
      >
        <div className='flex flex-row gap-2 items-center'>
          <div>Rodzaj</div>
          <Select
            className='ml-4'
            value={type}
            inputProps={{
              'aria-labelledby': 'table-page-select-label'
            }}
            onChange={e => setType(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 'min-content'
                }
              }
            }}
          >
            <MenuItem value={'all'}>Wszystkie</MenuItem>
            <MenuItem value={ProductTypes.CAMP}>Kolonie i obozy</MenuItem>
            <MenuItem value={ProductTypes.DAYCAMP}>Półkolonie</MenuItem>
            <MenuItem value={ProductTypes.SCHOOL_TRIP}>
              Wycieczki szkolne
            </MenuItem>
            <MenuItem value={ProductTypes.GROUPS}>Noclegi dla grup</MenuItem>
          </Select>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div>Status</div>
          <Select
            className='ml-4'
            value={status}
            inputProps={{
              'aria-labelledby': 'table-page-select-label'
            }}
            onChange={e => setStatus(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: 'min-content'
                }
              }
            }}
          >
            <MenuItem value={'all'}>Wszystkie</MenuItem>
            <MenuItem value={ProductStatus.ACTIVE}>aktywny</MenuItem>
            <MenuItem value={ProductStatus.ARCHIVED}>zarchiwizowany</MenuItem>
            <MenuItem value={ProductStatus.DRAFT}>szkic</MenuItem>
            <MenuItem value={ProductStatus.SUSPENDED}>wstrzymany</MenuItem>
          </Select>
        </div>
      </Toolbar>

      <div className='mt-2'>
        <Routes>
          {/* <Route path="dodaj" element={<AddProduct superMode />} /> */}
          <Route path=':productId' element={<ProductPage />} />
          <Route
            index
            element={
              <SuperProductsTable
                queryParams={mergedQueryParams}
                searchValue={searchValue}
              />
            }
          />
        </Routes>
      </div>
    </>
  )
}

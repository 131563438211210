import React from "react"
import { motion } from "framer-motion"

export interface AnimateType {
  children: React.ReactNode
  type: "fade-in" | "fade-in-y" | "hover"
  duration?: number
  className?: string
}

function Animate({ children, type, className, duration }: AnimateType) {
  if (type === "fade-in") {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: duration }}
        className={className}
      >
        {children}
      </motion.div>
    )
  }

  if (type === "fade-in-y") {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: duration }}
        className={className}
      >
        {children}
      </motion.div>
    )
  }

  if (type === "hover") {
    return (
      <motion.div
        whileHover={{ opacity: 1, y: -3 }}
        transition={{ duration: duration }}
        className={className}
      >
        {children}
      </motion.div>
    )
  }

  return
}

export default Animate

import { HeaderPackage } from "./HeaderPackage"
import { PackagePaymentSection } from "./PackagePaymentSection"

export interface IPackageInfoProps {
  packageName?: string
  activeStatus?: string
  remainingTime?: string
  boughtOn?: string
  validUntil?: string
  isExpired: boolean
}

export function PackageInfo({
  packageName,
  activeStatus,
  remainingTime,
  boughtOn,
  validUntil,
  isExpired
}: IPackageInfoProps) {
  console.log("PackageInfo", packageName)
  return (
    <>
      <div className="w-full mx-auto flex flex-col md:flex-row my-10 space-x-0 md:space-x-5 space-y-6 md:space-y-0">
        <div className="w-8/12 md:w-6/12 py-6 px-4 flex bg-fc-lightblue-box flex-col justify-around text-center space-y-5">
          <div className="flex justify-end">
            <div className="bg-[#B8B8B8] rounded-full text-white px-3 cursor-pointer font-display text-lg">
              i
            </div>
          </div>
          <h4 className="font-sans font-bold text-xl">
            {packageName ?? "Brak Pakietu"}
          </h4>
          <p
            className="font-sans text-md"
            style={{
              color:
                activeStatus === "Aktywny"
                  ? "var(--fc-green-action)"
                  : "var(--fc-color-1)"
            }}
          >
            <span className="drop-shadow-lg">
              {packageName && <>{activeStatus}</>}
            </span>
            {(!packageName || isExpired) && (
              <span className="drop-shadow-lg text-fc-red">Nieaktywny</span>
            )}
            {packageName && isExpired && (
              <>
                <div className="drop-shadow-lg text-fc-red">Pakiet wygasł</div>
              </>
            )}
          </p>
        </div>
        <div
          className="w-full flex flex-col font-sans"
          style={{
            justifyContent:
              activeStatus === "Aktywny" ? "center" : "space-between"
          }}
        >
          <div className="space-y-2">
            <p className="text-fc-gray-4">
              Pozostało:{" "}
              <span
                style={{
                  color:
                    activeStatus === "Aktywny" ? "black" : "var(--fc-color-1)"
                }}
              >
                {remainingTime}
              </span>
            </p>
            {boughtOn && (
              <p className="text-fc-gray-4">
                Wykupiony: <span className="text-black">{boughtOn}</span>
              </p>
            )}
            {packageName && (
              <p className="text-fc-gray-4">
                Ważny do dnia: <span className="text-black">{validUntil}</span>
              </p>
            )}
          </div>
          {activeStatus !== "Aktywny" && (
            <div className="flex flex-col space-y-4 items-baseline md:space-y-0 md:flex-row justify-between md:items-center font-display">
              <p className="font-extrabold text-xs mt-3 md:mt-0">
                Po wygaśnięciu pakietu, Twoje konto zostanie zablokowane.
              </p>
              {/* Not implemented yet <ButtonPackage name="Przedłuż aktualny pakiet" /> */}
            </div>
          )}
        </div>
      </div>
      {/* {activeStatus !== 'Aktywny' && ( */}
      <div className="w-full auto">
        <HeaderPackage title="Płatności" />
        <PackagePaymentSection />
      </div>
      {/* )} */}
    </>
  )
}

export const BlueBackground = ({
  children,
  disabled,
}: {
  children: any
  disabled?: boolean
}) => (
  <div
    className={`p-4 ${disabled ? "bg-[#E9E9E9]" : " bg-[#F3F7FE] "} rounded`}
  >
    {children}
  </div>
)

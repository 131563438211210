export const style: any = {
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute' as 'absolute',
	alignItems: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {xs: '100%', sm: '100%', md: '670px'},
	height: {xs: '100%', sm: '100%', md: '426px'},
	padding: '40px 48px',
	borderRadius: '8px',
	bgcolor: 'background.paper',
	boxShadow: '0px 20px 26px -6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)',
	p: 4,
};

export const titleModalWrapper: any = {
	display: 'flex',
	flexDirection: {xs: 'column-reverse', sm: 'row'},
	width: '100%',
	justifyContent: {xs: 'start', sm: 'space-between'},

	'& > div': {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
	},
	'&  button': {
		position: 'relative',
		left:'-10px',
		padding: {xs: '0'},
		visibility: {sm: 'visible', md: 'hidden', lg: 'visible'},
		alignSelf: {xs: 'flex-start', sm: 'center'},
	},
	'& span': {
		display: {xs: 'flex', sm: 'none'},
	},
};
export const modalTitle: any = {
	fontFamily: 'Mulish',
	fontStyle: 'normal',
	fontWeight: 'bold',
	fontSize: {xs: '24px', sm: '32px'},
	lineHeight: '40px',
	letterSpacing: '0.01em',
};
export const closeModal = {
	color: '#042675',
	fontSize: '16px',
	margin: '10px',

};

export const textFieldStyle: any = {
	width: '100%',
	color: 'red',
	marginTop: '30px',
	'& textarea': {
		fontFamily: 'Lato',
		color: '#0B0B0B',
		fontSize: '16px',
		margin: '0 10px',
		letterSpacing: '0.01em',
	},
};

export const buttonsWrapper: any = {
	display: 'flex',
	alignSelf: 'flex-end',
	marginTop: '45px',
	'& > button:first-of-type': {
		marginRight: '32px',
	},
	'& > button:last-of-type': {
		borderRadius: '8px',
		width: '117px',
		height: '46px',
		'&:disabled': {
			backgroundColor: '#AEC1EE',
			color: '#FFFFFF',
		},
	},
};
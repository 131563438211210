import { MenuItem } from "@mui/material"
import { InputField } from "../FormCommons"

export const AgeSelectDropdown = (props) => {
  const ageOptions = Array.from(Array(20).keys())
    // .filter((n) => (maxAge ? n < maxAge : true))
    .map((n) => (
      <MenuItem value={n + 1} key={n}>
        {n + 1} lat
      </MenuItem>
    ))

  return (
    <div>
      <InputField {...props} select>
        {ageOptions}
      </InputField>
    </div>
  )
}

import { isValidElement, forwardRef } from "react"
import Typography, { TypographyProps } from "@mui/material/Typography"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox"
import {
  Controller,
  ControllerProps,
  FieldValues,
  UnpackNestedValue,
  PathValue,
  Path
} from "react-hook-form"
import clsx from "clsx"
import { useTranslation } from "react-i18next"

export function SectionTitle({
  children,
  onEdit,
  onDelete,
  useBlueBackground = true
}: any) {
  const { t } = useTranslation()
  return (
    <div
      className={`mb-2 ${
        useBlueBackground && "bg-blue-50"
      } p-4 flex justify-between items-center rounded`}
    >
      <div className="text-2xl font-bold">{children}</div>
      {onEdit && (
        <div
          onClick={onEdit}
          className="font-bold hover:underline cursor-pointer text-fc-light-blue"
        >
          {t("edit", "Edytuj")}
        </div>
      )}
      {onDelete && (
        <IconButton aria-label="delete" onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  )
}

export function LabeledField({
  label,
  value,
  children,
  direction,
  className
}: {
  label: string
  value?: any
  children?: any
  direction?: "row" | "column"
  className?: string
}) {
  return (
    <div
      className={clsx(
        "my-4 text-lg",
        direction === "row" && "flex gap-3 !my-1",
        className && className
      )}
      key={label}
    >
      <dt
        className={clsx(
          "text-sm text-gray-500 font-medium ",
          direction === "row" && "w-[30%]"
        )}
      >
        {label}
      </dt>
      {children !== undefined || value ? (
        <dd
          className={clsx(
            "whitespace-pre-line",
            direction === "row" && "w-[70%]"
          )}
        >
          {typeof value != "object" ? value : ""}
          {children}
        </dd>
      ) : (
        "-"
      )}
    </div>
  )
}

export function DescriptionList({
  data,
  separator,
  children,
  direction = "column"
}: {
  data: Record<string, React.ReactNode | Record<string, React.ReactNode>>
  separator?: boolean
  children?: any
  direction?: "row" | "column"
}) {
  return (
    <dl className="px-4">
      {Object.keys(data).map((dataKey, index) => {
        const currentData = data[dataKey]
        // currentData is nested
        if (
          currentData &&
          Object.keys(currentData).length !== 0 &&
          !isValidElement(currentData) &&
          typeof currentData === "object" &&
          !Array.isArray(currentData)
        ) {
          return (
            <div className="flex" key={index}>
              {Object.keys(currentData).map((dataNestedKey, index) => (
                <div className="my-4 text-lg w-full" key={index}>
                  <dt className="text-sm text-gray-500 font-medium">
                    {dataNestedKey}
                  </dt>
                  <dd className="whitespace-pre-line">
                    {
                      (currentData as Record<string, React.ReactNode>)[
                        dataNestedKey
                      ]
                    }
                  </dd>
                </div>
              ))}
            </div>
          )
        }
        return (
          <LabeledField
            direction={direction}
            label={dataKey}
            value={currentData}
          />
        )
      })}
      {children}
      {separator && <FormSectionSeparator />}
    </dl>
  )
}

export function Heading2(props: TypographyProps<"h2">) {
  return <Typography variant="h5" component="h2" fontWeight="bold" {...props} />
}

export function Heading3(props: TypographyProps<"h3">) {
  return (
    <Typography
      className="mb-4"
      component="h3"
      fontSize={20}
      fontWeight={600}
      {...props}
    />
  )
}
export function SmallCaptionText({ children }) {
  return (
    <div className="mb-4 mt-2 text-sm text-fc-color-accent-4 ">{children}</div>
  )
}
export function Subtitle(props: TypographyProps<"div">) {
  return (
    <Typography
      variant="subtitle2"
      component="div"
      color="grey.600"
      {...props}
    />
  )
}

export const InputField = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    // console.log("Input field, value", props)
    return (
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        sx={{
          // https://github.com/mui-org/material-ui/issues/27707
          "& .MuiInputLabel-asterisk, & .MuiFormLabel-asterisk": {
            display: "none"
          }
        }}
        inputRef={ref}
        {...props}
      />
    )
  }
)

type InputCheckProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
> = Omit<ControllerProps<TFieldValues, TName>, "render"> & {
  label: string
  readonly?: false
}

type InputCheckReadonlyProps = CheckboxProps & {
  label: string
  readonly: true
}

export function InputCheck(props: InputCheckReadonlyProps): JSX.Element
export function InputCheck<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>(props: InputCheckProps<TFieldValues, TName>): JSX.Element

export function InputCheck<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>
>({
  label,
  readonly,
  ...props
}:
  | InputCheckProps<TFieldValues, TName>
  | InputCheckReadonlyProps): JSX.Element {
  if (readonly) {
    return (
      <FormGroup className="mb-4">
        <FormControlLabel
          control={<Checkbox {...props} />}
          label={label}
          disabled
          sx={{
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: "unset"
            }
          }}
        />
      </FormGroup>
    )
  }
  return (
    <Controller
      // @ts-ignore
      defaultValue={false as UnpackNestedValue<PathValue<TFieldValues, TName>>}
      render={({ field: { value, ref, ...field } }) => (
        <FormGroup className="mb-4">
          <FormControlLabel
            control={<Checkbox checked={value} inputRef={ref} {...field} />}
            label={label}
          />
        </FormGroup>
      )}
      {...(props as InputCheckProps<TFieldValues, TName>)}
    />
  )
}

export const FormSectionSeparator = () => (
  <div className="mb-8 border-b pt-4" />
  // {/* <Box mb={4} mt={2} style={{ borderTop: "1px solid #E9E9E9" }} /> */}
)

export function FormHelperText({ children }: { children: any }) {
  return (
    <Typography color="menteGrey.4" fontSize="14">
      {children}
    </Typography>
  )
}

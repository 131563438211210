import React from "react"

function ParticipantWidget({ booking }) {
  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - new Date(birthday).getTime()
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  return (
    <div className="pt-[37px]">
      <p className="font-bold">Uczestnicy ({booking.participants.length})</p>
      {booking.participants.map((p, index) => {
        var person = p.person ?? p
        return (
          <div key={index}>
            <div className="text-fc-gray-4">
              {person.first_name} {person.last_name},{" "}
              {calculateAge(person.birth_date)} lat
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ParticipantWidget

import { useRouter } from 'next/router'
import * as locales from "react-date-range/dist/locale"

const useLocale = () => {
    const router = useRouter()
    const code = router.locale
    return { locale: router.locale === 'en' ? locales.enGB : locales.pl, code: code }
}

export default useLocale

import { useBookingApi } from "./useBookingApi"
import { MojeButton } from "../../components/ui/MojeButton"
import { useSnackbar } from "notistack"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { useState } from "react"
import { RejectReservationPopup } from "./RejectReservationPopup"
import { ChangeBookingPricePopup } from "./ChangeBookingPricePopup"

const ReservationActionsNew = ({ booking, onSaved }) => {
  const { runCommand, isSaving } = useBookingApi()
  const [showChangePricePopup, setShowChangePricePopup] = useState(false)
  const [showRejectPopup, setShowRejectPopup] = useState(false)

  const run = async (command, successMessage) => {
    var res = await runCommand(command)
    if (res?.success) {
      onSaved(successMessage)
    }
  }

  const handleDoAccept = async () => {
    return run(
      {
        type: "Booking.ConfirmReservation",
        aggregate_id: booking.id,
        payload: {}
      },
      "Rezerwacja zaakceptowana"
    )
  }

  return (
    <div className="py-4">
      {/* 2023-03-08 not in use <div
        className="underline font-bold  mb-5 cursor-pointer text-fc-light-blue"
        onClick={() => setShowChangePricePopup(true)}
      >
        ZMIEŃ CENĘ
      </div> */}

      <RejectReservationPopup
        onClose={() => setShowRejectPopup(false)}
        open={showRejectPopup}
        booking={booking}
        onSaved={onSaved}
      />

      {/* <ChangeBookingPricePopup
        onClose={() => setShowChangePricePopup(false)}
        open={showChangePricePopup}
        booking={booking}
        onSaved={onSaved}
      /> */}
      <div className="my-2">
        <MojeButton
          variant="contained"
          color="primary"
          onClick={handleDoAccept}
          isSaving={isSaving}
        >
          Zaakceptuj
        </MojeButton>
      </div>
      <div className="my-2">
        <MojeButton
          variant="outlined"
          color="primary"
          onClick={() => setShowRejectPopup(true)}
        >
          Odrzuć
        </MojeButton>
      </div>
    </div>
  )
}
const ChatBookingSummary = ({ booking }) => {
  return (
    <div className="my-4">
      <div>
        {booking.parent?.first_name} {booking.parent?.last_name}
      </div>
      <div>{booking.product_data?.name}</div>
      <div>
        {ProductUtils.formatDate(booking.start_date)}
        {"-"}
        {ProductUtils.formatDate(booking.end_date)}
      </div>
      {booking.participants?.map((p: any, index) => (
        <div key={index}>
          {p?.first_name} {p?.last_name}
        </div>
      ))}
    </div>
  )
}

export const ChatBookingCard = ({ booking, onSaved }) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleSaved = async (successMessage) => {
    console.log("handleSaved")
    enqueueSnackbar(successMessage, { variant: "success" })
    onSaved()
  }
  return (
    <div>
      <ChatBookingSummary booking={booking} />
      {/* {booking.status === "NEW" && (
        <ReservationActionsNew booking={booking} onSaved={handleSaved} />
      )} */}
      {booking.status === "RESERVED" && (
        <div className="my-2 font-bold">Rezerwacja ZAAKCEPTOWANA!</div>
      )}
      {/* {booking.status === "NEW" && (
        <ReservationActionsNew booking={booking} onSaved={handleSaved} />
      )} */}
    </div>
  )
}

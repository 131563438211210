import React, { useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CompanySideWidget from './CompanySideWidget'
import Link from 'next/link'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { WakacjeChip, WakacjeChipVariant } from '../../components/WakacjeChip'
import { ProductUtils } from '../../../utils/ProductUtils'
import { useRouter } from 'next/router'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'next-i18next'

function CompanyInfoMobile ({
  company,
  setIsMobileInfoOpen,
  product,
  userChatId
}: {
  company: any
  product: any
  userChatId?: string
  setIsMobileInfoOpen
}) {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const router = useRouter()
  const { t } = useTranslation('common')
  let navigate
  if (userChatId) {
    navigate = useNavigate()
  }

  if (product?.type === 'SCHOOLTRIP') {
    product.type = 'wycieczka-szkolna'
  } else if (product?.type === 'GROUPACCOMODATION') {
    product.type = 'nocleg-dla-grup'
  }
  console.log(' company,product', company, product)
  return (
    <>
      <div className=' lg:text-2xl flex justify-between border-b md:border-b-0 py-2 w-[100%]  px-3 items-center'>
        {!userChatId && (
          <Link href='#'>
            <div
              className='flex'
              onClick={() => {
                router.back()
              }}
            >
              {!md && <ChevronLeftIcon />}
              <p className='font-bold'>
                {!userChatId ? company?.name : product?.user_name}
              </p>
            </div>
          </Link>
        )}
        {userChatId && (
          <Link href='#'>
            <p className='font-bold'>{product?.user_name}</p>
          </Link>
        )}

        {!md && product?.type === 'wycieczka-szkolna' && (
          <div>
            <img
              className='cursor-pointer'
              onClick={() => setIsMobileInfoOpen(true)}
              src='/svg/info-icon-blue.svg'
              width='24'
              height='24'
            />
          </div>
        )}
      </div>
      <div
        className=' text-xs md:text-sm flex gap-2 mb-6 border-b md:shadow-none py-2 w-[100%]  px-3 items-center'
        style={{ boxShadow: !md ? ' 0 3px 2px -2px gray' : '' }}
      >
        <div>
          {t('general.offer', 'Oferta')}:{' '}
          <span className='font-bold'>
            {product?.product_name ?? product?.product.name}
          </span>
        </div>
        {md && (
          <div>
            <WakacjeChip
              variant={WakacjeChipVariant.BLUE}
              label={ProductUtils.typeLabel(
                product?.product_type ?? product?.type
              )}
            />
          </div>
        )}
        {md && product?.type === 'wycieczka-szkolna' && (
          <div className='flex gap-2'>
            {' '}
            {t('account.inquiry_details', 'Szczegóły zapytania')}{' '}
            <img
              className='cursor-pointer'
              onClick={() => setIsMobileInfoOpen(true)}
              src='/svg/info-icon-blue.svg'
              width='14'
              height='14'
            />
          </div>
        )}

        {product?.type === 'wycieczka-szkolna' && userChatId && (
          <div className='flex gap-2 items-center ml-auto'>
            {' '}
            {t(
              'account.prepare_custom_offer',
              'Po ustaleniu szczegółów z Klientem przygotuj dedykowaną ofertę'
            )}{' '}
            :{' '}
            <Button
              variant='contained'
              onClick={() => {
                navigate(
                  `/products/schooltrip/individual-offer/${product?.product_id}/${userChatId}`
                )
              }}
            >
              {t('account.create_offer', 'Utwórz ofertę')}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default CompanyInfoMobile

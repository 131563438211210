import axios from "axios"

export const useAcknowledgeMessages = (rootUrl) => {
  const acknowledge = (chat_hash, role, authToken) => {
    var url = rootUrl + `/messages/v1/chats/${chat_hash}/ack`

    const payload = {
      chat_hash,
      role
    }

    const headers = {
      Authorization: `Bearer ${authToken}`
    }

    axios
      .post(url, payload, { headers })
      .then((response) => console.log(response))
  }

  return { acknowledge }
}

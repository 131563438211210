import clsx from "clsx"
import React from "react"

function Heading({
  children,
  className,
  size = "h3",
  as: Tag = "div",
  color = "black",
}: {
  children: React.ReactNode
  className?: string
  size?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  as?: React.ElementType
  color?: "black" | "gray" | "blue" | "red" | "lightBlue"
}) {
  return (
    <Tag
      className={clsx(
        "font-bold",
        size === "h1" && "text-5xl",
        size === "h2" && "text-4xl",
        size === "h3" && "text-3xl",
        size === "h4" && "text-2xl",
        size === "h5" && "text-xl",
        size === "h6" && "text-lg",
        color === "black" && "text-fc-black",
        color === "gray" && "text-fc-gray-4",
        color === "blue" && "text-fc-primary",
        color === "red" && "text-fc-red",
        color === "lightBlue" && "text-fc-light-blue",
        className,
      )}
    >
      {children}
    </Tag>
  )
}

export default Heading

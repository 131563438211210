import { useLocation } from "react-router-dom"
import { DescriptionList, InputField } from "../../../../components/FormCommons"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import { FormSectionContainer } from "./components/FormSectionContainer"
import { useFormContext } from "react-hook-form"
import { getLocalizedKey } from "../../../../utils/getLocalizedKey"

export const ProductSectionConditionsForm = ({ register, values }: any) => {
  const { watch } = useFormContext()
  const location = useLocation()

  const participationDescriptionKey = getLocalizedKey(
    "participation_description"
  )

  return (
    <>
      <FormSectionContainer sectionTitle="Dodatkowe informacje">
        <InputField
          label="Opis"
          multiline
          minRows={3}
          value={watch(participationDescriptionKey)}
          {...register(participationDescriptionKey)}
        />

        {/* <div>
        <MojeTextInput
          {...register("discounts_description", { required: false })}
          id={`discounts_description-${index}`}
          label="Informacje o zniżkach"
          error={errors.discounts_description?.message}
          fullWidth
          multiline
          rows={3}
          placeholder="Informacje o zniżkach
Dodaj informację o zniżkach i promocjach. Np. Przy rezerwacji dla rodzeństwa 20% zniżki. Zarezerwuj dwa turnusy i zapłać tylko X PLN."
        />
      </div> */}
      </FormSectionContainer>

      {/* {values.type !== "wycieczka-szkolna" && (
        <FormSectionContainer sectionTitle="Informacje o zniżkach">
          <InputField
            label="Opis"
            multiline
            minRows={3}
            {...register("discounts_description")}
          />
        </FormSectionContainer>
      )} */}
    </>
  )
}

export const ProductSectionConditionsPreview = ({ values }: any) => {
  const participationDescriptionKey = getLocalizedKey(
    "participation_description"
  )

  return (
    <DescriptionList
      data={
        values.type === "wycieczka-szkolna"
          ? {
              "Dodatkowe informacje": values[participationDescriptionKey]
            }
          : {
              "Dodatkowe informacje": values[participationDescriptionKey]
              // "Informacje o zniżkach": values.discounts_description
            }
      }
    />
  )
}

export const ProductSectionConditionsView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()

  const participationDescriptionKey = getLocalizedKey(
    "participation_description"
  )

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var productData: any = {
      id: values.id,
      participation_conditions: data.participation_conditions,
      participation_description: data[participationDescriptionKey]
    }
    console.log("new product data", data)
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }

  return (
    <EditableFormSection
      title="Ważne informacje"
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        console.log("Builder props", builderProps)
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionConditionsForm values={values} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <ProductSectionConditionsPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { ProductTypeChip } from "../../../components/events/ProductTypeChip"
import { ApiTable, ApiTableRefType } from "../../../components/table/ApiTable"
import { useProductApi } from "../events/api/useProductApi"
import { ProductsRowDetails } from "./ProductRowDetails"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "../../../components/WakacjeChip"
import { Link } from "react-router-dom"
import {
  months,
  ProductStatus,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"
import { useRef, useState } from "react"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { useTranslation } from "react-i18next"
import { OptionButton } from "../places/OptionButton"
import { ArrowIcon } from "../../../components/icons/ArrowIcon"
import clsx from "clsx"
import { CheckIcon } from "../../../components/icons/CheckIcon"
import { XIcon } from "../../../components/icons/XIcon"
import { EditTripDataDialog } from "../../../components/TripDialogs/EditTripDataDialog"
import { AddTripDataDialog } from "../../../components/TripDialogs/AddTripDataDialog"
import { groupTableColumns } from "../groups/groupTableColumns"
import { useObjectTypes } from "../../../hooks/useObjectTypes"

export const ActionWithLoader = ({ onClick, children }) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (params) => {
    setLoading(true)
    await onClick(params)
    setLoading(false)
  }
  return (
    <div onClick={handleClick}>
      {children({ onClick: handleClick, loading })}
    </div>
  )
}

export const ProductActionWithLoader = ({ onClick, label, icon }) => {
  const Icon = icon
  return (
    <div className="menu-items-table-container">
      <ActionWithLoader onClick={onClick}>
        {({ onClick, loading }) => {
          return (
            <div className="flex flex-row items-center">
              <div className="text-lg">
                {loading ? (
                  <div className="text-base">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <Icon fontSize="inherit" fill="black" />
                )}
              </div>
              <p className="ml-4 font-normal">{label}</p>
            </div>
          )
        }}
      </ActionWithLoader>
    </div>
  )
}
const ProductsTableInner = ({
  listApi,
  showExpandColumn,
  queryParams,
  prefix,
  type,
  sortAlphabetically,
  ...props
}: {
  listApi: Function
  showExpandColumn?: boolean
  queryParams?: any
  prefix: string
  type: string
  sortAlphabetically?: boolean
  isArchive?: boolean
}) => {
  const tableRef = useRef<ApiTableRefType>()
  const { runProductCommand } = useProductApi()
  const { t } = useTranslation()
  const [selectedRow, setSelectedRow] = useState(null)

  const [popupEditData, setPopupEditData] = useState(undefined)

  const [popupAddDate, setPopupAddDate] = useState(undefined)

  const allObjectTypes = useObjectTypes()

  const mapObjectTypes = (objectType) => {
    const names = objectType
      .map((typeId) => {
        const foundType = allObjectTypes.find((type) => type.id === typeId)
        return foundType ? foundType.name : null
      })
      .filter(Boolean)
      .join(", ")

    return names
  }

  let titleColumName = t("ui.school_trips.offer", "Oferta")
  if (type === "camp") {
    titleColumName = t("ui.camps.name_table_field", "Nazwa Kolonii/ Obozu")
  }
  if (type === "daycamp") {
    titleColumName = t("ui.daycamps.daycamp_name", "Nazwa Półkolonii")
  }
  if (type === "schooltrip") {
    titleColumName = t("ui.school_trips.trip_name", "Nazwa Wycieczki")
  }

  const refetch = () => {
    if (tableRef.current) {
      tableRef.current.refetch()
    }
  }

  const handleActivateProduct = async (product) => {
    const command = {
      type: "Product.Activate",
      aggregateId: product.id,
      payload: {}
    }
    try {
      await runProductCommand(command)
      refetch()
    } catch (err) {
      console.error("Error activating product")
    }
  }

  const handlePushProductToDraft = async (product) => {
    const command = {
      type: "Product.SendToDraft",
      aggregateId: product.id,
      payload: {}
    }
    try {
      await runProductCommand(command)
      refetch()
    } catch (err) {
      console.error("Error activating product")
    }
  }

  const handleSuspendProduct = async (product) => {
    const command = {
      type: "Product.Suspend",
      aggregateId: product.id,
      payload: {}
    }
    try {
      await runProductCommand(command)
      refetch()
    } catch (err) {
      console.error("Error suspending product")
    }
  }
  const handleArchiveOffer = async (product) => {
    const command = {
      type: "Product.Archive",
      aggregateId: product.id,
      payload: {}
    }
    try {
      await runProductCommand(command)
      refetch()
    } catch (err) {
      console.error("Error archiving product")
    }
  }

  const handleDeleteOffer = async (product) => {
    const command = {
      type: "Product.Delete",
      aggregateId: product.id,
      payload: {}
    }
    try {
      await runProductCommand(command)
      refetch()
    } catch (err) {
      console.error("Error archiving product")
    }
  }

  const handleDuplicateOffer = async (product) => {}

  let columns: any = []
  if (props.isArchive) {
    if (type !== "schooltrip") {
      columns = [
        {
          header: titleColumName,
          width: "30%",
          cell: (row: any) => (
            <>
              <div className="font-bold text-lg mb-2 leading-tight">
                {row.name}
              </div>
              {row.status && (
                <div>
                  <WakacjeChip
                    label={row.status}
                    variant={WakacjeChipVariant.GREEN}
                    showStatus={true}
                  />
                </div>
              )}
            </>
          ),
          sort: false
        },
        {
          header: t("ui.school_trips.category", "Kategoria"),
          cell: (row: any) => {
            return (
              <div className="whitespace-nowrap">
                <ProductTypeChip type={row.type} />
              </div>
            )
          }
        },
        {
          header: t("ui.school_trips.age", "Wiek"),
          cell: (row: any) => {
            return (
              <>
                {row.min_age}
                {"-"}
                {row.max_age}
              </>
            )
          }
        },
        {
          header: t("ui.school_trips.participants", "Uczestnicy"),
          align: "center",
          cell: (row: any) => {
            return (
              <>
                <p>{row.stat_participants_count}</p>
              </>
            )
          }
        },
        {
          header: t("ui.school_trips.terms", "Terminy"),
          cell: (row: any) => (
            <div className="flex gap-4 items-center">
              <div className="flex flex-row gap-2">
                <div title="Aktywne terminy">{row.stat_active_trips_count}</div>
                <div>/</div>
                <div title="Wszystkie terminy (łącznie z zakończonymi)">
                  {row.stat_all_trips_count}
                </div>
              </div>

              <button
                className={clsx("rotate-180")}
                onClick={(e) => {
                  e.preventDefault()
                  setSelectedRow(row.id === selectedRow ? undefined : row.id)
                }}
              >
                <ArrowIcon />
              </button>
            </div>
          )
        }
      ]
    }
    if (type === "schooltrip") {
      columns = [
        {
          header: t("ui.school_trips.trip_name", "Nazwa Wycieczki"),
          width: "80%",
          cell: (row: any) => (
            <>
              <div className="font-bold text-lg mb-2">{row.name}</div>
              {row.status && (
                <div>
                  <WakacjeChip
                    label={row.status}
                    variant={WakacjeChipVariant.GREEN}
                    showStatus={true}
                    type={type}
                  />
                </div>
              )}
            </>
          ),
          sort: false
        },
        {
          header: t("ui.school_trips.category", "Kategoria"),
          cell: (row: any) => {
            return (
              <div className="whitespace-nowrap">
                <ProductTypeChip type={row.type} />
              </div>
            )
          }
        },
        {
          header: t("ui.school_trips.type", "Rodzaj"),
          cell: (row: any) => {
            return (
              <>
                <p className="whitespace-nowrap">
                  {row.trip_type === "jednodniowa"
                    ? "1-dniowa"
                    : t("product.one_day", "1-dniowa").replace(
                        "1-",
                        `${row.duration_days ?? 1}-`
                      )}
                </p>
              </>
            )
          }
        },
        {
          header: t("ui.school_trips.age", "Wiek"),
          cell: (row: any) => {
            return (
              <p className="whitespace-nowrap">
                {row.min_age}
                {"-"}
                {row.max_age}
              </p>
            )
          }
        },
        {
          header: t("ui.school_trips.availability", "Dostępność"),
          cell: (row: any) => {
            return (
              <>
                {row.availability && (
                  <p>
                    {row.availability.length === 12
                      ? "cały rok"
                      : row.availability
                          .map((i) => {
                            const m = months.find((m) => m.value === i)
                            return m?.name ?? i
                          })
                          .join(", ")}
                  </p>
                )}
              </>
            )
          }
        }
      ]
    }
  } else {
    if (type === "camp" || type === "daycamp") {
      columns = [
        {
          header: titleColumName,
          width: "70%",
          cell: (row: any) => (
            <>
              <div className="font-bold text-lg mb-2 leading-tight">
                {row.name}
              </div>
              {row.status && (
                <div>
                  <WakacjeChip
                    label={row.status}
                    variant={WakacjeChipVariant.GREEN}
                    showStatus={true}
                  />
                </div>
              )}
            </>
          ),
          sort: false
        },
        {
          header: t("ui.camps.category", "Kategoria"),
          cell: (row: any) => {
            return (
              <div className="whitespace-nowrap">
                <ProductTypeChip type={row.type} />
              </div>
            )
          }
        },
        {
          header: t("ui.camps.age", "Wiek"),
          cell: (row: any) => {
            return (
              <>
                {row.min_age}
                {"-"}
                {row.max_age}
              </>
            )
          }
        },
        {
          header: t("ui.camps.participants", "Uczestnicy"),
          align: "center",
          cell: (row: any) => {
            return (
              <>
                <p>{row.stat_participants_count}</p>
              </>
            )
          }
        },
        // {
        //   header: t("ui.school_trips.category", "Opłaty"),
        //   cell: (row: any) => {
        //     return (
        //       <>
        //         <p>{ProductUtils.formatPriceInCents(row.stat_payments_total)}</p>
        //       </>
        //     )
        //   }
        // },
        // {
        //   header: "Suma opłat",
        //   cell: (row: any) => {
        //     return (
        //       <>
        //         <p>-</p>
        //         {/* <p>15 000/100 000 zł</p> */}
        //       </>
        //     )
        //   }
        // },
        // {
        //   header: "Dostępność",
        //   cell: (row: any) => {
        //     return <></>
        //   }
        // },
        {
          header: t("ui.camps.terms", "Terminy"),
          cell: (row: any) => (
            <div className="flex gap-4 items-center">
              <div className="flex flex-row gap-2">
                <div title="Aktywne terminy">{row.stat_active_trips_count}</div>
                <div>/</div>
                <div title="Wszystkie terminy (łącznie z zakończonymi)">
                  {row.stat_all_trips_count}
                </div>
              </div>

              <button
                className={clsx("rotate-180")}
                onClick={(e) => {
                  e.preventDefault()
                  setSelectedRow(row.id === selectedRow ? undefined : row.id)
                }}
              >
                <ArrowIcon />
              </button>
            </div>
          )
        },
        {
          header: t("ui.camps.options", "Opcje"),
          cell: (row: any) => {
            return (
              <div>
                <OptionButton
                  row={row}
                  options={["edit", "duplicate", "add", "preview", "remove"]}
                  handleDuplicate={handleDuplicateOffer}
                  handleDelete={handleDeleteOffer}
                  rowType="offer"
                  setPopupAddDate={setPopupAddDate}
                />
              </div>
            )
          }
        }
      ]
    }

    if (type === "schooltrip") {
      columns = [
        {
          header: t("ui.school_trips.trip_name", "Nazwa Wycieczki"),
          width: "80%",
          cell: (row: any) => (
            <>
              <div className="font-bold text-lg mb-2">{row.name}</div>
              {row.status && (
                <div>
                  <WakacjeChip
                    label={row.status}
                    variant={WakacjeChipVariant.GREEN}
                    showStatus={true}
                    type={type}
                  />
                </div>
              )}
            </>
          ),
          sort: false
        },
        {
          header: t("ui.school_trips.category", "Kategoria"),
          cell: (row: any) => {
            return (
              <div className="whitespace-nowrap">
                <ProductTypeChip type={row.type} />
              </div>
            )
          }
        },
        {
          header: t("ui.school_trips.type", "Rodzaj"),
          cell: (row: any) => {
            return (
              <>
                <p className="whitespace-nowrap">
                  {row.trip_type === "jednodniowa"
                    ? "1-dniowa"
                    : t("product.one_day", "1-dniowa").replace(
                        "1-",
                        `${row.duration_days ?? 1}-`
                      )}
                </p>
              </>
            )
          }
        },
        {
          header: t("ui.school_trips.age", "Wiek"),
          cell: (row: any) => {
            return (
              <p className="whitespace-nowrap">
                {row.min_age}
                {"-"}
                {row.max_age}
              </p>
            )
          }
        },
        {
          header: t("ui.school_trips.availability", "Dostępność"),
          cell: (row: any) => {
            return (
              <>
                {row.availability && (
                  <p>
                    {row.availability.length === 12
                      ? "cały rok"
                      : row.availability
                          .map((i) => {
                            const m = months.find((m) => m.value === i)
                            return m?.name ?? i
                          })
                          .join(", ")}
                  </p>
                )}
              </>
            )
          }
        },
        {
          header: t("ui.school_trips.options", "Opcje"),
          cell: (row: any) => {
            return (
              <div>
                <OptionButton
                  row={row}
                  options={["edit", "duplicate", "remove"]}
                  handleDuplicate={handleDuplicateOffer}
                  handleDelete={handleDeleteOffer}
                />
              </div>
            )
          }
        }
      ]
    }
    if (type === "groups") {
      columns = groupTableColumns(
        handleDuplicateOffer,
        handleDeleteOffer,
        mapObjectTypes,
        t
      )
    }
  }

  return (
    <>
      <ApiTable
        {...props}
        prefix={prefix}
        ref={tableRef}
        columns={columns}
        listApi={listApi}
        queryParams={queryParams}
        showExpandColumn
        nestedTableComponent={ProductsNestedTripsTable}
        rowLinkBuilder={(row: any) => {
          if (row.type === PRODUCT_TYPE_SCHOOL_TRIP.value) {
            return `/products/${prefix}/${row.id}/details`
          }
          return `/products/${prefix}/${row.id}/details`
        }}
        rowDetailsRenderer={(row) => (
          <ProductsRowDetails
            row={row}
            setPopupEditData={setPopupEditData}
            setPopupAddDate={setPopupAddDate}
          />
        )}
        rowPopupBuilder={(row) => {
          if (row.status === ProductStatus.ARCHIVED) {
            return (
              <ProductActionWithLoader
                label={t("activate", "Aktywuj")}
                onClick={() => handlePushProductToDraft(row)}
                icon={CheckIcon}
              />
            )
          }

          return (
            <>
              {type !== "schooltrip" &&
                row.status !== ProductStatus.ARCHIVED &&
                row.status !== ProductStatus.SUSPENDED && (
                  <Link
                    to={`${row.id}/turnusy/dodaj`}
                    className="menu-items-table-container"
                  >
                    <AddCircleOutlineIcon fontSize="inherit" />
                    <p className="ml-4 font-normal">
                      {t("add_term", "Dodaj termin")}
                    </p>
                  </Link>
                )}
              {(row.status === ProductStatus.DRAFT ||
                row.status === ProductStatus.SUSPENDED ||
                row.status === ProductStatus.ARCHIVED) && (
                <ProductActionWithLoader
                  label={t("activate", "Aktywuj")}
                  onClick={() => handleActivateProduct(row)}
                  icon={CheckIcon}
                />
              )}
              {row.status === ProductStatus.ACTIVE && (
                <div className="menu-items-table-container">
                  <ActionWithLoader
                    onClick={() => {
                      if (type === "schooltrip") {
                        handleArchiveOffer(row)
                      } else {
                        handleSuspendProduct(row)
                      }
                    }}
                  >
                    {({ onClick, loading }) => {
                      return (
                        <div className="flex flex-row items-center">
                          <div className="text-lg">
                            {loading ? (
                              <div className="text-base">
                                <LoadingSpinner />
                              </div>
                            ) : (
                              <XIcon fill="black" />
                            )}
                          </div>
                          <p className="ml-4 font-normal">
                            {t("deactivate", "Dezaktywuj")}
                          </p>
                        </div>
                      )
                    }}
                  </ActionWithLoader>
                </div>
              )}
              {/* <div className="menu-items-table-container">
              <ContentCopyIcon fontSize="inherit" />
              <p className="ml-4 font-normal">Duplikuj ofertę</p>
            </div> */}
              {/* <div className="menu-items-table-container">
              <LockIcon fontSize="inherit" />
              <p className="ml-4 font-normal">Wstrzymaj zapisy</p>
            </div> */}
              {type !== "schooltrip" && row.status !== ProductStatus.ARCHIVED && (
                <div className="menu-items-table-container">
                  <ActionWithLoader onClick={() => handleArchiveOffer(row)}>
                    {({ onClick, loading }) => {
                      return (
                        <div className="flex flex-row">
                          <div className="text-lg">
                            {loading ? (
                              <div className="text-base">
                                <LoadingSpinner />
                              </div>
                            ) : (
                              <DeleteOutlineIcon fontSize="inherit" />
                            )}
                          </div>
                          <p className="ml-4 font-normal">
                            {t("archive", "Archiwizuj ofertę")}
                          </p>
                        </div>
                      )
                    }}
                  </ActionWithLoader>
                </div>
              )}
            </>
          )
        }}
        selectedRow={selectedRow}
        sortAlphabetically={sortAlphabetically}
      />
      {popupEditData && !popupAddDate && (
        <EditTripDataDialog
          open={!!popupEditData}
          product={popupEditData}
          onClose={() => setPopupEditData(undefined)}
        />
      )}
      {popupAddDate && !popupEditData && (
        <AddTripDataDialog
          open={!!popupAddDate}
          product={popupAddDate}
          onClose={() => setPopupAddDate(undefined)}
        />
      )}
    </>
  )
}

const ProductsNestedTripsTable = () => {}

export const ProductsTable = ({
  queryParams,
  ...props
}: {
  queryParams: any
  nothingFoundComponent?: any
  prefix: string
  type: string
  sortAlphabetically?: boolean
  isArchive?: boolean
}) => {
  const { listProducts } = useProductApi()

  return (
    <ProductsTableInner
      listApi={listProducts}
      queryParams={queryParams}
      {...props}
    />
  )
}

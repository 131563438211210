import { Typography } from "@mui/material"
import styled from "@emotion/styled"

let BoxDiv = styled.div({
  backgroundColor: "#F3F6FD",
  width: 180,
  padding: 20,
  textAlign: "center",
  margin: 10,
  borderRadius: 8
})

export function WakacjeInfoCard(props: {
  label: string
  value: string
  width?: number
}) {
  return (
    <BoxDiv
      className="flex flex-col"
      style={{
        width: props.width ?? 180
      }}
    >
      <Typography
        variant="h6"
        component="h1"
        sx={{
          color: "#0C48D7",
          paddingBottom: "10px",
          fontSize: 18,
          fontWeight: "bold"
        }}
        className="whitespace-nowrap"
      >
        {props.value}
      </Typography>
      <div className="flex-1"></div>
      <Typography variant="subtitle2" component="h1">
        {props.label}
      </Typography>
    </BoxDiv>
  )
}

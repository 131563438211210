import {
  FieldPath,
  FormProvider,
  RegisterOptions,
  get,
  useForm
} from "react-hook-form"
import { NewProductWizardButtonsBar } from "../products/forms/NewProductWizardButtonsBar"
import { SectionTitle } from "../../../components/FormCommons"
import { useTranslation } from "react-i18next"
import { ProductSectionDescriptionForm } from "./ProductSectionDescription"
import { ProductSectionAccommodationForm } from "./ProductSectionAccommodation"
import { ProductSectionLocationForm } from "../products/forms/ProductSectionLocation"
import { ProductSectionPriceForm } from "./ProductSectionPrice"
import { ProductSectionPlacesForm } from "../products/forms/ProductSectionPlace/ProductSectionPlace"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { productGroupsAccommodationSchema } from "./ProductJoinedSectionsGroupsAccommodation"

const NewGroupsAccommodationsPageDetails = ({
  values,
  onCancel,
  onSubmit,
  isSubmitting
}: any) => {
  const { t } = useTranslation()

  var defaultValues = values
    ? {
        ...values
      }
    : {}
  const type = values.type
  console.log("PagePricing type: ", type)

  const formMethods = useForm<any>({
    mode: "onTouched",
    resolver: yupResolver(productGroupsAccommodationSchema(t)),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { errors, isValid }
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  function onFormSubmit(data: any) {
    onSubmit(data)
  }
  return (
    <div>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <SectionTitle>
            {t("ui.groups.description_header", "Opis")}
          </SectionTitle>
          <ProductSectionDescriptionForm
            register={register}
            values={values}
            formMethods={formMethods}
          />

          <SectionTitle>
            {t("ui.groups.holiday_lettings", "Miejsca noclegowe")}
          </SectionTitle>
          <ProductSectionAccommodationForm
            register={register}
            values={values}
            {...formMethods}
          />
          <SectionTitle>{t("ui.groups.location", "Lokalizacja")}</SectionTitle>
          <ProductSectionLocationForm
            register={register}
            values={values}
            {...formMethods}
          />

          <SectionTitle>
            {t("ui.groups.price_and_payments", "Cena i płatności")}
          </SectionTitle>
          <ProductSectionPriceForm
            register={register}
            values={values}
            {...formMethods}
          />
          <div className="mb-8" />

          <SectionTitle>{t("ui.groups.attractions", "Atrakcje")}</SectionTitle>
          <ProductSectionPlacesForm />
          <NewProductWizardButtonsBar
            onSubmit={handleSubmit}
            onCancel={onCancel}
            isValid={isValid}
            finalStep
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default NewGroupsAccommodationsPageDetails

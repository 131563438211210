// useTranslatedStatesAndCountries.tsx
import { useTranslation } from "react-i18next"

export const useTranslatedStatesAndCountries = () => {
  const { t } = useTranslation()

  const listOfUSAStates = [
    { value: "AL", label: t("states.AL", "Alabama") },
    { value: "AK", label: t("states.AK", "Alaska") },
    { value: "AZ", label: t("states.AZ", "Arizona") },
    { value: "AR", label: t("states.AR", "Arkansas") },
    { value: "CA", label: t("states.CA", "California") },
    { value: "CO", label: t("states.CO", "Colorado") },
    { value: "CT", label: t("states.CT", "Connecticut") },
    { value: "DE", label: t("states.DE", "Delaware") },
    { value: "FL", label: t("states.FL", "Floryda") },
    { value: "GA", label: t("states.GA", "Georgia") },
    { value: "HI", label: t("states.HI", "Hawaje") },
    { value: "ID", label: t("states.ID", "Idaho") },
    { value: "IL", label: t("states.IL", "Illinois") },
    { value: "IN", label: t("states.IN", "Indiana") },
    { value: "IA", label: t("states.IA", "Iowa") },
    { value: "KS", label: t("states.KS", "Kansas") },
    { value: "KY", label: t("states.KY", "Kentucky") },
    { value: "LA", label: t("states.LA", "Luizjana") },
    { value: "ME", label: t("states.ME", "Maine") },
    { value: "MD", label: t("states.MD", "Maryland") },
    { value: "MA", label: t("states.MA", "Massachusetts") },
    { value: "MI", label: t("states.MI", "Michigan") },
    { value: "MN", label: t("states.MN", "Minnesota") },
    { value: "MS", label: t("states.MS", "Missisipi") },
    { value: "MO", label: t("states.MO", "Missouri") },
    { value: "MT", label: t("states.MT", "Montana") },
    { value: "NE", label: t("states.NE", "Nebraska") },
    { value: "NV", label: t("states.NV", "Nevada") },
    { value: "NH", label: t("states.NH", "New Hampshire") },
    { value: "NJ", label: t("states.NJ", "New Jersey") },
    { value: "NM", label: t("states.NM", "Nowy Meksyk") },
    { value: "NY", label: t("states.NY", "Nowy Jork") },
    { value: "NC", label: t("states.NC", "Karolina Północna") },
    { value: "ND", label: t("states.ND", "Dakota Północna") },
    { value: "OH", label: t("states.OH", "Ohio") },
    { value: "OK", label: t("states.OK", "Oklahoma") },
    { value: "OR", label: t("states.OR", "Oregon") },
    { value: "PA", label: t("states.PA", "Pensylwania") },
    { value: "RI", label: t("states.RI", "Rhode Island") },
    { value: "SC", label: t("states.SC", "Karolina Południowa") },
    { value: "SD", label: t("states.SD", "Dakota Południowa") },
    { value: "TN", label: t("states.TN", "Tennessee") },
    { value: "TX", label: t("states.TX", "Teksas") },
    { value: "UT", label: t("states.UT", "Utah") },
    { value: "VT", label: t("states.VT", "Vermont") },
    { value: "VA", label: t("states.VA", "Wirginia") },
    { value: "WA", label: t("states.WA", "Waszyngton") },
    { value: "WV", label: t("states.WV", "Wirginia Zachodnia") },
    { value: "WI", label: t("states.WI", "Wisconsin") },
    { value: "WY", label: t("states.WY", "Wyoming") },
    { value: "DC", label: t("states.DC", "Dystrykt Kolumbii") }
  ]

  const listOfEuropeanCountries = [
    { value: "PL", label: t("countries.PL", "Polska") },
    { value: "DE", label: t("countries.DE", "Niemcy") },
    { value: "FR", label: t("countries.FR", "Francja") },
    { value: "ES", label: t("countries.ES", "Hiszpania") },
    { value: "IT", label: t("countries.IT", "Włochy") },
    { value: "GB", label: t("countries.GB", "Wielka Brytania") },
    { value: "UA", label: t("countries.UA", "Ukraina") },
    { value: "RU", label: t("countries.RU", "Rosja") },
    { value: "SE", label: t("countries.SE", "Szwecja") },
    { value: "NO", label: t("countries.NO", "Norwegia") },
    { value: "FI", label: t("countries.FI", "Finlandia") },
    { value: "DK", label: t("countries.DK", "Dania") },
    { value: "NL", label: t("countries.NL", "Holandia") },
    { value: "BE", label: t("countries.BE", "Belgia") },
    { value: "LU", label: t("countries.LU", "Luksemburg") },
    { value: "CZ", label: t("countries.CZ", "Czechy") },
    { value: "SK", label: t("countries.SK", "Słowacja") },
    { value: "HU", label: t("countries.HU", "Węgry") },
    { value: "AT", label: t("countries.AT", "Austria") },
    { value: "CH", label: t("countries.CH", "Szwajcaria") },
    { value: "PT", label: t("countries.PT", "Portugalia") },
    { value: "IE", label: t("countries.IE", "Irlandia") },
    { value: "GR", label: t("countries.GR", "Grecja") },
    { value: "RO", label: t("countries.RO", "Rumunia") },
    { value: "BG", label: t("countries.BG", "Bułgaria") },
    { value: "HR", label: t("countries.HR", "Chorwacja") },
    { value: "SI", label: t("countries.SI", "Słowenia") },
    { value: "RS", label: t("countries.RS", "Serbia") },
    { value: "BA", label: t("countries.BA", "Bośnia i Hercegowina") },
    { value: "ME", label: t("countries.ME", "Czarnogóra") },
    { value: "MK", label: t("countries.MK", "Macedonia Północna") },
    { value: "AL", label: t("countries.AL", "Albania") },
    { value: "EE", label: t("countries.EE", "Estonia") },
    { value: "LV", label: t("countries.LV", "Łotwa") },
    { value: "LT", label: t("countries.LT", "Litwa") },
    { value: "BY", label: t("countries.BY", "Białoruś") },
    { value: "MD", label: t("countries.MD", "Mołdawia") },
    { value: "IS", label: t("countries.IS", "Islandia") },
    { value: "TR", label: t("countries.TR", "Turcja") },
    { value: "CY", label: t("countries.CY", "Cypr") },
    { value: "MT", label: t("countries.MT", "Malta") },
    { value: "LI", label: t("countries.LI", "Liechtenstein") },
    { value: "AD", label: t("countries.AD", "Andora") },
    { value: "MC", label: t("countries.MC", "Monako") },
    { value: "SM", label: t("countries.SM", "San Marino") },
    { value: "VA", label: t("countries.VA", "Watykan") },
    { value: "XK", label: t("countries.XK", "Kosowo") }
  ]

  return { listOfUSAStates, listOfEuropeanCountries }
}

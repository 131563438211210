import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import IconButton from "@mui/material/IconButton"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { useLocation } from "react-router-dom"

type ItemProps = {
  routeData: PrimaryRouteData
  linkComponent: SideMenuProps["linkComponent"]
  primaryActive?: boolean
  secondaryActive?: number
}

function ItemDesktop({
  routeData,
  linkComponent,
  primaryActive,
  secondaryActive
}: ItemProps) {
  const Link = linkComponent

  const secondary = routeData["secondary"]

  const location = useLocation()
  const title = (
    <div>
      <p>{routeData["label"]}</p>
      {/* Additional component */}
      {routeData.toolsComponent && routeData.toolsComponent()}
    </div>
  )
  return (
    <Stack sx={{ marginY: 4 }}>
      <div>{title}</div>
      {secondary && (
        <List component="div" disablePadding>
          {secondary.map((routeData: SecondaryRouteData, index) => {
            const activeItem =
              location.pathname + location.search === routeData.pathname
            return (
              <Link href={routeData["pathname"]} key={routeData["pathname"]}>
                <ListItemButton
                  selected={primaryActive && index === secondaryActive}
                  sx={{
                    borderRadius: 2,
                    marginY: 2,
                    "&.Mui-selected .MuiTypography-root": {
                      color: "primary.main",
                      fontWeight: "bold"
                    },
                    "&:hover": {
                      backgroundColor: "#E6EEFD",
                      color: "primary.main"
                    },
                    color: activeItem ? "primary.main" : null,
                    backgroundColor: activeItem ? "#E6EEFD" : null,
                    fontWeight: "bold"
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: activeItem ? "primary.main" : null,
                      fill: activeItem ? "primary.main" : null
                    }}
                  >
                    {routeData["icon"]}
                  </ListItemIcon>
                  <ListItemText
                    primary={routeData["label"]}
                    sx={{ fontWeight: "bold" }}
                  />
                  {routeData.endAdornment && routeData.endAdornment}
                </ListItemButton>
              </Link>
            )
          })}
        </List>
      )}
    </Stack>
  )
}

function ItemMobile({ routeData, linkComponent, primaryActive }: ItemProps) {
  const secondary = routeData["secondary"]
  const Link = linkComponent
  return (
    <Box marginBottom={6}>
      <Stack direction="row" alignItems="center">
        <ListItemIcon
          sx={{
            color: primaryActive ? "primary.main" : null
          }}
        >
          {routeData["icon"]}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            component: "h2",
            variant: "h6",
            color: primaryActive ? "primary.main" : "text.primary",
            sx: { fontWeight: "bold" }
          }}
          primary={routeData["label"]}
        />
      </Stack>
      <ul>
        {secondary ? (
          secondary.map((routeData: SecondaryRouteData) => (
            <Stack
              key={routeData["pathname"]}
              component="li"
              direction="row"
              paddingY={2}
              borderBottom="1px solid rgba(196, 196, 196, 0.25)"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color="text.primary"
                fontWeight="bold"
                fontFamily="h1.fontFamily"
              >
                {routeData["label"]}
              </Typography>
              <Link href={routeData["pathname"]}>
                <IconButton
                  aria-label="go"
                  color="primary"
                  sx={{ border: "1px solid" }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Link>
            </Stack>
          ))
        ) : (
          <Stack
            direction="row"
            paddingY={2}
            borderBottom="1px solid rgba(196, 196, 196, 0.25)"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color="text.primary"
              fontWeight="bold"
              fontFamily="h1.fontFamily"
            >
              {routeData["label"]}
            </Typography>
            <Link href={routeData["pathname"]}>
              <IconButton
                aria-label="go"
                color="primary"
                sx={{ border: "1px solid" }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Link>
          </Stack>
        )}
      </ul>
    </Box>
  )
}

export type CommonRouteData = {
  pathname?: string
  label: string
  icon?: React.ReactElement
  endAdornment?: React.ReactElement
}

export type PrimaryRouteData = CommonRouteData & {
  secondary?: SecondaryRouteData[]
  toolsComponent?: Function
}

export type SecondaryRouteData = CommonRouteData

export type SideMenuProps = React.ComponentPropsWithoutRef<typeof List> & {
  routes: PrimaryRouteData[]
  linkComponent: React.ComponentType<{ href: string }>
  primaryActive?: number
  secondaryActive?: number
}

export function SideMenu({
  routes,
  linkComponent,
  primaryActive,
  secondaryActive,
  ...props
}: SideMenuProps) {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const ItemComponent = md ? ItemDesktop : ItemMobile

  return (
    <List
      component="nav"
      sx={{
        paddingX: [4, null, 2, 4],
        paddingY: 2,
        flexShrink: 0,
        width: ["100%", "100%", "auto"]
      }}
      {...props}
    >
      {routes.map((route, index) => (
        <ItemComponent
          key={index}
          routeData={route}
          linkComponent={linkComponent}
          primaryActive={index === primaryActive}
          secondaryActive={secondaryActive}
        />
      ))}
    </List>
  )
}

import axios from "axios"
import useAxios from "axios-hooks"
import { useCompanyContext } from "../../../../contexts/company-context"
import { useCurrency } from "../../../../hooks/useCurrency"
import { useCallback } from "react"

export const useProductApi = () => {
  const companyContext = useCompanyContext()
  const { selectedCurrency } = useCurrency()

  const createProduct = useCallback(
    async (data: any) => {
      console.log("createProduct selectedCurrency.currency", selectedCurrency)
      const url =
        process.env.REACT_APP_WAKACJE_API_URL + "/products-admin/v1.1/products"
      const res = await axios.post(url, {
        ...data,
        currency: selectedCurrency.currency
      })
      return res.data
    },
    [selectedCurrency.currency]
  )

  const createTrip = useCallback(
    async (productId: string, data: any) => {
      console.log(
        "createProduct selectedCurrency.currency 12",
        selectedCurrency
      )
      const url =
        process.env.REACT_APP_WAKACJE_API_URL +
        `/products-admin/v1.1/products/${productId}/trips/${data.id}`

      const res = await axios.post(url, {
        ...data,
        currency: selectedCurrency.currency
      })
      return res.data
    },
    [selectedCurrency.currency]
  )
  const updateProduct = useCallback(
    async (data: any) => {
      if (!data.id) {
        throw new Error("No product.id")
      }
      const url =
        process.env.REACT_APP_WAKACJE_API_URL +
        `/products-admin/v1.1/products/${data.id}`

      const res = await axios.post(url, {
        ...data,
        currency: selectedCurrency.currency
      })
      return res.data
    },
    [selectedCurrency.currency]
  )

  const runProductCommand = async (command: any) => {
    if (!command.aggregateId) {
      throw new Error("No command.aggregateId")
    }
    const url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/products/${command.aggregateId}`

    const res = await axios.post(url, { command })
    return res.data
  }

  const runTripCommand = async (command: any) => {
    if (!command.aggregateId) {
      throw new Error("No command.aggregateId")
    }
    const url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/products-admin/v1.1/trips/${command.aggregateId}`

    const res = await axios.post(url, { command })
    return res.data
  }

  const listProducts = useCallback(
    async (query: any = {}, pagination: any) => {
      query.company_id = companyContext.company?.id
      let searchParams = new URLSearchParams({
        ...query
      })
      const url =
        process.env.REACT_APP_WAKACJE_API_URL +
        `/products-admin/v1.1/products?${searchParams}`

      const res = await axios.get(url)
      return res.data
    },
    [companyContext.company?.id]
  )

  const superListProducts = useCallback(
    async (query: any = {}, pagination: any) => {
      query.sort_by = "created_at"
      let searchParams = new URLSearchParams({
        ...query
        // currency: selectedCurrency.symbol
      })
      const url =
        process.env.REACT_APP_WAKACJE_API_URL +
        `/products-admin/v1.1/products?${searchParams}`
      const res = await axios.get(url)
      return res.data
    },
    []
  )

  const listTrips = useCallback(async (query: any) => {
    let url =
      process.env.REACT_APP_WAKACJE_API_URL + "/products-admin/v1.1/trips"
    if (query) {
      url +=
        "?" +
        new URLSearchParams({
          ...query
        }).toString()
    }
    const res = await axios.get(url)
    return res.data
  }, [])

  const getTrip = async (id: any) => {
    const url =
      process.env.REACT_APP_WAKACJE_API_URL + `/products-admin/v1.1/trips/${id}`
    const res = await axios.get(url)
    return res.data
  }

  const updateTrip = useCallback(
    async (productId: string, data: any) => {
      const url =
        process.env.REACT_APP_WAKACJE_API_URL +
        `/products-admin/v1.1/products/${productId}/trips/${data.id}`

      const command = {
        type: "Trip.Update",
        aggregateId: data.id,
        payload: {
          ...data,
          currency: selectedCurrency.currency
        }
      }
      const res = await axios.post(url, { command })
      return res.data
    },
    [selectedCurrency.currency]
  )

  return {
    createTrip,
    createProduct,
    updateProduct,
    listProducts,
    superListProducts,
    listTrips,
    getTrip,
    updateTrip,
    runProductCommand,
    runTripCommand
  }
}

export const useTrip = (id: string) => {
  const url =
    process.env.REACT_APP_WAKACJE_API_URL + `/products-admin/v1.1/trips/${id}`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch }
}

export const useProduct = (id: string) => {
  const url =
    process.env.REACT_APP_WAKACJE_API_URL +
    `/products-admin/v1.1/products/${id}`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch }
}

import {
  DescriptionList,
  FormSectionSeparator,
  Heading3,
  InputField,
  SmallCaptionText
} from "../../../../components/FormCommons"
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import * as Yup from "yup"
import {
  ProductTypes,
  ProductUtils,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_GROUPS
} from "wakacje-web/src/utils/ProductUtils"
import { useFormContext, useFormState } from "react-hook-form"
import { useCategories } from "../../../../components/useCategories"
import { ProductSectionTransportForm } from "./ProductSectionTransport"
import { countries } from "../countries"
import allCountries from "country-region-data/data.json"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { FieldError } from "../../../../components/FieldError"

export const ProductLocationsChecks = ({ register, watch, setValue }) => {
  const { t: t1 } = useTranslation()
  const { errors } = useFormState()
  const { categories } = useCategories(t1)

  const regCategories = register("location_types")

  const value = watch("location_types")
  const locationOptions = categories
    ? categories
        .filter((c) => c.id.indexOf("lokalizacja") === 0)
        .sort((a, b) => a.subcategory?.localeCompare(b.subcategory))
    : []

  return (
    <div>
      <div className="grid grid-cols-3 grap-4">
        {locationOptions.map((op: any, index) => (
          <div className="col-span-3" key={index}>
            <div className="flex flex-row">
              <Checkbox
                checked={value && value?.indexOf(op.id) > -1}
                onChange={(ev) => {
                  var newValues = value ? [...value] : []
                  newValues = ev.target.checked
                    ? [...newValues, op.id]
                    : newValues.filter((o) => o !== op.id)
                  setValue("location_types", newValues)
                }}
              ></Checkbox>
              <ListItemText primary={op.subcategory} />
            </div>
          </div>
        ))}
      </div>

      <FieldError label={errors?.location_types?.message} />
    </div>
  )
}

export const ProductSectionLocationForm = (props: any) => {
  const { watch, getValues, register, setValue, trigger } =
    useFormContext<any>()
  const { errors } = useFormState()
  const values = getValues()
  const isPoland = watch("country_is_poland")

  const { t, i18n } = useTranslation()

  const type = watch("type")
  const countryField = watch("country_code")
  var province = watch("location.province")

  let selectedCountry = allCountries.find((obj) => {
    return obj.countryShortCode === countryField
  })

  useEffect(() => {
    if (countryField !== "PL") {
      setValue("location.province", "", {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      })
    } else {
      trigger("location.province")
    }
  }, [countryField])

  return (
    <div className="mb-8">
      <>
        <>
          {/* 
        <div className="mb-2">
          <RadioGroup
            value={isPoland}
            onChange={(event) => {
              console.log("Radio on change", event.target.value)
              formContext.setValue("country_is_poland", event.target.value)
            }}
          >
            <FormControlLabel
              label="Polska"
              value="yes"
              control={<Radio value="yes" />}
            />
            <FormControlLabel
              label="Za granicą"
              value="no"
              control={<Radio value="no" />}
            />
          </RadioGroup>
        </div>
        */}

          <InputField
            label={t("ui.groups.location_name", "Nazwa ośrodka*")}
            {...register("location_name", { required: true })}
          />

          <div className="my-4">
            <FormControl className="w-full ">
              <InputLabel id="countrty">
                {`${t("ui.groups.country", "Kraj")}*`}
              </InputLabel>
              <Select
                labelId="country"
                id="country_code"
                // defaultValue={isUpdateForm ? place.data.country : "PL"}
                label={`${t("ui.groups.country", "Kraj")}*`}
                {...register("country_code")}
                defaultValue={countryField}
              >
                {countries.map((c, index) => (
                  <MenuItem key={index} value={c.code}>
                    {i18n.language === "pl" ? c.name_pl : c.name_en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FieldError label={errors?.country?.message} />
          </div>
          <div>
            {selectedCountry && countryField === "PL" && (
              <FormControl className="w-full ">
                <InputLabel id="province">
                  {`${t("ui.groups.province", "Województwo")}*`}
                </InputLabel>
                <Select
                  labelId="province"
                  id="province"
                  label={`${t("ui.groups.province", "Województwo")}*`}
                  {...register("location.province")}
                  defaultValue={province}
                  // defaultValue={isUpdateForm && place.data.province}
                >
                  {selectedCountry.regions.map((pr, index) => (
                    <MenuItem value={pr.name} key={index}>
                      {pr.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FieldError label={errors?.location?.province?.message} />
          </div>

          {isPoland === "no" && (
            <>
              <InputField
                select
                label={t("ui.groups.country", "Kraj")}
                SelectProps={{
                  defaultValue: values?.location?.province || ""
                }}
                value={watch("country_code")}
                {...register("country_code", { required: true })}
              >
                {countries.map((c, index) => (
                  <MenuItem key={index} value={c.code}>
                    {c.name_pl}
                  </MenuItem>
                ))}
              </InputField>
            </>
          )}
          {/* {isPoland === "yes" && (
            <>
              <InputField
                select
                label="Województwo"
                SelectProps={{
                  defaultValue: values?.location?.province || ""
                }}
                value={watch("location.province")}
                {...register("location.province")}
              >
                {Object.keys(provinces).map((pr) => (
                  <MenuItem value={pr}>{provinces[pr]}</MenuItem>
                ))}
              </InputField>
            </>
          )} */}
          <div>
            <InputField
              label={`${t("ui.groups.city", "Miejscowość")}*`}
              {...register("location.city")}
            />
            <FieldError label={errors?.location?.city?.message} />
          </div>
          {isPoland === "yes" && (
            <InputField
              label={t("ui.groups.post_code", "Kod pocztowy")}
              {...register("location.post_code", { required: true })}
              // inputComponent={TextMaskPostCode}
            />
          )}
          <div>
            <InputField
              label={`${t("ui.groups.street", "Ulica")}*`}
              {...register("location.street")}
            />
            <FieldError label={errors?.location?.street?.message} />
          </div>
          <div className="flex justify-items-stretch gap-4  flex-row">
            <div className="mr-2 w-36">
              <InputField
                label={`${t("ui.groups.house_number", "Numer domu")}*`}
                {...register("location.house_number")}
              />

              <FieldError label={errors?.location?.house_number?.message} />
            </div>
            <div className="w-36">
              <InputField
                label={t("ui.groups.room_number", "Numer lokalu")}
                {...register("location.room_number")}
              />
            </div>
          </div>
          {/* {type === "polkolonie" && (
            <>
              <InputField
                label="Wskazówki dojazdu (opcjonalne)"
                {...register("location.driving_directions", {
                  required: false
                })}
                multiline
                rows={4}
              />
            </>
          )} */}
          {type === PRODUCT_TYPE_GROUPS.value && (
            <>
              <div className="border-t border-[#E9E9E9] my-8" />
              <Heading3>
                {t("ui.groups.transport_tips", "Wskazówki dojazdu")}
              </Heading3>
              <InputField
                label={t(
                  "ui.groups.transport_tips_optional",
                  "Wskazówki dojazdu (opcjonalne)"
                )}
                {...register("location.driving_directions", {
                  required: false
                })}
                multiline
                rows={4}
              />
              <div className="border-t border-[#E9E9E9] my-8" />
            </>
          )}
        </>
      </>
      <div className="mb-8" />
      {/* <FormSectionSeparator /> */}
      <Heading3>{t("ui.groups.location_type", "Typ lokalizacji")}</Heading3>
      <SmallCaptionText>
        {`${t(
          "ui.groups.select_option_required",
          "Zaznacz przynajmniej jedną opcję"
        )}*`}
      </SmallCaptionText>

      <div>
        <ProductLocationsChecks
          watch={watch}
          register={register}
          setValue={setValue}
        />
      </div>
    </div>
  )
}

export const ProductSectionLocationPreview = ({ values }: any) => {
  const { t: t1 } = useTranslation()

  const { categoriesByCode } = useCategories(t1)

  const { t } = useTranslation()

  let regionNames = new Intl.DisplayNames(["pl"], { type: "region" })

  var fields = {}
  if (values.accommodation) {
    fields["Nocleg"] = values.accommodation?.name
  }

  var cityAndPostCode = values.location?.city + ", " + values.location?.postcode
  var street = ""
  if (values.location) {
    if (values.location && values.location?.street) {
      street =
        values?.location?.street +
        " " +
        values?.location?.house_number +
        (values?.location?.room_number
          ? ", " + values.location.room_number
          : "")
    }
  }

  if (values.type === PRODUCT_TYPE_DAYCAMP.value) {
    fields = { ...fields, "Nazwa ośrodka": values.location_name }
  }
  fields = {
    ...fields,
    [t("ui.groups.country", "Kraj")]:
      values.country_name ?? regionNames.of(values.country_code),
    [t("ui.groups.province", "Województwo")]: values.location?.province

    // "Miejscowość, Kod pocztowy": cityAndPostCode,
    // "Ulica i nr domu, nr lokalu": street
  }

  if (values.type === PRODUCT_TYPE_DAYCAMP.value) {
    fields = {
      ...fields,
      [t("ui.attractions.city", "Miasto")]: values.location?.city,
      [t("ui.attractions.street", "Ulica")]: street,
      [t("ui.attractions.postcode", "Kod pocztowy")]:
        values.location?.post_code ?? "-"
    }
  } else if (values.type === PRODUCT_TYPE_GROUPS.value) {
    fields = {
      ...fields,
      [t("ui.attractions.city", "Miasto")]: values.location?.city,
      [t("ui.attractions.street", "Ulica")]: values?.location?.street ?? "-",
      [t("ui.attractions.postcode", "Kod pocztowy")]:
        values.location?.post_code ?? "-",
      [t("ui.attractions.house_number", "Numer domu")]:
        values.location?.house_number ?? "-",
      [t("ui.attractions.room_number", "Numer lokalu")]:
        values.location?.room_number ?? "-"
    }
  } else {
    fields = {
      ...fields,
      [t("ui.groups.destination", "Miejsce docelowe")]: values.location?.city
    }
  }
  // if (values.type !== PRODUCT_TYPE_SCHOOL_TRIP.value) {
  //   values["Kod Pocztowy"] = values.location?.post_code
  //   values["Ulica"] = values.location?.street ?? "-"
  //   if (values.country_code !== "PL") {
  //     fields = {
  //       "Nazwa ośrodka": values.location_name,
  //       ...fields,
  //       Kraj: values.country_name,

  //       Miasto: values.location?.city,
  //       Ulica: values.location?.street ?? "-"
  //     }
  //   } else {
  //     fields = {
  //       ...fields
  //     }
  //   }
  // }

  var locationTypesFields = {
    [t("ui.groups.location_type", "Typ lokalizacji")]: values.location_types
      ? values.location_types.map((s) => categoriesByCode[s] ?? s).join(", ")
      : "-"
  }

  var pickupRoute = ""
  if (values.pickup_route && values.pickup_route.length > 0) {
    pickupRoute = values.pickup_route
      .map(
        (p) =>
          p.address +
          ", " +
          (p.price === 0
            ? t("ui.groups.included", "w cenie")
            : p.price + " zł") +
          ", " +
          (p.status === "yes"
            ? t("ui.groups.confirmed", "Potwierdzone")
            : t("ui.groups.not_confirmed", "Niepotwierdzone"))
      )
      .join("\n")
  }

  const ownTransportType =
    values?.transport_types?.length &&
    values?.transport_types[0]?.type === "own"

  let fieldsTransport = {
    [t("ui.groups.transport_type_full", "Rodzaj Transportu")]:
      ProductUtils.getVehicleTypeLabel(values.transport_vehicle),
    [t("ui.groups.transport_route", "Trasa dojazdu")]: pickupRoute,
    [t("ui.groups.organised_transport_tips", "Informacje dotyczące dojazdu")]:
      values.location?.driving_directions ?? "-"
  }

  if (ownTransportType) {
    fieldsTransport[
      t("ui.groups.own_transport_tips", "Informacje dotyczące dojazdu własnego")
    ] = values.own_transport_details ?? "-"
  }

  if (values.type === PRODUCT_TYPE_GROUPS.value) {
    return (
      <div>
        <DescriptionList data={fields} />
        <div className="mb-4 border-b pt-2 mx-4" />
        <DescriptionList
          data={{
            [t("ui.groups.transport_tips", "Wskazówki dojazdu")]:
              values.location?.driving_directions ?? "-"
          }}
        />
        <div className="mb-4 border-b pt-2 mx-4" />
        <DescriptionList data={locationTypesFields} />
      </div>
    )
  }

  return (
    <div>
      <DescriptionList data={fields} />
      {/* <div className="mb-4 border-b pt-2" /> */}
      <DescriptionList data={locationTypesFields} />
      {/* <div className="m4-8 border-b pt-2" /> */}

      {/*  Seperated this from DescriptionList because values are array */}
      <div className="px-4 pt-4">
        <p className="text-sm text-gray-500 font-medium ">
          {t("ui.groups.transport_type", "Dojazd")}
        </p>

        {values?.transport_types ? (
          <div>
            {values.transport_types.map((value) => {
              return (
                <div key={value.type}>
                  {ProductUtils.getTransportTypeLabel(value.type, t)}
                </div>
              )
            })}
          </div>
        ) : (
          "-"
        )}
      </div>

      <DescriptionList data={fieldsTransport} />
    </div>
  )
}

export const productSectionLocationSchema: any = {
  country_code: Yup.string().required("Kraj jest wymagany"),
  location: Yup.object().shape({
    province: Yup.string().test(
      "country_code",
      "Województwo jest wymagane",
      function (value) {
        const country_code = this.from?.[1]?.value?.country_code
        return (
          country_code !== "PL" || (country_code === "PL" && value.length > 0)
        )
      }
    ),
    city: Yup.string().required("Miejsce docelowe jest wymagane"),
    street: Yup.string().required("Ulica jest wymagana")
  }),
  //
  transport_type: Yup.string().when("type", {
    is: (type) => type === PRODUCT_TYPE_GROUPS.value,
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Pole jest wymagane")
  })
  //
}

export const ProductSectionLocationView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()
  const { t } = useTranslation()

  const saveHandler = async (data: any) => {
    const productData = {
      id: values.id,
      country_code: data.country_code,
      country_name: data.country_name,
      location: data.location,
      location_types: data.location_types,
      location_name: data.location_name,
      transport_description: data.transport_description,
      transport_vehicle: data.transport_vehicle,
      transport_type: data.transport_type,
      pickup_route: data.pickup_route
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  const initialValues = {
    ...values,
    location_types: values.location_types ?? [],
    country_is_poland: values.country_code === "PL"
  }
  if (initialValues.country_code && !initialValues.country) {
    initialValues.country = initialValues.country_code
  }

  if (values.type !== ProductTypes.DAYCAMP) {
    productSectionLocationSchema.transport_vehicle = Yup.string().when(
      "transport_type",
      {
        is: "provided",
        then: Yup.string().required(
          t(
            "ui.groups.transport_type_required",
            "Rodzaj transportu jest wymagany"
          )
        )
      }
    )
  }

  return (
    <EditableFormSection
      title={t("ui.groups.location", "Lokalizacja")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={productSectionLocationSchema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <>
                <ProductSectionLocationForm
                  values={initialValues}
                  register={builderProps.register}
                  watch={builderProps.watch}
                />
                {values.type !== PRODUCT_TYPE_GROUPS.value && (
                  <ProductSectionTransportForm
                    values={values}
                    {...builderProps}
                  />
                )}
              </>
            )}
            {!builderProps.isEditing && (
              <>
                <ProductSectionLocationPreview values={values} />
              </>
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, FormProvider } from "react-hook-form"
import Button from "@mui/material/Button"
import { InputField } from "../../../components/FormCommons"
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material"
import { FormErrors } from "../../../components/FormErrors"
import { usePlace, usePlacesApi } from "./usePlacesApi"
import { useSnackbar } from "notistack"
import { UidUtils } from "wakacje-web/src/utils/UidUtils"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { countries } from "../products/countries"
import { useEffect, useMemo, useState } from "react"
import { useCompanyContext } from "../../../contexts/company-context"
import allCountries from "country-region-data/data.json"
import { LoadingSpinner } from "../../../components/LoadingSpinner"

import { ProductSectionPhotosForm } from "../products/forms/ProductSectionPhotos"
import { useAuthContext } from "../../../contexts/auth-context"
import { t } from "i18next"
import { useTranslation } from "react-i18next"

const placeSchema = yup.object().shape({
  name: yup.string().required("Nazwa atrakcji jest wymagana"),
  description: yup.string().required("Opis atrakcji jest wymagany"),
  website_url: yup.string(),
  country: yup.string(),
  city: yup.string(),
  province: yup.string(),
  postcode: yup.string(),
  street: yup.string(),
  house_number: yup.string(),
  room_number: yup.string(),
  scope: yup.string()
})

/* Seperate schema because accommodation doesn't have description field */
const accommodationSchema = yup.object().shape({
  name: yup.string().required("Nazwa atrakcji jest wymagana"),
  website_url: yup.string(),
  country: yup.string().required("Kraj jest wymagany"),
  city: yup.string().required("Miasto jest wymagane"),
  province: yup.string().required("Województwo jest wymagane"),
  postcode: yup.string(),
  street: yup.string(),
  house_number: yup.string(),
  room_number: yup.string()
})

export function AddPlaceForm({
  isUpdateForm,
  type,
  submitRedirect,
  setShowForm
}: {
  isUpdateForm?: boolean
  type?: any // "place" | "accommodation"
  submitRedirect?: boolean
  setShowForm?: Function
}) {
  let { id } = useParams()
  const { t, i18n } = useTranslation()

  const [isSaving, setIsSaving] = useState(false)
  const authContext = useAuthContext()
  const isSuperAdmin = authContext.isSuperAdmin
  function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const companyContext = useCompanyContext()
  let query = useQuery()

  const { pathname } = useLocation()

  if (pathname === "/places/dodaj") {
    type = query.get("type") || type
  }

  const api = usePlacesApi()

  const formMethods = useForm({
    mode: "onChange",
    resolver: yupResolver(type === "place" ? placeSchema : accommodationSchema),
    defaultValues: {
      name: "",
      description: "",
      website_url: "",
      country: "PL",
      city: "",
      province: "",
      postcode: "",
      street: "",
      house_number: "",
      room_number: "",
      media: null,
      scope: "company"
    }
  })

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid }
  } = formMethods

  const countryField = watch("country")

  let selectedCountry = allCountries.find((obj) => {
    return obj.countryShortCode === countryField
  })

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    //Prevent submit in nested from
    //https://github.com/react-hook-form/react-hook-form/issues/1005
    if (event) {
      // sometimes not true, e.g. React Native
      if (typeof event.preventDefault === "function") {
        event.preventDefault()
      }
      if (typeof event.stopPropagation === "function") {
        // prevent any outer forms from receiving the event too
        event.stopPropagation()
      }
    }

    return handleSubmit(async (data: any) => {
      const id = UidUtils.generateUid("pl")
      const newPlace: any = {
        id,
        ...data,
        type: type
      }
      newPlace.scope = isSuperAdmin ? data.scope : "company"
      if (newPlace.scope === "company") {
        newPlace.company_id = companyContext.company?.id
      }

      if (isValid) {
        try {
          setIsSaving(true)
          let res = await api.createPlace(newPlace)

          if (res.success) {
            enqueueSnackbar("Miejsce zapisane")
            setIsSaving(false)

            if (setShowForm) {
              setShowForm(false)
            }

            if (submitRedirect) {
              navigate(
                type === "place" ? "/places/place" : "/places/accommodation"
              )
            }
          }
        } catch (err) {
          setIsSaving(false)
          console.error("Error saving place", err)
        }
      }
    })(event)
  }

  const handleRemove = async () => {
    setIsSaving(true)
    try {
      setIsSaving(true)
      let res = await api.deletePlace(id)

      if (res.success) {
        enqueueSnackbar("Miejsce usunięte")
        navigate(type === "place" ? "/places/place" : "/places/accommodation")

        setIsSaving(false)
      }
    } catch (err) {
      setIsSaving(false)
      console.error("Error deleting place", err)
    }
  }
  const handleCancel = () => {
    if (setShowForm) {
      setShowForm(false)
    }
    if (submitRedirect) {
      navigate(type === "place" ? "/places/places" : "/places/accommodation")
    }
  }

  async function onFormUpdate(data: any) {
    const updatePlace: any = {
      id,
      ...data
    }

    if (isValid) {
      setIsSaving(true)
      try {
        setIsSaving(true)
        let res = await api.updatePlace(updatePlace)

        if (res.success) {
          enqueueSnackbar("Zaktualizowane miejsce")
          navigate(type === "place" ? "/places/place" : "/places/accommodation")

          setIsSaving(false)
        }
      } catch (err) {
        setIsSaving(false)
        console.error("Error update place", err)
      }
    }
  }

  const place = usePlace(id)

  useEffect(() => {
    if (place.data) {
      Object.entries(place.data).forEach(([name, value]: any) =>
        setValue(name, value)
      )
    }
  }, [place.data])

  if (place?.data || !isUpdateForm) {
    return (
      <main className="p-4 flex-auto max-w-screen-xl mx-auto">
        <div className="max-w-screen-md mx-auto my-8 ">
          <p className="text-2xl font-bold">
            {type === "place"
              ? t("ui.attractions.attractions", "Atrakcje")
              : t("ui.attractions.holiday_lettings", "Noclegi")}
            {isUpdateForm && `: ${place.data.name}`}
          </p>
          <FormProvider {...formMethods}>
            <form
              className="my-3"
              onSubmit={
                isUpdateForm ? handleSubmit(onFormUpdate) : onFormSubmit
              }
            >
              <InputField
                className="w-1/2"
                label={
                  type === "place"
                    ? t("ui.attractions.attraction_name", "Nazwa atrakcji*")
                    : t("ui.attractions.place_name", "Nazwa ośrodka*")
                }
                {...register("name")}
                autoComplete="off"
                InputLabelProps={{ shrink: isUpdateForm }}
              />
              <InputField
                className="w-1/2"
                label={
                  type === "place"
                    ? t(
                        "ui.attractions.attraction_description",
                        "Opis atrakcji*"
                      )
                    : t("ui.attractions.place_description", "Opis ośrodka*")
                }
                {...register("description")}
                autoComplete="off"
                multiline
                minRows={3}
                InputLabelProps={{ shrink: isUpdateForm }}
              />

              <InputField
                className="w-1/2"
                label={t("ui.attractions.website", "Adres strony internetowej")}
                {...register("website_url")}
                autoComplete="off"
                InputLabelProps={{ shrink: isUpdateForm }}
              />
              <p className="text-2xl font-bold mt-8 mb-4">
                {t("ui.attractions.location", "Lokalizacja")}
              </p>
              <div className="my-4">
                <FormControl className="w-full ">
                  <InputLabel id="country">
                    {t("ui.attractions.country", "Kraj")}
                  </InputLabel>
                  <Select
                    labelId="country"
                    id="country"
                    {...register("country")}
                    defaultValue={
                      place?.data?.country ? place.data.country : "PL"
                    }
                    label={t("ui.attractions.country", "Kraj*")}
                  >
                    {countries.map((c, index) => (
                      <MenuItem key={index} value={c.code}>
                        {i18n.language === "pl" ? c.name_pl : c.name_en}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {selectedCountry && (
                <FormControl className="w-full ">
                  <InputLabel id="province">
                    {t("ui.attractions.province", "Województwo")}
                  </InputLabel>
                  <Select
                    labelId="province"
                    id="province"
                    label={t("ui.attractions.province", "Województwo")}
                    {...register("province")}
                    defaultValue={isUpdateForm && place.data.province}
                  >
                    <MenuItem value={""} sx={{ height: "2rem" }} />
                    {selectedCountry.regions.map((pr, index) => (
                      <MenuItem value={pr.name} key={index}>
                        {pr.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <div className="my-4">
                <InputField
                  label={t("ui.attractions.city", "Miasto")}
                  {...register("city")}
                  autoComplete="off"
                  InputLabelProps={{ shrink: isUpdateForm }}
                />
              </div>
              <div className="w-[25%]">
                <InputField
                  label={t("ui.attractions.postcode", "Kod pocztowy")}
                  {...register("postcode")}
                  autoComplete="off"
                  InputLabelProps={{ shrink: isUpdateForm }}
                />
              </div>
              <div>
                <InputField
                  label={t("ui.attractions.street", "Ulica")}
                  {...register("street")}
                  autoComplete="off"
                  InputLabelProps={{ shrink: isUpdateForm }}
                />
              </div>
              <div className="flex gap-6">
                <div className="w-[25%]">
                  <InputField
                    label={t("ui.attractions.house_number", "Numer domu")}
                    {...register("house_number")}
                    autoComplete="off"
                    InputLabelProps={{ shrink: isUpdateForm }}
                  />
                </div>
                <div className="w-[25%] mb-5">
                  <InputField
                    label={t("ui.attractions.room_number", "Numer lokalu")}
                    {...register("room_number")}
                    autoComplete="off"
                    InputLabelProps={{ shrink: isUpdateForm }}
                  />
                </div>
              </div>
              {/* <PlaceMediaWidget watch={watch} setValue={setValue} /> */}
              <ProductSectionPhotosForm
                photosNumber={type === "place" ? 3 : 10}
                showMainPhoto={false}
                mediaInit={place?.data?.media}
                // in edit page, trigger validation
                triggerValidation={() => {
                  trigger("media")
                }}
              />
              <FormErrors errors={errors}></FormErrors>

              {isSuperAdmin && (
                <div className="flex flex-row items-center gap-4">
                  <div>
                    {t(
                      "ui.attractions.superadmin_option",
                      "Opcja super admina"
                    )}
                    :
                  </div>
                  <div className="w-60">
                    <InputField
                      label={t("ui.attractions.access", "Zakres dostępu")}
                      autoComplete="off"
                      select
                      InputLabelProps={{ shrink: isUpdateForm }}
                      defaultValue={place?.data?.scope}
                      {...register("scope")}
                    >
                      <MenuItem value="company" key={"company"}>
                        {t(
                          "ui.attractions.just_my_company",
                          "Tylko moja firma"
                        )}
                      </MenuItem>
                      <MenuItem value="global" key={"global"}>
                        {t("ui.attractions.global", "Globalne")}
                      </MenuItem>
                    </InputField>
                  </div>
                </div>
              )}
              <div className="flex flex-row">
                <div className="">
                  <Button
                    className="mx-4"
                    variant="text"
                    type="button"
                    size="large"
                    onClick={handleCancel}
                  >
                    {t("ui.attractions.close_button", "Anuluj")}
                  </Button>
                </div>
                <div>
                  {id && (
                    <Button
                      className="mx-4"
                      variant="text"
                      type="button"
                      size="large"
                      onClick={handleRemove}
                    >
                      {t("ui.attractions.remove_button", "Usuń miejsce")}
                    </Button>
                  )}
                </div>
                <div className="flex-1" />
                <div className="text-right">
                  <Button
                    className="mx-4"
                    variant="contained"
                    type="submit"
                    size="large"
                    disabled={!isValid}
                  >
                    {isSaving ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      t("ui.attractions.save_button", "Zapisz")
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </main>
    )
  }

  return (
    <div className="flex justify-center pt-[100px]">
      <LoadingSpinner />
    </div>
  )
}

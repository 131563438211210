export enum PosterTemplateElementType {
  BACKGROUND = "BACKGROUND",
  SELECTABLE_PHOTO = "SELECTABLE_PHOTO",
  PHOTO = "PHOTO",
  DIV = "DIV",
  EDITABLE_TEXT = "EDITABLE_TEXT",
  TEXT = "TEXT",
  QR = "QR",
}

export const PosterOrderStatus = {
  ORDERED: "ORDERED",
  PAID: "PAID",
  CANCELED: "CANCELED",
}

const fontStyleSmall = {
  fontSize: 2.5,
  lineHeight: 1.2,
  fontWeight: 300,
  fontFamily: "Riffic Free",
  color: "white",
}

const PosterColors = {
  orange: "#F1B13A",
}

const fontStyleMedium = {
  fontSize: 3.5,
  lineHeight: 1.2,
  fontWeight: 500,
  // fontFamily: "Riffic Free",
}

const fontStyleProductName = {
  fontSize: 5,
  lineHeight: 1.2,
  fontWeight: 400,
  // fontFamily: "Riffic Free",
}
export type PosterTemplateElement = {
  type: PosterTemplateElementType
  position?: string
  name?: string
  code?: string
  top?: number
  left?: number
  width?: number
  height?: number
  children?: Array<PosterTemplateElement>
  style?: any
  className?: string
  placeholder?: string
  content?: string
}

export type PosterPageTemplate = {
  elements: Array<PosterTemplateElement>
  width: number
  height: number
}

export type PosterTemplate = {
  name: string
  code: string
  poster: PosterPageTemplate
  posterImgUrl: string
  leafletImgUrl: string
  brochureFront: PosterPageTemplate
  brochureBack: PosterPageTemplate
}

const bialyPasekWiecejInformacji: PosterTemplateElement = {
  name: "Biały pasek",
  code: "Container",
  type: PosterTemplateElementType.DIV,
  style: {
    backgroundColor: "white",
    padding: 1.5,
    height: 16,
    display: "flex",
    flexDirection: "row",
    fontSize: 2.5,
    color: PosterColors.orange,
    alignItems: "center",
  },
  children: [
    {
      name: "Strona www",
      type: PosterTemplateElementType.TEXT,
      code: "FOOTER_TEXT",
      style: {
        flex: 1,
        paddingLeft: 1,
        paddingRight: 1,
      },
    },
    // {
    //   name: "Arrow",
    //   type: PosterTemplateElementType.TEXT,
    //   code: "FOOTER_TEXT",
    //   style: {
    //     width: 20,
    //   },
    // },
    {
      name: "Qr",
      type: PosterTemplateElementType.QR,
      code: "QR",
      style: {
        width: 20,
        padding: 0.3,
      },
    },
  ],
}

const footer: PosterTemplateElement = {
  name: "Footer",
  code: "Container",
  type: PosterTemplateElementType.DIV,
  style: {
    backgroundColor: PosterColors.orange,
    padding: 1.5,
    height: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  children: [
    {
      type: PosterTemplateElementType.TEXT,
      content: "dostarczone przez fajnyczas.pl",
      style: {
        color: "white",
        fontSize: 1.5,
        textAlign: "center",
      },
    },
  ],
}

export const fieldDefinitions = {
  TITLE: {
    max: 15,
    label: "Tytuł plakat i ulotka",
  },
  PRODUCT_NAME: {
    maxLen: 17,
    label: "Podtytuł plakat i ulotka",
    placeholder: "Kolonie i obozy",
  },
  PRODUCT_DATE: {
    max: 35,
    label: "Data wydarzenia",
  },
  DESCRIPTION_1: {
    max: 128,
    label: "tutaj możesz dodać krótki opis",
  },
  DESCRIPTION_2: {
    max: 128,
    label: "Opis 2 dla tego plakatu",
  },
  DESCRIPTION_3: {
    max: 128,
    label: "Opis 3 dla tego plakatu",
  },
  EVENT_TITLE_1: {
    max: 128,
    label: "Nagłówek 1",
    placeholder: "01-07 Sierpnia 2022, Giżycko",
  },
  EVENT_DESCRIPTION_1: {
    max: 92,
    label: "Opis nagłówek 1",
    placeholder: "tutaj możesz dodać krótki opis",
  },
  EVENT_TITLE_2: {
    max: 128,
    label: "Nagłówek 2",
    placeholder: "22-27 Sierpnia 2022, Giżycko",
  },
  EVENT_DESCRIPTION_2: {
    max: 92,
    label: "Opis nagłówek 2",
    placeholder: "tutaj możesz dodać krótki opis",
  },
  EVENT_TITLE_3: {
    max: 128,
    label: "Nagłówek 3",
    placeholder: " 01-08 Września 2022, Giżycko",
  },
  EVENT_DESCRIPTION_3: {
    max: 92,
    label: "Opis nagłówek 3",
    placeholder: "tutaj możesz dodać krótki opis",
  },
}

const kColorBlue = "#262165"

const orangeBackSection = (index) => {
  return {
    name: "Container",
    code: "CONTAINER" + index,
    type: PosterTemplateElementType.DIV,
    style: {
      height: 20,
      display: "flex",
      flexDirection: "row",
      marginBottom: 5,
    },
    children: [
      {
        name: "Zdjęcie " + index,
        code: "BACK_PHOTO" + index,
        type: PosterTemplateElementType.SELECTABLE_PHOTO,
        top: 0,
        left: 0,
        style: {
          width: 40,
          height: 20,
          borderRadius: 100,
        },
      },
      {
        name: "ContainerRight",
        code: "CONTAINERR" + index,
        type: PosterTemplateElementType.DIV,
        style: {
          height: 20,
          display: "flex",
          flexDirection: "column",
        },
        children: [
          {
            name: "Tytuł",
            code: "EVENT_TITLE_" + index,
            type: PosterTemplateElementType.EDITABLE_TEXT,
            placeholder: "nazwa firmy",
            className: "text-white text-4xl text-center",
            style: {
              ...fontStyleMedium,
              textAlign: "left",
              color: kColorBlue,
              height: 8,
            },
          },
          {
            name: "Tytuł",
            code: "EVENT_DESCRIPTION_" + index,
            type: PosterTemplateElementType.EDITABLE_TEXT,
            placeholder: "nazwa firmy",
            className: "text-white text-4xl text-center",
            style: {
              ...fontStyleSmall,
              color: "white",
              textAlign: "left",
              flex: 1,
            },
          },
        ],
      },
    ],
  }
}
export const orangeBrochureFront: PosterPageTemplate = {
  width: 100,
  height: 148,
  elements: [
    {
      name: "Główny obrazek",
      code: "MAIN_PHOTO",
      type: PosterTemplateElementType.SELECTABLE_PHOTO,
      top: 0,
      left: 0,
      width: 100,
      height: 102,
      position: "absolute",
      className: "flex flex-col",
      style: {
        position: "relative",
      },
      children: [
        {
          code: "LOGO_URL",
          type: PosterTemplateElementType.PHOTO,
          style: {
            position: "absolute",
            left: 10,
            top: 10,
            width: 18,
            height: 18,
            borderRadius: 100,
            overflow: "hidden",
          },
        },
      ],
    },
    {
      name: "Bottom",
      code: "BOTTOM",
      type: PosterTemplateElementType.DIV,
      top: 102,
      left: 0,
      width: 100,
      height: 148 - 82,
      position: "absolute",
      style: {
        backgroundColor: "#F1B13A",
      },
      children: [
        {
          name: "Spacer",
          code: "SPACER",
          type: PosterTemplateElementType.DIV,
          style: {
            height: 2,
          },
        },
        {
          name: "Tytuł",
          code: "TITLE",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "nazwa firmy",
          className: "text-white text-4xl text-center",
          style: {
            fontSize: 10,
            lineHeight: 1.2,
            height: 13,
          },
        },
        {
          name: "Nazwa Produktu",
          code: "PRODUCT_NAME",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "Kolonie - Lato 2022",
          className: "text-white text-2xl text-center",
          style: {
            ...fontStyleProductName,
            color: "white",
            height: 7,
          },
        },
        {
          name: "Spacer",
          code: "SPACER",
          type: PosterTemplateElementType.DIV,
          style: {
            height: 13,
          },
        },
        {
          name: "Div1",
          code: "DIV1",
          type: PosterTemplateElementType.DIV,
          style: {
            display: "flex",
            flexDirection: "row",
          },
          children: [
            {
              name: "Adres www",
              code: "WWW_URL",
              type: PosterTemplateElementType.EDITABLE_TEXT,
              placeholder: "Kolonie - Lato 2022",
              className: "text-white text-2xl text-center",
              style: {
                ...fontStyleMedium,
                color: "white",
                width: "100%",
                flex: 1,
              },
            },
            {
              name: "Telefon",
              code: "PHONE",
              type: PosterTemplateElementType.EDITABLE_TEXT,
              placeholder: "Kolonie - Lato 2022",
              className: "text-white text-2xl text-center",
              style: {
                ...fontStyleMedium,
                color: "white",
                flex: 1,
              },
            },
          ],
        },
      ],
    },
  ],
}

export const orangeBrochureBack: PosterPageTemplate = {
  width: 100,
  height: 148,
  elements: [
    {
      name: "Background",
      code: "BACKGROUND",
      type: PosterTemplateElementType.DIV,
      top: 0,
      left: 0,
      width: 100,
      height: 148,
      position: "absolute",
      style: {
        backgroundColor: "#F1B13A",
        padding: 8,
      },
      children: [
        {
          name: "Tytuł",
          code: "TITLE",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "nazwa firmy",
          style: {
            fontSize: 8,
            lineHeight: 1.2,
            textAlign: "center",
            color: "white",
            fontFamily: "Poetsen One",
          },
        },
        {
          name: "Nazwa Produktu",
          code: "PRODUCT_NAME",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "Kolonie - Lato 2022",
          style: {
            fontSize: 8,
            lineHeight: 1.2,
            textAlign: "center",
            color: "white",
            fontFamily: "Poetsen One",
          },
        },
        orangeBackSection(1),
        orangeBackSection(2),
        orangeBackSection(3),
      ],
    },
  ],
}

export const orangePoster: PosterPageTemplate = {
  width: 100,
  height: 148,
  elements: [
    {
      name: "Główny obrazek",
      code: "MAIN_PHOTO",
      type: PosterTemplateElementType.SELECTABLE_PHOTO,
      height: 82,
      className: "flex flex-col",
      style: {
        flex: 1,
        position: "relative",
      },
    },
    {
      name: "Bottom",
      code: "CONTENT",
      type: PosterTemplateElementType.DIV,
      height: 56,
      style: {
        backgroundColor: PosterColors.orange,
        position: "relative",
      },
      children: [
        {
          code: "LOGO_URL",
          type: PosterTemplateElementType.PHOTO,
          style: {
            position: "absolute",
            right: 5,
            top: -18,
            width: 22,
            height: 22,
            borderRadius: 100,
            overflow: "hidden",
          },
        },
        {
          name: "Tytuł",
          code: "TITLE",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "nazwa firmy",
          className: "text-white text-4xl text-center",
          style: {
            fontSize: 10,
            lineHeight: 1.2,
          },
        },
        {
          name: "Nazwa Produktu",
          code: "PRODUCT_NAME",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "Kolonie - Lato 2022",
          className: "text-white text-2xl text-center",
          style: {
            fontSize: 8,
            lineHeight: 1.2,
          },
        },
        {
          name: "Data",
          code: "PRODUCT_DATE",
          type: PosterTemplateElementType.EDITABLE_TEXT,
          placeholder: "Kolonie - Lato 2022",
          className: "text-white text-2xl text-center",
          style: {
            fontSize: 3,
            lineHeight: 1.2,
            color: kColorBlue,
            fontWeight: 900,
            textAlign: "left",
            padding: 1,
          },
        },
        {
          name: "CONTAINER",
          code: "CONTAINER",
          type: PosterTemplateElementType.DIV,
          style: {
            display: "flex",
          },
          children: [
            {
              name: "Opis 1",
              code: "DESCRIPTION_1",
              type: PosterTemplateElementType.EDITABLE_TEXT,
              placeholder: "Opis 1",
              style: {
                ...fontStyleSmall,
                color: "white",
                flex: 1,
                padding: 1.5,
                textAlign: "left",
              },
            },
            {
              name: "Opis 1",
              code: "DESCRIPTION_2",
              type: PosterTemplateElementType.EDITABLE_TEXT,
              placeholder: "Opis 1",
              style: {
                ...fontStyleSmall,
                flex: 1,
                padding: 1.5,
                textAlign: "left",
                fontFamily: "Riffic Free",
              },
            },
          ],
        },
      ],
    },
    {
      ...bialyPasekWiecejInformacji,
      // top: 120,
      // left: 0,
      // width: 100,
      height: 20,
      // position: "absolute",
    },
    footer,
  ],
}

export const orangeTemplate: PosterTemplate = {
  name: "Pomarańczowe lato",
  code: "ORANGE2022",
  poster: orangePoster,
  posterImgUrl: "orange_poster_mini.png",
  leafletImgUrl: "orange_leaflet_mini.png",
  brochureBack: orangeBrochureBack,
  brochureFront: orangeBrochureFront,
}

const greenTemplate: PosterTemplate = {
  ...orangeTemplate,
  name: "Zielona Przygoda",
  code: "GREEN2022",
  posterImgUrl: "green_poster_mini.png",
  leafletImgUrl: "green_leaflet_mini.png",
}

export class PosterUtils {
  static getPosterTemplates(): Array<PosterTemplate> {
    return [orangeTemplate, greenTemplate]
  }
  static getTemplateByCode(code) {
    var templates = PosterUtils.getPosterTemplates()
    return templates.find((t) => t.code === code)
  }

  static statusLabel = (status) => {
    if (status === PosterOrderStatus.ORDERED) {
      return "Zamówione"
    }
    if (status === PosterOrderStatus.PAID) {
      return "Opłacone"
    }
    if (status === PosterOrderStatus.CANCELED) {
      return "Anulowane"
    }
    return status
  }
}

import { FcTabPanel, FcTabsHeader } from "../../../components/FcTabs/FcTabs"
import { SuperAdminOffers } from "./SuperAdminOffers"

import { useTabs } from "../useTabs"
import { SuperAdminTrips } from "./SuperAdminTrips"
import { SuperAdminAttractions } from "./SuperAdminAttractions"
import { SuperAdminAccommodations } from "./SuperAdminAccommodations"

export const SuperAdminProducts = () => {
  const { selectedTab, handleTabChange } = useTabs()

  return (
    <>
      <h1 className="text-[32px] font-bold">Produkty</h1>
      <FcTabsHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabs={["Oferty", "Terminy", "Atrakcje", "Noclegi"]}
      />

      <div className="w-full">
        <FcTabPanel selectedTab={selectedTab} index={0}>
          <SuperAdminOffers />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={1}>
          <SuperAdminTrips />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={2}>
          <SuperAdminAttractions />
        </FcTabPanel>
        <FcTabPanel selectedTab={selectedTab} index={3}>
          <SuperAdminAccommodations />
        </FcTabPanel>
      </div>
    </>
  )
}

import { Grid } from "@mui/material"
import { Heading3, InputField } from "../../../components/FormCommons"
import { FormSectionContainer } from "../products/forms/components/FormSectionContainer"
import { Controller } from "react-hook-form"
import { PriceCentsInput } from "../../../components/ui/PriceInput"

export const IndividualSchoolTripOfferPriceSection = ({
  register,
  control
}: any) => {
  const currentDate = new Date().toISOString().split("T")[0]

  return (
    <FormSectionContainer>
      <InputField
        multiline
        minRows={4}
        label={"Opis"}
        {...register("price_description", { required: false })}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputField
            multiline
            minRows={4}
            label={"Cena zawiera*"}
            {...register("price_includes_text")}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            multiline
            minRows={4}
            label={"Cena nie zawiera"}
            {...register("price_not_includes_text")}
          />
        </Grid>
      </Grid>
      <Heading3>Opłaty</Heading3>
      <div className="flex justify-items-stretch gap-4">
        <div className="w-48">
          <Controller
            render={({ field }) => (
              <PriceCentsInput
                value={field.value}
                onChange={field.onChange}
                label={`*Całość / 1 osobę`}
                inputProps={{ min: 1 }}
                fullWidth
              />
            )}
            control={control}
            name="full_price"
          />
        </div>

        <div className="w-48">
          <InputField
            {...register("payment_due_date", { required: false })}
            id={`payment_due_date`}
            label="*Termin płatności"
            type="date"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: currentDate }}
            onChange={(e) => {
              const selectedDate = e.target.value
              if (selectedDate && selectedDate < currentDate) {
                e.target.value = currentDate
              }
            }}
          />
        </div>
      </div>

      <div className="flex justify-items-stretch gap-4">
        <div className="w-48">
          <Controller
            render={({ field }) => (
              <PriceCentsInput
                value={field.value}
                onChange={field.onChange}
                label={`Zaliczka / 1 osobę`}
                inputProps={{ min: 1 }}
                fullWidth
              />
            )}
            control={control}
            name="advance_price"
          />
        </div>
      </div>
    </FormSectionContainer>
  )
}

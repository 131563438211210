import React from "react"
import Demo from "wakacje-web/src/components/Demo"

function Test() {
  return (
    <div>
      <Demo />
    </div>
  )
}

export default Test

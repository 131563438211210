import { RegisterCompany } from "../organizer/company/RegisterCompany"

export const NewUserWelcomeIndex = (props: any) => {
  return (
    <div className="container max-w-screen-lg m-auto">
      <div className="my-2 text-center text-fc-primary font-bold">
        Witamy w Fajny Czas!
      </div>
      <RegisterCompany />
    </div>
  )
}

import { useState } from "react"
import { useFormContext } from "react-hook-form"
import {
  DescriptionList,
  InputField,
  SectionTitle
} from "../../../../components/FormCommons"
import { TimeSelectDropdown } from "../../../../components/forms/TimeSelectDropdown"
import { SectionContainer } from "./CreateOfferForm"
import DateRangePicker from "wakacje-web/src/components/DateRangePicker"
import "react-date-range/dist/styles.css"
import "wakacje-web/styles/DateRangePicker.scss"
import { format } from "date-fns"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useQuotationApi } from "../../api/useQuotationApi"

function FormSectionTerm() {
  const { register, setValue } = useFormContext()

  const [date, setDate] = useState({
    startDate: undefined,
    endDate: undefined
  })
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleConfirmDateRange = (val) => {
    setValue("start_date", format(date.startDate, "yyyy-MM-dd"))
    setValue("end_date", format(date.endDate, "yyyy-MM-dd"))
    setValue("departure_date", format(date.startDate, "yyyy-MM-dd"))
    setValue("return_date", format(date.endDate, "yyyy-MM-dd"))
    setAnchorEl(null)
  }

  return (
    <SectionContainer>
      <SectionTitle>Termin</SectionTitle>
      {/* Date Range */}
      <div className="mb-2">
        <div className="w-80">
          <InputField
            label="Zakres daty*"
            value={
              date.startDate && date.endDate
                ? `${format(date.startDate, "dd/MM/yyyy")} - ${format(
                    date.endDate,
                    "dd/MM/yyyy"
                  )}
             `
                : "dd/mm/yyyy"
            }
            type="text"
            InputLabelProps={{ shrink: true }}
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
            inputProps={{ readOnly: true }}
          />

          <DateRangePicker
            startDate={date.startDate}
            endDate={date.endDate}
            anchorElement={anchorEl}
            onDateChange={(newDate) => setDate(newDate)}
            onClose={() => setAnchorEl(null)}
            onConfirm={handleConfirmDateRange}
            onReset={() => {}}
          />
        </div>
      </div>

      {/* Departure */}
      <div className="mb-2 ">
        <p className="mb-2">Wyjazd</p>
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <InputField
              label="Data Wyjazdu*"
              type="date"
              InputLabelProps={{ shrink: true }}
              {...register("departure_date")}
            />
          </div>{" "}
          <div className="flex-1">
            <TimeSelectDropdown
              label="Godzina wyjazdu*"
              type="date"
              {...register("departure_time")}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <InputField
              label="Adres wyjazdu*"
              {...register("departure_address")}
            />
          </div>
          <div className="flex-1">
            <InputField
              label="Uwagi*"
              {...register("departure_address_description")}
            />
          </div>
        </div>
      </div>

      {/* return */}
      <div className="mb-2 ">
        <p className="mb-2">Powrót</p>
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <InputField
              label="Data Powrót*"
              type="date"
              InputLabelProps={{ shrink: true }}
              {...register("return_date")}
            />
          </div>{" "}
          <div className="flex-1">
            <TimeSelectDropdown
              label="Godzina Powrót*"
              type="date"
              {...register("return_time")}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex-1">
            <InputField label="Adres powrót*" {...register("return_address")} />
          </div>
          <div className="flex-1">
            <InputField
              label="Uwagi*"
              {...register("return_address_description")}
            />
          </div>
        </div>
      </div>
    </SectionContainer>
  )
}

export const FormSectionTermPreview = ({ values }) => {
  return (
    <div>
      <DescriptionList
        data={{
          "Zakres daty": `${values.start_date} - ${values.end_date}`
        }}
      />
      {/* // Todo Bank - add option to draw separator line at the bottom of DescriptionList */}
      <DescriptionList
        data={{
          "Miejsce zbiorki": values.departure_remarks,
          Adres: values.departure_address,
          Data: values.departure_date,
          Godzina: values.departure_hour
        }}
      />

      <DescriptionList
        data={{
          "Miejsce powrotu": values.return_remarks,
          Adres: values.departure_address,
          Data: values.departure_date,
          Godzina: values.departure_hour
        }}
      />
    </div>
  )
}

export const FormSectionTermView = ({ values, refetch }: any) => {
  const { runCommand, isSaving, updateQuotation } = useQuotationApi()

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    // TODO Bank - here we need to pass ONLY attributes that are present in this section + .id parameter
    // id must be
    var newValues = {
      id: values.id,
      departure_address: data.departure_address,
      departure_remarks: data.departure_remarks,
      departure_date: data.departure_date,
      departure_hour: data.departure_hour,

      return_address: data.return_address,
      return_remarks: data.return_remarks,
      return_date: data.return_date,
      return_hour: data.return_hour,

      transport_type: data.transport_type,
      pickup_route: data.pickup_route
    }
    console.log("new quotation data", newValues)
    return updateQuotation(values.id, newValues)
  }

  const handleRefresh = () => {
    refetch()
  }

  const _values = { ...values, pickup_route: values.pickup_route ?? [] }
  console.log("_values", _values)
  return (
    <EditableFormSection
      title="Termin"
      values={_values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && <FormSectionTerm {...builderProps} />}
            {!builderProps.isEditing && (
              <FormSectionTermPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

export default FormSectionTerm

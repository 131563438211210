import { Controller, useFormContext } from "react-hook-form"
import {
  FormHelperText,
  Heading3,
  InputField
} from "../../../components/FormCommons"
import { ProductSectionAgeForm } from "../products/forms/ProductSectionAge"
import { ProductSectionPhotosForm } from "../products/forms/ProductSectionPhotos"
import { getLocalizedKey } from "../../../utils/getLocalizedKey"

export const GeneralInfoIndividualSchoolTripOffer = ({
  register,
  control
}: any) => {
  const { watch } = useFormContext()

  const startDate = watch("start_date")

  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2">
        <Controller
          name={"start_date"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            var val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            return (
              <InputField
                type="date"
                onChange={onChange}
                value={val}
                InputLabelProps={{ shrink: true }}
                label="Data od*"
                id={`start_date`}
              />
            )
          }}
        />
        <Controller
          name={"end_date"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            var val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            var minDate =
              startDate.length > 10 ? startDate.substr(0, 10) : startDate
            return (
              <InputField
                type="date"
                onChange={onChange}
                value={val}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: minDate
                }}
                label="Data do*"
                id={`end_date`}
                onBlur={(e) => {
                  if (e.target.value < minDate) {
                    e.target.value = null
                    onChange(e)
                  }
                }}
              />
            )
          }}
        />
      </div>
      <InputField
        value={watch(nameKey)}
        label={"Nazwa wycieczki*"}
        {...register(nameKey)}
      />
      <InputField
        value={watch(descriptionKey)}
        multiline
        minRows={4}
        label={"Opis wycieczki*"}
        {...register(descriptionKey)}
      />
      <div className="mb-8" />
      <Heading3 id="h3-1">Wiek uczestników</Heading3>
      <div className="mb-4">
        <FormHelperText>Wyjazd dedykowany uczestnikom</FormHelperText>
      </div>
      <ProductSectionAgeForm register={register} showMaxParticipants={false} />
      <div className="mb-8" />
      <ProductSectionPhotosForm />
    </>
  )
}

import { MojeButton } from "../../components/MojeButton"
import { ProductUtils } from "../../utils/ProductUtils"
import { ContactRow } from "../../white/home/HomeSectionContact"
import { BookingStatus } from "../wakacje-types"
import { BookingStatusChip } from "./BookingStatusChip"

export const SideWidgetUserSummary = ({
  user,
  booking,
  onCreateOffer
}: {
  user: any
  booking?: any
  onCreateOffer?: any
}) => {
  return (
    <div className="mt-5">
      <div className="font-bold text-4xl mb-4">{user.name}</div>
      {booking && (
        <div>
          <BookingStatusChip booking={booking} />
        </div>
      )}

      <div className="mb-8" />
      {booking && booking.status !== BookingStatus.REQUEST && (
        <div className="font-bold">Oferta</div>
      )}

      <div>
        {booking?.product?.name && (
          <a
            className="text-fc-accent-3 underline cursor-pointer hover:no-underline"
            href={
              booking.product &&
              `/products/${booking.product.type}/${booking.product.id}`
            }
          >
            {booking?.product?.name}
          </a>
        )}

        {booking?.type === "INQUIRY" && <p>{booking?.product?.name}</p>}
      </div>

      {booking?.trip_data && (
        <div className="text-fc-gray-4">
          {ProductUtils.formatDate(booking?.trip_data?.start_date)} -{" "}
          {ProductUtils.formatDate(booking?.trip_data?.end_date)}
        </div>
      )}

      {/* Temporary Removed until got data from api */}
      {/* {booking.status === BookingStatus.REQUEST && (
        <>
          <div className="flex flex-col gap-2">
            <p className="font-bold leading-[30px] font-base">
              Dane kontaktowe
            </p>
            <div className="flex gap-5">
              <div className="min-w-6 h-6">
                <img src="/svg/phone.svg" />
              </div>
              <span className="truncate">887 887 404</span>
            </div>
            <div className="flex gap-5">
              <div className="min-w-6 h-6">
                <img src="/svg/email.svg" />
              </div>
              <span className="truncate">adriannakolanek@gmail.com</span>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-10">
            <p className="font-bold leading-5 font-base">Uczestnicy (2)</p>
            <p className="font-semibold leading-5 text-[#7E7E7E]">
              Dawid Kowalski, 12 lat
            </p>
            <p className="font-semibold leading-5 text-[#7E7E7E]">
              Dawid Kowalski, 13 lat
            </p>
          </div>
          <div className="flex flex-col gap-2 mt-12">
            <p className="font-bold leading-5 font-base">Multimedia i pliki</p>
          </div>
        </>
      )} */}
    </div>
  )
}

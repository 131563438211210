import { Box } from "@mui/system"
import { FormattedDate } from "react-intl"
import { Routes, Route } from "react-router-dom"
import { ApiTable } from "../../../components/table/ApiTable"
import { useProductApi } from "../../organizer/events/api/useProductApi"
import { TripPage } from "../../organizer/events/event/TripPage"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"

const SuperTripsTable = ({ queryParams }) => {
  const { listTrips } = useProductApi()
  const columns = [
    {
      header: "Nazwa",
      cell: (row: any) => (
        <div>
          <div>
            <strong>{row.name ?? "Brak"}</strong>
          </div>
          <div>{row.product?.name ?? "Brak"}</div>
        </div>
      )
    },

    {
      header: "Firma",
      cell: (row: any) => <div>{row.company?.name ?? "Brak"}</div>
    },
    {
      header: "Status",
      cell: (row: any) => (
        <div>
          <FormattedDate value={row.product?.status} />
        </div>
      )
    }
  ]
  return (
    <ApiTable
      columns={columns}
      listApi={listTrips}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/super/trips/${row.id}`}
      queryParams={queryParams}
    ></ApiTable>
  )
}

export const SuperAdminTrips = () => {
  const { listTrips } = useProductApi()
  const { setSearchTextDebounced, queryParams } = useSearchText()

  console.log("queryParams", queryParams)

  return (
    <div className="mt-10">
      <Box width="100%">
        <Toolbar
          setSearchTextDebounced={setSearchTextDebounced}
          api={listTrips}
        />
        <Routes>
          <Route path=":tripId" element={<TripPage />} />
          <Route
            index
            element={<SuperTripsTable queryParams={queryParams} />}
          />
        </Routes>
      </Box>
    </div>
  )
}

import { FormattedDate } from "react-intl"
import { ApiTable } from "../../components/table/ApiTable"
import { WakacjeChip, WakacjeChipVariant } from "../../components/WakacjeChip"
import { useCompanyContext } from "../../contexts/company-context"
import { usePosterOrdersApi } from "./usePosterOrdersApi"

import { PosterUtils } from "./PosterUtils"

export const PosterOrdersTable = (props: any) => {
  const companyContext = useCompanyContext()
  const { list } = usePosterOrdersApi()

  const queryParams = {
    company_id: companyContext.company!.id,
  }
  const columns = [
    {
      header: "Pakiet",
      cell: (row) => {
        return (
          <div>
            <div className="font-bold">{row.product_name}</div>
            <div className="text-sm">{row.special_request}</div>
          </div>
        )
      },
    },
    {
      header: "Data",
      cell: (row) => {
        return (
          <div>
            <FormattedDate value={row.created_at} />
          </div>
        )
      },
    },
    {
      header: "Status",
      cell: (row: any) => (
        <>
          <div>{row.name}</div>
          {row.status && (
            <div>
              <WakacjeChip
                label={PosterUtils.statusLabel(row.status)}
                variant={WakacjeChipVariant.GREEN}
              />
            </div>
          )}
        </>
      ),
      sort: true,
    },
  ]

  return (
    <ApiTable
      {...props}
      columns={columns}
      listApi={list}
      queryParams={queryParams}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/plakaty/${row.id}`}
    />
  )
}

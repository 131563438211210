import axios from "axios"

export const usePackageOrdersApi = () => {
  const list = async (query = {}, pagination: any) => {
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/v1/packageorders?${searchParams}`
    var res = await axios.get(url)
    return res.data
  }

  return { list }
}

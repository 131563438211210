import {
  FieldPath,
  RegisterOptions,
  useForm,
  get,
  FormProvider
} from "react-hook-form"
import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { Heading3 } from "../../../../components/FormCommons"
import { NewProductWizardButtonsBar } from "./NewProductWizardButtonsBar"
import { ProductSectionConditionsForm } from "./ProductSectionConditions"
import {
  ProductSectionFoodForm,
  productSectionFoodFormSchema
} from "./ProductSectionFood"
import {
  ProductSectionInsuranceForm,
  productSectionInsuranceFormSchema
} from "./ProductSectionInsurance"
import {
  ProductSectionPlacesForm,
  productSectionPlacesFormSchema
} from "./ProductSectionPlace/ProductSectionPlace"
import {
  ProductSectionScheduleForm,
  productSectionScheduleSchema
} from "./ProductSectionSchedule"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"

export const NewProductPageSchedule = ({
  values,
  onCancel,
  onSubmit,
  isSubmitting
}: any) => {
  var defaultValues = values
    ? {
        ...values,
        schedule: values?.schedule ?? []
      }
    : {
        schedule: []
      }

  var schema = {
    ...productSectionScheduleSchema,
    ...productSectionFoodFormSchema,
    ...productSectionInsuranceFormSchema
  }
  // Add this only for school trip
  if (
    values.type === PRODUCT_TYPE_SCHOOL_TRIP.value ||
    values.type === PRODUCT_TYPE_CAMP.value ||
    values.type === PRODUCT_TYPE_DAYCAMP.value
  ) {
    schema = { ...schema, ...productSectionPlacesFormSchema }
  }

  const productSecondPageSchema = Yup.object(schema)

  const formMethods = useForm<any>({
    mode: "all",
    resolver: yupResolver(productSecondPageSchema),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { errors, isValid }
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  function onFormSubmit(data: any) {
    onSubmit(data)
  }

  return (
    <section>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <BlueBackground>
            <Heading3 id="h3-1">Plan wydarzenia</Heading3>
          </BlueBackground>
          <div className="mb-8" />

          <ProductSectionScheduleForm />

          <ProductSectionFoodForm {...formMethods} register={register} />
          {/* <FormSectionSeparator /> */}
          <ProductSectionInsuranceForm register={register} values={values} />
          {/* <FormSectionSeparator /> */}
          <ProductSectionConditionsForm register={register} values={values} />

          {/* New feature from Nov 22 - added "Atrakcje" to Camp (Peter) */}
          <BlueBackground>
            <Heading3 id="h3-1">Atrakcje</Heading3>
          </BlueBackground>
          <div className="mb-8" />
          <ProductSectionPlacesForm />
          {/* <FormSectionSeparator /> */}
          <NewProductWizardButtonsBar
            onCancel={onCancel}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </section>
  )
}

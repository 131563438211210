function KidIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0625 25.25C19.0625 26.8025 17.8025 28.0625 16.25 28.0625C14.6975 28.0625 13.4375 26.8025 13.4375 25.25C13.4375 23.6975 14.6975 22.4375 16.25 22.4375C17.8025 22.4375 19.0625 23.6975 19.0625 25.25ZM29.75 22.4375C28.1975 22.4375 26.9375 23.6975 26.9375 25.25C26.9375 26.8025 28.1975 28.0625 29.75 28.0625C31.3025 28.0625 32.5625 26.8025 32.5625 25.25C32.5625 23.6975 31.3025 22.4375 29.75 22.4375ZM45.5 23C45.5 35.42 35.42 45.5 23 45.5C10.58 45.5 0.5 35.42 0.5 23C0.5 10.58 10.58 0.5 23 0.5C35.42 0.5 45.5 10.58 45.5 23ZM19.985 5.27C23.135 10.49 28.85 14 35.375 14C36.41 14 37.4225 13.8875 38.39 13.73C35.24 8.51 29.525 5 23 5C21.965 5 20.9525 5.1125 19.985 5.27ZM5.945 17.3075C9.7925 15.125 12.7625 11.57 14.18 7.3175C10.3325 9.5 7.3625 13.055 5.945 17.3075ZM41 23C41 21.245 40.73 19.5575 40.2575 17.96C38.6825 18.2975 37.0625 18.5 35.375 18.5C28.3325 18.5 22.055 15.26 17.915 10.1975C15.5525 15.9575 10.85 20.48 5 22.685C5.0225 22.775 5 22.8875 5 23C5 32.9225 13.0775 41 23 41C32.9225 41 41 32.9225 41 23Z"
        fill="#323232"
      />
    </svg>
  )
}

export default KidIcon

import {Box} from '@mui/material';
import {
	docsSectionWrapper,
	iconButtonAction,
	infoDocsContainer,
	infoDocsText,
} from './docsStyle';
import {buttonMark} from '../chatSection/styleChatSection';
import IconButton from '@mui/material/IconButton';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Typography from '@mui/material/Typography';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import {showMore} from '../../studentStyle';

export const DocsSection = () => {

	return (
			<>
			<Box sx={docsSectionWrapper}>

				<Box sx={infoDocsContainer}>
					<IconButton sx={buttonMark}>
						<DescriptionOutlinedIcon fontSize={'medium'}/>
					</IconButton>

					<Typography sx={infoDocsText} component="span">
						formularz-damian-an...
						<span>15:32, 07.06.2021</span>
					</Typography>
				</Box>

				<Box sx={infoDocsContainer}>
					<IconButton sx={iconButtonAction}>
						<DownloadIcon fontSize={'medium'}/>
					</IconButton>

					<IconButton sx={iconButtonAction}>
						<RemoveRedEyeOutlinedIcon fontSize={'medium'}/>
					</IconButton>
				</Box>

			</Box>

				{/*MOCK COPY*/}
				<Box sx={docsSectionWrapper}>

					<Box sx={infoDocsContainer}>
						<IconButton sx={buttonMark}>
							<DescriptionOutlinedIcon fontSize={'medium'}/>
						</IconButton>

						<Typography sx={infoDocsText} component="span">
							formularz-damian-an...
							<span>15:32, 07.06.2021</span>
						</Typography>
					</Box>

					<Box sx={infoDocsContainer}>
						<IconButton sx={iconButtonAction}>
							<DownloadIcon fontSize={'medium'}/>
						</IconButton>

						<IconButton sx={iconButtonAction}>
							<RemoveRedEyeOutlinedIcon fontSize={'medium'}/>
						</IconButton>
					</Box>

				</Box>
				<Box sx={docsSectionWrapper}>

					<Box sx={infoDocsContainer}>
						<IconButton sx={buttonMark}>
							<DescriptionOutlinedIcon fontSize={'medium'}/>
						</IconButton>

						<Typography sx={infoDocsText} component="span">
							formularz-damian-an...
							<span>15:32, 07.06.2021</span>
						</Typography>
					</Box>

					<Box sx={infoDocsContainer}>
						<IconButton sx={iconButtonAction}>
							<DownloadIcon fontSize={'medium'}/>
						</IconButton>

						<IconButton sx={iconButtonAction}>
							<RemoveRedEyeOutlinedIcon fontSize={'medium'}/>
						</IconButton>
					</Box>

				</Box>
				{/*MOCK COPY*/}
				<Typography sx={showMore}>
					Zobacz więcej (3)
				</Typography>
			</>
	);
};
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom"
import { SuperAdminCompanies } from "./SuperAdminCompanies"
import { SuperAdminTrips } from "./SuperAdminProducts/SuperAdminTrips"

import { SuperAdminKids } from "./SuperAdminAccounts/SuperAdminKids"

import { SuperAdminPosters } from "./SuperAdminOthers/SuperAdminPosters"
import { SuperAdminPackageOrders } from "./SuperAdminPackageOrders"
import { SuperAdminMessages } from "./SuperAdminReservations/SuperAdminMessages"

import { SuperAdminLeads } from "./SuperAdminOthers/SuperAdminLeads"
import styled from "@emotion/styled"

import { SuperAdminOthers } from "./SuperAdminOthers/SuperAdminOthers"
import { ArticlesIndex } from "./articles/ArticlesIndex"
import { SuperAdminPayments } from "./SuperAdminPayments/SuperAdminPayments"
import { SuperAdminAccounts } from "./SuperAdminAccounts/SuperAdminAccounts"
import { SuperAdminReservations } from "./SuperAdminReservations/SuperAdminReservations"
import { SuperAdminProducts } from "./SuperAdminProducts/SuperAdminProducts"

function SuperAdminTabs({ children }: any) {
  // const CompaniesAndProduct: TabData[] = [
  //   { label: "Firmy", path: "companies" },
  //   { label: "Produkty", path: "products" },
  //   { label: "Wydarzenia (Terminy)", path: "trips" },
  //   { label: "Licencje", path: "packageorders" },
  //   { label: "Wiadomości", path: "messages" },
  //   { label: "Rezerwacje", path: "bookings" }
  // ]

  // const Users: TabData[] = [
  //   { label: "Użytkownicy", path: "users" },
  //   { label: "Dzieci", path: "kids" }
  // ]

  // const Other: TabData[] = [
  //   { label: "Ustawienia", path: "settings" },
  //   { label: "Plakaty", path: "posters" },
  //   { label: "Leady", path: "leads" }
  // ]

  const { pathname } = useLocation()

  const SuperAdminMenu = [
    { label: "Produkty", path: `../products` },
    { label: "Rezerwacje", path: `../reservations` },
    { label: "Konta", path: `../accounts` },
    { label: "Płatności", path: `../payments` },
    { label: "Blog", path: `../articles` },
    { label: "Inne", path: `../others` }
  ]

  return (
    <div className="flex w-full ">
      {/* Side col */}
      <div className="w-[270px] px-[20px] min-h-screen h-auto shadow-xl py-[25px] shrink-0">
        <h3 className="text-xs uppercase">Super Admin</h3>

        <div className="flex flex-col items-start mt-[8px] ">
          {SuperAdminMenu.map((item) => {
            return (
              <ButtonStyle
                key={item.label}
                className={
                  pathname.includes(item.path.split("/").pop()) ? "active" : ""
                }
              >
                <Link to={item.path}>{item.label}</Link>
              </ButtonStyle>
            )
          })}
        </div>
      </div>

      {/* main col */}
      <div className="w-full px-[50px] overflow-x-auto">
        <div className="mt-[22px]">{children}</div>
      </div>
    </div>
  )
}

const ButtonStyle = styled.button`
  &&.active {
    color: var(--fc-color-accent-3);
    background-color: #eaf0fd;
  }
  width: 100%;
  text-align: left;
  line-height: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  color: #222222;
  :hover {
    color: var(--fc-color-accent-3);
    background-color: #eaf0fd;
  }
  a {
    padding: 20px 10px;
    display: block;
    width: 100%;
    height: 100%;
  }
`

export function SuperAdminIndex() {
  return (
    <>
      <Routes>
        <Route
          path="companies/*"
          element={
            <SuperAdminTabs>
              <SuperAdminCompanies />
            </SuperAdminTabs>
          }
        />

        <Route
          path="products/*"
          element={
            <SuperAdminTabs>
              <SuperAdminProducts />
            </SuperAdminTabs>
          }
        />
        <Route
          path="trips/*"
          element={
            <SuperAdminTabs>
              <SuperAdminTrips />
            </SuperAdminTabs>
          }
        />
        <Route
          path="accounts/*"
          element={
            <SuperAdminTabs>
              <SuperAdminAccounts />
            </SuperAdminTabs>
          }
        />
        <Route
          path="kids/*"
          element={
            <SuperAdminTabs>
              <SuperAdminKids />
            </SuperAdminTabs>
          }
        />

        <Route
          path="articles/*"
          element={
            <SuperAdminTabs>
              <ArticlesIndex />
            </SuperAdminTabs>
          }
        />
        <Route
          path="others/*"
          element={
            <SuperAdminTabs>
              <SuperAdminOthers />
            </SuperAdminTabs>
          }
        />
        <Route
          path="posters/*"
          element={
            <SuperAdminTabs>
              <SuperAdminPosters />
            </SuperAdminTabs>
          }
        />
        <Route
          path="packageorders/*"
          element={
            <SuperAdminTabs>
              <SuperAdminPackageOrders />
            </SuperAdminTabs>
          }
        />
        <Route
          path="messages/*"
          element={
            <SuperAdminTabs>
              <SuperAdminMessages />
            </SuperAdminTabs>
          }
        />
        <Route
          path="reservations/*"
          element={
            <SuperAdminTabs>
              <SuperAdminReservations />
            </SuperAdminTabs>
          }
        />
        <Route
          path="leads/*"
          element={
            <SuperAdminTabs>
              <SuperAdminLeads />
            </SuperAdminTabs>
          }
        />
        <Route
          path="payments/*"
          element={
            <SuperAdminTabs>
              <SuperAdminPayments />
            </SuperAdminTabs>
          }
        />
        <Route index element={<Navigate to="products" />} />
      </Routes>
    </>
  )
}

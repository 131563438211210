import React from "react"

export const PlusIcon = ({ fill }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7987 11.47H8.7987V8.70336H11.582V7.70336H8.7987V4.80336H7.7987V7.70336H4.91536V8.70336H7.7987V11.47ZM8.2487 14.8034C7.33759 14.8034 6.47648 14.6284 5.66536 14.2784C4.85425 13.9284 4.14592 13.4506 3.54036 12.845C2.93481 12.2395 2.45703 11.5311 2.10703 10.72C1.75703 9.90892 1.58203 9.04225 1.58203 8.12003C1.58203 7.20892 1.75703 6.34781 2.10703 5.5367C2.45703 4.72559 2.93481 4.02003 3.54036 3.42003C4.14592 2.82003 4.85425 2.34503 5.66536 1.99503C6.47648 1.64503 7.34314 1.47003 8.26536 1.47003C9.17648 1.47003 10.0376 1.64503 10.8487 1.99503C11.6598 2.34503 12.3654 2.82003 12.9654 3.42003C13.5654 4.02003 14.0404 4.72559 14.3904 5.5367C14.7404 6.34781 14.9154 7.21448 14.9154 8.1367C14.9154 9.04781 14.7404 9.90892 14.3904 10.72C14.0404 11.5311 13.5654 12.2395 12.9654 12.845C12.3654 13.4506 11.6598 13.9284 10.8487 14.2784C10.0376 14.6284 9.17092 14.8034 8.2487 14.8034ZM8.26536 13.8034C9.83203 13.8034 11.1654 13.2506 12.2654 12.145C13.3654 11.0395 13.9154 9.69781 13.9154 8.12003C13.9154 6.55337 13.3654 5.22003 12.2654 4.12003C11.1654 3.02003 9.82648 2.47003 8.2487 2.47003C6.68203 2.47003 5.34592 3.02003 4.24036 4.12003C3.13481 5.22003 2.58203 6.55892 2.58203 8.1367C2.58203 9.70336 3.13481 11.0395 4.24036 12.145C5.34592 13.2506 6.68759 13.8034 8.26536 13.8034Z"
        fill={fill}
      />
    </svg>
  )
}

import { Box, Typography } from "@mui/material"
import {
  blockTitle,
  buttonsContainer,
  studentButton,
  studentCardBlock,
  studentData,
  studentIcon,
  studentInfoWrapper,
  studentName,
  studentWrapper,
  titleBlock,
} from "./studentStyle"
import Alert from "@mui/material/Alert"
import FaceIcon from "@mui/icons-material/Face"
import Button from "@mui/material/Button"
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined"
import { PaymentSection } from "./sections/PaymentSection/PaymentSection"
import { ChatSection } from "./sections/chatSection/ChatSection"
import { DocsSection } from "./sections/docsSection/DocsSection"
import { CuratorSection } from "./sections/curatorSection/CuratorSection"
import StudentModal from "./sections/studentModal/StudentModal"

export const Student = () => {
  return (
    <Box sx={studentWrapper}>
      <Typography sx={titleBlock} component="h1">
        Profil uczestnika
      </Typography>

      {/*STUDENT BLUE CARD BLOCK*/}
      <Box sx={studentCardBlock}>
        <Box
          sx={{
            display: "flex",
            padding: { xs: "35px 0" },
          }}
        >
          <FaceIcon sx={studentIcon} />

          <Box sx={studentInfoWrapper}>
            <Typography sx={studentName} component="span">
              Damian Biernacki
            </Typography>
            <Typography sx={studentData} component="span">
              Data dołączenia: 11.06
            </Typography>
          </Box>
        </Box>

        <Box sx={buttonsContainer}>
          <Button
            fullWidth={false}
            sx={{
              ...studentButton,
              visibility: { md: "visible", lg: "hidden" },
            }}
            variant={"outlined"}
          >
            Pobierz PDF
          </Button>

          <StudentModal />
        </Box>
      </Box>
      {/*STUDENT BLUE CARD BLOCK*/}

      <Typography sx={blockTitle} component="span">
        Status
      </Typography>
      <Alert
        icon={<AssignmentTurnedInOutlinedIcon fontSize="inherit" />}
        severity="success"
      >
        <strong>Formular zaakceptowany</strong>
      </Alert>

      <Typography sx={blockTitle} component="span">
        Płatności
      </Typography>

      {/*PAYMENT BLOCK*/}
      <PaymentSection />
      {/*PAYMENT BLOCK*/}

      <Typography sx={blockTitle} component="span">
        Czat z opiekunem
      </Typography>

      {/*CHAT BLOCK*/}
      <ChatSection />
      {/*CHAT BLOCK*/}

      <Typography sx={blockTitle} component="span">
        Udostępnione dokumenty
      </Typography>

      {/*DOCS BLOCK*/}
      <DocsSection />
      {/*DOCS BLOCK*/}

      <Typography sx={blockTitle} component="span">
        Opiekun
      </Typography>

      {/*CURATOR BLOCK*/}
      <CuratorSection />
      {/*CURATOR BLOCK*/}
    </Box>
  )
}

import i18n from "../i18n"

export const getLocalizedKey = (key: string) => {

    const language = i18n.language

    if (language === 'en') {
        return `${key}_en`
    } else {
        return key
    }
}

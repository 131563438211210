import React from "react"

export const Tag = ({
  label,
  onClick,
  iconSrc
}: {
  label: string
  onClick: () => void
  iconSrc?: string
}) => {
  return (
    <div className="rounded-full border border-[color:var(--fc-color-primary-blue)] px-3 py-1 text-[color:var(--fc-color-primary-blue)] text-[9px] flex justify-between items-center gap-2 uppercase">
      {iconSrc && (
        <img
          src={iconSrc}
          alt=""
          width="16"
          height="16"
          //className="cursor-pointer transition ease-in-out  hover:scale-150"
        />
      )}
      {label}
      <img
        onClick={onClick}
        src="/svg/close-icon-small.svg"
        alt=""
        width="16"
        height="16"
        className="cursor-pointer transition ease-in-out  hover:scale-150"
      />
    </div>
  )
}

import axios from "axios"
import { useSnackbar } from "notistack"
import { useState } from "react"
import { InputField } from "../../../components/FormCommons"
import { ApiTable } from "../../../components/table/ApiTable"
import { MojeButton } from "../../../components/ui/MojeButton"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"

import { useSettingsApi } from "./useSettingsApi"

const SettingForm = ({ setting }) => {
  const [value, setValue] = useState(setting.value)
  const { enqueueSnackbar } = useSnackbar()

  const handleSave = async () => {
    console.log("val", value)
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + `/v1/settings/${setting.code}`
    var res = await axios.post(url, { ...setting, value })
    console.log("res")
    enqueueSnackbar("Zapisano!", {
      variant: "success"
    })
    alert("Zapisano")
    return res.data
  }
  return (
    <>
      <div className="mb-4 text-2xl">Edytuj ustawienie</div>
      <div className="font-bold">{setting.code}</div>
      <div>Wartość</div>
      <InputField
        value={value}
        onChange={(event) => {
          setValue(event.currentTarget.value)
        }}
      />

      <MojeButton label="Zapisz" onClick={handleSave} variant="contained" />
    </>
  )
}
export const SuperAdminSettings = () => {
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const { list } = useSettingsApi()
  const [setting, setSetting] = useState()

  const columns = [
    {
      header: "Parametr",
      cell: (row: any) => <div>{row.code ?? "Brak"}</div>
    },
    {
      header: "Wartość",
      cell: (row: any) => <div>{row.value ?? "Brak"}</div>
    }
  ]

  return (
    <>
      <Toolbar setSearchTextDebounced={setSearchTextDebounced} api={list} />
      <ApiTable
        columns={columns}
        listApi={list}
        showExpandColumn
        onRowClicked={setSetting}
      ></ApiTable>
      {setting && (
        <>
          <SettingForm setting={setting} />
        </>
      )}
    </>
  )
}

import Box from '@mui/material/Box';
import {
	buttonMark,
	chatBlockWrapper,
	chatButton,
	infoBlock,
} from './styleChatSection';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const ChatSection = () => {
	return (
			<Box sx={chatBlockWrapper}>

				<Box sx={infoBlock}>

					<IconButton sx={buttonMark} color={'success'}>
						<MarkChatReadOutlinedIcon fontSize={'medium'}/>
					</IconButton>

					<Typography component={'span'}>
						Brak wiadomości
					</Typography>
				</Box>

				<Button sx={chatButton}>
					Rozpocznij konwersację
				</Button>

			</Box>
	);
};
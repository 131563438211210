import { debounce } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { FormattedDate, FormattedTime } from "react-intl"
import { Routes, Route } from "react-router-dom"
import { ApiTable } from "../../../components/table/ApiTable"
import { MojeTextInput } from "../../../components/ui/MojeTextInput"
import { useKidsApi } from "../../organizer/events/api/useKidsApi"
import { SuperDataExportButton } from "../SuperDataExport"

const SuperKidsTable = ({ queryParams }) => {
  const { adminList } = useKidsApi()
  const columns = [
    {
      header: "Data dodania",
      cell: (row: any) => {
        // console.log("Dodane", row)
        return (
          <div>
            <FormattedDate value={row.created_at} />{" "}
            <FormattedTime value={row.created_at} />
          </div>
        )
      },
      field: "created_at"
    },
    {
      header: "Imię i nazwisko dziecka",
      cell: (row: any) => (
        <div>
          <div>
            {row.first_name} {row.last_name}
          </div>
          <div>{row.pesel}</div>
        </div>
      ),
      field: "full_name"
    },
    {
      header: "Data urodzenia",
      field: "birth_date"
    },
    {
      header: "Użytkownik",
      cell: (row: any) => <div>{row.user?.name ?? "Brak"}</div>,
      field: "owner_user_id"
    },

    {
      header: "Lokalizacja",
      cell: (row: any) => {
        var parts = []
        if (row.street) {
          parts.push(row.street + " " + row.street_number)
        }
        if (row.postcode) {
          parts.push(row.postcode)
        }
        if (row.city) {
          parts.push(row.city)
        }
        if (row.province) {
          parts.push(row.province)
        }
        if (row.country) {
          parts.push(row.country)
        }
        return (
          <div>
            {parts.join(", ")}
            <br />
          </div>
        )
      },
      field: "created_at"
    }
  ]
  return (
    <ApiTable
      columns={columns}
      listApi={adminList}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/super/kids/${row.id}`}
      queryParams={queryParams}
      usePagination
      initialSort={{direction: "desc", field: "created_at"}}
    ></ApiTable>
  )
}

function SuperAdminKidPage() {
  return <div>SuperAdminKidPage</div>
}

export function SuperAdminKids() {
  const [searchText, setSearchText] = useState("")
  const { adminList } = useKidsApi()
  const queryParams: any = {}
  if (searchText) {
    queryParams.search = searchText
  }

  const setSearchTextDebounced = debounce(async (name) => {
    setSearchText(name)
  }, 500)

  return (
    <div className="mt-10">
      <Box width="100%">
        {/* <div className="flex flex-row items-center">
          <SuperDataExportButton api={adminList} />
           <div className="flex-1" />
          <div>Szukaj:</div>
          <div className="w-64 mx-4">
            <MojeTextInput
              margin="none"
              onChange={(ev) => setSearchTextDebounced(ev.target.value)}
              // variant="filled"
              size="small"
              fullWidth
            />
          </div>
        </div> */}
        <Routes>
          <Route path=":id" element={<SuperAdminKidPage />} />
          <Route index element={<SuperKidsTable queryParams={queryParams} />} />
        </Routes>
      </Box>
    </div>
  )
}

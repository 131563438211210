export class PhotoUtils {
  static generateCloudinaryLink(url, width, height, g_position = "auto") {
    url = url.replace("http://", "https://")
    // console.log(
    //   "mediaUrl ss",
    //   url,
    //   url.indexOf(
    //     "https://res.cloudinary.com/www-fajnyczas-pl/image/upload/"
    //   ) == 0
    // )
    if (
      url.indexOf(
        "https://res.cloudinary.com/www-fajnyczas-pl/image/upload/"
      ) == 0
    ) {
      url = url.replace(
        "https://res.cloudinary.com/www-fajnyczas-pl/image/upload",
        ""
      )
      return (
        "https://res.cloudinary.com/www-fajnyczas-pl/image/upload/" +
        `w_${width},h_${height},c_fill,${
          g_position === "north" ? "g_north" : "g_auto"
        },f_auto` +
        url
      )
    }
    return url
  }
}

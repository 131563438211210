import { useFormContext, useFormState } from "react-hook-form"
import {
  DescriptionList,
  Heading3,
  InputField
} from "../../../components/FormCommons"
import { useState } from "react"
import { Button } from "@mui/material"
import AddAccommodationModal from "./AddAccommodationModal"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import { useProductApi } from "../events/api/useProductApi"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { useTranslation } from "react-i18next"

export const ProductSectionAccommodationForm = ({
  register,
  values,
  watch,
  getValues
}) => {
  const { t } = useTranslation()
  const { errors } = useFormState()
  const { setValue } = useFormContext()
  const facility = watch("facility")
  const [accommodationIndex, setAccomodationIndex] = useState(0)
  const [openAccomodationModal, setOpenAccomodationModal] = useState(false)
  const handleOpenAccomodationModal = () => {
    setOpenAccomodationModal(true)
    facility?.length
      ? setAccomodationIndex(facility.length)
      : setAccomodationIndex(0)
  }
  const handleCloseAccomodationModal = () => setOpenAccomodationModal(false)

  const removeItem = (indexToRemove) => {
    const filteredFacility = facility.filter(
      (item, index) => index !== indexToRemove
    )
    setValue("facility", filteredFacility)
  }

  return (
    <div>
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.holiday_lettings_number", "Liczba miejsc noclegowych")}
      </Heading3>
      <InputField
        type="number"
        value={watch("max_guests") || ""}
        label={t(
          "ui.groups.max_guests_input",
          "Wpisz maksymalną liczbę gości w Twoim obiekcie*"
        )}
        {...register("max_guests")}
        error={errors?.max_guests}
        helperText={errors?.max_guests?.message}
      />
      <div className="border-t border-[#E9E9E9] my-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.holiday_lettings_type", "Rodzaj miejsc noclegowych")}
      </Heading3>
      <div className="mb-8" />
      {facility &&
        facility.map((item, index) => (
          <div key={index}>
            <div
              onClick={() => {
                setAccomodationIndex(index)
                setOpenAccomodationModal(true)
              }}
              className="rounded-[8px] shadow-lg p-[16px] flex flex-col gap-[10px] hover:cursor-pointer"
            >
              <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-[20px] items-center">
                  <span className="font-bold text-[18px] leading-[16px]">
                    {item?.number_of_beds}x
                  </span>
                  <span className="text-[16px] leading-[26px]">
                    {t("ui.groups.room", "Pokój")} {item?.number_of_people}
                    {t("ui.groups.-person", "-osobowy")}
                  </span>
                </div>
                <button
                  className="hover:text-red-500"
                  onClick={(e) => {
                    e.stopPropagation()
                    removeItem(index)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
              <div>
                <p className="text-[14px] decoration-[#7E7E7E] lowercase">
                  {item.amenities &&
                    item.amenities
                      .map((amenity) => amenity.replace("pokój.", ""))
                      .join(", ")}
                </p>
              </div>
            </div>
            <div className="mb-8" />
          </div>
        ))}
      <div className="mb-8" />
      <div className="flex justify-center">
        <Button
          variant="outlined"
          size="large"
          onClick={handleOpenAccomodationModal}
          startIcon={<AddIcon />}
        >
          {t("ui.groups.add_holiday_lettings", "Dodaj miejsca noclegowe")}
        </Button>
      </div>
      <AddAccommodationModal
        handleClose={handleCloseAccomodationModal}
        open={openAccomodationModal}
        setValue={setValue}
        register={register}
        accommodationIndex={accommodationIndex}
        getValues={getValues}
      />
      <div className="mb-8" />
    </div>
  )
}

export const ProductSectionAccommodation = ({ values, refetch }: any) => {
  const { t } = useTranslation()
  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: data.id,
      max_guests: data.max_guests,
      facility: data.facility
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }

  const initialValues = { ...values, object_type: values.object_type ?? [] }

  return (
    <EditableFormSection
      title={t("ui.groups.holiday_lettings", "Miejsca noclegowe")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      //validationSchema={schema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionAccommodationForm
                values={values}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <ProductSectionAccommodationPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

export const ProductSectionAccommodationPreview = ({
  values
}: {
  values: any
}) => {
  const { t } = useTranslation()
  const maxGuests = {}
  maxGuests[t("ui.groups.number_of_guests", "Ilość miejsc noclegowych")] =
    values["max_guests"]
  return (
    <div>
      <section className="mb-8">
        <DescriptionList separator data={maxGuests} />
        <div className="mx-4">
          <dt className="text-sm text-gray-500 font-medium my-2">
            {t("ui.groups.holiday_lettings_type", "Rodzaj miejsc noclegowych")}
          </dt>
          {values.facility
            ? values.facility.map((item, index) => (
                <div key={index}>
                  <div className="rounded-[8px] shadow-lg p-[16px] flex flex-col gap-[10px]">
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row gap-[20px] items-center">
                        <span className="font-bold text-[18px] leading-[16px]">
                          {item?.number_of_beds}x
                        </span>
                        <span className="text-[16px] leading-[26px]">
                          {t("ui.groups.room", "Pokój")}{" "}
                          {item?.number_of_people}
                          {t("ui.groups.-person", "-osobowy")}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="text-[14px] decoration-[#7E7E7E] lowercase">
                        {item.amenities &&
                          item.amenities
                            .map((amenity) => amenity.replace("pokój.", ""))
                            .join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className="mb-8" />
                </div>
              ))
            : "-"}
        </div>
      </section>
    </div>
  )
}

import { Tooltip } from "@mui/material"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import DuplicateIcon from "../../../components/icons/DuplicateIcon"
import { TrashIcon } from "../../../components/icons/TrashIcon"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { PlusIcon } from "../../../components/icons/PlusIcon"
import SearchIcon from "../../../components/icons/SearchIcon"

export const ActionWithLoader = ({ onClick, children }) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (params) => {
    setLoading(true)
    await onClick(params)
    setLoading(false)
  }
  return (
    <div onClick={handleClick}>
      {children({ onClick: handleClick, loading })}
    </div>
  )
}

export const ProductActionWithLoader = ({ onClick, children }) => {
  return (
    <div className="">
      <ActionWithLoader onClick={onClick}>
        {({ loading }) => {
          return (
            <div className="flex flex-row items-center">
              <div className="text-lg">
                {loading ? (
                  <div className="text-base">
                    <LoadingSpinner />
                  </div>
                ) : (
                  ""
                )}
              </div>
              {children}
            </div>
          )
        }}
      </ActionWithLoader>
    </div>
  )
}

type OptionType =
  | "editOffer"
  | "edit"
  | "duplicate"
  | "remove"
  | "add"
  | "preview"
  | "openChat"

const RemoveButton = ({ disabledRemove }) => {
  const { t } = useTranslation()
  return (
    <div
      className={clsx(
        "items-center",
        "flex gap-1 ",
        disabledRemove ? "text-[#B8B8B8] cursor-default" : "hover:underline"
      )}
    >
      <TrashIcon
        fill={disabledRemove ? "#B8B8B8" : "#0C48D7"}
        width="16"
        height="16"
      />
      <p className="py-2">{t("remove", "Usuń")}</p>
    </div>
  )
}

export const OptionButton = ({
  row,
  options,
  handleDelete,
  handleDuplicate,
  editUrl,
  rowType,
  chatUrl,
  setPopupEditData,
  setPopupAddDate
}: {
  row
  options: OptionType[]
  handleDelete?: Function
  handleDuplicate?: Function
  editUrl?: string
  chatUrl?: string
  rowType?: string
  disableRemove?: boolean
  setPopupEditData?: (a) => void
  setPopupAddDate?: (a) => void
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handlePreview = (row) => {
    if (row.type === "dedykowana-wycieczka-szkolna") {
      window.open(
        `https://www.fajnyczas.pl/dedykowana-oferta/${row.trips?.[0].id}`,
        "_blank"
      )
    } else {
      window.open(
        `https://www.fajnyczas.pl/${row.company?.fc_website_prefix}/${row.id}`,
        "_blank"
      )
    }
  }

  let disabledRemove = false

  if (rowType && rowType === "trip" && row?.stat_participants_count === 0) {
    disabledRemove = true
  }

  if (rowType && rowType === "offer" && row?.stat_active_trips_count === 0) {
    disabledRemove = true
  }

  // Need to check if this place or accommmodation is using in any offer
  if (row?.type === "place" && row?.status === "active") {
    disabledRemove = true
  }
  // Need to check if this place or accommmodation is using in any offer
  if (row?.type === "accommodation" && row?.status === "active") {
    disabledRemove = true
  }
  if (
    row?.type === "dedykowana-wycieczka-szkolna" &&
    row?.stat_participants_count === 0
  ) {
    disabledRemove = false
  }
  return (
    <div className="w-full flex flex-col md:flex-row justify-items-start text-fc-accent-3 gap-4 items-center text-xs">
      {options && options.includes("editOffer") && (
        <div
          className="flex gap-1 items-center hover:underline"
          onClick={(e) => {
            e.preventDefault()
            if (setPopupEditData) {
              setPopupEditData(row)
            } else {
              navigate(editUrl ?? `${row.id}/details`)
            }
          }}
        >
          <img src="/svg/edit-icon.svg" alt="edit" width="16" height="16" />
          <p>{t("edit", "Edytuj ")}</p>
        </div>
      )}
      {options && options.includes("edit") && (
        <div
          className="flex gap-1 items-center hover:underline p-2"
          onClick={(e) => {
            e.preventDefault()
            if (setPopupEditData) {
              e.preventDefault()
              setPopupEditData(row)
            } else {
              navigate(editUrl ?? `${row.id}/details`)
            }
          }}
        >
          <img src="/svg/edit-icon.svg" alt="edit" width="16" height="16" />
          <p>{t("edit", "edytuj ")}</p>
        </div>
      )}
      {options && options.includes("add") && setPopupAddDate && (
        <div
          className="flex gap-1 items-center ml-4"
          onClick={(e) => {
            e.preventDefault()
            if (setPopupAddDate) {
              setPopupAddDate(row)
            }
          }}
        >
          <PlusIcon
            fill={row.stat_all_trips_count > 0 ? "#0C48D7" : "#ff0000"}
          />
          <p
            className={clsx(
              "hover:underline",
              "whitespace-nowrap",
              row.stat_all_trips_count > 0
                ? "text-fc-accent-3"
                : "text-[#ff0000]"
            )}
          >
            {t("add_term", "Dodaj termin")}
          </p>
        </div>
      )}
      {options && options.includes("add") && !setPopupAddDate && (
        <Link
          to={`${row.id}/turnusy/dodaj`}
          className="flex gap-1 items-center ml-4"
        >
          <PlusIcon
            fill={row.stat_all_trips_count > 0 ? "#0C48D7" : "#ff0000"}
          />
          <p
            className={clsx(
              "hover:underline",
              "whitespace-nowrap",
              row.stat_all_trips_count > 0
                ? "text-fc-accent-3"
                : "text-[#ff0000]"
            )}
          >
            {t("add_term", "Dodaj termin")}
          </p>
        </Link>
      )}
      {options &&
        options.includes("preview") &&
        row.company?.fc_website_prefix && (
          <ProductActionWithLoader
            onClick={(e) => {
              e.preventDefault()
              handlePreview(row)
            }}
          >
            <div className="flex gap-1 items-center">
              <SearchIcon fill={"#0C48D7"} width="14" height="14" />
              <p
                className={clsx(" hover:underline", "text-fc-accent-3", "py-2")}
              >
                {t("preview", "Podgląd")}
              </p>
            </div>
          </ProductActionWithLoader>
        )}
      {/* {options && options.includes("duplicate") && (
        <ProductActionWithLoader
          onClick={(e) => {
            e.preventDefault()
            handleDuplicate(row)
          }}
        >
          <div className="flex gap-2 hover:underline ">
            <DuplicateIcon />
            <p>duplikuj</p>
          </div>
        </ProductActionWithLoader>
      )} */}
      {options && options.includes("remove") && (
        <ProductActionWithLoader
          onClick={(e) => {
            e.preventDefault()
            if (!disabledRemove) {
              handleDelete(row)
            }
          }}
        >
          {disabledRemove ? (
            <Tooltip
              title={
                rowType === "offer"
                  ? t(
                      "disable_description_1",
                      "Aby usunąć wydarzenie, terminy nie mogą być aktywne"
                    )
                  : t(
                      "disable_description_2",
                      "Nie można usunąć terminu jeśli masz zapisanych uczestników"
                    )
              }
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "none",
                    fontSize: "11px",
                    bgcolor: "black",
                    "& .MuiTooltip-arrow": {
                      color: "black"
                    }
                  }
                }
              }}
            >
              <div>
                <RemoveButton disabledRemove={disabledRemove} />
              </div>
            </Tooltip>
          ) : (
            <RemoveButton disabledRemove={disabledRemove} />
          )}
        </ProductActionWithLoader>
      )}
      {options && options.includes("openChat") && (
        <ProductActionWithLoader
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <div className="flex gap-1 items-center hover:underline p-2">
            <img
              src="/svg/chat.svg"
              alt=""
              width="16"
              height="16"
              className="cursor-pointer"
            />
            <Link
              key={row.id}
              to={chatUrl ? `/chats/${chatUrl}` : `/chats/${row.id}`}
            >
              <p className="text-fc-accent-3">
                {t("open_chat", "Otwórz czat")}{" "}
              </p>
            </Link>
          </div>
        </ProductActionWithLoader>
      )}
    </div>
  )
}

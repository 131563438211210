// PAYMENT BLOCK
export const paymentContainer: any = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  width: "100%",
  height: { xs: "100%", sm: "150px" },
}

export const priceWrapper: any = {
  display: "flex",
  flexDirection: "column",
  width: { xs: "100%", sm: "40%", md: "40%" },
}

export const priceContainer: any = {
  display: "flex",
  borderRadius: "8px",
  alignItems: "center",
  padding: { xs: "20px", sm: "0px" },
  flexDirection: "column",
  backgroundColor: "#F3F6FD",
  justifyContent: "center",
  flex: 1,
  marginBotton: { xs: "20px", sm: "0" },
}

export const priceHead = {
  fontFamily: "Mulish",
  fontWeight: 700,
  color: "#0C48D7",
  fontSize: "36px",
  lineHeight: "40px",
  letterSpacing: "-0.01em",
  "& > span": {
    fontSize: "1rem",
  },
}
export const priceInfo = {
  fontFamily: "Lato",
  paddingTop: "10px",
  fontSize: "16px",
  lineHeight: "17px",
  fontWeight: "bold",
}
export const priceText: any = {
  display: "flex",
  justifyContent: "center",
  lineHeight: "25px",
  fontSize: "16px",
  color: " #7E7E7E",
}

export const paymentInfoMainContainer: any = {
  display: "flex",
  flexDirection: "column",
  padding: "10px 0",
  justifyContent: "space-between",
}

export const paymentInfoWrapper = {
  display: "flex",
  width: "100%",
  padding: { xs: "20px 0", sm: "0" },
}

export const paymentSuccessIcon: any = {
  fontSize: "1.3rem",
  color: "#5CAA0B",
}
export const iconSuccessBox: any = {
  display: "flex",
  margin: "0px 16px 0px 32px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "100%",
  background: "rgba(92, 170, 11, 0.1)",
  width: "48px",
  height: "48px",
}

export const paymentInfoBlock: any = {
  display: "flex",
  flexDirection: "column",
  paddingBottom: "20px",
}

export const paymentInfoText = {
  lineHeight: "17px",
  fontSize: "16px",
  "& > span": {
    fontWeight: 600,
  },
}

export const paymentInfoSecondText = {
  color: " #7E7E7E",
  // paddingBottom:'20px',
}
// PAYMENT BLOCK

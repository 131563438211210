function SearchIcon({fill = "#111111", width="21", height="22"}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7492 20.1905L15.0851 14.5264C16.4462 12.8924 17.1249 10.7966 16.98 8.67497C16.8352 6.55334 15.878 4.56922 14.3075 3.13536C12.737 1.7015 10.6742 0.92831 8.54821 0.976622C6.42218 1.02493 4.39663 1.89103 2.89292 3.39475C1.3892 4.89846 0.523103 6.92401 0.474791 9.05004C0.426479 11.1761 1.19967 13.2389 2.63353 14.8093C4.06738 16.3798 6.05151 17.337 8.17314 17.4819C10.2948 17.6267 12.3906 16.948 14.0246 15.587L19.6886 21.251L20.7492 20.1905ZM1.9992 9.25103C1.9992 7.91601 2.39508 6.61096 3.13678 5.50093C3.87848 4.3909 4.93269 3.52574 6.16609 3.01485C7.39949 2.50395 8.75669 2.37028 10.0661 2.63073C11.3754 2.89118 12.5782 3.53406 13.5222 4.47806C14.4662 5.42207 15.1091 6.6248 15.3695 7.93417C15.63 9.24354 15.4963 10.6007 14.9854 11.8341C14.4745 13.0675 13.6093 14.1218 12.4993 14.8635C11.3893 15.6052 10.0842 16.001 8.7492 16.001C6.9596 15.999 5.24386 15.2873 3.97842 14.0218C2.71298 12.7564 2.00119 11.0406 1.9992 9.25103V9.25103Z"
        fill={fill}
      />
    </svg>
  )
}

export default SearchIcon

import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormHelperText, Heading3 } from "../../../../components/FormCommons"
import { months } from "wakacje-web/src/utils/ProductUtils"

export const ProductFormAvailability = () => {
  const { watch, register, setValue } = useFormContext<any>()
  const { t } = useTranslation()

  // Need to keep this register ! don't remove
  register("availability")
  const availabilityValue = watch("availability")

  const togggleValue = (val, on) => {
    var newValues = availabilityValue
      ? availabilityValue.filter((f: any) => f !== val)
      : []
    if (on) {
      newValues.push(val)
    }
    newValues = newValues.sort((a, b) => a - b)
    setValue("availability", newValues)
  }

  var hasAllYear = availabilityValue && availabilityValue.length === 12
  return (
    <div>
      <Heading3 id="h3-1" className="mb-4">
        {t("product.availability", "Dostępność")}
      </Heading3>
      <div className="mb-4">
        <FormHelperText>
          Określ, w jakim okresie możesz zorganizować wydarzenie*
        </FormHelperText>
      </div>
      <div>
        <FormControlLabel
          control={<Checkbox checked={hasAllYear} />}
          onChange={(event: any) => {
            if (event.target.checked) {
              // Add all months
              setValue(
                "availability",
                months.map((m) => m.value)
              )
            } else {
              // Remove all months
              // togggleValue("*", event.target.checked)
              setValue("availability", [])
            }
          }}
          label={t("product.availability_whole_year", "cały rok")}
        />

        {/* {!hasAllYear && ( */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {months.slice(0, 6).map((month, index) => {
              return (
                <div key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          availabilityValue &&
                          availabilityValue.indexOf(month.value) > -1
                        }
                        disabled={hasAllYear}
                      />
                    }
                    onChange={(event: any) => {
                      togggleValue(month.value, event.target.checked)
                    }}
                    label={month.name}
                  />
                </div>
              )
            })}
          </Grid>
          <Grid item xs={6}>
            {months.slice(6, 12).map((month) => {
              return (
                <div key={month.value}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          availabilityValue &&
                          availabilityValue.indexOf(month.value) > -1
                        }
                        disabled={hasAllYear}
                      />
                    }
                    onChange={(event: any) => {
                      togggleValue(month.value, event.target.checked)
                    }}
                    label={month.name}
                  />
                </div>
              )
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

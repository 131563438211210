import { useState, useEffect } from "react"

const useCurrentUrl = () => {
    const [currentUrl, setCurrentUrl] = useState("")

    useEffect(() => {
        const getCurrentUrl = () => {
            if (typeof window !== "undefined") {
                const url = window.location.hostname.toLowerCase()
                setCurrentUrl(url)
            }
        }

        getCurrentUrl()

        return () => { }
    }, [])

    return currentUrl
}

export default useCurrentUrl

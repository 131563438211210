
const RegulaminIndex = () => {
    return (
        <div className="text-center w-full p-4">

            <h5 className="text-center p-4">
                Regulamin dla Usługodawców Serwisu FajnyCzas.pl  <br /> § 1<br />
                POSTANOWIENIA OGÓLNE
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5" >
                <li>Niniejszy Regulamin określa zasady korzystania z serwisu internetowego FajnyCzas.pl przez Usługodawców.</li>
                <li>Regulamin oraz Serwis posługują się następującymi pojęciami:</li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>Operator - Mente Spółka Akcyjna, Plac Zwycięstwa 2, 90-312 Łódź, NIP 7282875373, KRS 0001044782</li>
                    <li>Serwis - serwis internetowy FajnyCzas.pl</li>
                    <li>Usługodawca - osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, będąca przedsiębiorcą wpisanym do rejestru organizatorów turystyki i przedsiębiorców ułatwiających nabywanie powiązanych imprez turystycznych, która po akceptacji Regulaminu uzyskała dostęp do Serwisu i za jego pośrednictwem oferuje swoje Usługi Klientom</li>
                    <li>Klient - osoba fizyczna, która po akceptacji Regulaminu uzyskała dostęp do Serwisu i za jego pośrednictwem może skorzystać z Usług oferowanych przez Usługodawców</li>
                    <li>Usługa - świadczenia oferowane przez Usługodawców w Serwisie, obejmujące organizację usług turystycznych w rozumieniu przepisów ustawy z dnia 24 listopada 2017 roku o imprezach turystycznych i powiązanych usługach turystycznych</li>
                    <li>Rezerwacja - Usługa wybrana i opłacona przez Klienta</li>
                    <li>Rejestracja - rejestracja w Serwisie przez Usługodawcę lub Klienta, wymagająca podania podstawowych informacji umożliwiających stworzenie Konta; wyróżnia się: </li>
                    <ol className="pl-6 text-left" style={{ listStyleType: 'none' }}>
                        <li>- Rejestrację Usługodawcy - niezbędna dla uzyskania dostępu do funkcjonalności Serwisu przewidzianych dla Usługodawcy, </li>
                        <li>- Rejestrację Klienta - niezbędna dla uzyskania pełnego dostępu do funkcjonalności Serwisu przewidzianych dla Klienta, </li>
                    </ol>
                    <li>Użytkownik - osoba korzystająca z Serwisu bez uprzedniej Rejestracji, dla której dostępne są jedynie wybrane funkcjonalności,</li>
                    <li>Konto - podstrona Serwisu z dostępem ograniczonym przez Login i Hasło, umożliwiająca Usługodawcom i Klientom zarządzanie wprowadzonymi przez nich danymi oraz aktywne uczestnictwo w Serwisie,</li>
                    <li>Opłata - zapłata dokonywana przez Usługodawcę na rzecz Operatora, umożliwiająca korzystanie z funkcjonalności Serwisu w ramach wybranego Pakietu,</li>
                    <li>Pakiet - zakres funkcjonalności udostępnianych Usługodawcy, dostępny w trzech wersjach: Pakiet Mini, Pakiet Standard i Pakiet Full,</li>
                    <li>Płatność - zapłata za Usługi wybrane przez Klienta, której wykonanie powoduje dokonanie Rezerwacji,</li>
                    <li>System Płatności - system płatności internetowych realizowanych za pośrednictwem serwisu www.przelewy24.pl,</li>
                    <li>Login - ciąg znaków identyfikujący Usługodawcę i Klienta w Serwisie, którego wpisanie łącznie z Hasłem warunkuje dostęp do Konta; Login nie może być zwrotem powszechnie uznawanym za obraźliwe, naruszać praw osób trzecich, ani godzić w dobre obyczaje,</li>
                    <li>Hasło - ciąg o długości przynajmniej 8 znaków, na które składają się wielka i mała litera oraz cyfra, którego wpisanie łącznie z Loginem warunkuje dostęp do Konta.</li>
                </ol>
            </ol>
            <h5 className="text-center mt-5">
                § 2
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Właścicielem Serwisu jest Operator.</li>
                <li>Usługi oferowane w Serwisie są świadczone wyłącznie przez Usługodawców, a Operator pośredniczy jedynie w ich realizacji, udostępniając Usługodawcom możliwość odpłatnego oferowania Usług w Serwisie, a Klientom nabycie tych Usług. Operator nie ponosi jakiejkolwiek odpowiedzialności brak realizacji oraz sposób realizacji Usług świadczonych przez Usługodawców, a także za ewentualny brak płatności lub niepełną płatność za Usługi.</li>
                <li>Możliwość odpłatnego oferowania Usług w Serwisie polega na udostępnieniu Usługodawcom przez Operatora podstrony w ramach Serwisu, której adres zwierać będzie oznaczenie Usługodawcy oraz domenę FajnyCzas.pl. Usługodawca samodzielnie decyduje o zawartości udostępnionej mu podstrony, przy czym nie może ona zawierać treści zabronionych przez obowiązujące przepisy prawne lub postanowienia Regulaminu. Minimalna zawartość podstrony powinna określać warunki uczestniczenia w imprezie turystycznej, wystarczające do podjęcia decyzji o zakupie Usługi i konsekwencjach z tym związanych.</li>
                <li>W ramach udostępnionej Usługodawcy podstrony w Serwisie, Operator w szczególności:</li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>umożliwia Klientom i Użytkownikom zapoznania z oferowanymi przez Usługodawcę imprezami turystycznymi, zapewniając możliwość ich wyszukiwania, przeglądania i porządkowania według różnorodnych kryteriów,</li>
                    <li>umożliwia dokonanie Rezerwacji oferowanych przez Usługodawcę imprez turystycznych,</li>
                    <li>umożliwia uzyskanie informacji na temat warunków uczestniczenia w imprezach turystycznych oferowanych w Serwisie przez Usługodawców,</li>
                    <li>pozwala na umieszczanie przez Klientów opinii w Serwisie dotyczących jakości Usług świadczonych przez Usługodawców.</li>
                </ol>
                <li>Usługi oferowane w Serwisie przez Usługodawców będą widoczne zarówno na podstronie dedykowanej konkretnemu Usługodawcy, jak również na stronie głównej Serwisu, pośród ofert zamieszczonych przez innych Usługodawców. Kolejność wyświetlania w Serwisie Usług oferowanych przez poszczególnych Usługodawców uzależniona jest od kryteriów wyszukiwania wybranych przez Klienta lub Użytkownika.</li>
                <li>Usługodawcy oferują w Serwisie Usługi skierowane do dzieci i młodzieży, przy czym nabywcą Usługi w Serwisie może być wyłącznie osoba pełnoletnia. W przypadku konieczności wyrażenia zgody przez rodzica lub opiekuna prawnego na wzięcie udziału przez osobę niepełnoletnią w imprezie turystycznej, stosowna zgoda winna zostać przekazana bezpośrednio Usługodawcy.</li>
                <li>Operator nie gwarantuje zainteresowania Usługami oferowanymi w Serwisie przez Usługodawców, jak również nie jest zobowiązany do podejmowania jakichkolwiek działań mających na celu zwiększenie tego zainteresowania, poza działaniami wskazanymi w Regulaminie.</li>
                <li>Czynności świadczone przez Operatora polegają na utrzymaniu Serwisu i zapewnieniu do niego stałego dostępu Usługodawcom i Klientom, w celu umożliwienia im zawarcia umowy o udział w imprezie turystycznej, w której Operator nie występuje jako strona. Zawarcie umowy o udział w imprezie turystycznej dokonywane jest na warunkach ustalonych przez Usługodawcę oraz Klienta, na treść których Operator nie ma wpływu.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 3<br />
                PODSTAWA REALIZACJI USŁUG
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Przeglądanie Usług oferowanych w Serwisie ma charakter nieopłatny i jest dostępne dla Usługodawców, Klientów oraz Użytkowników.</li>
                <li>Zakupu Usług oferowanych w Serwisie mogą dokonać wyłącznie Klienci. Rejestracja Klienta jest nieodpłatna, przy czym wymaga złożenia oświadczenia o ukończeniu osiemnastego roku życia. Operator nie ponosi odpowiedzialności za złożenie przez Klienta nieprawdziwego oświadczenia lub podania nieprawdziwych danych osobowych.</li>
                <li>Rejestracja Usługodawcy w Serwisie wymaga zakupienia jednego z oferowanych przez Operatora Pakietów:</li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>Pakiet Mini - umożliwia dodanie w Serwisie do dziesięciu ofert, które pozostaną aktywne w okresie dziewięćdziesięciu dni od dnia wykupienia Pakietu, którego jednorazowy koszt zakupu wynosi 300,00 złotych (słownie: trzysta złotych 00/100) brutto.</li>
                    <li>Pakiet Standard - umożliwia dodanie w Serwisie do dziesięciu ofert, które pozostaną aktywne w okresie stu osiemdziesięciu dni od dnia wykupienia Pakietu, którego jednorazowy koszt zakupu wynosi 500,00 złotych (słownie: pięćset złotych 00/100) brutto.</li>
                    <li>Pakiet Full - umożliwia dodanie w Serwisie do dziesięciu ofert, które pozostaną aktywne w okresie trzydziestu sześćdziesięciu pięćdziesięciu dni od dnia wykupienia Pakietu, którego jednorazowy koszt zakupu wynosi 800,00 złotych (słownie: osiemset złotych 00/100) brutto.</li>
                </ol>
                <li>W ramach każdej dodanej oferty, Usługodawca może wskazać dowolną ilość terminów, w których dana impreza turystyczna będzie realizowana.</li>
                <li>Operator dopuszcza możliwość zakupienia Pakietu na warunkach innych niż określone w § 3 ust. 3 Regulaminu, w oparciu o indywidualne ustalenia poczynione z Usługodawcą.</li>
                <li>Operator podejmuje działania zmierzające do weryfikacji Usługodawców, w tym w szczególności w zakresie jakości oferowanych przez nich Usług, przy czym Operator nie ponosi odpowiedzialności za realizację tychże Usług, jak również za jej ewentualne niewykonanie lub niewłaściwe wykonanie.</li>
                <li>Zabronione jest oferowanie w Serwisie Usług sprzecznych z prawem, a także zawierających treści niestosowne, obraźliwe, sprzecznych z zasadami współżycia społecznego i obraźliwych.</li>
                <li>Usługodawcy zobowiązani są świadczyć Usługi w uwzględnieniem profesjonalnego charakteru ich działalności oraz mając na względzie, iż ich odbiorcami będą dzieci i młodzież.</li>
                <li>Przed dokonaniem płatności za Usługę, Klient określa jej parametry spośród udostępnionych przez Usługodawcę. Z chwilą prawidłowego dokonania Płatności dochodzi do zawarcia umowy o udział w imprezie turystycznej pomiędzy Usługodawcą i Klientem. Dokonanie płatności powoduje powstanie u Klienta roszczenia o wykonanie Usługi w sposób zgodny z jej opisem w Serwisie.</li>
                <li>Serwis daje możliwość przygotowania przez Usługodawcę oferty indywidualnej, przygotowanej na bazie formularza informacyjnego dostępnego na podstronie Usługodawcy. Możliwość wysłania formularza z zapytaniem o przygotowanie oferty indywidualnej mają wyłącznie Klienci.</li>
                <li>W przypadku nieprzekazania środków pieniężnych Usługodawcy przez System Płatności w wyniku zakwestionowania płatności w oparciu o obowiązujące przepisy prawa lub wewnętrzne regulacje Systemu Płatności, Usługodawca będzie uprawniony do powstrzymania się od realizacji Usługi do czasu otrzymania zapłaty, co nie będzie stanowiło niewykonania lub niewłaściwego wykonania umowy.</li>
                <li>Operator nie ponosi odpowiedzialności za opóźnienia w procesie Rejestracji, Rezerwacji lub świadczeniu Usługi wynikające z przyczyn od niego niezależnych, takich jak zaksięgowanie płatności na rachunku bankowym.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 4<br />
                REALIZACJA USŁUG
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Klient za pośrednictwem udostępnionej Usługodawcy podstrony ma możliwość określenia parametrów oferowanej Usługi i dokonania jej Rezerwacji, dla skuteczności której konieczne jest prawidłowe wykonanie płatności przez Klienta na rzecz Usługodawcy.</li>
                <li>Z chwilą prawidłowego wykonania Płatności za Usługę, pomiędzy Usługodawcą a Klientem dochodzi do zawarcia umowy o udział w imprezie turystycznych.</li>
                <li>Klient ma możliwość dokonania częściowej Płatności za Usługę w formie zaliczki, która również będzie skutkować dokonaniem Rezerwacji. W przypadku wpłaty zaliczki, Klient zobowiązany jest do wpłaty pozostałej kwoty zgodnie z harmonogramem wpłat zaproponowanym przez Usługodawcę. Jeżeli Klient nie dokona wpłaty zgodnie z ww. harmonogramem, Usługodawca ma prawo zatrzymać pobraną zaliczkę. Postanowienia  § 8 Regulaminu stosuje się odpowiednio.</li>
                <li>Usługodawca zobowiązany jest potwierdzić Klientowi fakt skutecznego dokonania Rezerwacji wysyłając mu za pośrednictwem poczty elektronicznej wiadomość zawierającą szczegóły zakupionej Usługi.</li>
                <li>Usługodawca ma obowiązek niezwłocznie usunąć podstronę zawierającą ofertę Usługi, która stała się nieaktualna. W sytuacji, gdy Klient dokona Rezerwacji Usługi, która w rzeczywistości nie jest już dostępna na warunkach wskazanych w ofercie, na Usługodawcę może zostać nałożona kara pieniężna w wysokości 500 złotych, o którą pomniejszona zostanie kwota przelewu wskazanego w § 6 ust. 6 Regulaminu. Przed naliczeniem kary pieniężnej, Operator podejmie próbę kontaktu z Usługodawcą celem wyjaśnienia czy usunięcie oferty nastąpiło niezwłocznie czy z opóźnieniem.</li>
                <li>Realizacja Usługi nastąpi w sposób i na zasadach określonych w opisie Usługi zamieszczonym na podstronie Usługodawcy. Wszelkie zmiany sposobie w realizacji Usługi względem jej opisu, wymagają zgodnych ustaleń Usługodawcy i Klienta.</li>
                <li>Dokonując Rezerwacji Klient oświadcza, że brak jest przeciwwskazań do skorzystania przez niego lub przez osobę, na rzecz której dokonuje Rezerwacji, z Usług będącej przedmiotem Rezerwacji, w tym szczególności, że stan zdrowia lub inne okoliczności nie uniemożliwiają skorzystania z Usługi w sposób i na warunkach określonych przez Usługodawcę. W przypadku zatajenia przez Klienta informacji wymaganych przez Usługodawcę lub udzielenia informacji nieprawdziwych, wszelkie negatywne konsekwencje swoich działań i zaniechań ponosi wyłącznie Klient.</li>
                <li>W razie stwierdzenia, że Klient zataił informacje istotne dla Usługodawcy lub przekazał informacje nieprawdziwe, Usługodawca uprawniony jest do odmowy wykonania Usługi, z jednoczesnym zwrotem Płatności w pełnej wysokości. W przypadku stwierdzenia, że Klient zataił informacje istotne dla Usługodawcy lub przekazał informacje nieprawdziwe w trakcie wykonywania Usługi, Usługodawca uprawniony jest do zatrzymania Płatności w pełnej wysokości, niezależnie od ewentualnego niewykonania lub niewłaściwego wykonania Usługi, o ile nastąpi to z przyczyn związanych z zatajeniem informacji lub przekazaniem przez Klienta informacji nieprawdziwych.</li>
                <li>Dokonując Rezerwacji Klient ma świadomość, że w przypadku zatajenia informacji o stanie zdrowia lub innych okoliczności, które stwarzają zagrożenie dla niego samego lub innych osób korzystających z Usługi, albo uniemożliwiają zapewnienie właściwej opieki przez Usługodawcę, pomimo zachowania przez niego należytej staranności. Usługodawca każdorazowo wskazuje w opisie Usługi wszelkie informacje, do udzielenia których Klient jest zobowiązany. Informacje te, w tym także informacje o stanie zdrowia (dane wrażliwe), przekazywane są bezpośrednio Usługodawcy, z pominięcie Operatora, który nie przetwarza ich w żadnym zakresie.</li>
                <li>Rezerwacja Usługi trwa od chwili prawidłowego jej opłacenia przez Klienta do momentu, w którym miała nastąpić realizacja Usługi. Przełożenie Usługi na inny termin może zostać przewidziane przez Usługodawcę i ujęte w opisie Usługi, przy czym brak takiego zastrzeżenia oznacza, że Usługodawca możliwości takiej nie przewidział.</li>
                <li>Niewykorzystanie przez Klienta Usługi objętej Rezerwacją nie powoduje powstania po stronie Klienta roszczenia o zwrot dokonanej Płatności, chyba że Usługodawca w sposób jednoznaczny przewidział taką możliwość w opisie Usługi.</li>
                <li>Po dokonaniu Rezerwacji przez Klienta, a przed realizacją Usługi, Usługodawca ma prawo podnieść wartość Usługi o maksymalnie 8%. Podniesienie wartości Usługi, może nastąpić wyłącznie w sytuacji zwiększenia się kosztów ponoszonych przez Usługodawcę związanych m.in. z  przewozami pasażerskimi, wysokością podatków i opłat od usług turystycznych oraz zmianą kursów walut mających znaczenie dla oferowanej Usługi.</li>
                <li>Usługodawca ma obowiązek poinformować Klienta w formie wiadomości e-mail o zmianie całkowitej ceny Usługi nie później niż na 20 dni kalendarzowych przed datą wyjazdu. Powyższa wiadomość musi zawierać informację jaki czynnik spowodował podniesienie się kosztów realizacji Usługi oraz jaki była wartość tego czynnika przed dniem podniesienia ceny Usługi oraz po tym dniu.</li>
                <li>W przypadku gdy Klient dokona Rezerwacji, a wartość Usługi przed jej realizacją wzrośnie o więcej niż 8%, Usługodawca ma obowiązek niezwłocznie powiadomić Klienta o tej okoliczności w formie wiadomości e-mail. Klientowi w takiej sytuacji przysługuje prawo odstąpienia od umowy oraz zwrot wpłaconych środków w pełnej wysokości. Postanowienia § 8 ust. 2 oraz 7-8 Regulaminu stosuje się odpowiednio.</li>
                <li>Operator zapewnia dostęp do Serwisu oraz oferowanych w nim funkcjonalności i Usług siedem dni w tygodniu, dwadzieścia cztery godziny na dobę, z wyłączeniem przerw technicznych oraz powodowanych działaniem siły wyższej.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 5<br />
                KWESTIE TECHNICZNE
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Operator uprawniony jest do wprowadzania modyfikacji technicznych do Serwisu i sposobu korzystania z oferowanych w nim funkcjonalności.</li>
                <li>Operator przewiduje przerwy techniczne w funkcjonowaniu Serwisu, podczas których dostęp do niego może być ograniczony lub niemożliwy. Przerwa techniczna trwająca nie dłużej niż dwadzieścia cztery godziny nie uprawnia Usługodawców i/lub Klientów do wystąpienia z jakimkolwiek roszczeniem względem Operatora.</li>
                <li>W przypadku wystąpienia przerwy technicznej, której czas trwania przekroczy dwadzieścia cztery godziny, Operator na pisemny wniosek Usługodawcy przedłuży Okres abonamentowy o czas trwania przerwy technicznej.</li>
                <li>Operator nie ponosi odpowiedzialności niewykonanie lub nienależyte wykonanie ciążących na nim obowiązków z przyczyn wynikających z działań lub zaniechań osób trzecich oraz w przypadku wystąpienia siły wyższej.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 6<br />
                ROZLICZENIE PŁATNOŚCI ZA USŁUGI I ZASADY NALICZANIA PROWIZJI
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Obsługa Płatności realizowanych przez Klientów na rzecz Usługodawców z tytułu dokonania Rezerwacji dokonywana jest za pośrednictwem Systemu Płatności.</li>
                <li>Obsługa Opłat realizowanych przez Usługodawców na rzecz Operatora w celu rozpoczęcia lub przedłużenia Okresu Subskrypcji może być dokonana za pośrednictwem Systemu Płatności lub bezpośrednio na rachunek bankowy Operatora.</li>
                <li>Sposób korzystania z usług oferowanych przez System płatności, w tym w szczególności dokumentacja regulująca zasady świadczenia usług, której akceptacja jest niezbędna dla skorzystania z tych usług, znajduje się pod adresem <a href="www.przelewy24.pl/regulamin">www.przelewy24.pl/regulamin</a>.</li>
                <li>Wartość Płatności za poszczególne Usługi wynika z cen ustalanych przez Usługodawców.</li>
                <li>Ceny wszystkich Rezerwacji oferowanych w Serwisie, a także ceny Opłat ustalone przez Operatora, zawierają podatek od towarów i usług w aktualnie obowiązującej stawce.</li>
                <li>Płatności za Usługi zaksięgowane na rachunku bankowym Operatora w danym miesiącu, będą przekazywane zbiorczo, w formie jednego przelewu, do 3. dnia roboczego miesiąca następnego, na rachunki bankowe poszczególnych Usługodawców.</li>
                <li>Od każdej Płatności dokonanej przez Klienta, Operator pobiera od Usługodawcy prowizję, która będzie stanowiła wynagrodzenie Operatora. Stawka prowizji zależy od rodzaju Usługi:</li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>Usługi obejmujące obozy, kolonie i półkolonie – prowizja w wysokości 10%</li>
                    <li>Usługi obejmujące wycieczki szkolne – prowizja w wysokości 8%</li>
                </ol>
                <li>W celu rozliczenia prowizji, Operator zobowiązany jest do wystawienia faktury VAT do 5. dnia każdego miesiąca, na kwotę obejmującą zbiorczą wartość wszystkich prowizji należnych od poszczególnych Usługodawców za poprzedni miesiąc rozliczeniowy.</li>
                <li>W przypadku złożenia przez Klienta oświadczenia o odstąpieniu od umowy z Usługodawcą, Operator zobowiązany jest zwrócić Usługodawcy naliczoną prowizję w wysokości odpowiadającej wartości zwrotu jaki przysługuje Klientowi, zgodnie z § 8 ust. 3-6 Regulaminu. Operator dokona ww. zwrotu na rachunek bankowy wskazany przez Usługodawcę, w terminie 7 dni roboczych od daty zgłoszenia przez Usługodawcę faktu odstąpienia od umowy, w formie wiadomości e-mail.</li>
                <li>Opłaty dokonywane w celu opłacenia wybranego przez Usługodawcę Pakietu przekazywane są w całości na rzecz Operatora.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 7<br />
                ROZLICZENIA W SYTUACJI PROBLEMÓW TECHNICZNYCH
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>W sytuacji, w której Klient, pomimo prawidłowego dokonania Płatności, nie uzyska Rezerwacji, będzie mu przysługiwało uprawnienie do wszczęcia procedury reklamacyjnej, przy czym reklamacja winna zostać złożona bezpośrednio do Usługodawcy. W przypadku poinformowania Operatora o zaistniałej sytuacji, podejmie działania zmierzające do wsparcia Klienta w kontaktach z Usługodawcą i wyjaśnieniu wszelkich okoliczności sprawy.</li>
                <li>W sytuacji, w której pomimo prawidłowego dokonania przez Usługodawcę Opłaty, nie zostaną mu udostępnione funkcjonalności związane z wybranym Pakietem, będzie mu przysługiwało uprawnienie do wszczęcia procedury reklamacyjnej opisanej w § 9 Regulaminu.</li>
                <li>Jeśli przyczynę reklamacji stanowi działanie lub zaniechanie Systemu Płatności, Operator przekaże reklamację do rozpatrzenia temu podmiotowi. W takiej sytuacji reklamacja rozstrzygnięcia zostanie w oparciu o regulacje wewnętrzne Systemu Płatności, a o sposobie rozstrzygnięcia Klient zostanie poinformowany przez Operator lub system Płatności.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 8<br />
                ODSTĄPIENIE OD UMOWY PRZEZ KLIENTA
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Klientowi przysługuje prawo odstąpienia od umowy zawartej z Usługodawcą w terminie czternastu dni od dnia jej zawarcia, bez podawania przyczyny, o ile do dnia odstąpienia Usługa nie została wykonana w całości bądź w części.</li>
                <li>Odstąpienie od umowy może nastąpić poprzez złożenie stosownego oświadczenia Usługodawcy w formie pisemnej, poprzez wysłanie go listem poleconym na adres wskazany przez Usługodawcę, lub za pośrednictwem poczty elektronicznej. Za datę odstąpienia uważa się dzień, w którym Usługodawca uzyskał możliwość zapoznania się z ww. oświadczeniem.</li>
                <li>Klientowi, który złożył oświadczenie o odstąpieniu od umowy zawartej z Usługodawcą przysługuje zwrot wpłaconych środków w wysokości zależnej od rodzaju Usługi oraz ilości dni kalendarzowych pozostałych do dnia wyjazdu. Daty wyjazdu nie uwzględnia się w obliczeniach.</li>
                <li>Wysokość zwrotu obliczana jest na podstawie całkowitej wartości Usługi, jednak ostateczna kwota do wypłaty nie może być wyższa niż kwota uiszczona przez Klienta w momencie dokonania Rezerwacji.</li>
                <li>Wysokość zwrotu dla umów obejmujących Usługi jednodniowe (data wyjazdu jest taka sama jak data powrotu):</li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>100% - odstąpienie od umowy do 3 dni od dnia zawarcia umowy,</li>
                    <li>50% - odstąpienie od umowy do 14 dni pozostałych do dnia wyjazdu,</li>
                    <li>20% - odstąpienie od umowy od 13 do 7 dni pozostałych do dnia wyjazdu,</li>
                    <li>0% - odstąpienie od umowy poniżej 7 dni pozostałych do dnia wyjazdu.</li>
                </ol>
                <li>Wysokość zwrotu dla umów obejmujących Usługi wielodniowe: </li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>100% - odstąpienie od umowy do 7 dni od dnia zawarcia umowy,</li>
                    <li>80% - odstąpienie od umowy do 28 dni pozostałych do dnia wyjazdu,</li>
                    <li>50% - odstąpienie od umowy od 27 do 14 dni pozostałych do dnia wyjazdu,</li>
                    <li>20% - odstąpienie od umowy od 13 do 7 dni pozostałych do dnia wyjazdu,</li>
                    <li>0% - odstąpienie od umowy poniżej 7 dni pozostałych do dnia wyjazdu.</li>
                </ol>
                <li>Klientowi, który odstąpił od umowy, przysługuje względem Usługodawcy roszczenie o zwrot wpłaconych środków w wysokości wskazanej w § 8 ust. 4-6 Regulaminu. Usługodawca jest zobowiązany zwrócić środki w terminie 14 dni od dnia otrzymania wiadomości o odstąpieniu od umowy, bezpośrednio na rachunek bankowy wskazany przez Klienta bądź za pośrednictwem Operatora.</li>
                <li>Operator nie ponosi odpowiedzialności za działania lub zaniechania Usługodawcy w kwestii zwrotu środków należnych Klientowi. W przypadku poinformowania Operatora o sporze między Klientem a Usługodawcą w kwestii zwrotu wpłaconych środków, Operator podejmie działania polegające na udzieleniu stronom sporu informacji o ich prawach i obowiązkach, może również pośredniczyć w kontaktach między Usługodawcą a Klientem.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 9<br />
                ZAKOŃCZENIE ŚWIADCZENIA USŁUG
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Zakończenie obowiązywania umowy zawartej pomiędzy Operatorem a Usługodawcą może nastąpić: </li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>Po upływie czasu, przez jaki wybrany Pakiet pozwala Usługodawcy oferować Usługi w Serwisie.</li>
                    <li>Na skutek wypowiedzenia umowy przez Operatora lub Usługodawcę, z przyczyn i na warunkach opisanych w Regulaminie.</li>
                </ol>
                <li>Wypowiedzenie umowy przez Operatora może nastąpić w razie rażącego naruszenia postanowień Regulaminu lub naruszenia obowiązujących przepisów prawa, w tym w szczególności w przypadku: </li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>Niewywiązania lub nienależytego wywiązania się z obowiązku świadczenia Usług objętych Rezerwacją.</li>
                    <li>Świadczenia Usług w sposób niezgodny z ich opisem umieszczonym w Serwisie przez Usługodawcę.</li>
                    <li>Oferowania w Serwisie Usług niedopuszczalnych obowiązującymi przepisami prawa lub naruszających zasady współżycia społecznego.</li>
                    <li>Nieuzasadnionej odmowy dokonania zwrotu środków należnych Klientowi z tytułu odstąpienia od umowy na zasadach określonych w § 8 ust. 1-7 Regulaminu.</li>
                </ol>
                <li>Wypowiedzenie umowy przez Usługodawcę może nastąpić w przypadku: </li>
                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>Niezapewnienia przez Operatora prawidłowego dostępu do Serwisu z przyczyn przez Operatora zawinionych, przez czas dłuższy niż 72 godziny.</li>
                    <li>Zawinionego przez Operatora braku możliwości dokonywania Rezerwacji w Serwisie przez Klientów przez czas dłuższy niż 72 godziny.</li>
                </ol>
                <li>W przypadku wypowiedzenia umowy przez Operatora, Usługodawcy nie przysługuje roszczenie o zwrot środków za niewykorzystany czas obowiązywania wybranego Pakietu. W przypadku wypowiedzenia Umowy przez Usługodawcę z przyczyn, o których mowa w § 9 ust. 3 Regulaminu, Usługodawcy przysługuje roszczenie o zwrot środków za niewykorzystany czas obowiązywania wybranego Pakietu. </li>
            </ol>
            <h5 className="text-center mt-5">
                § 10<br />
                POSTĘPOWANIE REKLAMACYJNE
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Reklamacje związane z funkcjonowaniem Serwisu mogą być kierowane przez Usługodawców na adres e-mail kontakt@FajnyCzas.pl lub adres korespondencyjny Operatora: Plac Zwycięstwa 2, 90-312 Łódź.</li>
                <li>Operator ponosi odpowiedzialność za zapewnienie Usługodawcom stałego dostępu do funkcjonalności Serwisu wynikających z wybranego przez nich rodzaju Konta.</li>
                <li>Reklamacja może zostać złożona w terminie 14 dni od dnia wystąpienia zawinionego przez Operatora zdarzenia, które na skutek uniemożliwienia lub utrudnienia korzystania przez Usługodawcę z Serwisu spowodowało powstanie u niego szkody.</li>
                <li>Szkoda poniesiona przez Usługodawcę, za którą Operator może ponosić odpowiedzialność, obejmuje wyłączenie poniesionej straty, z wyłączeniem utraconych korzyści.</li>
                <li>Reklamacja, aby mogła zostać rozpatrzona przez Operatora, powinna zawierać co najmniej:</li>

                <ol className="pl-6 text-left" style={{ listStyleType: 'lower-alpha' }}>
                    <li>dane identyfikujące Usługodawcę, to jest: dokładny opis sytuacji będącej podstawą złożenia reklamacji.</li>
                    <li>imię i nazwisko oraz Login w przypadku osób fizycznych.</li>
                    <li>firmę wraz z siedzibą oraz Login w przypadku osób prawnych oraz jednostek organizacyjnych nieposiadających osobowości prawnej.</li>
                    <li>dokładny opis sytuacji będącej podstawą złożenia reklamacji.</li>
                </ol>
                <li>Reklamacja zostanie rozpatrzona w terminie 14 dni od dnia jej otrzymania, chyba że dla jej rzetelnego rozpatrzenia czas ten okaże się niewystarczający. W takie sytuacji Operator poinformuje Usługodawcę o przyczynach opóźnienia i planowanym czasie zakończenia postępowania reklamacyjnego. Wszelkie informacje dotyczące reklamacji, w tym także sposób jej rozstrzygnięcia, będą przekazywane Usługodawcy na wskazany przez niego podczas Rejestracji adres e-mail.</li>
                <li>Rozstrzygnięcie reklamacji może polegać na jej uwzględnieniu lub uznaniu za bezzasadną. W przypadku uwzględnienia reklamacji, Operator zaproponuje Usługodawcy działania naprawcze, które będą miały na celu zniwelowanie poniesionej szkody.</li>
                <li>W przypadku niezaakceptowania przez Usługodawcę zaproponowanego rozwiązania, będzie mógł on złożyć wniosek o ponowne rozpatrzenie reklamacji, w terminie 7 dni od dnia otrzymania rozstrzygnięcia reklamacji. W tej sytuacji Operator zajmie stanowisko w terminie 14 dni od dnia otrzymania wniosku o ponowne rozpatrzenie reklamacji, które będzie miało charakter ostateczny w toku postępowania reklamacyjnego.</li>
                <li>Usługodawca może dochodzić jakichkolwiek roszczeń od Operatora na zasadach ogólnych dopiero po wyczerpaniu procedury reklamacyjnej.</li>
                <li>Reklamacje dotyczące jakości Usług realizowanych przez Usługodawców powinny być składane bezpośrednio tym Usługodawcom, w sposób i na zasadach przez nich przewidzianych. Jednocześnie w przypadku poinformowania Operatora o zastrzeżeniach względem jakości Usług, Operator podejmie czynności w celu zweryfikowania zgłoszenia, a samą reklamację przekaże właściwemu Usługodawcy. Potwierdzenie zastrzeżeń wobec Usługodawcy nie powoduje powstania żadnego rodzaju odpowiedzialności Operatora względem Klienta, w tym w szczególności nie powoduje powstania odpowiedzialności odszkodowawczej, przy czym Operator podejmie starania, aby nie dopuścić do ponownego wystąpienia tego typu sytuacji.</li>
                <li>Reklamacje dotyczące Płatności dokonywanych przez Usługodawców na rzecz Operatora oraz przez Klientów na rzecz Usługodawców, będą rozpatrywane w oparciu o postanowienia niniejszego paragrafu, z zastrzeżeniem postanowień ujętych w § 7 Regulaminu.</li>
            </ol>
            <h5 className="text-center mt-5">
                § 11<br />
                POSTANOWIENIA KOŃCOWE
            </h5>
            <ol className="list-decimal pl-6 text-left mt-5">
                <li>Korzystanie z Serwisu jest jednoznaczne z akceptacją postanowień Regulaminu oraz innych dokumentów wewnętrznych stosowanych prze Operatora, z których treścią można zapoznać się w Serwisie.</li>
                <li>Wszelkie spory sądowe dotyczące roszczeń związanych z korzystaniem z Serwisu, będą rozstrzygane przez sąd powszechny miejscowo właściwy dla siedziby Operatora.</li>
                <li>W przypadku wprowadzenia zmian w treści Regulaminu, Usługodawcy i Klienci zostaną o tym fakcie zawiadomieni za pośrednictwem Serwisu lub poczty elektronicznej, poprzez wysłanie wiadomości na wskazane przez nich adresy e-mail, co najmniej 7 dni przed dniem wejścia zmian w życie. Operator informuje, iż wprowadzenie zmian w treści Regulaminu może być spowodowane także wymogami podmiotów współpracujących z Operatorem, w tym w szczególności w zakresie obsługi Płatności.</li>
            </ol>

        </div>
    )
}

export default RegulaminIndex
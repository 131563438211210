import {
  DescriptionList,
  Heading3,
  InputField
} from "../../../../components/FormCommons"
import diacritics from "diacritics"
import slugify from "slugify"
import { IMaskInput, IMaskMixin } from "react-imask"

import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useCompanyApi } from "../../events/api/useCompanyApi"
import {
  UploadablePhoto,
  buildPhoto
} from "../../products/forms/ProductSectionPhotos"
import { forwardRef, useCallback, useEffect, useState } from "react"
import { debounce } from "@mui/material"
import { MojeTextInput } from "../../../../components/ui/MojeTextInput"
import axios from "axios"

export const TextMaskPostCode = forwardRef(function TextMaskCustom(
  props: any,
  ref
) {
  const { onChange, ...other } = props
  console.log("TextMaskPostCode", TextMaskPostCode)
  return (
    <IMaskInput
      {...other}
      mask="00-000"
      definitions={{
        "#": /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})
const PostCodeInput: any = IMaskMixin(({ inputRef, ...props }: any) => {
  console.log("Msk prop", props)
  return (
    <MojeTextInput
      variant="outlined"
      name={props.name}
      id={props.id}
      label={props.label}
      fullWidth={props.fullWidth}
      value={props.value}
      forceValidEndorsement={props.forceValidEndorsement}
      inputRef={inputRef} // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
    />
  )
})

export const CompanySectionGeneralForm = ({
  register,
  watch,
  setValue,
  trigger
}: any) => {
  const [isCalculatingSlug, setIsCalculatingSlug] = useState(false)
  const handlePhotoUpdated = (photo) => {
    console.log("handlePhotoUpdated", photo)
    setValue("logo_image", buildPhoto(photo))
    return true
  }
  const postCodeVal = watch("postcode")
  const postCodeValid = /\d{2}-\d{3}/.test(postCodeVal)
  const postCodeReg = register("postcode")
  const fcWebsitePrefix = register("fc_website_prefix")
  const websiteUrlSuggestionWatch = watch("website_url_suggestion")
  const nameWatch = watch("name")
  const fcWebsiteWatch = watch("fc_website_prefix")
  const logoImageValue = watch("logo_image")
  console.log("fcWebsiteWatch", fcWebsiteWatch)

  const debouncedWebsite = useCallback(
    debounce(async (name) => {
      console.log("debouncedWebsite", websiteUrlSuggestionWatch, name)
      if (name && name.length > 0) {
        setIsCalculatingSlug(true)
        try {
          let url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/website/slug`
          var result = await axios({
            method: "POST",
            data: {
              name: name
            },
            url
          })
          console.log("Slug resukt", result)
          if (result.status === 200) {
            console.log("slug result", result.data.url)
            console.log("Setting value")
            setValue("fc_website_prefix", result.data.url)
          }
        } catch (err) {}
        setIsCalculatingSlug(false)
      }
    }, 1000),
    []
  )

  useEffect(() => {
    console.log("Name changed", nameWatch)

    if (nameWatch) {
      var normalized = diacritics.remove(
        slugify(nameWatch, {
          lower: true
        })
      )
      setValue("website_url_suggestion", normalized)
    } else {
      setValue("website_url_suggestion", "")
    }
  }, [nameWatch])

  useEffect(() => {
    console.log("websiteUrlSuggestionWatch", websiteUrlSuggestionWatch)
    debouncedWebsite(websiteUrlSuggestionWatch)
  }, [websiteUrlSuggestionWatch])

  console.log("Company section general", nameWatch)
  return (
    <>
      <div className="flex gap-4 items-center">
        <InputField
          className="w-1/2"
          label="NIP"
          {...register("nip")}
          autoComplete="off"
        />
        {/* <Button className="w-1/2" color="primary">
          Pobierz informacje z bazy CEIDG
        </Button> */}
      </div>
      <InputField
        label="Nazwa firmy*"
        {...register("name", {
          required: true
        })}
        autoComplete="off"
      />
      <InputField
        placeholder="Nazwa strony (link do strony)"
        {...register("website_url_suggestion", {
          required: true
        })}
        autoComplete="off"
      />

      {/* <div className="font-bold">Twoja darmowa strona fajnyczas.pl</div>
      <div className="my-2 flex flex-row">
        <div className=" text-lg">{fcWebsiteWatch}</div>
        <div className="font-bold text-lg mx-1">.fajnyczas.pl</div>
        <div>{isCalculatingSlug && <>Generujemy adres strony</>}</div>
      </div> */}

      <InputField
        label="Opis firmy (widoczny dla klientów)"
        {...register("description", {
          required: true
        })}
        autoComplete="off"
        multiline
        minRows={6}
      />

      <InputField label="Miasto*" {...register("city")} autoComplete="off" />
      <PostCodeInput
        name={postCodeReg.name}
        id={postCodeReg.id}
        error={postCodeReg.error}
        onBlur={postCodeReg.onBlur}
        value={postCodeVal}
        ref={postCodeReg.ref}
        mask="00-000"
        label="Kod pocztowy*"
        autoComplete="off"
        onAccept={(val) => {
          console.log("onAccept", val)
          setValue("postcode", val)
          console.log("trigger postcode")
          trigger("postcode")
          // postCodeReg.onChange(val)
        }}
        forceValidEndorsement={postCodeValid}
        fullWidth
      />
      <InputField
        label="Adres*"
        {...register("address_string")}
        autoComplete="off"
      />
      <Heading3>Logo firmy</Heading3>
      <div className="w-20 h-20 rounded-full my-2 bg-gray-100 cursor:pointer overflow-hidden">
        <UploadablePhoto
          onPhotoUploaded={handlePhotoUpdated}
          photo={logoImageValue}
        />
      </div>
    </>
  )
}

export const CompanySectionGeneralPreview = ({ values }: any) => {

  return (
    <div>
      <DescriptionList
        data={{
          "Nazwa firmy": values.name,
          NIP: values.nip,
          Adres: values.address_string,
          "Opis firmy": values.description,
          Miasto: values.city
        }}
      />
      <section className="my-8 px-4">
        <dt className="text-sm text-gray-500 font-medium mb-2">Logo Firmy</dt>
        {values.logo_image?.url ? (
          <dd className="mx-4">
            <img
              className="w-32 h-32 rounded-full object-cover"
              src={values.logo_image?.url}
              alt="Logo"
            />
          </dd>
        ) : (
          <div>Nie wybrano</div>
        )}
      </section>
    </div>
  )
}

export const CompanySectionGeneral = ({
  values,
  refetch,
  onSave
}: {
  onSave?: Function
  values: any
  refetch: Function
}) => {
  const { updateCompany } = useCompanyApi()
  const [val, setValue] = useState(values)

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var updateData: any = {
      id: data.id,
      nip: data.nip,
      city: data.city,
      postcode: data.postcode,
      address_string: data.address_string,
      logo_image: data.logo_image,
      fc_website_prefix: data.fc_website_prefix,
      description: data.description,
      name: data.name
    }

    console.log("updateData", updateData)
    if (onSave) {
      return onSave(data)
    }

    console.log("new company", data)
    return updateCompany(updateData)
  }

  const handleRefresh = () => {
    refetch()
  }

  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const update = (data: object) => {
    setValue(data)
  }
  return (
    <EditableFormSection
      title="Dane podstawowe"
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionGeneralForm values={val} {...builderProps} />
            )}
            {!builderProps.isEditing && (
              <CompanySectionGeneralPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import { Formik } from "formik"
import { useState } from "react"
import { useSnackbar } from "notistack"
import * as Yup from "yup"
import { AuthContextType, useAuthContext } from "../../contexts/auth-context"
import { Box } from "@mui/system"
import { Button, CircularProgress, Stack, TextField } from "@mui/material"
import { ConfirmationForm } from "./ConfirmationForm"
import { useAuth } from "wakacje-web/src/auth/AuthContext"
import { MojeTextInput } from "../../components/ui/MojeTextInput"
import { PasswordTooltip } from "./PasswordTooltip"

const STEP_REGISTER = "REGISTER"
const STEP_CONFIRM = "CONFIRM"

const formSchema = Yup.object({
  email: Yup.string().required("Wprowadź e-mail"),
  password: Yup.string()
    .required("Wprowadź nowe hasło")
    .min(
      8,
      "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę"
    ),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę"
  // )
  passwordCorrect: Yup.boolean().required().isTrue()
})

export const LoginByEmailForm = ({
  onClickResetPassword,
  loginMode
}: {
  onClickResetPassword: Function
  loginMode?: string
}) => {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [currentStep, setCurrentStep] = useState(STEP_REGISTER)
  const { enqueueSnackbar } = useSnackbar()
  const [signupParams, setSignUpParams] = useState<any>(undefined)
  const authContext: AuthContextType = useAuthContext()
  const [errorCode, setErrorCode] = useState()
  const firebaseAuth = useAuth()

  // const migrateUserFromCognitoToFirebase = async (signInRes, signupParams) => {
  //   console.log("migrateUserFromCognitoToFirebase", signInRes, signupParams)
  //   // Create firebase user
  //   // const newFirebaseParams = {
  //   //   signInRes.attributes.given_name,
  //   //   signInRes.attributes.family_name,
  //   // }
  //   var newFirebaseUser = await firebaseAuth.signup(
  //     signupParams.username,
  //     signupParams.password,
  //     signInRes.attributes.given_name,
  //     signInRes.attributes.family_name
  //   )

  //   console.log("newFirebaseUser", newFirebaseUser)

  //   const body = {
  //     firebase_id: newFirebaseUser.user?.uid,
  //     cognito_id: signInRes.username
  //   }
  //   var url = process.env.REACT_APP_WAKACJE_API_URL + "/v1.1/migrateCognitoUser"
  //   try {
  //     console.log("call migrateCognitoUser ", url, body)
  //     var res = await axios.post(url, body)
  //     console.log("migrate res", res)
  //     if (res.status === 200) {
  //       // Login as firebase
  //       console.log("Try login as firebase")
  //       var loginres = await firebaseAuth.login(
  //         signupParams.username,
  //         signupParams.password
  //       )
  //       console.log("Firebase login res", loginres)
  //       // Logout cognito
  //       Auth.signOut()
  //     }
  //   } catch (err) {
  //     console.error("Error migrating user")
  //   }
  // }

  const tryFirebaseLogin = async (_signUpParams) => {
    var res = await firebaseAuth.login(
      _signUpParams.username,
      _signUpParams.password
    )
    return res
  }

  const handleSubmit = async (
    values: any,
    { setSubmitting }: { setSubmitting: Function }
  ) => {
    // if (currentStep === STEP_CONFIRM) {
    //   return handleConfirm(values, { setSubmitting })
    // }
    const _signUpParams = {
      username: values.email.trim(),
      password: values.password.trim(),
      attributes: {}
    }

    try {
      setErrorMessage(undefined)
      setErrorCode(undefined)
      var firebaseLoginRes = await tryFirebaseLogin(_signUpParams)
      if (firebaseLoginRes?.user) {
        await authContext.refetchCurrentUser()
        enqueueSnackbar("Zalogowano!", { variant: "success" })
        return
      }
    } catch (err) {
      if (err.code) {
        setErrorCode(err.code)
      }
      if (err.code && err.code.toLowerCase() === "auth/user-not-found") {
        setErrorMessage("Ten email nie jest zarejestrowany w systemie")
      }
      if (err.code?.toLowerCase() === "auth/wrong-password") {
        setErrorMessage("Wprowadzone hasło jest niepoprawne")
      }
    }

    // try {
    //   setSubmitting(true)

    //   setErrorMessage(undefined)
    //   console.log("signIn", _signUpParams)
    //   const res = await Auth.signIn(_signUpParams)

    //   if (res.userSub && res.userConfirmed === false) {
    //     // we got user - redirect to confirmation page
    //     setSignUpParams(_signUpParams)
    //     setCurrentStep(STEP_CONFIRM)
    //     // setConfirmationCodeMedium(res.codeDeliveryDetails.DeliveryMedium)
    //   } else {
    //     // Already confirmed
    //     var signInRes = await Auth.signIn(
    //       _signUpParams.username,
    //       _signUpParams.password
    //     )
    //     console.log("signInRes", signInRes)

    //     await migrateUserFromCognitoToFirebase(signInRes, _signUpParams)

    //     authContext.refetchCurrentUser()

    //     await Auth.currentAuthenticatedUser()
    //     enqueueSnackbar("Zalogowano!", { variant: "success" })
    //   }
    // } catch (err: any) {
    //   setSubmitting(false)
    //   if (err.code === "InvalidParameterException") {
    //     setErrorMessage(
    //       "Błędne hasło - nowe hasło musi mieć minimum 8 znaków w tym "
    //     )
    //   } else if (err.code === "UsernameExistsException") {
    //     setErrorMessage("To konto już istnieje")
    //   } else {
    //     setErrorMessage(err.message)
    //   }
    // }
  }

  const loginData: any = {
    email: "",
    password: "",
    passwordCorrect: false
  }

  // if (isResetPassword) {
  //   return <ResetPasswordForm />
  // }
  return (
    <div>
      {currentStep === STEP_CONFIRM && (
        <ConfirmationForm signupParams={signupParams} />
      )}

      {currentStep === STEP_REGISTER && (
        <Formik
          initialValues={loginData}
          onSubmit={handleSubmit}
          validationSchema={formSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitForm
          }) => (
            <form
              onSubmit={handleSubmit}
              className="Form"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitForm()
                }
              }}
            >
              <Stack>
                <Box>
                  <TextField
                    label="E-mail"
                    variant="outlined"
                    size="small"
                    margin="normal"
                    onChange={handleChange}
                    value={values.email}
                    id="email"
                    name="email"
                    fullWidth
                  />
                </Box>
                <Box>
                  {currentStep === STEP_REGISTER && (
                    <PasswordTooltip setFieldValue={setFieldValue}>
                      <MojeTextInput
                        type="password"
                        placeholder="Hasło"
                        name="password"
                        size="small"
                        id="password"
                        required
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                        fullWidth
                        passwordField
                      />
                    </PasswordTooltip>
                  )}
                </Box>
                <Button
                  sx={{
                    fontWeight: "normal",
                    alignSelf: "flex-end",
                    marginBottom: 1
                  }}
                  variant="text"
                  onClick={() => {
                    onClickResetPassword()
                  }}
                >
                  Przypomnij hasło
                </Button>
                <div
                  style={{
                    marginTop: 5,
                    marginBottom: 20,
                    fontSize: "16px",
                    textAlign: "left",
                    color: "#EF4444",
                    lineHeight: "18px"
                  }}
                >
                  {/* {errors && (
                    <span className="alert alert-danger help-block">
                      {errors.password}
                    </span>
                  )} */}

                  {errorMessage && (
                    <div
                      style={{
                        textAlign: "center",
                        padding: 10,
                        height: 30,
                        color: "red"
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        submitForm()
                      }}
                      fullWidth
                    >
                      {isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Zaloguj się"
                      )}
                    </Button>
                  </Box>
                </div>
              </Stack>
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

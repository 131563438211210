import { FormHelperText, Heading3 } from "../../../../../components/FormCommons"

export const FormSectionContainer = ({
  children,
  sectionTitle,
  sectionSubtitle
}: {
  children: any
  sectionTitle?: string
  sectionSubtitle?: string
}) => {
  return (
    <div className="pb-4 mb-12 mt-4 ">
      {sectionTitle && <Heading3 id="h3-1">{sectionTitle}</Heading3>}
      {sectionSubtitle && (
        <div className="mb-4 mt-2">
          <FormHelperText>{sectionSubtitle}</FormHelperText>
        </div>
      )}
      {children}
    </div>
  )
}

import axios from "axios"
import useAxios from "axios-hooks"

export const useArticleApi = () => {
  const createArticle = async (data: any) => {
    var url = process.env.REACT_APP_WAKACJE_HTTP_API_URL + "/blog/v1.0/articles"

    var res = await axios.post(url, data)
    return res.data
  }

  const updateArticle = async (data: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/blog/v1.0/articles/${data.id}`
    var res = await axios.post(url, data)
    return res.data
  }

  const runCommand = async (command: any) => {
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + `/blog/v1.0/articles/${command.aggregateId}`
    var res = await axios.post(url, { command })
    return res.data
  }

  const listArticles = async (query = {}, pagination: any) => {
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/blog/v1.0/articles?${searchParams}`
      console.log('@@@url w super',url)
    var res = await axios.get(url)
    return res.data
  }
  return {
    createArticle,
    updateArticle,
    listArticles,
    runCommand
  }
}

export const useArticle = (id: string) => {
  var url = process.env.REACT_APP_WAKACJE_HTTP_API_URL + `/blog/v1.0/articles/${id}`

  const [{ data, loading, error }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch, error }
}

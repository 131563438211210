export const curatorContainer: any = {
	display: 'flex',
	flexDirection: 'column',
};

export const titleBlock = {
	display: 'flex',
	width: '100%',
	background: 'rgba(5, 89, 232, 0.03)',
	borderRadius: '8px',
	marginBottom: '32px',

	'& > span': {
		margin: '16px',
		fontSize: '20px',
		lineHeight: '28px',
		fontWeight: 600,
	},
};

export const infoBlockWrapper: any = {
	display: 'flex',
	width: '100%',
	flexDirection: {xs: 'column', sm: 'column', md: 'row'},
	justifyContent: 'space-between',
	padding: {xs: '0 10px 60px 10px'},
};

export const infoText: any = {
	display: 'flex',
	width: '50%',
	flexDirection: 'column',
	color: '#707070',
	marginBottom:{xs:'10px' , sm:'0 0 30px 0', md:'0'},

	'& > span': {
		fontSize: '18px',
		margin: '2px 0',
		color: '#0B0B0B',
	},
};
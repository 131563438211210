import { Box, Grid, Container } from "@mui/material"
import { WakacjeChip, WakacjeChipVariant } from "../../components/WakacjeChip"
import { WakacjeInfoCard } from "../../components/WakacjeInfoCard"
import {
  Heading2,
  Subtitle,
  SectionTitle,
  LabeledField,
} from "../../components/FormCommons"

export const SandboxIndex = () => {
  return (
    <Container maxWidth="xl">
      <Box py={4}>
        <WakacjeChip variant={WakacjeChipVariant.GREEN} label="Demo1" />
        <WakacjeChip variant={WakacjeChipVariant.GREY} label="Demo1" />
        <WakacjeInfoCard label="Label" value="15/20" />
        <Box width={700} height={700}>
          <Box>Form components (sections)</Box>
          <Heading2>Heading</Heading2>
          <Subtitle>Subtitle</Subtitle>
          <SectionTitle>Section title</SectionTitle>
          LabeledField:
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LabeledField label="Field Label" value="Field value" />
            </Grid>
            <Grid item xs={6}>
              <LabeledField label="Use grid " value="For layout" />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

import { Box } from "@mui/system"
import { TripDetailsView } from "../../../../trip/components/TripDetailsView"

export const TripTabEdit = ({ trip, refetch }: { trip: any; refetch: any }) => {
  return (
    <Box>
      <TripDetailsView trip={trip} refetch={refetch} />
    </Box>
  )
}

export const CheckIcon = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.82936 10.9846L0.931152 4.58457L1.88325 3.70124L7.82936 9.2179L17.6647 0.0595703L18.6258 0.942904L7.82936 10.9846Z"
        fill={fill ?? "white"}
      />
    </svg>
  )
}

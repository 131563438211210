function HumanIcon() {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45703 6.76664H7.7987C8.6987 6.09164 9.25703 5.03331 9.25703 3.88331C9.25703 1.86664 7.5737 0.224976 5.50703 0.224976C3.44036 0.224976 1.7487 1.86664 1.7487 3.88331C1.7487 5.04164 2.30703 6.09164 3.20703 6.76664H2.54036C1.39036 6.76664 0.457031 7.67498 0.457031 8.79998V16.1166V17.3333H1.70703H9.28203H10.532V16.1166V8.79998C10.5404 7.68331 9.5987 6.76664 8.45703 6.76664ZM3.15703 4.73331C3.14036 4.68331 3.1237 4.62498 3.10703 4.56664C3.08203 4.49164 3.05703 4.41664 3.04036 4.33331C3.0237 4.21664 3.00703 4.09164 3.00703 3.96664C3.00703 3.94164 2.9987 3.92498 2.9987 3.89998C2.9987 3.89998 2.9987 3.89998 2.9987 3.89164V3.88331C2.9987 2.53331 4.11536 1.44164 5.4987 1.44164C6.88203 1.44164 7.9987 2.53331 7.9987 3.88331V3.89164C7.9987 3.89164 7.9987 3.89164 7.9987 3.89998C7.9987 3.92498 7.99036 3.94164 7.99036 3.96664C7.98203 4.09164 7.9737 4.21664 7.9487 4.33331C7.93203 4.41664 7.90703 4.49164 7.88203 4.57498C7.86536 4.63331 7.8487 4.68331 7.83203 4.74164C7.7987 4.84164 7.7487 4.93331 7.6987 5.02498C7.68203 5.04998 7.6737 5.07498 7.65703 5.09998C7.23203 5.84164 6.4237 6.34998 5.49036 6.34998C4.55703 6.34998 3.75703 5.82498 3.33203 5.08331C3.31536 5.06664 3.2987 5.04164 3.29036 5.01664C3.24036 4.92498 3.1987 4.83331 3.15703 4.73331ZM9.29036 16.1166H1.70703V8.79998C1.70703 8.34998 2.08203 7.98331 2.54036 7.98331H8.4487C8.90703 7.98331 9.28203 8.34998 9.28203 8.79998V16.1166H9.29036Z"
      />
    </svg>
  )
}

export default HumanIcon

import axios from "axios"
import useAxios from "axios-hooks"
import { useState } from "react"

export const usePaymentCommand = (): [Function, boolean] => {
  const [isRunning, setIsRunning] = useState(false)

  const runCommand = async (command) => {
    setIsRunning(true)
    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/payments/new`
    try {
      var res = await axios.post(url, { command })
      setIsRunning(false)
      return res.data
    } catch (err) {
      setIsRunning(false)
      throw err
    }
  }

  return [runCommand, isRunning]
}
export const usePayment = (id: string) => {
  var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/payments/${id}`
  const [{ data, loading }, refetch] = useAxios(url, { useCache: false })
  return { loading, data, refetch }
}

export const usePayments = () => {
  const list = async (query = {}, pagination: any) => {
    let searchParams = new URLSearchParams(query)
    var url =
      process.env.REACT_APP_WAKACJE_API_URL + `/v1/payments?${searchParams}`
    var res = await axios.get(url)
    return res.data
  }

  return { list }
}

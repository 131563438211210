import Table from "../../../components/table/Table"
import { OptionButton } from "../../../pages/organizer/places/OptionButton"

export const SchoolTripBookingsTable = ({ data, product }) => {
  var columns: any = [
    {
      header: "Nazwa Wycieczki",
      cell: (row: any) => <>{row.product?.name}</>,
      sort: true
    },
    {
      header: "Termin",
      cell: (row: any) => (
        <>
          {row.start_date} - {row.end_date}
        </>
      ),
      sort: true
    },
    {
      header: "Wiek",
      cell: (row: any) => (
        <>
          {row.min_age && row.max_age ? `${row.min_age} - ${row.max_age}` : ""}
        </>
      ),
      sort: true
    },
    {
      header: "Organizator wycieczki",
      cell: (row: any) => <>{row.user_name}</>,
      sort: true
    },
    {
      header: "Uczestnicy",
      cell: (row: any) => <>{row.participants_count}</>,
      sort: true
    },
    {
      header: "Cena/ 1os.",
      cell: (row: any) => <></>,
      sort: true
    },
    {
      header: "Suma wplat",
      cell: (row: any) => <></>,
      sort: true
    },
    {
      header: "Opcje",
      cell: (row: any) => (
        <>
          <OptionButton
            row={row}
            options={["edit"]}
            editUrl={`/products/${product.id}/bookings/${row.id}`}
          />
        </>
      ),
      sort: true
    },
    {
      header: "Type",
      cell: (row: any) => <>{row.type}</>,
      sort: true
    }
  ]

  const sorted = data?.data
    ? data.data.filter((b) => b.type === "quotation")
    : []

  return (
    <div>
      <Table
        columns={columns}
        // listApi={listApi}
        rows={sorted}
        showExpandColumn
        rowLinkBuilder={(row: any) =>
          `/products/${product.id}/bookings/${row.id}`
        }
      />
    </div>
  )
}

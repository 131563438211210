import { useTranslation } from "react-i18next"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "../../../components/WakacjeChip"

export const GroupTypeChip = () => {
  const { t } = useTranslation()

  return (
    <WakacjeChip
      variant={WakacjeChipVariant.BLUE}
      label={t("ui.groups.category_chip", "NOCLEGI DLA GRUP")}
    />
  )
}

import { CampFeesDiscount } from "./CampFeesDiscount"
import { CampFeesBase } from "./CampFeesBase"
import { CampFeesFirstMinute } from "./CampFeesFirstMinute"
import { CampFeesLastMinute } from "./CampFeesLastMinute"

export const CampFees = ({ control, productCurrency }) => {
  return (
    <>
      <CampFeesBase control={control} productCurrency={productCurrency} />

      <CampFeesFirstMinute
        control={control}
        productCurrency={productCurrency}
      />

      <CampFeesLastMinute control={control} productCurrency={productCurrency} />

      <CampFeesDiscount control={control} productCurrency={productCurrency} />
    </>
  )
}

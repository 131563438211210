export const docsSectionWrapper = {
	display:'flex',
	width:'100%',
	justifyContent:'space-between',
	margin:'20px 0',
} ;

export const infoDocsContainer = {
	display:'flex',

} ;

export const infoDocsText:any = {
	display:'flex',
	flexDirection:'column',
	fontWeight:'bold',
	fontSize:{xs:'14px' , sm:'1rem'},

	'& > span' :{
		fontWeight:400,
		color:'#7E7E7E',
	}
} ;

export const iconButtonAction = {
	color: '#042675',
	width:'48px',
	height: '48px',
} ;
import { useTranslation } from "react-i18next"
import { InputField } from "../../../../../components/FormCommons"

function ItemSearch({ setSearch }) {
  const { t } = useTranslation()

  return (
    <div className="mt-10">
      <InputField
        label={t("ui.attractions.search", "Szukaj")}
        autoComplete="off"
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
    </div>
  )
}

export default ItemSearch

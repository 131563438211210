import { Box } from '@mui/system'
import { ApiTable } from '../../../components/table/ApiTable'
import axios from 'axios'
import { FormattedDate, FormattedTime } from 'react-intl'
import Toolbar from '../Toolbar/Toolbar'
import { useSearchText } from '../useSearchText'
import { ProductTypeChip } from '../../../components/events/ProductTypeChip'
import { Link } from 'react-router-dom'
import { InquirySuperConfirmationPopup } from '../../../components/InquirySuperConfirmationPopup'
import { useState } from 'react'

const generateProductLink = product => {
  return `/${product?.company?.fc_website_prefix}/${product.slug ?? product.id}`
}

const SuperInquiriesTable = ({
  queryParams,
  setOpenParticipant,
  setPopupData
}) => {
  const listInquiries = async (query = {}) => {
    let searchParams = new URLSearchParams({
      ...query,
      view: 'super'
    })

    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/inquiries/v1?${searchParams}`

    var res = await axios.get(url)

    //adjusting types naming from backend to frontend convention, issue to be addressed later
    if (res?.data?.data) {
      for (var inquiry of res.data.data) {
        if (inquiry.type === 'SCHOOLTRIP') {
          inquiry.type = 'wycieczka-szkolna'
        } else if (inquiry.type === 'GROUPACCOMODATION') {
          inquiry.type = 'nocleg-dla-grup'
        }
      }
    }

    return res.data
  }

  const columns = [
    {
      header: 'Data wysłania',
      field: 'user',
      cell: (row: any) => (
        <>
          <div className='text-xs leading-none'>
            {row.created_at && (
              <div>
                <FormattedDate value={row.created_at} />{' '}
                <FormattedTime value={row.created_at} />
              </div>
            )}
          </div>
        </>
      )
    },
    {
      header: 'Użytkownik',
      field: 'user',
      cell: (row: any) => {
        var person = row?.user
        return (
          <>
            {person && (
              <div
                onClick={() => {
                  setPopupData(row)
                  setOpenParticipant(true)
                }}
              >
                <p className='text-base font-bold underline text-fc-accent-3 hover:no-underline'>
                  {person?.name}
                </p>
                <p className='text-xs mt-1'>{row.user_mail}</p>
                <p className='text-xs mt-1'>{row.user_phone}</p>
              </div>
            )}
          </>
        )
      }
    },
    {
      header: 'Firma',
      field: 'company',
      cell: (row: any) => (
        <div className='text-xs'>{row.company.name ?? '-'}</div>
      )
    },
    {
      header: 'Nazwa wydarzenia',
      field: 'company',
      cell: (row: any) => (
        <>
          <div className='leading-none pb-2'>
            {row.company?.fc_website_prefix ? (
              <Link
                to={`https://www.fajnyczas.pl/${row.company.fc_website_prefix}/${row.product_id}`}
              >
                <div className='font-semibold text-xs mb-3 underline text-fc-accent-3 hover:no-underline'>
                  {row.product?.name}
                </div>
              </Link>
            ) : (
              <div className='font-semibold text-xs mb-3 text-fc-accent-3'>
                {row.product?.name}
              </div>
            )}

            <ProductTypeChip type={row.type} />
          </div>
        </>
      )
    },
    {
      header: 'Termin',
      field: 'user',
      align: 'center',
      cell: (row: any) => (
        <>
          <div className='leading-none font-bold text-xs'>
            <p className='whitespace-nowrap'>
              <FormattedDate value={row.start_date} />
              {' - '} <FormattedDate value={row.end_date} />
            </p>
          </div>
        </>
      )
    },
    {
      header: 'Liczba osób',
      field: 'user',
      align: 'center',
      cell: (row: any) => (
        <div className='text-sm font-bold'>{row.participants_count}</div>
      )
    },

    {
      header: 'Miejsce wyjazdu',
      field: 'company',
      align: 'center',
      cell: (row: any) => (
        <>
          <div className='leading-none text-xs flex justify-center'>
            {row?.pickup_place}
          </div>
          <div className='leading-none text-xs flex justify-center'>
            {row?.pickup_cost}
          </div>
        </>
      )
    },
    {
      header: 'Treść zapytania',
      field: 'user',
      width: 200,
      cell: (row: any) => (
        <div className='text-sm font-bold max-h-[60px] overflow-hidden text-ellipsis '>
          {row.content}
        </div>
      )
    }
  ]
  return (
    <ApiTable
      columns={columns}
      listApi={listInquiries}
      showExpandColumn
      //   rowLinkBuilder={(row: any) => `/super/chats`}
    ></ApiTable>
  )
}

export function SuperAdminInquiries () {
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const [openParticipant, setOpenParticipant] = useState(false)
  const [popupData, setPopupData] = useState(null)

  return (
    <div className='mt-10'>
      <Box width='100%'>
        <Toolbar setSearchTextDebounced={setSearchTextDebounced} api={[]} />
        <SuperInquiriesTable
          queryParams={queryParams}
          setOpenParticipant={setOpenParticipant}
          setPopupData={setPopupData}
        />
        {openParticipant && (
          <InquirySuperConfirmationPopup
            data={popupData}
            setOpenParticipant={setOpenParticipant}
            open={openParticipant}
          />
        )}
      </Box>
    </div>
  )
}

import { ProductTypes, ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { DescriptionList, LabeledField } from "../../../components/FormCommons"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { useProductApi } from "../../organizer/events/api/useProductApi"
import { TripForm } from "./TripForm"
import { useTranslation } from "react-i18next"
import { useCurrency } from "../../../hooks/useCurrency"
import { useProductUtils } from "../../../hooks/useProductUtils"

// export const ProductSectionFoodForm = ({
//   register,
//   values,
//   setValue,
//   control,
// }: any) => {
//   return (
//     <>
//       <Heading3 id="h3-1">Wyżywienie</Heading3>
//       <Controller
//         name="food_type"
//         control={control}
//         render={({
//           field,
//           fieldState: { invalid, isTouched, isDirty, error },
//           formState,
//         }) => (
//           <RadioGroup {...field}>
//             <FormControlLabel
//               label="Tak"
//               value="tak"
//               control={<Radio value="tak" />}
//             />
//             <FormControlLabel
//               label="Nie"
//               value="nie"
//               control={<Radio value="nie" />}
//             />
//           </RadioGroup>
//         )}
//       />
//       <Typography>Podaj krótki opis co gotujesz</Typography>

//       <InputField
//         label="Opis"
//         multiline
//         rows={2}
//         {...register("food_description")}
//       />
//     </>
//   )
// }

export const TripDetailsPreview = ({ values }: any) => {
  const { t } = useTranslation()

  const { selectedCurrency } = useCurrency()
  const { formatPriceInCents } = useProductUtils()

  const baseFields = {
    [t('ui.trips.name_info', 'Nazwa turnusu:')]: values?.name,
    [t('ui.trips.date_info', 'Data:')]: `${ProductUtils.formatDate(
      values.start_date
    )} - ${ProductUtils.formatDate(values.end_date)}`,
    [t('ui.trips.available_spots_info', 'Ilość dostępnych miejsc:')]:
      values.available_spots,
    [t('ui.trips.base_price_info', 'Cena bazowa:')]:
      // ProductUtils.formatPriceInCents(values.add_term_payments_base),
      `${formatPriceInCents(
        values.full_price,
        values.currency
      )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`,
    [t("ui.trips.base_price_date_info", "Termin płatności:")]:
      // ProductUtils.formatDate(values.add_term_payments_base_date),
      ProductUtils.formatDate(values.payment_due_date),
    [t(
      "ui.trips.first_minute_info",
      "Cena first minute:"
    )]: `${formatPriceInCents(
      values.add_term_payments_first_minute,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`,
    [t(
      "ui.trips.first_minute_date_info",
      "Oferta First minute obowiązuje do:"
    )]: ProductUtils.formatDate(values.add_term_payments_first_minute_date),
    [t(
      "ui.trips.last_minute_info",
      "Cena last minute:"
    )]: `${formatPriceInCents(
      values.add_term_payments_last_minute,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`,
    [t("ui.trips.last_minute_date_info", "Oferta Last minute obowiązuje od:")]:
      ProductUtils.formatDate(values.add_term_payments_last_minute_date),
    [t("ui.trips.deposit_info", "Zaliczka:")]: `${formatPriceInCents(
      values.advance_price,
      values.currency
    )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
  }
  /**
   * TODO: For now, I'm substituting full_price and payment_due_date for the base items
   * Since we're not changing them on the client app yet, this is to prevent any issues with
   * displaying and adding prices and dates for currently active camps/colonies.
   */

  return (
    <div>
      <DescriptionList data={baseFields} direction='row' />
      {/* <div className="flex flex-row  px-8">
        <div className="flex-1">
          <LabeledField label={"Wiek Od"} value={values.min_age ?? "-"} />
        </div>
        <div className="flex-1">
          <LabeledField label={"Wiek Do"} value={values.max_age ?? "-"} />
        </div>
      </div> */}
      {/* <div className="flex flex-row px-4">
        <div className="flex-1">
          <LabeledField
            label={"Ilość minimum"}
            value={values.min_count ?? "-"}
          />
        </div>
        <div className="flex-1">
          <LabeledField
            label={"Ilość maksimum"}
            value={values.max_count ?? "-"}
          />
        </div>
      </div> */}
      {/* <div className="px-8">
        <LabeledField
          label={"Informacje o zniżkach"}
          value={values.discounts_description ?? "-"}
        />
      </div> */}
    </div>
  )
}

export const TripDetailsView = ({ trip, refetch, watch }: any) => {
  const { updateTrip } = useProductApi()
  const { t } = useTranslation()

  const saveHandler = async (data: any) => {
    console.log('Save handler', data)
    var productData: any = {
      ...trip,
      ...data
    }
    console.log('new trip data', data)
    return updateTrip(data.product_id, productData)
  }
  const handleRefresh = () => {
    refetch()
  }

  console.log("[PRODUCT 2 test", trip)
  return (
    <EditableFormSection
      title={t('ui.trips.term', 'Termin')}
      values={trip}
      onSave={saveHandler}
      onSaved={handleRefresh}
      align='left'
    >
      {(builderProps: any) => {
        console.log('Builder props', builderProps)
        return (
          <div>
            {builderProps.isEditing && (
              <TripForm
                values={trip}
                {...builderProps}
                productType={trip.product_type}
                productCurrency={trip?.currency}
              />
            )}
            {!builderProps.isEditing && <TripDetailsPreview values={trip} />}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import { Button } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useCompanyContext } from "../../../contexts/company-context"
import { CompanySummary } from "./CompanySummary"

export const MyCompanyAbout = (props: any) => {
  const merchantContext = useCompanyContext()
  const navigate = useNavigate()

  useEffect(() => {
    merchantContext.refreshHomeData()
  }, [])
  
  return (
    <div className="py-8">
      {!merchantContext.company && (
        <Box>
          <div className="mb-8 text-xl">Nie masz jeszcze firmy</div>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={(event: any) => {
                navigate("/company/register")
              }}
            >
              Ustaw dane firmy
            </Button>
          </Box>
        </Box>
      )}
      {merchantContext.company && (
        <>
          <Box>
            <CompanySummary
              values={merchantContext.company}
              refetch={merchantContext.refreshHomeData}
            />
          </Box>
        </>
      )}
    </div>
  )
}

import axios from "axios"
import useAxios from "axios-hooks"

export const useCompanyApi = () => {
  const createCompany = async (data: any) => {
    console.log("createCompany", data, process.env)
    var url = process.env.REACT_APP_WAKACJE_API_URL + "/company/register"

    var res = await axios.post(url, data)
    return res.data
  }

  const runCommand = async (command: any) => {
    console.log("runCommand", command)
    if (!command?.aggregateId) {
      throw new Error("No company id")
    }
    var url =
      process.env.REACT_APP_WAKACJE_API_URL +
      `/v1/companies/command/${command.aggregateId}`
    var res = await axios.post(url, command)
    return res.data
  }

  const updateCompany = async (data: any) => {
    if (!data?.id) {
      throw new Error("No company id")
    }
    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/companies/${data.id}`
    var res = await axios.post(url, data)
    return res.data
  }

  const listCompanies = async (data?: any) => {   
    console.log("listCompanies", data)
    var url = process.env.REACT_APP_WAKACJE_API_URL + "/v1/companies"
    if (data) {
      url += "?" + new URLSearchParams(data).toString()
    }
    var res = await axios.get(url)
    return res.data
  }

  return { createCompany, updateCompany, listCompanies, runCommand }
}

export const useCompany = (id: string) => {
  var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/companies/${id}`
  const [{ data, loading }, refetch] = useAxios(url)
  return { loading, company: data, refetch }
}

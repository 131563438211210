import React from "react"

function CloseIcon({ fill = "black", width = "26", height = "25" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0862 12.0294L22.7622 3.30547C23.0613 3.00464 23.06 2.50464 22.7591 2.20547C22.4583 1.9063 21.9583 1.90768 21.6591 2.2085L12.9832 10.9325L4.25921 2.2565C3.95838 1.95733 3.45838 1.95871 3.15921 2.25953C2.86004 2.56036 2.86142 3.06036 3.16225 3.35953L11.8862 12.0355L3.21024 20.7595C2.91107 21.0603 2.91245 21.5603 3.21328 21.8595C3.41355 21.9589 3.61383 22.0584 3.81383 22.0578C4.01383 22.0573 4.21355 21.9567 4.31327 21.8564L12.9892 13.1325L21.7132 21.8084C21.8135 21.9082 22.0138 22.0076 22.2138 22.007C22.4138 22.0065 22.6135 21.9059 22.7132 21.8057C23.0124 21.5048 23.011 21.0048 22.7102 20.7057L14.0862 12.0294Z"
        fill={fill}
      />
    </svg>
  )
}

export default CloseIcon

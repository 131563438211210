import * as React from "react"
import { OpenCropProps } from "./SelectPhotoGalleryPopup"

interface IItemPhotoProps {
  url: string
  onPhotoSelected: React.Dispatch<React.SetStateAction<OpenCropProps>>
}

const ItemPhoto: React.FunctionComponent<IItemPhotoProps> = ({
  url,
  onPhotoSelected,
}) => {
  const handleOpenCrop = (e: React.MouseEvent<HTMLImageElement>) => {
    onPhotoSelected({
      open: true,
      url: e.currentTarget.src,
    })
  }

  return (
    <div className="cursor-pointer rounded-lg overflow-hidden shadow-lg">
      <img
        src={url}
        alt={url}
        className="w-full h-[107px] object-cover"
        onClick={handleOpenCrop}
      />
    </div>
  )
}

export default ItemPhoto

import axios from "axios"
import { useRef, useState } from "react"
import { ApiTable, ApiTableRefType } from "../../../components/table/ApiTable"
import { useTranslation } from "react-i18next"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { useCompanyContext } from "../../../contexts/company-context"
import { FormattedDate, FormattedTime } from "react-intl"
import { OptionButton } from "../places/OptionButton"
import { useProductApi } from "../events/api/useProductApi"
import { useCurrency } from "../../../hooks/useCurrency"
import { useProductUtils } from "../../../hooks/useProductUtils"

export const IndividualSchoolTripTable = ({
  queryParams
}: {
  queryParams: any
}) => {
  const { t } = useTranslation()

  const { selectedCurrency } = useCurrency()

  const { formatPriceInCents } = useProductUtils()

  var columns = [
    {
      header: t("ui.booking.column2", "Data aktualizacji"),
      cell: (row: any) => (
        <>
          <div className="text-xs leading-none">
            {row.created_at && (
              <div>
                <FormattedDate value={row.created_at} />{" "}
                <FormattedTime value={row.created_at} />
              </div>
            )}
          </div>
        </>
      ),
      sort: false,
      field: "updated_at"
    },
    {
      header: "Opiekun wycieczki",
      cell: (row: any) => (
        <>
          <div className="text-xs leading-none">
            {row.dedicated_trip_caregiver_user?.name}
          </div>
        </>
      ),
      sort: false
    },
    {
      header: "Nazwa oferty",
      cell: (row: any) => (
        <>
          <div className="leading-none pb-2">
            <p className="font-semibold text-xs mb-3">{row.name}</p>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: t("ui.booking.column6", "Termin"),
      cell: (row: any) => (
        <>
          {row.trips?.[0]?.start_date && (
            <div className="leading-none font-bold text-xs">
              <p className="whitespace-nowrap">{row.trips?.[0]?.start_date}-</p>
              <p className="whitespace-nowrap">-{row.trips?.[0]?.end_date}</p>
            </div>
          )}
        </>
      ),
      sort: false
    },
    {
      header: t("ui.booking.price_per_pax", "Cena za 1 os"),
      cell: (row: any) => (
        <>
          <div className="text-xs leading-none">
            <span className="whitespace-nowrap">
              {row.trips?.[0]?.full_price
                ? `${formatPriceInCents(
                    row.trips?.[0]?.full_price,
                    row.trips?.[0]?.currency
                  )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
                : ""}
            </span>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: "Suma wpłat",
      cell: (row: any) => (
        <>
          <div className="text-xs leading-none">
            <span className="whitespace-nowrap">
              {row.advance_payment_price
                ? `${formatPriceInCents(
                    row.paid_amount ?? 0 / row.participants_count,
                    row.trips?.[0]?.currency
                  )} ${ProductUtils.getCurrencyCode(selectedCurrency.currency)}`
                : ""}
            </span>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: t("ui.school_trips.options", "Opcje"),
      cell: (row: any) => {
        return (
          <div>
            <OptionButton
              row={row}
              options={["edit", "remove", "preview", "openChat"]}
              chatUrl={row?.slug?.split("/")[1]}
              editUrl={`/products/schooltrip/individual-offer/${row.slug}/${row.id}/details`}
              handleDuplicate={null}
              handleDelete={handleDeleteOffer}
              rowType="offer"
              setPopupAddDate={null}
            />
          </div>
        )
      }
    }
  ]
  const tableRef = useRef<ApiTableRefType>()
  const [selectedRow, setSelectedRow] = useState(null)
  const companyContext = useCompanyContext()
  const { runProductCommand } = useProductApi()
  const refetch = () => {
    console.log("refetch", tableRef.current)
    if (tableRef.current) {
      tableRef.current.refetch()
    }
  }
  const handleDeleteOffer = async (product) => {
    const command = {
      type: "Product.Delete",
      aggregateId: product.id,
      payload: {}
    }
    try {
      await runProductCommand(command)
      refetch()
    } catch (err) {
      console.error("Error archiving product")
    }
  }

  const lqueryParams = {
    ...queryParams,
    company_id: companyContext.company?.id,
    type: "dedykowana-wycieczka-szkolna"
  }

  const { listProducts } = useProductApi()

  return (
    <>
      <ApiTable
        ref={tableRef}
        columns={columns}
        listApi={listProducts}
        queryParams={lqueryParams}
        showExpandColumn
        // nestedTableComponent={BookingsNestedTable}
        rowDetailsRenderer={() => <></>}
        selectedRow={selectedRow}
        initialSort={{ direction: "desc", field: "created_at" }}
      />
      {/* {popupData && (
        <DialogParticipant
          open={!!popupData}
          participant={popupData?.participant}
          booking={popupData?.booking}
          onClose={() => setPopupData(undefined)}
        />
      )} */}
    </>
  )
}

import { CircularProgress } from "@mui/material"
import useAxios from "axios-hooks"
import { useParams } from "react-router-dom"
import { ChatBookingCard } from "./ChatBookingCard"
import { BookingSideSummaryWidget } from "wakacje-web/src/shared/booking/BookingSideSummaryWidget"
import { BookingsMessagesWidgetCompany } from "wakacje-web/src/shared/booking/BookingMessagesWidget"
import { BackButton } from "../../components/commons/button"
import { useState } from "react"
import { CreateOfferForm } from "../../domains/offer/components/CreateOfferForm/CreateOfferForm"
import { useAuth } from "wakacje-web/src/auth/AuthContext"

const MessageContentRenderer = ({ message, booking, onSaved }) => {
  return (
    <div>
      {message.type === "NEW_BOOKING" && (
        <ChatBookingCard booking={booking} onSaved={onSaved} />
      )}
    </div>
  )
}
const BookingPageInner = ({ bookingId }) => {
  var url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL +
    `/bookings/v2.0/bff/admin/bookings/${bookingId}`
  const [{ data, loading }, refresh] = useAxios(url, { useCache: false })
  const [isCreatingOffer, setIsCreatingOffer] = useState(false)
  const { auth } = useAuth()

  const handleSaved = () => {
    refresh()
  }

  return (
    <div className="fixed top-[64px] bottom-0 ">
      <div className="h-full">
        {loading && (
          <div className="p-16 text-center">
            <CircularProgress />
          </div>
        )}

        {!loading && data && (
          <>
            <div className="flex flex-row h-full">
              {/* Chat column */}
              <div className="flex-1 h-full flex flex-col w-[700px]">
                <div className="flex-1 ml-6 h-full flex flex-col">
                  <div className="py-7">
                    <BackButton destination="messages" />
                  </div>

                  {isCreatingOffer && (
                    <div className="overflow-y-auto h-[auto] pr-5">
                      <CreateOfferForm booking={data} />
                    </div>
                  )}

                  {!isCreatingOffer && (
                    <div className="flex-1 min-h-[0px]">
                      <BookingsMessagesWidgetCompany
                        booking={data}
                        mode="company"
                        accessToken={auth?.currentUser?.accessToken}
                        chatHash={data.id}
                        detailsRenderer={({ message }) => (
                          <MessageContentRenderer
                            onSaved={handleSaved}
                            message={message}
                            booking={data}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Widget column */}
              <div className="w-[296px] h-full border-l-2 ml-8 px-8 bg-gray border-[#D9D9D9]">
                <BookingSideSummaryWidget
                  booking={data}
                  company={data?.company}
                  onSaved={() => {}}
                  mode="company"
                  showParticipants
                  showPrices
                  onCreateOffer={() => {
                    console.log("onCreateOffer")
                    setIsCreatingOffer(true)
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export const BookingPage = () => {
  const params = useParams()
  return (
    <div className=" p-8 pt-[64px]">
      <BookingPageInner bookingId={params.id} />
    </div>
  )
}

import React from "react"

export const ArrowIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7615_266769)">
        <path
          d="M3.08203 13.97L10.7487 6.30336L18.4987 13.97"
          stroke="#0C48D7"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7615_266769">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.75 20.1367) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

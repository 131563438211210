import React, { useRef, useState } from "react"
import Modal from "@mui/material/Modal"
import { OpenCropProps } from "./SelectPhotoGalleryPopup"
import ReactCrop, { Crop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { useTranslation } from "react-i18next"

interface ISelectPhotoCropProps {
  openCrop: OpenCropProps
  removePhotoSelected: (props: any) => void
  cropRatio?: number
  onCancel?: Function
  onCropped?: Function
}
function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  let croppedImage = new File([u8arr], filename, { type: mime })
  return croppedImage
}

const SelectPhotoCrop: React.FunctionComponent<ISelectPhotoCropProps> = ({
  openCrop,
  removePhotoSelected,
  cropRatio,
  onCancel,
  onCropped
}) => {
  const [crop, setCrop] = useState<Crop>()
  console.log("SelectPhotoCrop", openCrop)
  const { t } = useTranslation()

  const imageRef = useRef<any>()
  const handleSelect = () => {
    if (crop) {
      // const scaleX = imageRef.current.naturalWidth / imageRef.current.width
      // const scaleY = imageRef.current.naturalHeight / imageRef.current.height
      const canvas = document.createElement("canvas")
      canvas.width = (crop.width * imageRef.current.naturalWidth) / 100
      canvas.height = (crop.height * imageRef.current.naturalHeight) / 100

      const ctx = canvas.getContext("2d")
      ctx.drawImage(
        imageRef.current as any,
        (crop.x * imageRef.current.naturalWidth) / 100,
        (crop.y * imageRef.current.naturalHeight) / 100,
        (crop.width * imageRef.current.naturalWidth) / 100,
        (crop.height * imageRef.current.naturalHeight) / 100,
        0,
        0,
        (crop.width * imageRef.current.naturalWidth) / 100,
        (crop.height * imageRef.current.naturalHeight) / 100
      )

      const reader = new FileReader()
      canvas.toBlob((blob) => {
        reader.readAsDataURL(blob)
        reader.onloadend = async () => {
          var file = await dataURLtoFile(reader.result, "cropped.jpg")
          onCropped(file)
        }
      })
    }
  }
  return (
    <Modal
      open={!!openCrop}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ outline: "none" }}
      disableAutoFocus={true}
      className="w-full h-full flex justify-center items-center"
    >
      <div className="w-[784px] h-auto bg-white font-lato rounded-md py-6">
        <p className="text-center font-lato">
          Dopasuj wybraną grafikę (przesuń i wybierz).{" "}
        </p>
        <div className="w-full mt-5 h-[350px] bg-black/60 flex justify-center items-center py-3">
          {Boolean(openCrop?.url) && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              aspect={cropRatio}
              className="w-[80%] mx-auto h-full object-cover"
            >
              <img
                ref={imageRef}
                alt="Crop me"
                src={openCrop.url}
                className="w-full h-full object-cover"
              />
            </ReactCrop>
          )}
        </div>
        <div className="w-full h-[50px] pt-3">
          <div className="w-[80%] h-full mx-auto flex justify-between items-center">
            <p
              className="text-fc-accent-3 cursor-pointer"
              onClick={(e: any) => onCancel()}
            >
              {t("back", "Powrót")}
            </p>
            <button
              className="text-white px-5 py-1 bg-fc-accent-3 rounded-lg"
              onClick={handleSelect}
            >
              {t("select", "Wybierz")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SelectPhotoCrop

import { Button, debounce } from "@mui/material"
import { Box } from "@mui/system"

import Table from "../../../../components/table/Table"
import PrintIcon from "../../../../components/icons/PrintIcon"
import { useState } from "react"
import { SearchBoxWithIcon } from "../../../../components/forms/SeachBoxWithIcon"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "wakacje-web/src/shared/components/WakacjeChip"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { ParticipantPopup } from "./ParticipantPopup"
import { FormattedDate } from "react-intl"
import { BookingsTable } from "../../booking/BookingsTable"

const columns = [
  {
    header: <div className="text-center">Uczestnik</div>,
    cell: (row: any) => (
      <>
        <p className="text-xl">
          {row.first_name} {row.last_name}
        </p>

        <p className="text-xs text-[#222222] mt-1">
          {ProductUtils.formatDateShort(row.birth_date)} |{" "}
          {ProductUtils.calculateAge(row.birth_date)}
        </p>
      </>
    )
  },
  {
    header: <div className="text-center">Opiekun</div>,
    cell: (row: any) => (
      <>
        {row.parent ? (
          <>
            {row.parent?.first_name} {row.parent.last_name}
          </>
        ) : (
          <span>brak</span>
        )}
      </>
    )
  },
  {
    header: <div className="text-center">Status</div>,
    cell: (row: any) => (
      <div className="text-center">
        <WakacjeChip label={row.status} variant={WakacjeChipVariant.GREEN} />
      </div>
    )
  },
  {
    header: <div className="text-center">Nr rezerwacji</div>,
    cell: (row: any) => (
      <div className="text-center text-xs">{row.booking_id}</div>
    )
  },
  {
    header: <div className="text-center">Cena / 1 osobę</div>,
    cell: (row: any) => (
      <div className="text-xs text-center">
        {ProductUtils.formatPrice(row.base_price_per_person)}
      </div>
    )
  },
  {
    header: <div className="text-center">Suma opłat</div>,
    cell: (row: any) => (
      <div className="text-xs text-center">
        {ProductUtils.formatPrice(row.received_payments_amount)}
      </div>
    )
  },
  {
    header: <div className="text-center">Data zgłoszenia</div>,
    cell: (row: any) => (
      <div className="text-xs text-center">
        <FormattedDate value={row.created_at} />
      </div>
    )
  },
  // {
  //   header: <div className="text-center">Czat z opiekunem</div>,
  //   cell: (row: any) => (
  //     <div className="text-xs text-center">
  //       <Button variant="outlined" sx={{ borderRadius: 28 }}>
  //         Otwórz czat
  //       </Button>
  //     </div>
  //   )
  // }

  // {
  //   header: "Data rezerwacji",
  //   cell: (row: any) => {
  //     return (
  //       <div>
  //         {row.created_at && (
  //           <FormattedDate
  //             value={row.created_at}
  //             year="2-digit"
  //             month="2-digit"
  //             day="2-digit"
  //           />
  //         )}
  //       </div>
  //     )
  //   },
  //   align: "center"
  // },
  // {
  //   header: "Płatność",
  //   cell: (row: any) => {
  //     return (
  //       <div>
  //         {row.received_payments_amount > 0
  //           ? ProductUtils.formatPriceInCents(row.received_payments_amount)
  //           : " 0"}
  //         {" / "}
  //         {ProductUtils.formatPriceInCents(row.total_price)}
  //       </div>
  //     )
  //   },
  //   align: "center"
  // }
  // {
  //   header: "Czat z opiekunem",
  //   cell: (row: any) => {
  //     return (
  //       <Link to={`/bookings/${row.booking_id}`}>
  //         <Button
  //           variant="outlined"
  //           startIcon={<MailOutlineIcon />}
  //           color="menteBrandBlue"
  //           sx={{ borderRadius: 28 }}
  //         >
  //           Napisz
  //         </Button>
  //       </Link>
  //     )
  //   },
  //   align: "center"
  // },
  // {
  //   header: "Telefon do opiekuna",
  //   cell: (row: any) => {
  //     return (
  //       <Button
  //         variant="outlined"
  //         startIcon={<LocalPhoneOutlinedIcon />}
  //         color="menteBrandBlue"
  //         sx={{ borderRadius: 28 }}
  //       >
  //         <a href={`tel:${row.parent.phone}`}>{row.parent.phone}</a>
  //       </Button>
  //     )
  //   },
  //   align: "center"
  // },
  // {
  //   header: "",
  //   cell: (row: any) => {
  //     return <KidPopup row={row} />
  //   }
  // }
]

export const TripParticipantsTable = ({ trip }: { trip: any }) => {
  console.log("TripParticipantsTable", trip)
  console.log()

  const handleDataLoaded = (data: any) => {
    console.log("handleDataLoaded", data)
  }

  const [searchText, setSearchText] = useState("")
  const [selectedRow, setSelectedRow] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const setSearchTextDebounced = debounce(async (name) => {
    setSearchText(name)
  }, 500)

  const handleRowClick = (row: any) => {
    console.log("row", row)
    setSelectedRow(row)
    setOpenPopup(true)
  }

  var bookingsQueryParams = {
    trip_id: trip.id,
    query: searchText
  }
  return (
    <div>
      <Box my={4} display="flex" flexDirection="row">
        <Box>
          <div className="flex items-center gap-6">
            <div
              className="cursor-pointer"
              onClick={() => {
                console.log("clicked print")
              }}
            >
              <PrintIcon />
            </div>
            <p className="font-bold text-[18px]">
              Liczba uczestników: {trip.bookings_stats?.participants_count}
              {trip.available_spots ? ` / ${trip.available_spots}` : ""}
            </p>
          </div>
        </Box>
        <Box flex={1} />
        <Box>
          <SearchBoxWithIcon setSearchTextDebounced={setSearchTextDebounced} />
        </Box>
      </Box>

      <BookingsTable queryParams={bookingsQueryParams} layout="participants" />
      <ParticipantPopup
        participant={selectedRow}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      />
    </div>
  )
}

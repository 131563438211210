import { useNavigate } from "react-router-dom"
import Link from "@mui/material/Link"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { useTranslation } from "react-i18next"

export function BackButton(props) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const backUrl = () => {
    if (props.destination) {
      console.log("backUrl", props)
      let destination = props.destination
      switch (destination) {
        case "polkolonie":
          return `/products/daycamp`
        case "kolonie":
          return `/products/camp`
        case "wycieczka-szkolna":
          return `/products/schooltrip`
        case "messages":
          return `/messages`
        case "inquiries":
          return "/inquiries"
        case "noclegi-dla-grup":
          return "/products/groups"
        default:
          destination = "trip"
      }
      return `/products/${destination}`
    }
    return "/products/camp"
  }

  return (
    <Link
      component="button"
      onClick={() => navigate(backUrl())}
      underline="none"
      color="unset"
      fontWeight="semibold"
      display="flex"
      sx={{
        alignContent: "center",
        justifyContent: "center"
      }}
      alignItems="center"
      {...props}
    >
      <div className="">
        <ArrowBackIosIcon
          className="text-xl"
          fontSize="medium"
          style={{ fontSize: 16 }}
        />
      </div>

      {props.label ? (
        props.label
      ) : (
        <>
          {props.destination === "messages"
            ? t("ui.messages.all_messages", "Wszystkie wiadomości")
            : t("back", "Powrót")}
        </>
      )}
    </Link>
  )
}

export const CheckCircleIcon = (props: any) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.02152 16.4258C4.0825 16.4258 0.0581055 12.7591 0.0581055 8.25911C0.0581055 3.75911 4.0825 0.175781 9.02152 0.175781C13.9605 0.175781 17.9849 3.84245 17.9849 8.34245C17.9849 12.8424 13.9605 16.4258 9.02152 16.4258ZM9.02152 1.42578C4.90567 1.42578 1.43006 4.50911 1.43006 8.34245C1.43006 12.1758 4.8142 15.2591 9.02152 15.2591C13.2288 15.2591 16.613 12.1758 16.613 8.34245C16.613 4.50911 13.1374 1.42578 9.02152 1.42578Z"
        fill="currentcolor"
      />
      <path
        d="M8.19814 10.8424L5.08838 8.09245L6.09448 7.17578L8.19814 9.09245L11.9481 5.67578L12.9542 6.50911L8.19814 10.8424Z"
        fill="currentcolor"
      />
    </svg>
  )
}

import { CameraAlt } from "@mui/icons-material"
import ContentEditable from "react-contenteditable"
import QRCode from "react-qr-code"
import {
  PosterPageTemplate,
  PosterTemplate,
  PosterTemplateElement,
  PosterTemplateElementType,
  fieldDefinitions,
  orangeTemplate
} from "./PosterUtils"
import { useEffect, useRef } from "react"
import { MojeTextInput } from "../../components/ui/MojeTextInput"
import { UploadablePhoto } from "../organizer/products/forms/ProductSectionPhotos"

const ElementEditableText = ({
  value,
  onChange,
  code,
  editable
}: {
  value?: string
  onChange: Function
  code: string
  editable: boolean
}) => {
  const contentEditable = useRef()

  const handleChange = (event) => {
    console.log("handleChange", event)
    if (fieldDefinitions[code]?.max) {
      console.log(
        "Max len",
        fieldDefinitions[code]?.max,
        event.target.value.length
      )
      if (event.target.value.length > fieldDefinitions[code]?.max) {
        console.log("too many characters")
        console.log(event)
        // event.preventDefault()
        return
      }
    }
    onChange(event.target.value)
  }
  if (fieldDefinitions[code]?.max) {
  }
  return (
    <ContentEditable
      innerRef={contentEditable}
      html={value}
      contentEditable={editable}
      //   html={this.state.html} // innerHTML of the editable div
      disabled={false} // use true to disable editing
      onChange={handleChange} // handle innerHTML change
    />
  )
}

const ElementText = ({ code, value }: { value?: string; code: string }) => {
  console.log("ElementText", value, code)
  return <div>{value}</div>
}

const ElementQR = ({
  code,
  value,
  scale
}: {
  value?: string
  code: string
  scale: number
}) => {
  if (fieldDefinitions[code]?.max) {
  }
  return (
    <div className="w-full h-full flex items-center justify-center">
      <QRCode value={value} size={13 * scale} />
    </div>
  )
}

const ElEditablePhoto = ({
  value,
  onChange,
  editable
}: {
  value?: string
  onChange: Function
  editable: boolean
}) => {
  const handlePhotoUpdated = (photo) => {
    console.log("photu uploaded", photo)
    onChange(photo)
  }
  console.log("ElEditablePhoto", value)
  return (
    <UploadablePhoto
      useGallery
      disabled={!editable}
      onPhotoUploaded={handlePhotoUpdated}
      photo={value && typeof value === "string" ? { url: value } : value}
    >
      <div
        className="bg-gray-100 flex items-center 
        justify-center h-full w-full overflow-hidden
        background-cover
        "
      >
        {/* <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center"> */}
        {editable && <CameraAlt />}
        {/* </div> */}
      </div>
    </UploadablePhoto>
  )
}
const PosterElementDiv = ({
  def,
  scale,
  values,
  setField,
  editable
}: {
  def: PosterTemplateElement
  scale: number
  values: any
  setField: Function
  editable: boolean
}) => {
  var style: any = {}

  if (def.position === "absolute") {
    style.position = "absolute"
    style.left = def.left * scale
    style.width = def.width * scale
    style.top = def.top * scale
    style.height = def.height * scale
  }
  if (def.height) {
    style.height = def.height * scale
  }
  // console.log("stype", style)

  var content: any

  if (def.type === PosterTemplateElementType.EDITABLE_TEXT) {
    content = (
      <ElementEditableText
        code={def.code}
        value={values[def.code]}
        onChange={(newValue) => {
          setField(def.code, newValue)
        }}
        editable={editable}
      />
    )
  } else if (
    def.type === PosterTemplateElementType.SELECTABLE_PHOTO ||
    def.type === PosterTemplateElementType.PHOTO
  ) {
    content = (
      <ElEditablePhoto
        value={values[def.code]}
        editable={
          editable && def.type === PosterTemplateElementType.SELECTABLE_PHOTO
        }
        onChange={(newValue) => {
          setField(def.code, newValue)
        }}
      />
    )
  } else if (def.type === PosterTemplateElementType.TEXT) {
    content = (
      <ElementText code={def.code} value={def.content ?? values[def.code]} />
    )
  } else if (def.type === PosterTemplateElementType.QR) {
    content = (
      <ElementQR code={def.code} value={values[def.code]} scale={scale} />
    )
  }
  if (def.style) {
    const scallableAttributes = [
      "fontSize",
      "padding",
      "paddingTop",
      "paddingBottom",
      "paddingLeft",
      "paddingRight",
      "marginTop",
      "marginBottom",
      "marginLeft",
      "marginRight",
      "height",
      "width",
      "top",
      "bottom",
      "left",
      "right"
    ]
    style = { ...style, ...def.style }
    scallableAttributes.forEach((key: string) => {
      if (def.style[key]) {
        style[key] = def.style[key] * scale
      }
    })
  }
  // console.log("style", style)
  var isEditable =
    def.type === PosterTemplateElementType.EDITABLE_TEXT ||
    def.type === PosterTemplateElementType.SELECTABLE_PHOTO

  style.fontFamily = "Poetsen One"
  return (
    <div
      id={def.code ? "template-" + def.code : ""}
      style={style}
      className={`
        ${
          isEditable && editable
            ? ` 
            cursor-pointer
            border-2
            border-transparent
            border-dashed
            hover:border-indigo-500 `
            : " "
        }
        ${def.className ?? ""}
        `}
    >
      {content}
      {def.children &&
        def.children.map((ch, index) => (
          <PosterElementDiv
            key={index}
            def={ch}
            values={values}
            scale={scale}
            setField={setField}
            editable={editable}
          />
        ))}
    </div>
  )
}

const EditorFields = ({ values, setField, editable }) => {
  const fields = { ...fieldDefinitions }
  return (
    <div>
      {Object.keys(fields).map((key, index) => (
        <div className="mb-3" key={index}>
          <div className="text-sm font-bold">
            {fields[key].label}
            {fields[key].max && <>{" (max " + fields[key].max + " znaków)"}</>}
          </div>
          <div>
            {editable ? (
              <MojeTextInput
                fullWidth
                value={values[key]}
                placeholder={fields[key].placeholder}
                onChange={(event) => setField(key, event.target.value)}
              />
            ) : (
              values[key]
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export const PosterPage = ({
  template,
  scale,
  values,
  setField,
  editable
}: {
  template: PosterPageTemplate
  scale: number
  values: any
  setField: Function
  editable: boolean
}) => {
  return (
    <div
      className="shadow-xl bg-white relative overflow-hidden flex flex-col"
      style={{
        width: template.width * scale,
        height: template.height * scale
      }}
    >
      {template.elements.map((el) => (
        <PosterElementDiv
          def={el}
          scale={scale}
          values={values}
          setField={setField}
          editable={editable}
        />
      ))}
    </div>
  )
}
export const PosterEditor = ({
  posterTemplate,
  onSetField,
  values,
  editable
}: {
  posterTemplate: PosterTemplate
  onSetField: Function
  values: any
  editable: boolean
}) => {
  var ratio = 5

  var template: PosterTemplate = orangeTemplate
  // var template = posterTemplate

  useEffect(() => {
    // console.log("Poster Editor - load font")
    // const loadFont = (url) => {
    //   let link = document.createElement("link")
    //   link.href = url
    //   link.rel = "stylesheet"
    //   link.type = "text/css"
    //   document.head.appendChild(link)
    // }
    // loadFont("/fonts/PoetsenOne-Regular.ttf")
    // loadFont("/fonts/RifficFree-Bold.ttf")
  })

  return (
    <div className="off-bg-slate-100 p-8 w-full">
      <div className="flex flex-row">
        <div id="leftPart">
          <PosterPage
            template={template.poster}
            scale={ratio}
            values={values}
            setField={onSetField}
            editable={editable}
          />
          <div className="text-center text-sm font-bold">Plakat</div>
        </div>
        <div
          id="rightPart flex-1 flex flex-col items-center"
          className="border-l pl-8 mx-8"
        >
          <div
            className="shadow bg-white relative overflow-hidden "
            style={{
              width: (template.brochureFront.width * ratio) / 2,
              height: (template.brochureFront.height * ratio) / 2
            }}
          >
            {template.brochureFront.elements.map((el) => (
              <PosterElementDiv
                def={el}
                scale={ratio / 2}
                values={values}
                setField={onSetField}
                editable={editable}
              />
            ))}
          </div>
          <div className="mb-8 text-center text-sm font-bold">Przód ulotki</div>

          <div
            className="shadow bg-white relative  overflow-hidden  "
            style={{
              width: (template.brochureBack.width * ratio) / 2,
              height: (template.brochureBack.height * ratio) / 2
            }}
          >
            {template.brochureBack.elements.map((el) => (
              <PosterElementDiv
                def={el}
                scale={ratio / 2}
                values={values}
                setField={onSetField}
                editable={editable}
              />
            ))}
          </div>
          <div className="mb-8 text-center  text-sm font-bold">Tył ulotki</div>
        </div>
      </div>
      <EditorFields values={values} setField={onSetField} editable={editable} />
    </div>
  )
}

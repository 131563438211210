import { useContext } from "react"
import { ExchangeRatesContext } from "../contexts/ExchangeRatesProvider"
import { CurrencyContext } from "../contexts/CurrencyContext"

export const useProductUtils = () => {
  const { selectedCurrency } = useContext(CurrencyContext)
  const exchangeRates = useContext(ExchangeRatesContext)

  const formatPriceWithSpace = (price) => {
    if (typeof price !== "number") {
      price = parseFloat(price)
    }

    let priceStr = price
      .toFixed(2)
      .replace(/(\.[0-9]*[1-9])0+$/, "$1")
      .replace(/\.00$/, "")

    const parts = priceStr.split(".")
    const integerPart = parts[0]
    const decimalPart = parts[1] ? "." + parts[1] : ""

    const integerWithSpaces = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ")

    return integerWithSpaces + decimalPart
  }

  const formatPriceInCents = (value, productCurrency) => {
    if (value === undefined || value === null) {
      return undefined
    }

    let convertedValue = value

    if (productCurrency !== selectedCurrency.currency && exchangeRates) {
      const productCurrencyRate = parseFloat(exchangeRates[productCurrency])
      const selectedCurrencyRate = parseFloat(
        exchangeRates[selectedCurrency.currency]
      )

      if (productCurrencyRate && selectedCurrencyRate) {
        const exchangeRate = selectedCurrencyRate / productCurrencyRate

        const priceInProductCurrency = value / 100

        const convertedPrice = priceInProductCurrency * exchangeRate
        convertedValue = convertedPrice * 100
      } else {
        console.error(
          "Exchange rates for currencies not found:",
          productCurrency,
          selectedCurrency.currency
        )
      }
    }

    const priceInSelectedCurrency = convertedValue / 100

    const formattedPrice = formatPriceWithSpace(priceInSelectedCurrency)

    return formattedPrice
  }

  return {
    formatPriceInCents
  }
}

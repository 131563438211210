import { Box } from "@mui/system"
import { ProductGeneralInfo } from "./forms/ProductGeneralInfo"
import {
  ProductTypes,
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"
import { ProductSectionSuperAdminView } from "./forms/ProductSectionSuperAdmin"
import { useAuthContext } from "../../../contexts/auth-context"
import { ProductSectionScheduleView } from "./forms/ProductSectionSchedule"
import { ProductSectionPlacesView } from "./forms/ProductSectionPlace/ProductSectionPlace"
import { ProductSectionPricingView } from "./forms/ProductSectionPricing"
import { SchoolTripSectionLocationView } from "./forms/SchoolTripSectionLocation"
import { ProductSectionLocationView } from "./forms/ProductSectionLocation"
import { ProductSectionDescription } from "../groups/ProductSectionDescription"
import { ProductSectionAccommodation } from "../groups/ProductSectionAccommodation"
import { ProductSectionPrice } from "../groups/ProductSectionPrice"

export const ProductSummaryPage = ({
  product,
  refetch
}: {
  product: any
  refetch: Function
}) => {
  const authContext = useAuthContext()
  return (
    <Box
      sx={{
        display: "flex",
        gap: "3rem",
        justifyContent: "flex-start"
      }}
    >
      <Box width={500}>
        {product.type === ProductTypes.GROUPS ? (
          <>
            <ProductSectionDescription values={product} refetch={refetch} />
            <ProductSectionAccommodation values={product} refetch={refetch} />
            <ProductSectionLocationView values={product} refetch={refetch} />
            <ProductSectionPrice values={product} refetch={refetch} />
            <ProductSectionPlacesView values={product} refetch={refetch} />
          </>
        ) : (
          <>
            <ProductGeneralInfo values={product} refetch={refetch} />
            {/* {product.type === PRODUCT_TYPE_SCHOOL_TRIP.value ? ( */}
            {product.type === ProductTypes.DAYCAMP ? (
              <ProductSectionLocationView values={product} refetch={refetch} />
            ) : (
              <SchoolTripSectionLocationView
                values={product}
                refetch={refetch}
              />
            )}
            {/* ) : (
        <ProductSectionLocationView values={product} refetch={refetch} />
      )} */}
            <ProductSectionScheduleView values={product} refetch={refetch} />
            {product.type === PRODUCT_TYPE_SCHOOL_TRIP.value && (
              <>
                <ProductSectionPlacesView values={product} refetch={refetch} />
              </>
            )}
            {/* New feature from Nov 22 - add Places for CAMP */}
            {(product.type === PRODUCT_TYPE_CAMP.value ||
              product.type === PRODUCT_TYPE_DAYCAMP.value) && (
              <>
                <ProductSectionPlacesView values={product} refetch={refetch} />
              </>
            )}
            <ProductSectionPricingView values={product} refetch={refetch} />
            {/* {product.type !== PRODUCT_TYPE_SCHOOL_TRIP.value && (
        <ProductSectionAmenitiesView values={product} refetch={refetch} />
      )} */}
          </>
        )}
      </Box>
      <Box width={500}>
        {authContext.isSuperAdmin && (
          <ProductSectionSuperAdminView values={product} refetch={refetch} />
        )}
      </Box>
    </Box>
  )
}

import { useNavigate } from "react-router-dom"
import { useCompanyContext } from "../../contexts/company-context"
import { MojeButton } from "../ui/MojeButton"
import { useAuthContext } from "../../contexts/auth-context"

export const ServiceBlocked = () => {
  const navigate = useNavigate()
  return (
    <div className="text-center flex flex-col items-center pt-10 h-full justify-center">
      <div className="text-2xl my-2 text-center">
        Usługa niedostępna. Twoje dostępy są zablokowane.
      </div>
      <div className="text-2xl my-2 text-center">
        Przejdź do zakładki Pakiet, aby aktywować usługę.
      </div>
      <img src="/assets/illustrations/key.svg" alt="Illustration" />
      <div className="my-6">
        <MojeButton
          label="Wybierz pakiet"
          color="primary"
          variant="contained"
          onClick={(event: any) => {
            /* please update link destination */
            navigate("/about/pakiety/wybierz")
          }}
        />
      </div>
    </div>
  )
}

export const withServiceBlocked = (Component) => {
  return () => {
    const companyContext = useCompanyContext()
    const authContext = useAuthContext()

    if (authContext.isSuperAdmin || companyContext.isActiveMerchant) {
      return <Component />
    }

    return (
      <div>
        <ServiceBlocked />
      </div>
    )
  }
}

import { FcTabPanel, FcTabsHeader } from "../../../components/FcTabs/FcTabs"

import { useTabs } from "../useTabs"
import { SuperAdminBookings } from "./SuperAdminBookings"
import { SuperAdminInquiries } from "./SuperAdminInquiries"
import { SuperAdminMessages } from "./SuperAdminMessages"

export const SuperAdminReservations = () => {
  const { selectedTab, handleTabChange } = useTabs()

  return (
    <>
      <h1 className="text-[32px] font-bold">Rezerwacje</h1>
      <FcTabsHeader
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        tabs={["Rezerwacje", "Wiadomości", "Zapytania"]}
      />

      <div className="w-full">
        <FcTabPanel selectedTab={selectedTab} index={0}>
          <SuperAdminBookings />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={1}>
          <SuperAdminMessages />
        </FcTabPanel>

        <FcTabPanel selectedTab={selectedTab} index={2}>
          <SuperAdminInquiries />
        </FcTabPanel>
      </div>
    </>
  )
}

import { WakacjeChip, WakacjeChipVariant } from "../../components/WakacjeChip"
import { PosterOrderStatus, PosterUtils } from "./PosterUtils"

export const PosterStatusChip = ({ order }) => {
  var variant = WakacjeChipVariant.GREY
  if (order.status === PosterOrderStatus.PAID) {
    variant = WakacjeChipVariant.GREEN
  } else if (order.status === PosterOrderStatus.CANCELED) {
    variant = WakacjeChipVariant.RED
  }
  return (
    <WakacjeChip
      label={PosterUtils.statusLabel(order.status)}
      variant={variant}
    />
  )
}

import { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@mui/material"
import RadioDropdown from "./RadioDropdown"
import {
  FormHelperText,
  Heading3,
  InputField
} from "../../../components/FormCommons"
import TagSelectDropdown from "./TagSelectDropdown"
import { useCategories } from "../../../components/useCategories"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

interface AddAccommodationModalProps {
  open: boolean
  handleClose: () => void
  setValue: any
  register: any
  accommodationIndex: number
  getValues: any
}

const AddAccommodationModal = ({
  open,
  handleClose,
  setValue,
  register,
  accommodationIndex,
  getValues
}: AddAccommodationModalProps) => {
  const { t } = useTranslation()
  const { trigger } = useFormContext()
  const [showForm, setShowForm] = useState(false)
  const [selectedItems, setSelectedItems] = useState({
    type: undefined,
    number_of_people: undefined,
    amenities: undefined,
    number_of_beds: undefined
  })
  const { accomodationTypeList, roomAmenitiesList } = useCategories(t)

  const setSelectedOptions = (identifier, value) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [identifier]: value
    }))
  }

  const numbersList = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
    { id: 11, name: "11" },
    { id: 12, name: "12" },
    { id: 13, name: "13" },
    { id: 14, name: "14" },
    { id: 15, name: "15" }
  ]

  useEffect(() => {
    const facility = getValues("facility")

    const foundFacility =
      facility &&
      facility.find((facility, index) => index === accommodationIndex)

    if (foundFacility) {
      setSelectedItems({
        amenities: foundFacility.amenities,
        type: foundFacility.type,
        number_of_people: foundFacility.number_of_people,
        number_of_beds: foundFacility.number_of_beds
      })
    } else {
      setSelectedItems({
        type: undefined,
        number_of_people: undefined,
        amenities: undefined,
        number_of_beds: undefined
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accommodationIndex, open])

  useEffect(() => {
    setShowForm(false)
  }, [open])

  const isFormValid = () => {
    return (
      selectedItems.type !== undefined &&
      selectedItems.number_of_people !== undefined &&
      selectedItems.number_of_beds !== undefined &&
      selectedItems.amenities !== undefined
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <div className="flex flex-row justify-end">
          <img
            className="hover:cursor-pointer"
            src="/assets/svg/close.svg"
            width={24}
            alt="Close"
            onClick={() => handleClose()}
          />
        </div>
        <span className="text-[24px] font-[700]">
          {t("ui.groups.add_holiday_letting", "Dodaj miejsce noclegowe")}
        </span>
      </DialogTitle>
      <DialogContent>
        {/* <Box sx={style}> */}
        <div className="mb-4" />
        <Heading3 id="h3-1" className="mb-4">
          {t("ui.groups.holiday_letting_type", "Rodzaj miejsca noclegowego")}
        </Heading3>
        <div className="mb-4">
          <FormHelperText>
            {t(
              "ui.groups.select_holiday_letting_type",
              "Wybierz rodzaj miejsca noclegowego"
            )}
          </FormHelperText>
        </div>
        <RadioDropdown
          items={accomodationTypeList}
          label={t("ui.groups.select_type", "Wybierz rodzaj*")}
          labelId="objectTypeId"
          fieldIdentifier={`type`}
          value={selectedItems.type}
          setValue={setSelectedOptions}
        />
        <div className="mb-8" />
        <Heading3 id="h3-1" className="mb-4">
          {t("ui.groups.persona_number", "Liczba osób")}
        </Heading3>
        <div className="mb-4">
          <FormHelperText>
            {t(
              "ui.groups.persona_number_description",
              "Wybierz liczbę osób jaką pomieści dane miejsce noclegowe, np. (pokój 2-osobowy)"
            )}
          </FormHelperText>
        </div>
        <div className="max-w-[40%]">
          <RadioDropdown
            items={numbersList}
            label={t("ui.groups.select_number", "Wybierz liczbę*")}
            labelId="numberOfPeople"
            fieldIdentifier={`number_of_people`}
            setValue={setSelectedOptions}
            value={selectedItems.number_of_people}
          />
        </div>
        <div className="mb-8" />
        <Heading3 id="h3-1" className="mb-4">
          {t("ui.groups.holiday_letting_number", "Liczba miejsca noclegowego")}
        </Heading3>
        <div className="mb-4">
          <FormHelperText>
            {t(
              "ui.groups.holiday_letting_number_description",
              "Wybierz liczbę danego rodzaju miejsca noclegowego, np. (10 pokoi 2-osbowych)"
            )}
          </FormHelperText>
        </div>
        <div className="max-w-[40%]">
          <InputField
            type="number"
            className="w-1/2 md:w-1/4"
            label={t("ui.groups.select_number", "Wybierz liczbę*")}
            value={
              selectedItems.number_of_beds
                ? selectedItems.number_of_beds
                : undefined
            }
            onChange={(event) => {
              setSelectedItems((prevState) => ({
                ...prevState,
                number_of_beds: event.target.value
              }))
            }}
          />
        </div>
        <div className="mb-8" />
        <Heading3 id="h3-1" className="mb-4">
          {t("ui.groups.holiday_letting_facilities", "Udogodnienia noclegu")}
        </Heading3>
        <div className="mb-4">
          <FormHelperText>
            {t(
              "ui.groups.holiday_letting_facilities_description",
              "Opisz jakie udogodnienia znajdują się w pokojach , np. łóżko małżeńskie, łóżka pojedyńcze, prywatne łazienki, ręczniki, suszarkę itp."
            )}
          </FormHelperText>
        </div>
        <TagSelectDropdown
          label={t(
            "ui.groups.select_holiday_letting_facilities",
            "Wybierz udogodnienia*"
          )}
          labelId="roomAmenitiesId"
          placeholder={t("ui.groups.facilities", "Udogodnienia")}
          setValue={setSelectedOptions}
          value={selectedItems.amenities}
          fieldIdentifier={`amenities`}
          items={roomAmenitiesList}
          trigger={trigger}
        />
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        {!showForm && (
          <div className="flex flex-row mt-10 gap-10 justify-end p-2">
            <div>
              <Button
                onClick={() => {
                  handleClose()
                }}
                variant="text"
                color="primary"
              >
                {t("ui.groups.close_button", "Anuluj")}
              </Button>
            </div>

            <div>
              <Button
                onClick={() => {
                  setValue(
                    `facility[${accommodationIndex}].type`,
                    selectedItems.type
                  )
                  setValue(
                    `facility[${accommodationIndex}].number_of_people`,
                    selectedItems.number_of_people
                  )
                  setValue(
                    `facility[${accommodationIndex}].number_of_beds`,
                    selectedItems.number_of_beds
                  )
                  setValue(
                    `facility[${accommodationIndex}].amenities`,
                    selectedItems.amenities
                  )
                  handleClose()
                }}
                variant="contained"
                color="primary"
                size="large"
                disabled={!isFormValid()}
              >
                {t("ui.groups.select_button", "Wybierz")}
              </Button>
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default AddAccommodationModal

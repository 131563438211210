import { Popover } from "@mui/material"
import * as React from "react"

interface IMenuItemsTableProps {
  anchorEl?: HTMLElement
  open?: boolean
  anchorOrigin?: {
    vertical: "top" | "center" | "bottom" | number
    horizontal: "left" | "center" | "right" | number
  }
  transformOrigin?: {
    vertical: "top" | "center" | "bottom" | number
    horizontal: "left" | "center" | "right" | number
  }
  handleCloseAnchor: (props: any) => void
}

const MenuItemsTable: React.FunctionComponent<IMenuItemsTableProps> = ({
  open,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  handleCloseAnchor,
  children,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCloseAnchor}
      anchorOrigin={{
        vertical: anchorOrigin.vertical,
        horizontal: anchorOrigin.horizontal,
      }}
      transformOrigin={{
        vertical: transformOrigin.vertical,
        horizontal: transformOrigin.horizontal,
      }}
      className="w-[250px] shadow-menu-items-table"
      sx={{
        ".MuiPopover-paper": {
          boxShadow:
            "0px 8px 22px -6px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.02) !important;",
        },
      }}
    >
      <div className="w-full h-auto shadow-menu-items-table font-lato rounded-lg">
        {children}
      </div>
    </Popover>
  )
}

export default MenuItemsTable

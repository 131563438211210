import {
  Control,
  Controller,
  FieldPath,
  RegisterOptions
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { InputField } from "../FormCommons"

export const TripDataDialogAvailableSpots = ({
  control,
  register
}: {
  control: Control
  register: (name: FieldPath<any>, options?: RegisterOptions) => {}
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Controller
        name={"available_spots"}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <InputField
              type="number"
              sx={{ width: `calc(50% - 0.5rem)` }}
              onChange={onChange}
              {...register("available_spots", {
                valueAsNumber: true
              })}
              label={t("ui.trips.available_spots_required", "Liczba miejsc*")}
              id={`available_spots`}
            />
          )
        }}
      />
    </>
  )
}

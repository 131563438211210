import {
  PaymentStep,
  usePaymentContext
} from "wakacje-web/src/shared/payments/PaymentsContext"
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from "@mui/material"
import { Box } from "@mui/system"
import { MojeButton } from "../../components/MojeButton"

export const PaymentPopup = () => {
  const { step, showWindow, closePopup, payment, popupBlocked } =
    usePaymentContext()

  const handleClose = () => {
    closePopup()
  }
  return (
    <Dialog
      onClose={handleClose}
      open={showWindow}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">Płatność</DialogTitle>
      {/* <Box style={getModalStyle()}> */}
      {/* <Box>
      <Typography>Edytuj dzien</Typography>
    </Box> */}
      <DialogContent style={{ width: "fit-content", minWidth: "500px" }}>
        {step === PaymentStep.GETTING_LINK && (
          <Box my={1}>
            <CircularProgress />
            <Box>Trwa generowanie płatności Przelewy24</Box>
          </Box>
        )}
        {step === PaymentStep.LINK_RECEIVED && (
          <Box my={1}>
            <Box>Płatność otwarta w oddzielnym oknie</Box>
            {popupBlocked && (
              <div className="my-1 text-red-500">Okno zablokowane</div>
            )}
            <Box>Jeżeli okno nie otworzyło się - kliknij na link poniżej:</Box>
            {payment && (
              <div>
                <a
                  href={payment.link}
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-underline text-blue-500 cursor-pointer"
                >
                  {payment.link}
                </a>
              </div>
            )}
            <Box my={1}>
              Możesz zamknąć to okno. Po dokonaniu płatności - odśwież okno.
            </Box>
            <Box>
              <MojeButton
                variant="contained"
                color="primary"
                onClick={(ev: any) => closePopup()}
                label="Zamknij"
              />
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

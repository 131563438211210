import { MenuItem, Popover } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'
import { useCurrency } from '../../hooks/useCurrency'

export const CurrencyDropdownMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { selectedCurrency, changeCurrency, CURRENCY_LIST } = useCurrency()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeCurrency = (currency: any) => {
    changeCurrency(currency)
    handleClose()
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <div
        className='flex items-center cursor-pointer'
        onClick={handleClick}
        style={{ marginLeft: '1vw' }}
      >
        <b>{selectedCurrency.symbol}</b>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { width: '300px', marginTop: '48px' }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'unset'
          }
        }}
      >
        <PopoverArrow />
        <ul>
          {CURRENCY_LIST.map(({ currency, name, symbol }) => (
            <MenuItem
              sx={{
                '&.MuiMenuItem-root': {
                  paddingX: '1.25rem',
                  paddingY: '1rem'
                }
              }}
              key={currency}
              onClick={() => handleChangeCurrency({ currency, name, symbol })}
            >
              <p className='uppercase font-lato ml-4'>
                {currency} / {name} ({symbol})
              </p>
            </MenuItem>
          ))}
        </ul>
      </Popover>
    </div>
  )
}

const PopoverArrow = styled.div`
  position: absolute;
  top: -16px;
  right: 0;
  width: 16px;
  height: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, 50%) rotate(-45deg);
  clip-path: polygon(
    -8px -8px,
    calc(100% + 8px) -8px,
    calc(100% + 8px) calc(100% + 8px)
  );
`

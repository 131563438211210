import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import Typo from "../../../components/typography"
import {
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"

const TypeButton = ({ type, onClick }: { type: any; onClick: any }) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: type.color
      }}
      className="p-4 mx-1 rounded-xl cursor-pointer flex flex-col items-center justify-center
        hover:shadow-xl
        hover:-translate-y-1
        transition-all
      "
    >
      <Box p={2} height={125}>
        <img src={type.icon} width={135} height={80} alt={type.label}></img>
      </Box>
      <Box
        style={{
          fontWeight: 600,
          fontSize: 22,
          color: "#111111"
        }}
      >
        {type.label}
      </Box>
      <Box>
        <Box
          color="#042675"
          bgcolor="white"
          width={178}
          px={2}
          py={1}
          borderRadius={2}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          fontWeight={700}
          mt={3}
        >
          <div>Utwórz</div>
        </Box>
      </Box>
    </div>
  )
}

const types = [
  {
    label: "Kolonie i obozy",
    icon: "/assets/types/kolonie.png",
    type: PRODUCT_TYPE_CAMP.value,
    color: PRODUCT_TYPE_CAMP.color
  },
  {
    label: "Półkolonie",
    icon: "/assets/types/polkolonie.png",
    type: PRODUCT_TYPE_DAYCAMP.value,
    color: PRODUCT_TYPE_DAYCAMP.color
  },
  {
    label: "Wycieczki szkolne",
    icon: "/assets/types/wycieczka-szkolna.png",
    type: PRODUCT_TYPE_SCHOOL_TRIP.value,
    color: PRODUCT_TYPE_SCHOOL_TRIP.color
  }
  // {
  //   label: "Zielone szkoły",
  //   icon: "/assets/types/zielona-szkola.png",
  //   type: PRODUCT_TYPE_SCHOOL.value,
  //   color: PRODUCT_TYPE_SCHOOL.color,
  // },
]

export const NewProdutTypeSelector = ({ onSelected }: any) => {
  return (
    <Box display="flex" flexDirection="row">
      {types.map((t, index) => (
        <TypeButton
          key={index}
          type={t}
          onClick={() => {
            onSelected(t.type)
          }}
        />
      ))}
    </Box>
  )
}

export const NewProductTypePopup = ({
  isOpen,
  onClose
}: {
  isOpen: boolean
  onClose: any
}) => {
  const { t } = useTranslation()
  return (
    <Dialog maxWidth="lg" open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typo.Title32Bold>
          {t("ui.products.create_new_offer", "Utwórz nową ofertę")}
        </Typo.Title32Bold>
      </DialogTitle>
      <DialogContent>
        <NewProdutTypeSelector onSelected={onClose} />
      </DialogContent>
    </Dialog>
  )
}

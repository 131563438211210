import { ApiTable } from "../../../components/table/ApiTable"
import { usePosterOrdersApi } from "../../posters/usePosterOrdersApi"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"

export const SuperAdminCustomerFee = () => {
  //   const { list } = usePosterOrdersApi()
  const list: any = []
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const columns = [
    {
      header: "Name",
      field: "name"
    },
    {
      header: "demo",
      cell: (row: any) => <div>demo</div>
    }
  ]

  return (
    <>
      <Toolbar setSearchTextDebounced={setSearchTextDebounced} api={list} />
      <ApiTable
        columns={columns}
        listApi={list}
        showExpandColumn
        rowLinkBuilder={(row: any) => `/super/payments/${row.id}`}
      ></ApiTable>
    </>
  )
}

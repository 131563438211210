import { useEffect, useState } from "react"
import { MojeTextInput } from "./MojeTextInput"
import React from "react"

export const PriceCentsInput = React.forwardRef<any, any>(
  ({ value, onChange, ...props }, ref) => {
    const [textValue, setTextValue] = useState(
      value ? (value / 100).toString() : ""
    )
    console.log("PriceCentsInput", value, onChange)

    useEffect(() => {
      console.log("Text value changed", textValue)
      try {
        var num = Number(textValue)
        const cents = num * 100
        console.log("Cents", cents)
        onChange({ target: { value: num * 100 } })
      } catch (err) {
        console.log("Not a number")
      }
    }, [textValue])
    return (
      <MojeTextInput
        inputRef={ref}
        {...props}
        value={textValue}
        onChange={(e) => {
          console.log("Changed", e.target.value)
          setTextValue(e.target.value)
        }}
      />
    )
  }
)

import { Routes, Route } from "react-router-dom"
import React from "react"
import {
  useNavigate,
  useParams,
  useLocation,
  resolvePath
} from "react-router-dom"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty"

import { PageTitle } from "../../../components/typography"
import FutureTripsIndex from "./FutureTripsIndex"
import { TripPage } from "./event/TripPage"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"

type TabData = {
  path: string
  label?: string
  element?: React.ReactNode
  icon?: React.ReactNode
}

const tabs: TabData[] = [
  {
    path: "przyszle",
    label: "Przyszłe",
    element: <FutureTripsIndex />,
    icon: <AssignmentTurnedInOutlinedIcon />
  },
  {
    path: "aktywne",
    label: "W trakcie",
    element: <div />,
    icon: <FlagOutlinedIcon />
  },
  {
    path: "przeszle",
    label: "Minione",
    element: <div />,
    icon: <HourglassEmptyIcon />
  }
]

export function EventsIndex() {
  const { type }: any = useParams()
  return (
    <div>
      <Routes>
        <Route
          path="/przyszle"
          element={<Events type={type} tab="przyszle" />}
        />
        <Route path="/aktywne" element={<Events type={type} tab="aktywne" />} />
        <Route path="/minione" element={<Events type={type} tab="minione" />} />

        <Route path="/:tripId/*" element={<TripPage />} />
      </Routes>
    </div>
  )
}

export default function Events({ type, tab }: { type: string; tab: string }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const tabIndex = tabs.findIndex((tabData: TabData) => tabData["path"] === tab)
  // if (tabIndex < 0) navigate(resolvePath(`../przyszle`, pathname))
  return (
    <Box marginTop={1}>
      <PageTitle>{ProductUtils.getTypeNamePlurial(type)}</PageTitle>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} aria-label="events tab">
          {tabs.map((tab) => (
            <Tab
              onClick={() =>
                navigate(resolvePath(`../${tab["path"]}`, pathname))
              }
              label={
                <div className="flex justify-center items-center">
                  <div className="mr-1">{tab["icon"]}</div>
                  {tab["label"] ?? tab["path"]}
                </div>
              }
              key={tab["path"]}
            />
          ))}
        </Tabs>
      </Box>
      {tab === "przyszle" && <FutureTripsIndex />}
      {tab === "aktywne" && <FutureTripsIndex />}
      {tab === "minione" && <FutureTripsIndex />}
    </Box>
  )
}

import {
  FieldPath,
  RegisterOptions,
  useForm,
  get,
  FormProvider
} from "react-hook-form"
import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { Heading3 } from "../../../../components/FormCommons"
import { ProductSectionFilesForm } from "../ProductSectionFiles"
import { NewProductWizardButtonsBar } from "./NewProductWizardButtonsBar"
import { ProductSectionConditionsForm } from "./ProductSectionConditions"
import {
  ProductSectionFoodForm,
  productSectionFoodFormSchema
} from "./ProductSectionFood"
import {
  ProductSectionInsuranceForm,
  productSectionInsuranceFormSchema
} from "./ProductSectionInsurance"
import {
  ProductSectionPlacesForm,
  productSectionPlacesFormSchema
} from "./ProductSectionPlace/ProductSectionPlace"
import {
  ProductSectionScheduleForm,
  productSectionScheduleSchema
} from "./ProductSectionSchedule"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import ErrorBoundary from "antd/lib/alert/ErrorBoundary"

const productSecondPageSchema = Yup.object({
  ...productSectionScheduleSchema,
  ...productSectionFoodFormSchema,
  ...productSectionInsuranceFormSchema,
  ...productSectionPlacesFormSchema
})

export const NewSchoolTripPageSchedule = ({
  values,
  onCancel,
  onSubmit,
  isSubmitting
}: any) => {
  var defaultValues = values
    ? {
        ...values,
        files: values.files ?? [],
        schedule: values.schedule ?? [],
        places: values.places ?? []
      }
    : {
        schedule: [],
        files: [],
        places: []
      }

  console.log("NewSchoolTripPageSchedule defaultValues", defaultValues)
  const formMethods = useForm<any>({
    mode: "onTouched",
    resolver: yupResolver(productSecondPageSchema),
    defaultValues
  })

  const {
    handleSubmit,
    formState: { errors, isValid }
  } = formMethods

  const register = (name: FieldPath<any>, options?: RegisterOptions) => ({
    ...formMethods.register(name, { ...options }),
    id: name,
    error: Boolean(get(errors, name, false))
  })

  function onFormSubmit(data: any) {
    onSubmit(data)
  }

  // console.log("watchData", watch())

  return (
    <section>
      <FormProvider {...formMethods}>
        <form className="my-8" onSubmit={handleSubmit(onFormSubmit)}>
          <BlueBackground>
            <Heading3 id="h3-1">Plan wydarzenia</Heading3>
          </BlueBackground>
          <div className="mb-8" />
          <ProductSectionScheduleForm />

          <ProductSectionFoodForm {...formMethods} register={register} />

          <ProductSectionInsuranceForm register={register} values={values} />

          <ProductSectionConditionsForm register={register} values={values} />

          <ErrorBoundary>
            <ProductSectionFilesForm
              register={register}
              values={values}
              showSection={false}
            />
          </ErrorBoundary>

          <BlueBackground>
            <Heading3 id="h3-1">Atrakcje</Heading3>
          </BlueBackground>
          <div className="mb-8" />
          <ErrorBoundary>
            <ProductSectionPlacesForm />
          </ErrorBoundary>

          <NewProductWizardButtonsBar
            onCancel={onCancel}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </section>
  )
}

import {
  DescriptionList,
  FormHelperText,
  FormSectionSeparator,
  Heading3,
  InputField
} from "../../../components/FormCommons"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import { useProductApi } from "../events/api/useProductApi"
import * as Yup from "yup"
import { useFormContext, useFormState } from "react-hook-form"
import { FieldError } from "../../../components/FieldError"
import { useTranslation } from "react-i18next"
import { getLocalizedKey } from "../../../utils/getLocalizedKey"
import {
  ProductSectionPhotosForm,
  ProductSectionPhotosPreview
} from "../products/forms/ProductSectionPhotos"
import {
  ProductSectionFoodForm,
  ProductSectionFoodPreview
} from "../products/forms/ProductSectionFood"
import TagSelectDropdown from "./TagSelectDropdown"
import { useCategories } from "../../../components/useCategories"
import { Divider } from "@mui/material"

export const productDescriptionGroupsSchema = {
  [getLocalizedKey("name")]: Yup.string().required(
    "Nazwa oferty jest wymagana"
  ),
  object_type: Yup.array()
    .required("Kategorie są wymagane")
    .test("non-empty", "Kategorie są wymagane", function (value) {
      // Custom test function to check if the array is not empty
      return Array.isArray(value) && value.length > 0
    }),
  [getLocalizedKey("description")]: Yup.string().required(
    "Opis oferty jest wymagany"
  )
}

export const ProductSectionDescriptionForm = ({ values, formMethods }: any) => {
  const { watch, setValue, register, trigger } = useFormContext()
  const { errors } = useFormState()
  const { t } = useTranslation()
  const { amenitiesList, typeList } = useCategories(t)
  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")

  const duration_days_options = []
  for (let i = 2; i <= 20; i++) {
    duration_days_options.push(i)
  }

  return (
    <>
      <InputField
        value={watch(nameKey) || ""}
        label={`${t("ui.groups.place_name", "Nazwa obiektu")}*`}
        {...register(nameKey, {
          required: true
        })}
      />

      <FieldError label={errors?.[nameKey]?.message} />
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.place_type", "Rodzaj obiektu")}
      </Heading3>
      <div className="mb-4">
        <FormHelperText>
          {t(
            "ui.groups.select_more_places",
            "Możesz wybrać więcej niż jeden rodzaj obiektu."
          )}
        </FormHelperText>
      </div>
      <TagSelectDropdown
        label={`${t("ui.groups.choose_place_type", "Rodzaj obiektu")}*`}
        labelId="rodzajObiektuId"
        placeholder={`${t(
          "ui.groups.choose_place_type",
          "Wybierz rodzaj obiektu"
        )}*`}
        value={watch("object_type") || []}
        setValue={setValue}
        fieldIdentifier="object_type"
        items={typeList}
        trigger={trigger}
      />
      <FieldError label={errors?.object_type?.message} />
      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.description_header", "Opis")}
      </Heading3>
      <InputField
        multiline
        minRows={4}
        value={watch(descriptionKey) || ""}
        label={`${t("ui.groups.place_describe", "Opisz swój obiekt")}*`}
        {...register(descriptionKey, {
          required: true
        })}
      />
      <FieldError label={errors?.description?.message} />

      <Divider sx={{ marginTop: "2rem" }} />

      <div className="mb-8" />
      <ProductSectionPhotosForm photosNumber={4} />

      <Divider sx={{ marginTop: "2rem" }} />

      <div className="mb-8" />
      <Heading3 id="h3-1" className="mb-4">
        {t("ui.groups.place_facilities", "Udogodnienia obiektu")}
      </Heading3>
      <div className="mb-4">
        <FormHelperText>
          {t(
            "ui.groups.select_more_facilities",
            "Możesz wybrać więcej niż jedno udogodnienie"
          )}
        </FormHelperText>
      </div>
      <TagSelectDropdown
        label={`${t("ui.groups.select_facilities", "Wybierz udogodnienia")}*`}
        labelId="udogodnieniaObiektuId"
        placeholder={t("ui.groups.facilities", "Udogodnienia")}
        setValue={setValue}
        value={watch("facility_amenities") || ""}
        fieldIdentifier="facility_amenities"
        items={amenitiesList}
        trigger={trigger}
      />
      <FieldError label={errors?.facility_amenities?.message} />

      <Divider sx={{ marginTop: "2rem" }} />

      <div className="mb-8" />
      <ProductSectionFoodForm
        {...formMethods}
        watch={watch}
        register={register}
      />
      <div className="mb-8" />
    </>
  )
}

export const ProductSectionDescription = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()
  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")
  const foodDescriptionKey = getLocalizedKey("food_description")

  const saveHandler = async (data: any) => {
    const productData: any = {
      id: values.id,
      name: data[nameKey],
      description: data[descriptionKey],
      media: data.media,
      object_type: data.object_type,
      facility_amenities: data.facility_amenities,
      food_type: data.food_type,
      food_diets: data.food_diets,
      food_description: data[foodDescriptionKey]
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }

  const initialValues = { ...values, object_type: values.object_type ?? [] }
  const { t } = useTranslation()

  return (
    <EditableFormSection
      title={t("product.description", "Opis")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={productDescriptionGroupsSchema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionDescriptionForm
                values={values}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <ProductSectionDescriptionPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

export function ProductSectionDescriptionPreview({ values }: { values: any }) {
  const { t } = useTranslation()
  const { amenitiesList, typeList } = useCategories(t)
  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")

  const descriptions = {}
  const name = {}

  name[t("product.event_name", "Nazwa wydarzenia")] = values[nameKey]
  descriptions[t("product.product_description", "Opis oferty")] =
    values[descriptionKey]

  return (
    <section>
      <section className="mb-8">
        <DescriptionList separator data={name} />
      </section>
      <section className="mb-8 mx-4">
        <dt className="text-sm text-gray-500 font-medium my-2">
          {t("ui.groups.place_type", "Rodzaj obiektu")}
        </dt>
        {values.object_type && values.object_type.length > 0 ? (
          <div className="flex gap-3 mb-6 flex-wrap">
            {values.object_type.map((selectedCategory, index) => (
              <div
                key={index}
                className="rounded-full border border-[color:var(--fc-color-primary-blue)] px-3 py-1 text-[color:var(--fc-color-primary-blue)] text-[9px] flex justify-between items-center gap-2 uppercase"
              >
                <img
                  src={
                    typeList.find((item) => item.id === selectedCategory)
                      ?.iconSrc
                  }
                  alt=""
                  width="16"
                  height="16"
                />

                {typeList.find((item) => item.id === selectedCategory)?.name}
              </div>
            ))}
          </div>
        ) : (
          "-"
        )}
        <FormSectionSeparator />
      </section>
      <section className="mb-8">
        <DescriptionList separator data={descriptions} />
      </section>

      <section className="mb-8 mx-4">
        <div className="text-sm text-gray-500 font-medium">
          {t("product.photos", "Zdjęcia")}
        </div>
        <ProductSectionPhotosPreview values={values} />
        <FormSectionSeparator />
      </section>
      <section className="mb-8 mx-4">
        <dt className="text-sm text-gray-500 font-medium my-2">
          {t("ui.groups.place_facilities", "Udogodnienia obiektu")}
        </dt>
        {values.facility_amenities && values.facility_amenities.length > 0 ? (
          <div className="flex gap-3 mb-6 flex-wrap">
            {values.facility_amenities.map((selectedCategory, index) => (
              <div className="rounded-full border border-[color:var(--fc-color-primary-blue)] px-3 py-1 text-[color:var(--fc-color-primary-blue)] text-[9px] flex justify-between items-center gap-2 uppercase">
                <img
                  src={
                    amenitiesList.find((item) => item.id === selectedCategory)
                      ?.iconSrc
                  }
                  alt=""
                  width="16"
                  height="16"
                />

                {
                  amenitiesList.find((item) => item.id === selectedCategory)
                    ?.name
                }
              </div>
            ))}
          </div>
        ) : (
          "-"
        )}
        <FormSectionSeparator />
      </section>
      <section>
        <ProductSectionFoodPreview values={values} />
      </section>
    </section>
  )
}

export const SelectableDiv = ({
  selected,
  children,
  onClick,
  className,
}: {
  children: any
  selected?: boolean
  onClick?: any
  className?: string
}) => {
  return (
    <div
      onClick={onClick}
      className={` border-2 
      transition-all
     cursor-pointer rounded-xl p-2
     ${selected ? " border-blue-500 bg-blue-100/20 " : "border-slate-200"}
     ${className ?? ""}
    `}
    >
      {children}
    </div>
  )
}

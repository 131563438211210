import { useTranslation } from "next-i18next"

export const useParticipantLanguages = () => {
  const { t } = useTranslation()

  return [
    {
      id: "languages.polish",
      name: t("languages.polish", "Polski")
    },
    {
      id: "languages.english",
      name: t("languages.english", "Angielski")
    },
    {
      id: "languages.ukrainian",
      name: t("languages.ukrainian", "Ukraiński")
    },
    {
      id: "languages.german",
      name: t("languages.german", "Niemiecki")
    },
    {
      id: "languages.russian",
      name: t("languages.russian", "Rosyjski")
    },
    {
      id: "languages.arabic",
      name: t("languages.arabic", "Arabski")
    },
    {
      id: "languages.bengali",
      name: t("languages.bengali", "Bengalski")
    },
    {
      id: "languages.chinese",
      name: t("languages.chinese", "Chiński")
    },
    {
      id: "languages.french",
      name: t("languages.french", "Francuski")
    },
    {
      id: "languages.hindi",
      name: t("languages.hindi", "Hindi")
    },
    {
      id: "languages.spanish",
      name: t("languages.spanish", "Hiszpański")
    },
    {
      id: "languages.japanese",
      name: t("languages.japanese", "Japoński")
    },
    {
      id: "languages.javanese",
      name: t("languages.javanese", "Jawajski")
    },
    {
      id: "languages.korean",
      name: t("languages.korean", "Koreański")
    },
    {
      id: "languages.malay",
      name: t("languages.malay", "Malajski")
    },
    {
      id: "languages.persian",
      name: t("languages.persian", "Perski")
    },
    {
      id: "languages.portuguese",
      name: t("languages.portuguese", "Portugalski")
    },
    {
      id: "languages.thai",
      name: t("languages.thai", "Tajski")
    },
    {
      id: "languages.turkish",
      name: t("languages.turkish", "Turecki")
    },
    {
      id: "languages.vietnamese",
      name: t("languages.vietnamese", "Wietnamski")
    },
    {
      id: "languages.italian",
      name: t("languages.italian", "Włoski")
    }
  ]
}

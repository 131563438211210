import React from "react"
import { parseISO, format, parse } from "date-fns"
import { pl } from "date-fns/locale"
import clsx from "clsx"
import { FileUtils } from "../../utils/file-utils"
import { ProductUtils } from "../../utils/ProductUtils"

const MessageLabelRow = ({ label, children }) => (
  <div className="flex flex-row mb-2 gap-4">
    <div className="font-bold md:w-60">{label}</div>
    <div className="flex-1 md:min-w-80">{children}</div>
  </div>
)
const MessageSpecialContent = ({ part }) => {
  console.log("MessageSpecialContent", part)
  if (part.type === "file") {
    return (
      <div
        onClick={(ev: any) => FileUtils.openS3File(part)}
        className="bg-gray-100 
        hover:bg-gray-200 transition-all
          rounded-full px-2 cursor-pointer flex flex-row
          items-center
        text-sm py-1 mb-2 text-[#000000]"
      >
        <div
          className="w-[24px] h-[24px] rounded-full 
            flex items-center justify-center mr-4 p-1"
          style={{
            background: "var(--fc-lightblue-box)"
          }}
        >
          <img src="/svg/document.svg" />
        </div>

        <div className="font-bold">{part.name ?? "File"}</div>
        <div
          className="ml-2 mr-2 w-[24px] h-[24px] rounded-full 
            flex items-center justify-center  "
          // style={{
          //   background: "var(--fc-lightblue-box)"
          // }}
        >
          <img src="/svg/download.svg" />
        </div>
      </div>
    )
  }

  return <div />
}
const SpecialMessageBooking = ({ message, mode, booking }) => {
  var safeStartDate = ""
  let inquiry = message.type === "NEW_INQUIRY" ? message?.content[0]?.inquiry : {}


  if (booking?.month) {
    safeStartDate = ProductUtils.inquiryStartDateMonth(booking)
  }
  return (
    <div>
      {message.type === "NEW_BOOKING" && (
        <>
          {mode === "user" && (
            <div>
              Twoja rezerwacja została wysłana do organizatora. Poczekaj, aż
              zostanie zaakceptowana.
            </div>
          )}
          {/* {mode === "company" && (
            // <div className="font-bold mb-2">
            //   Gratulacje! Masz kolejną rezerwację od:
            // </div>
          )} */}
        </>
      )}

      {message.type === "NEW_INQUIRY" && (
        <div>
          <MessageLabelRow label="Organizator:">
            {message?.company?.name}
          </MessageLabelRow>
          <MessageLabelRow label="Przybliżony termin:">
            {inquiry?.start_date.slice(0,10)+ " - " + inquiry?.end_date.slice(0,10)}
          </MessageLabelRow>
          <MessageLabelRow label="Ilość dni:">
            {ProductUtils.differenceInDaysYYYMMDD(inquiry?.start_date.slice(0,10), inquiry?.end_date.slice(0,10))}
          </MessageLabelRow>
          <MessageLabelRow label="Ilość uczestników:">
            {inquiry?.participants_count}
          </MessageLabelRow>
          {/* <MessageLabelRow label="Wiek uczestników:">
            {booking?.min_age}
            {" - "}
            {booking?.max_age}
          </MessageLabelRow> */}

          <MessageLabelRow label="Miejsce wyjazdu:">
            {inquiry?.pickup_place}
            {", "}
            {inquiry?.pickup_cost}
          </MessageLabelRow>


          <MessageLabelRow label="Dodatkowe informacje:">
            {inquiry?.content}
          </MessageLabelRow>
        </div>
      )}
    </div>
  )
}

const SpecialMessageResignation = ({ message, mode, booking }) => {
  // Przyczyna pozmiej
  return <div></div>
}

const SpecialMessageBookingConfirmed = ({ booking, message, mode }) => {
  return (
    <div>
      {mode === "user" && (
        <>
          <div>Twoja rezerwacja została zaakceptowana! </div>
          <div>
            {booking?.product && booking.product.company ? (
              <a
                className="text-fc-primary underline"
                href={`${booking?.product?.company.fc_website_url}/${booking.product.id}`}
                target="_blank"
                rel="noreferrer"
              >
                Sprawdź szczegóły wydarzenia{" "}
              </a>
            ) : (
              <span>Sprawdź szczegóły wydarzenia</span>
            )}{" "}
            i dokonaj płatności.
          </div>
        </>
      )}
    </div>
  )
}

const SpecialMessageBookingRejected = ({ booking, message, mode }) => {
  // console.log("SpecialMessageBookingRejected", booking)
  return (
    <div>
      {mode === "user" && (
        <>
          <div>Twoja rezerwacja została odrzucona! </div>
          <div>
            {/* {booking?.product && booking.product.company ? (
              <a
                className="text-fc-primary underline"
                href={`${booking?.product?.company.fc_website_url}/${booking.product.id}`}
                target="_blank"
                rel="noreferrer"
              >
                Sprawdź szczegóły wydarzenia{" "}
              </a>
            ) : (
              <span>Sprawdź szczegóły wydarzenia</span>
            )}{" "}
            i dokonaj płatności. */}
          </div>
        </>
      )}

      {booking.rejection_reason === "no_places_left" && (
        <div>Przyczyna: Brak miejsc</div>
      )}

      {booking.rejection_description && (
        <div className="py-2">Przyczyna: {booking.rejection_description}</div>
      )}
    </div>
  )
}

export function MessageBubble({
  msg,
  showDate,
  mode,
  detailsRenderer,
  booking
}: {
  msg: any
  showDate?: boolean
  mode: "user" | "company"
  detailsRenderer?: any
  booking?: any
}) {
  var text = msg.content_text
    ? msg.content_text
    : mode === "user"
    ? msg.customer_text
    : msg.company_text

  // var showContent = msg.type

  console.log("MessageBubble", msg)
  return (
    <>
      {showDate && (
        <div className="border-t border-[#E6EEFD] mt-10">
          <div className="text-center bg-white relative -top-[13px] w-[150px] block mx-auto">
            {format(parseISO(msg.created_at), "E, dd.MM", {
              locale: pl
            })}
          </div>
        </div>
      )}

      <div
        className={clsx(
          "flex flex-col mb-5 w-full",
          msg.direction === "FROM_USER" &&
            mode === "user" &&
            "mr-auto items-end",
          msg.direction === "TO_USER" &&
            mode === "user" &&
            "ml-auto items-start",
          msg.direction === "FROM_USER" &&
            mode === "company" &&
            "ml-auto items-start",
          msg.direction === "TO_USER" &&
            mode === "company" &&
            "mr-auto items-end"
        )}
      >
        <div>
          <div
            className={clsx(
              " p-5 rounded-md inline-block whitespace-pre-line",
              msg.direction === "FROM_USER" &&
                mode === "user" &&
                "bg-[#0559E8] text-[#FFFFFF]",
              msg.direction === "TO_USER" && mode === "user" && "bg-[#F9F9F9] text-[#000000]",
              msg.direction === "FROM_USER" &&
                mode === "company" &&
                "bg-[#F9F9F9]",
              msg.direction === "TO_USER" &&
                mode === "company" &&
                "bg-[#D9E9FF]"
            )}
          >
            {msg.title && <div className="font-bold mb-4">{msg.title}</div>}
            {msg.company_title && mode === "company" && (
              <div className="font-bold mb-4">{msg.company_title}</div>
            )}
            {msg.customer_title && mode === "user" && (
              <div className="font-bold mb-4">{msg.customer_title}</div>
            )}
            {msg.type === "MESSAGE" && text}

            {(msg.type === "NEW_BOOKING" || msg.type === "NEW_INQUIRY") && (
              <SpecialMessageBooking
                message={msg}
                mode={mode}
                booking={booking}
              />
            )}
            {msg.type === "RESIGNATION" && (
              <SpecialMessageResignation
                booking={booking}
                message={msg}
                mode={mode}
              />
            )}
            {msg.type === "BOOKING_CONFIRMED" && (
              <SpecialMessageBookingConfirmed
                booking={booking}
                message={msg}
                mode={mode}
              />
            )}
            {msg.type === "BOOKING_REJECTED" && (
              <SpecialMessageBookingRejected
                booking={booking}
                message={msg}
                mode={mode}
              />
            )}

            {detailsRenderer && detailsRenderer({ message: msg, mode })}

            {msg.content &&
              msg.content.map((part: any, index) => (
                <MessageSpecialContent key={index} part={part} />
              ))}
          </div>
        </div>

        {msg.sent_at !== null && (
          <div className="mt-2">
            <div
              className={`
            text-[#7E7E7E] md:text-sm `}
            >
              <div
                className={`
               flex
                ${
                  msg.direction !== "FROM_USER"
                    ? "flex-row-reverse"
                    : "flex-row"
                } 
              `}
              >
                {msg.readt_at && (
                  <img className="px-2" src="/svg/checked.svg" alt="" />
                )}
                {msg.direction === "FROM_USER" ?<span className="text-[#292727] mr-3">{msg.user?.name}</span> : <span className="text-[#292727] ml-3">{msg.company?.name}</span>}
                {format(parseISO(msg.sent_at), "HH:mm", {
                  locale: pl
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

import { DescriptionList } from "../../../../components/FormCommons"
import * as yup from "yup"
import { useEffect, useState } from "react"
import { useFormContext, useFormState } from "react-hook-form"

import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useCompanyApi } from "../../events/api/useCompanyApi"
import { MojeTextInput } from "../../../../components/ui/MojeTextInput"

export const companyContactDetailsSchema = {
  phone: yup.string().required("Numer telefonu jest wymagany"),
  email: yup
    .string()
    .email("Błędny format email")
    .required("Adres email jest wymagany"),
}

export const CompanySectionContactDetailsForm = () => {
  const { register } = useFormContext()
  const { errors } = useFormState()
  return (
    <>
      <MojeTextInput
        label="*Numer telefonu"
        {...register("phone")}
        fullWidth
        error={errors.phone?.message}
      />
      <MojeTextInput
        type="*email"
        label="Adres e-mail"
        {...register("email")}
        fullWidth
        error={errors.email?.message}
      />
      <MojeTextInput
        label="Adres WWW (opcjonalne)"
        {...register("www", { required: false })}
        fullWidth
        error={errors.www?.message}
      />
      <MojeTextInput
        label="Profil Facebook (opcjonalne)"
        {...register("facebook", { required: false })}
        fullWidth
        error={errors.facebook?.message}
      />
      <MojeTextInput
        label="Profil Instagram (opcjonalne)"
        {...register("instagram", { required: false })}
        fullWidth
        error={errors.instagram?.message}
      />
    </>
  )
}

export const CompanySectionContactDetailsPreview = ({ values }: any) => {
  return (
    <div>
      <DescriptionList
        data={{
          "Numer telefonu": values.phone,
          "E-mail": values.email,
          "Adres WWW": values.www,
          "Profil Facebook": values.facebook,
          "Profil Instagram": values.instagram,
        }}
      />
    </div>
  )
}

export const CompanySectionContactDetails = ({
  values,
  refetch,
  onSave,
}: {
  onSave?: Function
  values: any
  refetch: Function
}) => {
  const { updateCompany } = useCompanyApi()
  const [val, setValue] = useState(values)

  const saveHandler = async (data: any) => {
    var updateData: any = {
      id: values.id,
      phone: data.phone,
      email: data.email,
      www: data.www,
      facebook: data.facebook,
      instagram: data.instagram,
    }
    if (onSave) {
      return onSave(updateData)
    }

    return updateCompany(updateData)
  }
  const handleRefresh = () => {
    refetch()
  }
  useEffect(() => {
    if (val.id !== values.id) {
      setValue(values)
    }
  }, [values])

  const update = (data: object) => {
    setValue(data)
  }

  return (
    <EditableFormSection
      title="Dane kontaktowe"
      values={val}
      onSave={saveHandler}
      onSaved={handleRefresh}
      onUpdate={update}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <CompanySectionContactDetailsForm
                values={val}
                {...builderProps}
              />
            )}
            {!builderProps.isEditing && (
              <CompanySectionContactDetailsPreview values={val} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

import React from "react"
import { Global, css } from "@emotion/react"

export const WakacjeTheme = () => (
  <Global
    styles={css`
      :root {
        --wakacje-action-color-green: #5caa0b;
        --wakacje-action-color-orange: #fda500;
        --wakacje-action-color-red: #fd4943;
        --fc-color-1-red: #fd4943;
        --wakacje-color-blue: #0c48d7;

        // Colors as in UI kit
        --wakacje-brand-color-1-red: #fd4943;
        --wakacje-color-1-navy: #042675;
        --wakacje-accent-color-3-lightblue: ##0c48d7;
      }
    `}
  />
)

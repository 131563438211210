export const CalendarIcon = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7791_13849)">
        <path
          d="M47.25 12.375H44.4375V5.625H41.0625V12.375H12.9375V5.625H9.5625V12.375H6.75C5.5125 12.375 4.5 13.3875 4.5 14.625V46.125C4.5 47.3625 5.5125 48.375 6.75 48.375H27H39.15H47.25C48.4875 48.375 49.5 47.3625 49.5 46.125V14.625C49.5 13.3875 48.4875 12.375 47.25 12.375ZM46.125 45H39.15H27H7.875V21.375H46.125V45Z"
          fill="black"
        />
        <path d="M15.1875 25.875H11.8125V29.25H15.1875V25.875Z" fill="black" />
        <path d="M21.9375 25.875H18.5625V29.25H21.9375V25.875Z" fill="black" />
        <path d="M21.9375 32.625H18.5625V36H21.9375V32.625Z" fill="black" />
        <path d="M15.1875 32.625H11.8125V36H15.1875V32.625Z" fill="black" />
        <path d="M28.6875 25.875H25.3125V29.25H28.6875V25.875Z" fill="black" />
        <path d="M35.4375 25.875H32.0625V29.25H35.4375V25.875Z" fill="black" />
        <path d="M35.4375 32.625H32.0625V36H35.4375V32.625Z" fill="black" />
        <path d="M42.1875 25.875H38.8125V29.25H42.1875V25.875Z" fill="black" />
        <path d="M42.1875 32.625H38.8125V36H42.1875V32.625Z" fill="black" />
        <path d="M28.6875 32.625H25.3125V36H28.6875V32.625Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_7791_13849">
          <rect width="54" height="54" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { FormattedDate, FormattedTime } from "react-intl"
import { ApiTable } from "../../components/table/ApiTable"
import { usePackageOrdersApi } from "./usePackageOrders"

export const SuperAdminPackageOrders = () => {
  const { list } = usePackageOrdersApi()
  const columns = [
    {
      header: "Firma",
      cell: (row: any) => <div>{row.company?.name ?? "Brak"}</div>,
    },
    {
      header: "Zarejestrowana",
      cell: (row: any) => (
        <div>
          <FormattedDate value={row.created_at} />{" "}
          <FormattedTime value={row.created_at} />{" "}
        </div>
      ),
    },
    {
      header: "Pakiet",
      cell: (row: any) => <div>{row.package_code}</div>,
    },
    {
      header: "Daty",
      cell: (row: any) => (
        <div>
          {row.valid_from} - {row.valid_till}
        </div>
      ),
    },
    {
      header: "Status",
      cell: (row: any) => <div>{row.status}</div>,
    },
  ]

  return (
      <ApiTable
        columns={columns}
        listApi={list}
        showExpandColumn
        rowLinkBuilder={(row: any) => `/super/packageorders/${row.id}`}
      />
  )
}

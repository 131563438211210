import { ProductTypes } from "wakacje-web/src/utils/ProductUtils"
import * as yup from "yup"
/**
 * TODO: For now, I'm substituting full_price and payment_due_date for the base items
 * Since we're not changing them on the client app yet, this is to prevent any issues with
 * displaying and adding prices and dates for currently active camps/colonies.
 */
//TODO: For now, I'm not translating this; these validations are not appearing at all. This needs to be investigated and fixed.
export const getTripSchemaForProductType = (productType: ProductTypes) => {
  const fields: any = {
    name: yup.string().required("Nazwa turnusu jest wymagana"),
    start_date: yup.date().required("Data rozpoczęcia jest wymagana"),
    end_date: yup.date().required("Data zakończenia jest wymagana"),
    available_spots: yup
      .number()
      .required("Ilość dostępnych miejsc jest wymagana"),
    full_price: yup
      .number()
      .required("Całość / 1 osobę jest wymagana")
      .typeError("Całość / 1 osobę jest wymagana"),
    payment_due_date: yup.date().required("Data rozpoczęcia jest wymagana")
  }

  return yup.object().shape(fields)
}

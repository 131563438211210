import axios from "axios"
import { createContext, useContext, useEffect, useState } from "react"
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  ActionCodeSettings,
  sendEmailVerification,
  Auth
} from "@firebase/auth"
import { auth } from "../../config/firebase"
import { useRouter } from "next/router"

const AuthContextType = {}
export const AuthContext = createContext<any>({})
export const useAuth = () => useContext(AuthContext)

export const AuthContextProvider = ({
  children,
  type,
  client
}: {
  children: any
  type: string
  client: string
}) => {
  const router = useRouter()

  const [user, setUser] = useState<any>(null)
  const [loading, setLoading] = useState(true)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (window) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        console.log("onAuthStateChanged", user)
        if (user) {
          setUser({
            ...user,
            uid: user.uid,
            id: user.uid,
            email: user.email,
            displayName: user.displayName,
            accessToken: (user as any).accessToken
          })
        } else {
          setUser(null)
        }
        setReady(true)
        setLoading(false)
      })

      return () => unsubscribe()
    }
  }, [])

  const signup = async (
    email: string,
    password: string,
    first_name,
    last_name,
    phone,
    company_country_group,
    state_or_country,
    language,
    redirectUrl?: string
  ) => {
    var res = await createUserWithEmailAndPassword(auth, email, password)
    var currentUser = await auth.currentUser
    console.log("signup res ", currentUser)
    console.log("redirectUrl", redirectUrl)
    if (!redirectUrl) {
      console.log("Generating redirect url")
    }
    if (currentUser) {
      var updateRes = await updateProfile(res.user, {
        displayName: first_name + " " + last_name
      })
      const actionCodeSettings: any = {
        url: redirectUrl ?? "https://www.fajnyczas.pl/profil"
      }
      await sendEmailVerification(currentUser, actionCodeSettings)
    }
    await registerFCUser(currentUser, {
      first_name,
      last_name,
      phone,
      company_country_group,
      state_or_country,
      language
    })
    return res
  }

  const sendVerificationEmail = async (redirectUrl: any) => {
    var currentUser = await auth.currentUser
    return sendEmailVerification(currentUser, { url: redirectUrl })
  }
  const registerFCUser = async (currentUser, data) => {
    // Register to FC api
    console.log("registerFCUser", currentUser, data)
    const req = { ...data, type, client }
    var url = (window as any).fajnyczas_api_url + "/v1.1/public/register-user"
    var register_res = await axios.post(url, req, {
      headers: {
        Authorization: `Bearer ${await currentUser.getIdToken()}`
      }
    })
    console.log("register_res", register_res)
  }

  const login = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  }
  const _sendPasswordResetEmail = (
    email: string,
    actionCodeSettings?: ActionCodeSettings
  ) => {
    return sendPasswordResetEmail(auth, email, actionCodeSettings)
  }

  const logout = async () => {
    setUser(null)
    await signOut(auth)
    const { pathname } = router
    if (pathname.startsWith("/profil")) {
      router.push("/")
    }
  }
  const value = {
    user,
    login,
    signup,
    logout,
    auth,
    sendPasswordResetEmail: _sendPasswordResetEmail,
    accessToken: user?.accessToken,
    registerFCUser,
    ready,
    sendVerificationEmail
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

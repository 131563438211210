import { Control, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { InputField } from "../FormCommons"

export const TripDataDialogDates = ({ control }: { control: Control }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2">
        <Controller
          name={"start_date"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            return (
              <InputField
                type="date"
                onChange={onChange}
                value={val}
                label={t("ui.trips.date_from_required", "Data od*")}
                InputLabelProps={{ shrink: true }}
                id={`start_date`}
              />
            )
          }}
        />
        <Controller
          name={"end_date"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const val =
              value != null && value.length > 10 ? value.substr(0, 10) : value
            return (
              <InputField
                type="date"
                onChange={onChange}
                value={val}
                InputLabelProps={{ shrink: true }}
                label={t("ui.trips.date_to_required", "Data do*")}
                id={`end_date`}
              />
            )
          }}
        />
      </div>
    </>
  )
}

import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { blockTitle, studentButton } from "../../studentStyle"
import {
  modalBtnsContainer,
  modalStudentTitle,
  styleModal,
} from "./studentModalStyle"
import {
  closeModal,
  titleModalWrapper,
} from "../../../../organizer/help/sections/modalStyle"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {
  infoBlockWrapper,
  infoText,
  titleBlock,
} from "../curatorSection/curatorStyle"

interface ItextComponent {
  text1: string
  text2: string | string[]
}

const TextComponent: React.FC<ItextComponent> = ({
  text1 = "",
  text2 = "",
}) => {
  return (
    <Typography sx={infoText} component={"span"}>
      {text1}
      {Array.isArray(text2) ? (
        text2.map((t) => <span key={t}>{t}</span>)
      ) : (
        <span>{text2}</span>
      )}
    </Typography>
  )
}

const StudentModal = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button sx={studentButton} variant={"outlined"} onClick={handleOpen}>
        {" "}
        Informacje
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Box sx={titleModalWrapper}>
            <Typography sx={modalStudentTitle} component={"h2"}>
              Karta uczestnika
              <p>Jan Kowalski</p>
            </Typography>

            <Box>
              <IconButton
                onClick={() => setOpen((o) => !o)}
                size={"small"}
                color="primary"
                aria-label="add to shopping cart"
              >
                <CloseIcon sx={closeModal} />
              </IconButton>
              <span>Anuluj</span>
            </Box>
          </Box>

          <Typography sx={blockTitle} component="span">
            Uczestnik
          </Typography>

          <Box sx={titleBlock}>
            <span>Informacje podstawowe</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Imię"} text2={"Jan"} />
            <TextComponent text1={"Nazwisko"} text2={"Kowalski"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Data urodzenia"} text2={"12.12.2020"} />
            <TextComponent text1={"PESEL"} text2={"12122010110210"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Adres"}
              text2={["woj. mazowieckie", "30-434 Kraków, Wesoła 21"]}
            />
          </Box>

          <Box sx={titleBlock}>
            <span>Dodatkowe informacje</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Niepełnosprawność"} text2={"Tak"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Niedostosowanie społeczne"}
              text2={"Coś tam"}
            />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Alergie i uczulenia"}
              text2={"Dziecko jest diagnozowane"}
            />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Alergeny"} text2={"Orzechy, mleko"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Objawy alergii"} text2={"Dyszności"} />
          </Box>

          <Box sx={titleBlock}>
            <span>Choroby przewlekłe</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Choroby przewlekle"}
              text2={"Dziecko jest diagnozowane"}
            />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Choroba"} text2={"Jakaś tam choroba"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Symptomy"}
              text2={"Jakies tam symptomy choroby i długi tekst "}
            />
          </Box>

          <Box sx={titleBlock}>
            <span>Leki</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Leki"} text2={"Przyjmuje"} />
          </Box>
          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Przyczyna"} text2={"Jakas tam choroba"} />
          </Box>
          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Nazwa leku"} text2={"Luximo"} />
          </Box>
          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Dawka"}
              text2={"1 łyżeczka, 5 ml rano i wieczorem"}
            />
          </Box>

          <Box sx={titleBlock}>
            <span>Szczepienia</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Szczepienie"} text2={"Dur"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Data wykonania"} text2={"12.12.2010"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Szczepienie"} text2={"Dur"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Data wykonania"} text2={"12.12.2010"} />
          </Box>

          <Box sx={titleBlock}>
            <span>Inne ważne informacje</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Opis"}
              text2={"Wstaje nie wcześniej niż 9"}
            />
          </Box>

          <Box sx={titleBlock}>
            <span>Informacje podstawowe</span>
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Imię"} text2={"Jan"} />
            <TextComponent text1={"Nazwisko"} text2={"Kowalski"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent text1={"Numer telefonu"} text2={"512 515 231"} />
            <TextComponent text1={"Adres E-mai"} text2={"adres@mail.pl"} />
          </Box>

          <Box sx={infoBlockWrapper}>
            <TextComponent
              text1={"Adres"}
              text2={["woj. mazowieckie", "30-434 Kraków, Wesoła 21"]}
            />
          </Box>

          <Box sx={modalBtnsContainer}>
            <Button
              sx={studentButton}
              variant={"outlined"}
              onClick={handleOpen}
            >
              {" "}
              Pobierz PDF
            </Button>

            <Button
              sx={studentButton}
              variant={"outlined"}
              onClick={handleOpen}
            >
              {" "}
              Zamknij
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default StudentModal

import { Route, Navigate, Routes } from "react-router-dom"
import { AddPlaceForm } from "./AddPlace"
import PlacesHeader from "./placesHeader"

export const PlacesIndex = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to="place" />} />
        <Route path=":tab" element={<PlacesHeader />} />
        <Route path="/dodaj" element={<AddPlaceForm submitRedirect />} />
        <Route
          path="/place/:id"
          element={<AddPlaceForm isUpdateForm type="place" submitRedirect />}
        />
        <Route
          path="/place/:id/details"
          element={<AddPlaceForm isUpdateForm type="place" submitRedirect />}
        />
        <Route
          path="/accommodation/:id"
          element={
            <AddPlaceForm isUpdateForm type="accommodation" submitRedirect />
          }
        />
        <Route
          path="/accommodation/:id/details"
          element={
            <AddPlaceForm isUpdateForm type="accommodation" submitRedirect />
          }
        />
      </Routes>
    </>
  )
}

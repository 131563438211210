export const studentWrapper: any = {
  display: "flex",
  width: "100%",
  height: "100%",
  flexDirection: "column",
  padding: {
    xs: "16px",
    sm: "20px",
    md: "54px 145px",
    lg: "72px 387px",
  },
}
export const titleBlock: any = {
  fontFamily: "Mulish",
  fontWeight: 700,
  fontSize: { xs: "24px", sm: "32px" },
  lineHeight: "40,16px",
  letterSpacing: "0.01em",
  color: "#222222",
}

export const studentCardBlock: any = {
  display: "flex",
  marginTop: { xs: "16px", sm: "54px" },
  width: "100%",
  height: "214px",
  padding: "16px",
  flexDirection: "column",
  background: "rgba(5, 89, 232, 0.05)",
  borderRadius: "8px",
}

export const studentInfoWrapper: any = {
  display: "flex",
  flexDirection: "column",
}
export const studentIcon = {
  fontSize: "45px",
  marginRight: "30px",
}

export const studentName: any = {
  fontFamily: "Mulish",
  fontWeight: 700,
  fontSize: { xs: "20px", sm: "24px" },
  lineHeight: "30px",
  letterSpacing: "0.01em",
}
export const studentData: any = {
  fontFamily: "Lato",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "22px",
  margin: "6px 0px",
}

export const buttonsContainer = {
  display: "flex",
  width: "100%",
  justifyContent: { xs: "space-between", sm: "flex-end" },
  alignSelf: "flex-end",
  marginTop: "auto",
}
export const studentButton: any = {
  height: "40px",
  margin: { xs: "0", sm: "0 16px" },
  backgroundColor: "#FFFFFF",
  border: "1px solid #042675",
  color: "#042675",
}

export const blockTitle = {
  margin: { xs: "32px 0px", sm: "32px 0px", md: "32px 0px" },
  fontWeight: 600,
  fontSize: "20px",
}

export const showMore = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  color: "#042675",
}

import { createTheme } from "@mui/material/styles"

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    "2xl": true // adds the `2xl` breakpoint
  }

  interface PaletteColor {}
  interface Palette {
    menteBrandBlue: PaletteColor
    menteBrandRed: PaletteColor
    menteAccentLight: PaletteColor
    menteAccentDark: PaletteColor
    menteOrange: PaletteColor
    menteGrey: Record<string, string>
  }
  interface PaletteOptions {
    menteBrandBlue: PaletteColorOptions
    menteBrandRed: PaletteColorOptions
    menteAccentLight: PaletteColorOptions
    menteAccentDark: PaletteColorOptions
    menteOrange: PaletteColorOptions
    menteGrey: Record<string, string>
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    menteBrandBlue: true
    menteBrandRed: true
    menteAccentLight: true
    menteAccentDark: true
  }
}

const systemFonts = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
]

export const basicTheme = createTheme({
  // https://tailwindcss.com/docs/breakpoints
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      "2xl": 1536,
    },
  },
  palette: {
    primary: { main: "#0C48D7" },
    menteBrandBlue: { main: "#042675", contrastText: "#FFFFFF" },
    menteBrandRed: { main: "#FD4943", contrastText: "#FFFFFF" },
    menteAccentLight: { main: "#7DE7FA" },
    menteAccentDark: { main: "#0C48D7", contrastText: "#FFFFFF" },
    menteOrange: { main: "#FDA500" },
    menteGrey: {
      "1": "#F4F4F4",
      "2": "#E9E9E9",
      "3": "#B8B8B8",
      "4": "#7E7E7E",
    },
    text: {
      primary: "#222222",
    },
  },
  typography: {
    fontFamily: ["Lato", ...systemFonts].join(","),
    h1: { fontFamily: ["Mulish", ...systemFonts].join(",") },
    h2: { fontFamily: ["Mulish", ...systemFonts].join(",") },
    h3: { fontFamily: ["Mulish", ...systemFonts].join(",") },
    h4: { fontFamily: ["Mulish", ...systemFonts].join(",") },
    h5: { fontFamily: ["Mulish", ...systemFonts].join(",") },
    h6: { fontFamily: ["Mulish", ...systemFonts].join(",") },
  },
})

export const muiTheme = createTheme(basicTheme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
          fontWeight: "bold",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          "& > :not(style) + :not(style)": {
            marginLeft: basicTheme.spacing(2),
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          textTransform: "unset",
        },
      },
    },
  },
})


import { BookingStatus } from "../wakacje-types"

export class BookingUtils {
  static canBePaid(booking) {
    return (
      booking?.status === BookingStatus.RESERVED ||
      booking?.status === BookingStatus.ADVANCE_PAID ||
      booking?.status === BookingStatus.NEW
    )
  }
}

import { Button } from "@mui/material"
import { Routes, Route, useNavigate } from "react-router-dom"
import { PageTitle } from "../../components/typography"
import { CreateCompanyFirst } from "../../components/widgets/CreateCompanyFirst"
import { withServiceBlocked } from "../../components/widgets/ServiceBlocked"
import { useCompanyContext } from "../../contexts/company-context"
import { NewPosterPage } from "./NewPosterPage"
import { PosterOrderPage } from "./PosterOrderPage"
import { PosterOrdersTable } from "./PosterOrdersTable"

const PostersIndexInner = () => {
  const navigate = useNavigate()
  const companyContext = useCompanyContext()
  return (
    <div className="p-8">
      <PageTitle>
        Pakiet na FajnyCzas = Plakaty + ulotki + roczny dostęp
      </PageTitle>
      <div className="flex justify-between items-center">
        <div className="max-w-3xl">{/* <HelperHeader /> */}</div>
        <div>
          <Button
            className="mx-4"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/plakaty/dodaj")
            }}
          >
            Zamów pakiet
          </Button>
        </div>
      </div>
      {!companyContext.company && (
        <CreateCompanyFirst title="Przed zamówieniem plakatów - ustaw dane firmy" />
      )}
      {companyContext.company && (
        <>
          <PosterOrdersTable
            queryParams={{ company_id: companyContext.company.id }}
            nothingFoundComponent={() => (
              <div>Nie masz jeszcze zamowien plakatow</div>
            )}
          />
        </>
      )}
    </div>
  )
}

export const PostersIndex = withServiceBlocked(() => {
  const companyContext = useCompanyContext()
  if (!companyContext.company) {
    return (
      <CreateCompanyFirst title="Przed zamówieniem plakatów - ustaw dane firmy" />
    )
  }
  return (
    <div>
      <Routes>
        <Route
          path="dodaj"
          element={
            // companyContext.company ? (
            <NewPosterPage companyId={companyContext.company?.id} />
            // ) : (
            //   <>
            // <div>Przed dodaniem produktów ustaw dane firmy</div>
            //   </>
            // )
          }
        />
        <Route path=":id/*" element={<PosterOrderPage />}></Route>
        <Route index element={<PostersIndexInner />} />
      </Routes>
    </div>
  )
})

import axios from "axios"
import useAxios from "axios-hooks"

export const useKidsApi = () => {
  const list = async (pagination: any) => {
    var url = process.env.REACT_APP_WAKACJE_API_URL + "/v1/kids"
    var res = await axios.get(url)
    return res.data
  }

  const adminList = async (queryParams: any) => {
    /* todo - sorting on backend before returning partial data */
    var url = process.env.REACT_APP_WAKACJE_API_URL + "/v1/admin/kids"
    if (queryParams && Object.keys(queryParams).length > 0) {
      url += "?" + new URLSearchParams(queryParams).toString()
    }
    var res = await axios.get(url)
    return res.data
  }

  const get = async (id: any) => {
    var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/kids/${id}`
    var res = await axios.get(url)
    return res.data
  }
  return { list, get, adminList }
}

export const useKid = (id: string) => {
  var url = process.env.REACT_APP_WAKACJE_API_URL + `/v1/kids/${id}`
  const [{ data, loading }, refetch] = useAxios(url)
  return { loading, data, refetch }
}

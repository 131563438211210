export class PaymentUtils {
  static getStatusLabel(status) {
    if (status === "GATEWAY") {
      return "Oczekuje"
    }
    if (status === "VERIFIED") {
      return "Potwierdzona"
    }
    if (status === "CANCELED") {
      return "Anulowana"
    }
    return status
  }
}

import { Box } from "@mui/system"
import { useEffect } from "react"
import { useAuthContext } from "../../../contexts/auth-context"
import { Button, Container } from "@mui/material"

export const UserProfilePage = (props: any) => {
  const authContext = useAuthContext()

  useEffect(() => {
    authContext.refetchCurrentUser()
    // eslint-disable-next-line
  }, [])
  console.log("UserProfilePage", authContext)
  return (
    <Container maxWidth="lg">
      <div className="pt-8">
        <div>
          <div>Nazwa Użytkownika</div>
        </div>
        <div className="mb-8 font-bold">
          {authContext.currentUser
            ? authContext.currentUser.name ?? authContext.currentUser.email
            : "NIe zalogowany"}
        </div>
        <div>
          <div>Id</div>
          <div className="mb-8 font-bold">{authContext.currentUser?.id}</div>
        </div>

        <Box className="cursor-pointer">
          <Button
            onClick={(event: any) => authContext.logout()}
            variant="contained"
            color="primary"
          >
            Wyloguj
          </Button>
        </Box>
      </div>
    </Container>
  )
}

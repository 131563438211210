export const useCategories = (t) => {
  // let categoriesUrl = process.env.REACT_APP_WAKACJE_API_URL + `/v1/categories`

  const categories = [
    {
      id: "artystyczne.fotografia",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.photography", "Fotografia"),
      created_at: "2022-04-13T06:06:24.225Z",
      archived_at: null
    },
    {
      id: "artystyczne.makijaz",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.makeup", "Makijaż"),
      created_at: "2022-04-13T06:06:22.956Z",
      archived_at: null
    },
    {
      id: "artystyczne.muzyczne",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.music", "Muzyczne"),
      created_at: "2022-04-14T03:56:02.918Z",
      archived_at: null
    },
    {
      id: "artystyczne.malowanie",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.painting", "Plastyczne"),
      created_at: "2022-04-13T06:06:20.420Z",
      archived_at: null
    },
    {
      id: "artystyczne.szycie",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.sewing", "Szycie"),
      created_at: "2022-04-13T06:06:21.693Z",
      archived_at: null
    },
    {
      id: "artystyczne.teatralne",
      category: t("categories.artistic", "Artystyczne"),
      subcategory: t("categories.theatre", "Teatralne"),
      created_at: "2022-04-13T06:06:25.491Z",
      archived_at: null
    },
    {
      id: "jezykowe.angielski",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.english", "Angielski"),
      created_at: "2022-04-13T06:05:53.700Z",
      archived_at: null
    },
    {
      id: "jezykowe.francuski",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.french", "Francuski"),
      created_at: "2022-04-13T06:05:56.220Z",
      archived_at: null
    },
    {
      id: "jezykowe.hiszpański",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.spanish", "Hiszpański"),
      created_at: "2022-04-13T06:05:54.961Z",
      archived_at: null
    },
    {
      id: "jezykowe.inny",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.others", "Inne"),
      created_at: "2022-04-13T06:05:58.756Z",
      archived_at: null
    },
    {
      id: "jezykowe.niemiecki",
      category: t("categories.language", "Językowe"),
      subcategory: t("categories.german", "Niemiecki"),
      created_at: "2022-04-13T06:05:57.487Z",
      archived_at: null
    },
    {
      id: "komputerowe.e-sport",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.e-sport", "E-sport"),
      created_at: "2022-04-13T06:06:26.769Z",
      archived_at: null
    },
    {
      id: "komputerowe.influencer",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.influencer", "Influencer"),
      created_at: "2022-04-13T06:06:28.043Z",
      archived_at: null
    },
    {
      id: "komputerowe.programowanie",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.programming", "Programowanie"),
      created_at: "2022-04-13T06:06:30.577Z",
      archived_at: null
    },
    {
      id: "komputerowe.roboty-drony",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.robotics_and_drones", "Robotyka i drony"),
      created_at: "2022-04-13T06:06:31.833Z",
      archived_at: null
    },
    {
      id: "komputerowe.youtuber",
      category: t("categories.computer", "Komputerowe"),
      subcategory: t("categories.youtuber", "Youtuber"),
      created_at: "2022-04-13T06:06:29.316Z",
      archived_at: null
    },
    {
      id: "lokalizacja.gory",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.mountains", "Góry"),
      created_at: "2022-04-13T06:06:08.987Z",
      archived_at: null
    },
    {
      id: "lokalizacja.jeziora",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.lakes", "Jeziora"),
      created_at: "2022-04-13T06:06:10.253Z",
      archived_at: null
    },
    {
      id: "lokalizacja.las",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.forest", "Las"),
      created_at: "2022-04-13T06:06:14.067Z",
      archived_at: null
    },
    {
      id: "lokalizacja.miasto",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.city", "Miasto"),
      created_at: "2022-04-13T06:06:12.796Z",
      archived_at: null
    },
    {
      id: "lokalizacja.morze",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.sea", "Morze"),
      created_at: "2022-04-13T06:06:07.698Z",
      archived_at: null
    },
    {
      id: "lokalizacja.rzeka",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.river", "Rzeka"),
      created_at: "2022-05-20T09:55:20.087Z",
      archived_at: null
    },
    {
      id: "lokalizacja.wies",
      category: t("categories.location", "Lokalizacja"),
      subcategory: t("categories.village", "Wieś"),
      created_at: "2022-04-13T06:06:11.523Z",
      archived_at: null
    },
    {
      id: "pozostale.harcerskie",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.scouting", "Harcerskie"),
      created_at: "2022-04-13T05:58:06.963Z",
      archived_at: null
    },
    {
      id: "pozostale.kulinarne",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.culinary", "Kulinarne"),
      created_at: "2022-05-20T09:38:46.783Z",
      archived_at: null
    },
    {
      id: "pozostale.lego",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.lego", "Lego"),
      created_at: "2022-05-20T09:38:48.165Z",
      archived_at: null
    },
    {
      id: "pozostale.magia-fantasy",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.magic_and_fantasy", "Magia i fantasy"),
      created_at: "2022-09-19T10:56:43.232Z",
      archived_at: null
    },
    {
      id: "pozostale.naukowe",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.scientific", "Naukowe"),
      created_at: "2022-06-07T10:20:49.153Z",
      archived_at: null
    },
    {
      id: "pozostale.ogolnorekreacyjny",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.general_recreational", "Ogólnorekreacyjny"),
      created_at: "2022-04-13T05:58:05.704Z",
      archived_at: null
    },
    {
      id: "pozostale.przygodowe",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.adventurous", "Przygodowe"),
      created_at: "2022-05-20T09:38:45.385Z",
      archived_at: null
    },
    {
      id: "pozostale.wedrowne",
      category: t("categories.others", "Pozostałe"),
      subcategory: t("categories.wandering", "Wędrowne"),
      created_at: "2022-09-19T10:57:01.297Z",
      archived_at: null
    },
    {
      id: "sportowe.akrobatyka",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.acrobatics", "Akrobatyka"),
      created_at: "2022-04-19T13:41:46.321Z",
      archived_at: null
    },
    {
      id: "sportowe.bieganie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.running", "Bieganie"),
      created_at: "2022-09-15T10:22:14.269Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.dogoterapia",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.dog_therapy", "Dogoterapia"),
      created_at: "2022-06-03T07:31:02.554Z",
      archived_at: null
    },
    {
      id: "sportowe.hokej",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.hockey", "Hokej"),
      created_at: "2022-09-15T10:22:21.944Z",
      archived_at: null
    },
    {
      id: "sportowe.jezdzieckie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.equestrian", "Jeździeckie"),
      created_at: "2022-04-13T06:05:48.610Z",
      archived_at: null
    },
    {
      id: "sportowe.joga",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.yoga", "Joga"),
      created_at: "2022-06-03T07:29:57.521Z",
      archived_at: null
    },
    {
      id: "sportowe.kitesurfing",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.kitesurfing", "Kitesurfing"),
      created_at: "2022-04-13T06:05:43.524Z",
      archived_at: null
    },
    {
      id: "sportowe.lyzwiarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.ice_skating", "Łyżwiarstwo"),
      created_at: "2022-09-15T10:22:28.758Z",
      archived_at: null
    },
    {
      id: "sportowe.narciarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.skiing", "Narciarstwo"),
      created_at: "2022-09-15T10:22:37.842Z",
      archived_at: null
    },

    {
      id: "sportowe.nordic-walking",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.nordic_walking", "Nordic Walking"),
      created_at: "2022-09-15T10:22:56.113Z",
      archived_at: null
    },
    {
      id: "sportowe.nurkowanie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.diving", "Nurkowanie"),
      created_at: "2022-06-03T07:29:58.899Z",
      archived_at: null
    },
    {
      id: "sportowe.paintball",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.paintball", "Paintball"),
      created_at: "2022-09-15T10:23:02.258Z",
      archived_at: null
    },
    {
      id: "sportowe.pilkarskie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.football", "Piłkarskie"),
      created_at: "2022-04-13T06:05:46.065Z",
      archived_at: null
    },
    {
      id: "sportowe.plywanie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.swimming", "Pływanie"),
      created_at: "2022-06-03T07:30:00.328Z",
      archived_at: null
    },
    {
      id: "sportowe.rowerowe",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.cycling", "Rowerowe"),
      created_at: "2022-04-13T06:05:52.443Z",
      archived_at: null
    },
    {
      id: "sportowe.saneczkarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.luge", "Saneczkarstwo"),
      created_at: "2022-09-15T10:23:11.503Z",
      archived_at: null
    },
    {
      id: "sportowe.siatkarstwo",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.volleyball", "Siatkarstwo"),
      created_at: "2022-09-15T10:23:18.546Z",
      archived_at: null
    },
    {
      id: "sportowe.skitouring",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.skitouring", "Skitouring"),
      created_at: "2022-09-15T10:23:25.812Z",
      archived_at: null
    },
    {
      id: "sportowe.snowboard",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.snowboarding", "Snowboard"),
      created_at: "2022-09-15T10:23:34.229Z",
      archived_at: null
    },
    {
      id: "sportowe.Strzeleckie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.shooting", "Strzeleckie"),
      created_at: "2022-06-03T07:30:01.783Z",
      archived_at: null
    },
    {
      id: "sportowe.survival",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.survival", "Survival"),
      created_at: "2022-04-13T06:05:49.873Z",
      archived_at: null
    },
    {
      id: "sportowe.szachy",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.chess", "Szachy"),
      created_at: "2022-05-20T09:40:28.772Z",
      archived_at: null
    },
    {
      id: "sportowe.sztuki-walki",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.martial_arts", "Sztuki Walki"),
      created_at: "2022-04-19T13:41:52.844Z",
      archived_at: null
    },
    {
      id: "sportowe.taniec",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.dance", "Taniec"),
      created_at: "2022-04-13T06:05:51.186Z",
      archived_at: null
    },
    {
      id: "sportowe.tenisowe",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.tennis", "Tenis"),
      created_at: "2022-04-13T06:05:47.339Z",
      archived_at: null
    },
    {
      id: "sportowe.wedkarskie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.fishing", "Wędkarskie"),
      created_at: "2022-04-13T06:05:40.992Z",
      archived_at: null
    },
    {
      id: "sportowe.windsurfing",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.windsurfing", "Windsurfing"),
      created_at: "2022-04-13T06:05:42.265Z",
      archived_at: null
    },
    {
      id: "sportowe.wspinaczka",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.climbing", "Wspinaczka"),
      created_at: "2022-04-13T06:05:44.788Z",
      archived_at: null
    },
    {
      id: "sportowe.zeglarskie",
      category: t("categories.sports", "Sportowe"),
      subcategory: t("categories.sailing", "Żeglarskie"),
      created_at: "2022-04-13T05:58:08.221Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.odwykowe",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t("categories.rehabilitation", "Odwykowe"),
      created_at: "2022-04-13T06:06:15.351Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.wychowawcze",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t("categories.educational", "Wychowawcze"),
      created_at: "2022-04-13T06:06:19.155Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.zaburzenia-emocjonalne",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t(
        "categories.emotional_disorders",
        "Zaburzenia emocjonalne"
      ),
      created_at: "2022-04-13T06:06:17.890Z",
      archived_at: null
    },
    {
      id: "terapeutyczne.zaburzenia-zywienia",
      category: t("categories.therapeutic", "Terapeutyczne"),
      subcategory: t("categories.eating_disorders", "Zaburzenia żywienia"),
      created_at: "2022-04-13T06:06:16.620Z",
      archived_at: null
    }
  ]

  const categoriesByCode = categories
    ? categories.reduce((a, i) => ({ ...a, [i.id]: i.subcategory }), {})
    : []

  // const [{ data, loading }] = useAxios(categoriesUrl, { useCache: false })

  const amenitiesList = [
    {
      id: "udogodnienia.akceptujemy-zwierzęta",
      name: t("amenities.accept_pets", "Akceptujemy zwierzęta"),
      iconSrc: "/svg/pet.svg"
    },
    {
      id: "udogodnienia.altana",
      name: t("amenities.gazebo", "Altana"),
      iconSrc: "/svg/green-house.svg"
    },
    {
      id: "udogodnienia.bar",
      name: t("amenities.bar", "Bar"),
      iconSrc: "/svg/beer-mug.svg"
    },
    {
      id: "udogodnienia.basen-całoroczny",
      name: t("amenities.year_round_pool", "Basen całoroczny"),
      iconSrc: "/svg/swimming-pool.svg"
    },
    {
      id: "udogodnienia.basen-zewnętrzny",
      name: t("amenities.outdoor_pool", "Basen zewnętrzny"),
      iconSrc: "/svg/swimming-pool.svg"
    },
    {
      id: "udogodnienia.bilard",
      name: t("amenities.billiards", "Bilard"),
      iconSrc: "/svg/black-eight.svg"
    },
    {
      id: "udogodnienia.boisko-do-koszykówki",
      name: t("amenities.basketball_court", "Boisko do koszykówki"),
      iconSrc: "/svg/basketball-stand.svg"
    },
    {
      id: "udogodnienia.boisko-do-piłki-nożnej",
      name: t("amenities.football_field", "Boisko do piłki nożnej"),
      iconSrc: "/svg/football.svg"
    },
    {
      id: "udogodnienia.boisko-do-siatkówki",
      name: t("amenities.volleyball_court", "Boisko do siatkówki"),
      iconSrc: "/svg/volleyball.svg"
    },
    {
      id: "udogodnienia.grill",
      name: t("amenities.bbq", "Grill"),
      iconSrc: "/svg/hot-pot.svg"
    },
    {
      id: "udogodnienia.kajak",
      name: t("amenities.kayak", "Kajak"),
      iconSrc: "/svg/rowing.svg"
    },
    {
      id: "udogodnienia.kawiarnia",
      name: t("amenities.cafe", "Kawiarnia"),
      iconSrc: "/svg/tea-drink.svg"
    },
    {
      id: "udogodnienia.łódka",
      name: t("amenities.boat", "Łódka"),
      iconSrc: "/svg/sailboat.svg"
    },
    {
      id: "udogodnienia.miejsce-na-ognisko",
      name: t("amenities.campfire_site", "Miejsce na ognisko"),
      iconSrc: "/svg/fire-two.svg"
    },
    {
      id: "udogodnienia.mini-golf",
      name: t("amenities.mini_golf", "Mini golf"),
      iconSrc: "/svg/golf-course.svg"
    },
    {
      id: "udogodnienia.monitoring",
      name: t("amenities.monitoring", "Monitoring"),
      iconSrc: "/svg/surveillance-cameras.svg"
    },
    {
      id: "udogodnienia.ogród",
      name: t("amenities.garden", "Ogród"),
      iconSrc: "/svg/leaves.svg"
    },
    {
      id: "udogodnienia.parking-bezpłatny",
      name: t("amenities.free_parking", "Parking bezpłatny"),
      iconSrc: "/svg/parking.svg"
    },
    {
      id: "udogodnienia.parking-płatny",
      name: t("amenities.paid_parking", "Parking płatny"),
      iconSrc: "/svg/parking.svg"
    },
    {
      id: "udogodnienia.piłkarzyki",
      name: t("amenities.foosball", "Piłkarzyki"),
      iconSrc: "/svg/tambambule.svg"
    },
    {
      id: "udogodnienia.plac-zabaw-dla-duży-dzieci",
      name: t(
        "amenities.playground_for_big_kids",
        "Plac zabaw dla dużych dzieci"
      ),
      iconSrc: "/svg/children-pyramid.svg"
    },
    {
      id: "udogodnienia.plac-zabaw-dla-mały-dzieci",
      name: t(
        "amenities.playground_for_small_kids",
        "Plac zabaw dla małych dzieci"
      ),
      iconSrc: "/svg/baby-sling.svg"
    },
    {
      id: "udogodnienia.plaża",
      name: t("amenities.beach", "Plaża"),
      iconSrc: "/svg/sunshade.svg"
    },
    {
      id: "udogodnienia.pole-namiotowe",
      name: t("amenities.camping_field", "Pole namiotowe"),
      iconSrc: "/svg/camp.svg"
    },
    {
      id: "udogodnienia.pomost",
      name: t("amenities.pier", "Pomost"),
      iconSrc: "/svg/bridge-two.svg"
    },
    {
      id: "udogodnienia.pralnia",
      name: t("amenities.laundry", "Pralnia"),
      iconSrc: "/svg/washing-machine.svg"
    },
    {
      id: "udogodnienia.restauracja",
      name: t("amenities.restaurant", "Restauracja"),
      iconSrc: "/svg/knife-fork.svg"
    },
    {
      id: "udogodnienia.rowery",
      name: t("amenities.bicycles", "Rowery"),
      iconSrc: "/svg/riding.svg"
    },
    {
      id: "udogodnienia.rzutki",
      name: t("amenities.darts", "Rzutki"),
      iconSrc: "/svg/darts.svg"
    },
    {
      id: "udogodnienia.sala-gier",
      name: t("amenities.game_room", "Sala gier"),
      iconSrc: "/svg/game-three.svg"
    },
    {
      id: "udogodnienia.sala-imprezowa",
      name: t("amenities.party_room", "Sala imprezowa"),
      iconSrc: "/svg/music-cd.svg"
    },
    {
      id: "udogodnienia.sala-konferencyjna",
      name: t("amenities.conference_room", "Sala konferencyjna"),
      iconSrc: "/svg/every-user.svg"
    },
    {
      id: "udogodnienia.sala-telewizyjna",
      name: t("amenities.tv_room", "Sala telewizyjna"),
      iconSrc: "/svg/video.svg"
    },
    {
      id: "udogodnienia.sala-zabaw",
      name: t("amenities.playroom", "Sala zabaw"),
      iconSrc: "/svg/school.svg"
    },
    {
      id: "udogodnienia.sanitariat",
      name: t("amenities.sanitary_facilities", "Sanitariat"),
      iconSrc: "/svg/public-toilet.svg"
    },
    {
      id: "udogodnienia.siłownia",
      name: t("amenities.gym", "Siłownia"),
      iconSrc: "/svg/gym.svg"
    },
    {
      id: "udogodnienia.spa",
      name: t("amenities.spa", "Spa"),
      iconSrc: "/svg/green-new-energy.svg"
    },
    {
      id: "udogodnienia.sprzęt-grający",
      name: t("amenities.audio_equipment", "Sprzęt grający"),
      iconSrc: "/svg/radio-one.svg"
    },
    {
      id: "udogodnienia.stołówka",
      name: t("amenities.dining_room", "Stołówka"),
      iconSrc: "/svg/chopsticks-fork.svg"
    },
    {
      id: "udogodnienia.taras",
      name: t("amenities.terrace", "Taras"),
      iconSrc: "/svg/sunbath.svg"
    },
    {
      id: "udogodnienia.tenis",
      name: t("amenities.tennis", "Tenis"),
      iconSrc: "/svg/tennis.svg"
    },
    {
      id: "udogodnienia.udogodnienia-dla-osób-niepełnosprawnych",
      name: t(
        "amenities.disabled_facilities",
        "Udogodnienia dla osób niepełnosprawnych"
      ),
      iconSrc: "/svg/wheelchair.svg"
    },
    {
      id: "udogodnienia.zjeżdżalnia",
      name: t("amenities.slide", "Zjeżdżalnia"),
      iconSrc: "/svg/escalators.svg"
    }
  ]

  const typeList = [
    {
      id: "rodzaj.agroturystyka",
      name: t("place_type.agrotourism", "Agroturystyka"),
      iconSrc: "/svg/house-chimney-floor.svg"
    },
    {
      id: "rodzaj.apartament-mieszkanie",
      name: t("place_type.apartment_flat", "Apartament/Mieszkanie"),
      iconSrc: "/svg/sofa.svg"
    },
    {
      id: "rodzaj.dom-pracy-twórczej",
      name: t("place_type.creative_work_house", "Dom pracy twórczej"),
      iconSrc: "/svg/house-sun.svg"
    },
    {
      id: "rodzaj.dom-wycieczkowy",
      name: t("place_type.tourist_house", "Dom wycieczkowy"),
      iconSrc: "/svg/house-chimney-blank.svg"
    },
    {
      id: "rodzaj.domek-letniskowy",
      name: t("place_type.summer_house", "Domek letniskowy"),
      iconSrc: "/svg/house-chimney-user.svg"
    },
    {
      id: "rodzaj.hotel",
      name: t("place_type.hotel", "Hotel"),
      iconSrc: "/svg/hotel.svg"
    },
    {
      id: "rodzaj.hostel-motel",
      name: t("place_type.hostel_motel", "Hostel / Motel"),
      iconSrc: "/svg/building.svg"
    },
    {
      id: "rodzaj.kemping",
      name: t("place_type.camping", "Kemping"),
      iconSrc: "/svg/campground.svg"
    },
    {
      id: "rodzaj.ośrodek-kolonijny",
      name: t("place_type.camp_center", "Ośrodek kolonijny"),
      iconSrc: "/svg/city.svg"
    },
    {
      id: "rodzaj.ośrodek-szkoleniowo-wczasowy",
      name: t(
        "place_type.training_vacation_center",
        "Ośrodek szkoleniowo-wczasowy"
      ),
      iconSrc: "/svg/building-four.svg"
    },
    {
      id: "rodzaj.ośrodek-wczasowy",
      name: t("place_type.vacation_center", "Ośrodek wczasowy"),
      iconSrc: "/svg/garage-open.svg"
    },
    {
      id: "rodzaj.pensjonat",
      name: t("place_type.guest_house", "Pensjonat"),
      iconSrc: "/svg/house.svg"
    },
    {
      id: "rodzaj.pokój-gościnny-prywatna-kwatera",
      name: t(
        "place_type.guest_room_private_accommodation",
        "Pokój gościnny/prywatna kwatera"
      ),
      iconSrc: "/svg/single-bed.svg"
    },
    {
      id: "rodzaj.pole-biwakowe",
      name: t("place_type.camping_site", "Pole biwakowe"),
      iconSrc: "/svg/camp.svg"
    },
    {
      id: "rodzaj.schronisko",
      name: t("place_type.shelter", "Schronisko"),
      iconSrc: "/svg/building-three.svg"
    },
    {
      id: "rodzaj.schronisko-młodzieżowe",
      name: t("place_type.youth_hostel", "Schronisko młodzieżowe"),
      iconSrc: "/svg/weixin-people-nearby.svg"
    },
    {
      id: "rodzaj.szkolne-schronisko-młodzieżowe",
      name: t(
        "place_type.school_youth_hostel",
        "Szkolne schronisko młodzieżowe"
      ),
      iconSrc: "/svg/school.svg"
    },
    {
      id: "rodzaj.zakład-uzdrowiskowy",
      name: t("place_type.spa_facility", "Zakład uzdrowiskowy"),
      iconSrc: "/svg/geometric-flowers.svg"
    },
    {
      id: "rodzaj.inny-obiekt-noclegowy",
      name: t("place_type.other_accommodation", "Inny obiekt noclegowy"),
      iconSrc: "/svg/figma-component.svg"
    }
  ]

  const accomodationTypeList = [
    {
      id: "nocleg.apartment",
      name: t("accomodation.apartment", "Apartment"),
      value: "nocleg.apartment"
    },
    {
      id: "nocleg.domek",
      name: t("accomodation.cottage", "Domek"),
      value: "nocleg.domek"
    },
    {
      id: "nocleg.mieszkanie",
      name: t("accomodation.flat", "Mieszkanie"),
      value: "nocleg.mieszkanie"
    },
    {
      id: "nocleg.namiot",
      name: t("accomodation.tent", "Namiot"),
      value: "nocleg.namiot"
    },
    {
      id: "nocleg.pokój",
      name: t("accomodation.room", "Pokój"),
      value: "nocleg.pokój"
    }
  ]

  const roomAmenitiesList = [
    {
      id: "pokój.aneks-kuchenny",
      name: t("room_amenities.kitchenette", "Aneks kuchenny"),
      iconSrc: "/svg/tea-drink.svg"
    },
    {
      id: "pokój.balkon",
      name: t("room_amenities.balcony", "Balkon"),
      iconSrc: "/svg/landscape.svg"
    },
    {
      id: "pokój.czajnik",
      name: t("room_amenities.kettle", "Czajnik"),
      iconSrc: "/svg/kettle.svg"
    },
    {
      id: "pokój.dostawka-dla-dziecka",
      name: t("room_amenities.child_extra_bed", "Dostawka dla dziecka"),
      iconSrc: "/svg/bedding.svg"
    },
    {
      id: "pokój.krzesełko-do-karmienia-dziecka",
      name: t(
        "room_amenities.child_high_chair",
        "Krzesełko do karmienia dziecka"
      ),
      iconSrc: "/svg/chair-one.svg"
    },
    {
      id: "pokój.kuchenka",
      name: t("room_amenities.cooker", "Kuchenka"),
      iconSrc: "/svg/cook.svg"
    },
    {
      id: "pokój.lodówka",
      name: t("room_amenities.fridge", "Lodówka"),
      iconSrc: "/svg/refrigerator.svg"
    },
    {
      id: "pokój.łazienka-prywatna",
      name: t("room_amenities.private_bathroom", "Łazienka prywatna"),
      iconSrc: "/svg/toilet.svg"
    },
    {
      id: "pokój.łazienka-współdzielona",
      name: t("room_amenities.shared_bathroom", "Łazienka współdzielona"),
      iconSrc: "/svg/public-toilet.svg"
    },
    {
      id: "pokój.łóżko-pojedyncze",
      name: t("room_amenities.single_bed", "Łóżko pojedyncze"),
      iconSrc: "/svg/single-bed.svg"
    },
    {
      id: "pokój.łóżko-podwójne",
      name: t("room_amenities.double_bed", "Łóżko podwójne"),
      iconSrc: "/svg/double-bed.svg"
    },
    {
      id: "pokój.mikrofalówka",
      name: t("room_amenities.microwave", "Mikrofalówka"),
      iconSrc: "/svg/microwave-oven.svg"
    },
    {
      id: "pokój.podstawowe-naczynia",
      name: t("room_amenities.basic_dishes", "Podstawowe naczynia"),
      iconSrc: "/svg/update-rotation.svg"
    },
    {
      id: "pokój.pościel",
      name: t("room_amenities.bedding", "Pościel"),
      iconSrc: "/svg/book.svg"
    },
    {
      id: "pokój.pralka",
      name: t("room_amenities.washing_machine", "Pralka"),
      iconSrc: "/svg/washing-machine.svg"
    },
    {
      id: "pokój.prysznic",
      name: t("room_amenities.shower", "Prysznic"),
      iconSrc: "/svg/shower-head.svg"
    },
    {
      id: "pokój.przewijak",
      name: t("room_amenities.changing_table", "Przewijak"),
      iconSrc: "/svg/child-with-pacifier.svg"
    },
    {
      id: "pokój.ręczniki",
      name: t("room_amenities.towels", "Ręczniki"),
      iconSrc: "/svg/towel.svg"
    },
    {
      id: "pokój.suszarka",
      name: t("room_amenities.hair_dryer", "Suszarka"),
      iconSrc: "/svg/hair-dryer.svg"
    },
    {
      id: "pokój.sztućce",
      name: t("room_amenities.cutlery", "Sztućce"),
      iconSrc: "/svg/knife-fork.svg"
    },
    {
      id: "pokój.telewizor",
      name: t("room_amenities.television", "Telewizor"),
      iconSrc: "/svg/tv.svg"
    },
    {
      id: "pokój.wanienka-do-kąpieli",
      name: t("room_amenities.baby_bathtub", "Wanienka do kąpieli"),
      iconSrc: "/svg/bath.svg"
    },
    {
      id: "pokój.wanna",
      name: t("room_amenities.bathtub", "Wanna"),
      iconSrc: "/svg/bath.svg"
    },
    {
      id: "pokój.wi-fi",
      name: t("room_amenities.wifi", "Wi-fi"),
      iconSrc: "/svg/wifi.svg"
    },
    {
      id: "pokój.żelazko",
      name: t("room_amenities.iron", "Żelazko"),
      iconSrc: "/svg/iron-three.svg"
    }
  ]

  return {
    categories,
    categoriesByCode,
    loading: false,
    amenitiesList,
    typeList,
    accomodationTypeList,
    roomAmenitiesList
  }
}

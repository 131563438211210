import React from "react"
import { Header } from "./Header"

type PageProps = {
  children: React.ReactNode
}

export default function Page({ children }: PageProps) {
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <Header />
      <div className="flex-auto flex items-stretch flex-1 min-h-[0px]">
        {children}
      </div>
    </div>
  )
}

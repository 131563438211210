import { Routes, Route } from "react-router-dom"
import { ApiTable } from "../../../components/table/ApiTable"
import { SuperAdminCompanyPage } from "../SuperAdminCompanyPage"
import { FormattedDate, FormattedTime, useIntl } from "react-intl"
import axios from "axios"
import { BookingStatusChip } from "wakacje-web"
import Toolbar from "../Toolbar/Toolbar"
import { useSearchText } from "../useSearchText"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { testIdList } from "../../../utils/testDataID"
import MenuItem from "@mui/material/MenuItem"
import { Select } from "@mui/material"
import { useState } from "react"
import { ProductTypes } from "wakacje-web/src/utils/ProductUtils"
import { useCurrency } from "../../../hooks/useCurrency"

const listBookings = async (data) => {
  console.log("List bookings ", process.env)
  var url =
    process.env.REACT_APP_WAKACJE_HTTP_API_URL +
    `/bookings/v2.0/bff/super/bookings`
  data = data ?? {}
  data.view = "super"
  if (data) {
    url += "?" + new URLSearchParams(data).toString()
  }
  var res = await axios.get(url)
  if (process.env.NODE_ENV === "production" && res.data && res.data.data) {
    let allReservations = res.data.data
    res.data.data = allReservations.filter(
      (item) => !testIdList.includes(item.id)
    )
  }
  return res.data
}

const SuperBookingsTable = ({ queryParams }) => {
  const intl = useIntl()

  const { selectedCurrency } = useCurrency()

  const columns = [
    {
      header: "Data zgłoszenia",
      cell: (row: any) => (
        <div>
          <FormattedDate value={row.created_at} />{" "}
          <FormattedTime value={row.created_at} />{" "}
        </div>
      ),
      align: "right",
      field: "created_at"
    },
    {
      header: "Nazwa firmy",
      field: "company_name",
      cell: (row: any) => <div>{row.company_name}</div>
    },
    {
      header: "Nazwa wydarzenia",
      width: 250,
      cell: (row: any) => (
        <div className="text-left text-overflow-ellipsis overflow-ellipsis">
          <a
            href={`http://www.fajnyczas.pl/${row.company_id}/${row.product_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="whitespace-nowrap overflow-hidden max-w-full w-[250px] overflow-ellipsis">
              {row.product_name}
            </div>
            <div>{row.trip_name}</div>
          </a>
        </div>
      )
    },
    {
      header: "Termin",
      cell: (row: any) => (
        <>
          <div className="leading-none font-bold text-xs">
            <p className="whitespace-nowrap">{row.start_date}-</p>
            <p className="whitespace-nowrap">-{row.end_date}</p>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: "Typ",
      cell: (row: any) => (
        <>
          <div className="leading-none font-bold text-xs">
            <p className="whitespace-nowrap ">
              {ProductUtils.getTypeName(row.product_type)}
            </p>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: "Opiekun",
      field: "parent_name",
      cell: (row: any) => (
        <div>
          <div>
            {row.parent?.first_name} {row.parent?.last_name}
          </div>
          <div>{row.parent?.email}</div>
          <div>{row.parent?.phone}</div>
        </div>
      )
    },
    {
      header: "Liczba miejsc",
      field: "participants_count",
      cell: (row: any) => <div>{row.participants_count}</div>
    },
    {
      header: "Kwota",
      cell: (row: any) => (
        <>
          <div className="text-xs leading-none">
            <span className="whitespace-nowrap">
              {ProductUtils.formatPriceInCents(
                row.paid_amount ?? 0,
                false,
                selectedCurrency.symbol
              )}
            </span>
            <span>{"/"}</span>
            <span className="whitespace-nowrap">
              {row.total_price
                ? ProductUtils.formatPriceInCents(
                    row.total_price,
                    false,
                    selectedCurrency.symbol
                  )
                : ""}
            </span>
          </div>
        </>
      ),
      sort: false
    },
    {
      header: "Nr rezerwacji",
      cell: (row: any) => <div className="text-left">{row.id}</div>,
      field: "id"
    },
    {
      header: "Status",
      cell: (row: any) => {
        var title = row.fully_paid_at
          ? "Całkowicie opłacone " +
            intl.formatDate(row.fully_paid_at) +
            " " +
            intl.formatTime(row.fully_paid_at)
          : ""
        return (
          <div className="text-left whitespace-nowrap" title={title}>
            <BookingStatusChip booking={row} />
          </div>
        )
      },
      field: "status"
    }
  ]

  return (
    <ApiTable
      columns={columns}
      listApi={listBookings}
      showExpandColumn
      // rowLinkBuilder={(row: any) => `/bookings/${row.id}`}
      queryParams={queryParams}
    ></ApiTable>
  )
}

export const SuperAdminBookings = () => {
  const { setSearchTextDebounced, queryParams } = useSearchText()
  const [type, setType] = useState("all")
  const [status, setStatus] = useState("all")

  const mergedQueryParams = { ...queryParams }
  // const mergedQueryParams: {[k: string]: any} = {};

  if (type && type !== "all") {
    let param = type
    // if(type === "puste") {
    //   param = null
    // }
    mergedQueryParams.product_type = param
  }
  if (status && status !== "all") {
    mergedQueryParams.status = status
  }

  return (
    <>
      <Toolbar
        setSearchTextDebounced={setSearchTextDebounced}
        api={listBookings}
        queryParams={mergedQueryParams}
      >
        <div className="flex flex-row gap-2 items-center">
          <div>Rodzaj</div>
          <Select
            className="ml-4"
            value={type}
            inputProps={{
              "aria-labelledby": "table-page-select-label"
            }}
            onChange={(e) => setType(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: "min-content"
                }
              }
            }}
          >
            <MenuItem value={"all"}>Wszystkie</MenuItem>
            <MenuItem value={ProductTypes.CAMP}>Kolonie i obozy</MenuItem>
            <MenuItem value={ProductTypes.DAYCAMP}>Półkolonie</MenuItem>
            <MenuItem value={ProductTypes.SCHOOL_TRIP}>
              Wycieczki szkolne
            </MenuItem>
            {/* <MenuItem value={"puste"}>puste</MenuItem> */}
          </Select>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div>Status</div>
          <Select
            className="ml-4"
            value={status}
            inputProps={{
              "aria-labelledby": "table-page-select-label"
            }}
            onChange={(e) => setStatus(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  width: "min-content"
                }
              }
            }}
          >
            <MenuItem value={"all"}>Wszystkie</MenuItem>
            <MenuItem value={"FULLY_PAID"}>rezerwacja opłacona</MenuItem>
            <MenuItem value={"NEW"}>nowe zgłoszenie</MenuItem>
            <MenuItem value={"ADVANCE_PAID"}>zaliczka opłacona</MenuItem>
            <MenuItem value={"RESIGNED"}>rezygnacja</MenuItem>
            <MenuItem value={"RESERVED"}>zarezerwowano</MenuItem>
          </Select>
        </div>
      </Toolbar>

      <Routes>
        <Route path=":id" element={<SuperAdminCompanyPage />} />
        <Route
          index
          element={<SuperBookingsTable queryParams={mergedQueryParams} />}
        />
      </Routes>
    </>
  )
}

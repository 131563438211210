import { debounce } from "@mui/material"
import { useState } from "react"

export const useSearchText = () => {
  const [searchText, setSearchText] = useState("")
  const queryParams: any = {}
  if (searchText) {
    queryParams.search = searchText
    queryParams.query = searchText
  }
  const setSearchTextDebounced = debounce(async (name) => {
    setSearchText(name)
  }, 500)

  return { searchText, setSearchTextDebounced, queryParams }
}

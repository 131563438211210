import React from "react"
import { useProductApi } from "../events/api/useProductApi"
import { getLocalizedKey } from "../../../utils/getLocalizedKey"
import { useTranslation } from "react-i18next"
import { EditableFormSection } from "../../../components/forms/EditableFormSection"
import * as Yup from "yup"
import {
  ProductSectionDescriptionForm,
  ProductSectionDescriptionPreview
} from "./ProductSectionDescription"
import {
  ProductSectionAccommodationForm,
  ProductSectionAccommodationPreview
} from "./ProductSectionAccommodation"
import {
  ProductSectionLocationForm,
  ProductSectionLocationPreview
} from "../products/forms/ProductSectionLocation"
import {
  ProductSectionPriceForm,
  ProductSectionPricePreview
} from "./ProductSectionPrice"
import {
  ProductSectionPlacesForm,
  ProductSectionPlacesPreview
} from "../products/forms/ProductSectionPlace/ProductSectionPlace"
import { SectionTitle } from "../../../components/FormCommons"

export const productGroupsAccommodationSchema = (t) => {
  return Yup.object().shape({
    [getLocalizedKey("name")]: Yup.string().required(
      t(
        "groups_validation_messages.name_required",
        "Nazwa oferty jest wymagana"
      )
    ),
    object_type: Yup.array()
      .nullable()
      .default([])
      .required(
        t(
          "groups_validation_messages.category_required",
          "Kategorie są wymagane"
        )
      )
      .test(
        "non-empty",
        t(
          "groups_validation_messages.category_required",
          "Kategorie są wymagane"
        ),
        function (value) {
          return Array.isArray(value) && value.length > 0
        }
      ),
    [getLocalizedKey("description")]: Yup.string().required(
      t(
        "groups_validation_messages.description_required",
        "Opis oferty jest wymagany"
      )
    ),
    facility_amenities: Yup.array()
      .default([])
      .required(
        t(
          "groups_validation_messages.facility_amenities_required",
          "Udogodnienia obiektu są wymagane"
        )
      )
      .test(
        "non-empty",
        t(
          "groups_validation_messages.facility_amenities_required",
          "Udogodnienia obiektu są wymagane"
        ),
        function (value) {
          return Array.isArray(value) && value.length > 0
        }
      ),
    food_type: Yup.string().required(
      t(
        "groups_validation_messages.food_type_required",
        "Wyżywienie jest wymagane"
      )
    ),
    [getLocalizedKey("food_description")]: Yup.string()
      .nullable()
      .when("food_type", {
        is: "tak",
        then: Yup.string().required(
          t(
            "groups_validation_messages.food_description_required",
            "Szczegóły dotyczące wyżywienia są wymagane"
          )
        ),
        otherwise: Yup.string()
      }),
    country: Yup.string().required(
      t("groups_validation_messages.country_required", "Kraj jest wymagany")
    ),
    location: Yup.object()
      .shape({
        province: Yup.string(),
        city: Yup.string().required(
          t(
            "groups_validation_messages.city_required",
            "Miejscowość jest wymagana"
          )
        ),
        street: Yup.string().required(
          t("groups_validation_messages.street_required", "Ulica jest wymagana")
        ),
        house_number: Yup.string().required(
          t(
            "groups_validation_messages.house_number_required",
            "Numer domu jest wymagany"
          )
        )
      })
      .when("country_code", {
        is: "PL",
        then: Yup.object().shape({
          province: Yup.string().required(
            t(
              "groups_validation_messages.province_required",
              "Województwo jest wymagane"
            )
          )
        })
      }),
    location_types: Yup.array()
      .default([])
      .required(
        t(
          "groups_validation_messages.location_types_required",
          "Typ lokalizacji jest wymagany"
        )
      )
      .test(
        "non-empty",
        t(
          "groups_validation_messages.location_types_required",
          "Typ lokalizacji jest wymagany"
        ),
        function (value) {
          return Array.isArray(value) && value.length > 0
        }
      ),
    group_min_price: Yup.number()
      .min(
        0.01,
        t(
          "groups_validation_messages.group_min_price_error",
          "Cena minimalna dla grupy musi byc większa niż 0"
        )
      )
      .required(
        t(
          "groups_validation_messages.group_min_price_required",
          "Cena minimalna dla grupy jest wymagana"
        )
      ),
    individual_min_price: Yup.number()
      .min(
        0.01,
        t(
          "groups_validation_messages.individual_min_price_error",
          "Cena minimalna dla indywidualnej osoby musi być większa niż 0"
        )
      )
      .required(
        t(
          "groups_validation_messages.individual_min_price_required",
          "Cena minimalna dla indywidualnej osoby jest wymagana"
        )
      ),
    max_guests: Yup.string().required(
      t(
        "groups_validation_messages.max_guests_required",
        "Licza miejsc noclegowych jest wymagana"
      )
    )
  })
}

const ProductJoinedSectionsGroupsAccommodation = ({
  values,
  refetch,
  watch
}: any) => {
  const { updateProduct } = useProductApi()

  const nameKey = getLocalizedKey("name")
  const descriptionKey = getLocalizedKey("description")
  const foodDescriptionKey = getLocalizedKey("food_description")

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var productData: any = {
      id: values.id,
      name: data[nameKey],
      description: data[descriptionKey],
      media: data.media,
      object_type: data.object_type,
      facility_amenities: data.facility_amenities,
      food_type: data.food_type,
      food_diets: data.food_diets,
      food_description: data[foodDescriptionKey],
      //  ProductSectionAccommodationview
      max_guests: data.max_guests,
      facility: data.facility,
      //  ProductSectionLocationView
      country_code: data.country_code,
      country_name: data.country_name,
      location: data.location,
      location_types: data.location_types,
      location_name: data.location_name,
      //   ProductSectionPriceView
      individual_min_price: data.individual_min_price,
      group_min_price: data.group_min_price,
      price_description: data.price_description,
      prepayment: data.prepayment,
      prepayment_description: data.prepayment_description,
      tourist_tax: data.tourist_tax,
      tourist_tax_description: data.tourist_tax_description,
      dependent_price: data.dependent_price,
      dependent_price_description: data.dependent_price_description,
      cancellation_fee: data.cancellation_fee,
      cancellation_fee_description: data.cancellation_fee_description,
      //   ProductSectionPlacesView
      places: data.places
    }

    return updateProduct(productData)
  }
  const handleRefresh = () => {
    setTimeout(() => {
      refetch()
    }, 3000)
  }

  const initialValues = { ...values }
  const { t } = useTranslation()

  return (
    <>
      <EditableFormSection
        title={t("product.description", "Opis")}
        values={values}
        onSave={saveHandler}
        onSaved={handleRefresh}
        groupValidationSchema={productGroupsAccommodationSchema(t)}
        fullWidth
        stickyButtons
      >
        {(builderProps: any) => {
          return (
            <div>
              {builderProps.isEditing && (
                <>
                  <ProductSectionDescriptionForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.holiday_lettings", "Miejsca noclegowe")}
                  </SectionTitle>
                  <ProductSectionAccommodationForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.location", "Lokalizacja")}
                  </SectionTitle>
                  <ProductSectionLocationForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.price_and_payments", "Cena i płatności")}
                  </SectionTitle>
                  <ProductSectionPriceForm
                    values={initialValues}
                    {...builderProps}
                  />
                  <SectionTitle>
                    {t("ui.groups.attractions", "Atrakcje")}
                  </SectionTitle>
                  <ProductSectionPlacesForm />
                </>
              )}
              {!builderProps.isEditing && (
                <>
                  <ProductSectionDescriptionPreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.holiday_lettings", "Miejsca noclegowe")}
                  </SectionTitle>
                  <ProductSectionAccommodationPreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.location", "Lokalizacja")}
                  </SectionTitle>
                  <ProductSectionLocationPreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.price_and_payments", "Cena i płatności")}
                  </SectionTitle>
                  <ProductSectionPricePreview values={values} />
                  <SectionTitle>
                    {t("ui.groups.attractions", "Atrakcje")}
                  </SectionTitle>
                  <ProductSectionPlacesPreview values={values} />
                </>
              )}
            </div>
          )
        }}
      </EditableFormSection>
    </>
  )
}

export default ProductJoinedSectionsGroupsAccommodation

import { Calendar } from "react-date-range"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import DateRangePickerHeader from "./DateRangePickerHeader"
import DateRangeDay from "./DateRangeDay"
import { Button, Popover } from "@mui/material"
import { useTranslation } from "next-i18next"
import useLocale from "../../utils/useLocale"

const Popup = styled.div`
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`

const Topic = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  justify-content: center;
  text-transform: uppercase;
  color: #042675;
`

export const getShowDefaultEndDate = (d: Date) => {
  const date = new Date(d)
  date.setMonth(date.getMonth() + 1)
  date.setDate(1)
  return date
}

const getStartDateOfEndDate = (endDate: Date) => {
  const date = new Date(endDate)
  date.setDate(1)
  return date
}

export const getEndDateOfStartDate = (startDate: Date) => {
  const month = startDate.getMonth()
  const date = new Date(startDate)
  date.setDate(28)
  while (date.getMonth() === month) {
    date.setDate(date.getDate() + 1)
  }
  date.setDate(date.getDate() - 1)
  return date
}

export const isDateInRangePanel = (
  date: Date,
  startDate: Date,
  endDate: Date
) => {
  return (
    date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()
  )
}

export const getDatePreviousMonth = (d: Date) => {
  const date = new Date(d)
  date.setDate(0)
  return date
}

export const getDateNextMonth = (d: Date) => {
  const date = new Date(getEndDateOfStartDate(d))
  date.setDate(date.getDate() + 1)
  return date
}

export const getSelectionStartDate = (
  dateTarget: Date,
  startDate: Date,
  endDate: Date
) => {
  const dateSelection = new Date(dateTarget)
  if (startDate) {
    if (!endDate) {
      endDate = startDate
    }
    const selectYear = dateSelection.getFullYear()
    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()
    if (selectYear >= startYear && selectYear <= endYear) {
      const selectMonth = dateSelection.getMonth()
      const startMonth = startDate.getMonth()
      const endMonth = endDate.getMonth()
      if (
        (selectMonth >= startMonth || selectYear >= startMonth) &&
        (selectMonth <= endMonth || selectYear <= endYear)
      ) {
        if (selectYear === startYear && selectMonth === startMonth) {
          dateSelection.setDate(startDate.getDate())
        } else {
          dateSelection.setDate(1)
        }
      }
    }
  }
  return dateSelection
}

export const getSelectionEndDate = (
  dateTarget: Date,
  startDate: Date,
  endDate: Date
) => {
  const dateSelection = new Date(dateTarget)
  if (startDate) {
    if (!endDate) {
      endDate = startDate
    }
    const selectYear = dateSelection.getFullYear()
    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()
    if (selectYear >= startYear && selectYear <= endYear) {
      const selectMonth = dateSelection.getMonth()
      const startMonth = startDate.getMonth()
      const endMonth = endDate.getMonth()
      if (
        (selectMonth >= startMonth || selectYear >= startMonth) &&
        (selectMonth <= endMonth || selectYear <= endYear)
      ) {
        if (selectMonth === endMonth) {
          dateSelection.setDate(endDate.getDate())
        } else {
          dateSelection.setDate(getEndDateOfStartDate(dateTarget).getDate())
        }
      }
    }
  }
  return dateSelection
}

export const isEqualDate = (date: Date, date2: Date) => {
  return (
    date.getDate() === date2.getDate() &&
    date.getMonth() === date2.getMonth() &&
    date.getFullYear() === date2.getFullYear()
  )
}

const DateRangePicker = ({
  startDate = new Date(),
  endDate,
  anchorElement,
  onDateChange,
  onClose,
  onConfirm,
  onReset,
  minDate = undefined,
  ...props
}) => {
  const { t } = useTranslation()
  const localization = useLocale()
  const panel2Ref = useRef(undefined)
  const selectAction = useRef("from")
  const selectMode = useRef<0 | 1 | 2>(0)

  const [panel1Date, setPanel1Date] = useState(startDate)
  const [panel2Date, setPanel2Date] = useState(() =>
    getShowDefaultEndDate(new Date())
  )

  const onStartDateSelectHandler = (date) => {
    if (selectAction.current === "from") {
      selectAction.current = "to"
      selectMode.current = 1
      onDateChange({ startDate: date, endDate: undefined })
    } else {
      selectAction.current = "from"
      if (startDate.getTime() < date.getTime()) {
        onDateChange({ startDate, endDate: date })
      } else {
        onDateChange({ startDate: date, endDate: startDate })
      }
    }
  }

  const onEndDateSelectHandler = (date) => {
    if (selectAction.current === "from") {
      selectAction.current = "to"
      selectMode.current = 2
      onDateChange({ startDate: date, endDate: undefined })
    } else {
      selectAction.current = "from"
      if (startDate.getTime() < date.getTime()) {
        onDateChange({ startDate, endDate: date })
      } else {
        onDateChange({ startDate: date, endDate: startDate })
      }
    }
  }

  const onPanel2NextMonthHandler = () => {
    const date = getDateNextMonth(panel2Date)
    const date2 = getDateNextMonth(panel1Date)
    setPanel1Date(date2)
    setPanel2Date(date)
  }

  const onPanel1PreviousMonthHandler = () => {
    const date = getDatePreviousMonth(panel1Date)
    const date2 = getDatePreviousMonth(panel2Date)
    setPanel1Date(date)
    setPanel2Date(date2)
  }

  const isPanel1Select =
    (endDate && isDateInRangePanel(panel1Date, startDate, endDate)) ||
    (panel1Date.getMonth() === startDate.getMonth() &&
      panel1Date.getFullYear() === startDate.getFullYear()) ||
    (endDate &&
      panel1Date.getMonth() === endDate.getMonth() &&
      panel1Date.getFullYear() === endDate.getFullYear())

  const isPanel2Select =
    (endDate && isDateInRangePanel(panel2Date, startDate, endDate)) ||
    (panel2Date.getMonth() === startDate.getMonth() &&
      panel2Date.getFullYear() === startDate.getFullYear()) ||
    (endDate &&
      panel2Date.getMonth() === endDate.getMonth() &&
      panel2Date.getFullYear() === endDate.getFullYear())

  const open = Boolean(anchorElement)

  return (
    <Popover
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: props.anchorVertical ?? "center",
        horizontal: props.anchorHorizontal ?? "right"
      }}
      transformOrigin={{
        vertical: props.transformVertical ?? "center",
        horizontal: props.transformHorizontal ?? "left"
      }}
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          width: "718px",
          overflow: "hidden"
        }
      }}
      {...props}
    >
      <Popup
        className="flex flex-col py-5 px-2 md:px-6 sm:py-[34px] sm:px-[38px] overflow-hidden h-full"
        onClick={(e) => e.stopPropagation()}
      >
        <Topic>
          {t(
            "date_range_picker.departure_time_frame",
            "przedział czasowy wyjazdu"
          )}
        </Topic>
        <div className="flex flex-col sm:flex-row mt-4 sm:mt-[28px] gap-5 sm:gap-[50px] overflow-y-auto overflow-x-hidden border-b border-t border-[#E9E9E9] sm:border-0 py-4 sm:py-0">
          <div className="m-auto sm:m-0">
            <DateRangePickerHeader
              date={panel1Date}
              onPrevious={onPanel1PreviousMonthHandler}
            />
            <Calendar
              className="mt-4"
              navigatorRenderer={() => <></>}
              locale={localization.locale}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              showPreview={true}
              onChange={onStartDateSelectHandler}
              color="#DEEBF9"
              displayMode="dateRange"
              focusedRange={[0, 2]}
              rangeColors={isPanel1Select ? ["#DEEBF9"] : ["transparent"]}
              minDate={minDate}
              ranges={[
                {
                  startDate: getSelectionStartDate(
                    panel1Date,
                    startDate,
                    endDate
                  ),
                  endDate: getSelectionEndDate(panel1Date, startDate, endDate)
                }
              ]}
              dayContentRenderer={(date: Date) => {
                const isSelect =
                  isEqualDate(date, startDate) ||
                  isEqualDate(date, endDate || startDate)
                return (
                  <DateRangeDay
                    date={date}
                    isSelect={isPanel1Select && isSelect}
                  />
                )
              }}
            />
          </div>
          <div ref={panel2Ref}>
            <DateRangePickerHeader
              date={panel2Date}
              onNext={onPanel2NextMonthHandler}
            />
            <Calendar
              className="mt-4"
              navigatorRenderer={() => <></>}
              locale={localization.locale}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              showPreview={true}
              onChange={onEndDateSelectHandler}
              color="#DEEBF9"
              displayMode="dateRange"
              rangeColors={isPanel2Select ? ["#DEEBF9"] : ["transparent"]}
              minDate={minDate}
              ranges={[
                {
                  startDate: getSelectionStartDate(
                    panel2Date,
                    startDate,
                    endDate
                  ),
                  endDate: getSelectionEndDate(panel2Date, startDate, endDate)
                }
              ]}
              dayContentRenderer={(date: Date) => {
                const isSelect =
                  isEqualDate(date, startDate) ||
                  isEqualDate(date, endDate || startDate)
                return (
                  <DateRangeDay
                    date={date}
                    isSelect={isPanel2Select && isSelect}
                  />
                )
              }}
            />
          </div>
        </div>
        <div className="flex mt-[28px]">
          <Button
            style={{ width: "145px", height: "46px", color: "#213895 " }}
            onClick={onReset}
          >
            {t("date_range_picker.clear", "Wyczyść")}
          </Button>
          <Button
            className="ml-auto"
            variant="contained"
            style={{ width: "145px", height: "46px", marginLeft: "auto" }}
            onClick={onConfirm}
          >
            {t("date_range_picker.choose", "Wybierz")}
          </Button>
        </div>
      </Popup>
    </Popover>
  )
}

export default DateRangePicker

import { FormattedDate, FormattedTime } from "react-intl"
import { ApiTable } from "../../../components/table/ApiTable"
import { useCompanyContext } from "../../../contexts/company-context"
import { PaymentStatusChip } from "wakacje-web/src/shared/payments/PaymentStatusChip"
import { usePayments } from "./usePaymentsApi"

export const PaymentsTable = (props: any) => {
  const companyContext = useCompanyContext()
  const { list } = usePayments()

  const queryParams: any = {
    company_id: companyContext.company!.id,
    scope: "company"
  }
  if (props.type) {
    queryParams.type = props.type
  }
  const columns = [
    {
      header: "Produkt",
      cell: (row) => {
        return (
          <div>
            <div>
              {row.type === "COMPANY_PACKAGE_ORDER" && "Licencja FajnyCzas.pl"}
              {row.type === "COMPANY_POSTER_ORDER" && "Plakaty FajnyCzas.pl"}
            </div>
            <div>{row.template_code}</div>
          </div>
        )
      }
    },
    {
      header: "Data",
      cell: (row) => {
        return (
          <div>
            <FormattedDate value={row.created_at} />{" "}
            <FormattedTime value={row.created_at} />
          </div>
        )
      }
    },
    {
      header: "Status",
      cell: (row: any) => (
        <>
          <div>{row.name}</div>
          {row.status && (
            <div>
              <PaymentStatusChip payment={row} />
            </div>
          )}
        </>
      ),
      sort: true
    },
    {
      header: "Kod Transakcji",
      cell: (row: any) => (
        <>
          <div>{row.p24_statement}</div>
        </>
      ),
      sort: true
    }
  ]

  return (
    <ApiTable
      {...props}
      columns={columns}
      listApi={list}
      queryParams={queryParams}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/about/payments/${row.id}`}
    />
  )
}

function EditIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.2513 18.5833H2.83464C2.5013 18.5833 2.16797 18.3333 2.16797 17.9167C2.16797 17.5 2.41797 17.25 2.83464 17.25H18.2513C18.5846 17.25 18.918 17.5 18.918 17.9167C18.918 18.3333 18.5846 18.5833 18.2513 18.5833Z" />
      <path d="M16.25 6.25004L13.25 3.25004L14.4167 2.08337C14.9167 1.58337 15.8333 1.58337 16.4167 2.08337L17.5 3.16671C17.75 3.41671 17.9167 3.75004 17.9167 4.16671C17.9167 4.50004 17.75 4.91671 17.5 5.16671L16.25 6.25004ZM14.9167 3.16671L16.25 4.50004L16.5833 4.16671C16.5833 4.16671 16.5833 4.16671 16.5833 4.08337C16.5833 4.00004 16.5833 4.00004 16.5 3.91671L15.5 2.91671C15.4167 2.83337 15.3333 2.83337 15.25 2.91671L14.9167 3.16671Z" />
      <path d="M6.25 16.25H3.25V13.25L4.08333 12.3334L12.3333 4.08337L15.3333 7.08337L6.25 16.25ZM4.5 15H5.75L13.6667 7.08337L12.3333 5.75004L4.91667 13.1667L4.41667 13.6667L4.5 15Z" />
    </svg>
  )
}

export default EditIcon

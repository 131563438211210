import { useTranslation } from "react-i18next"

export const useObjectTypes = () => {
  const { t } = useTranslation()

  return [
    {
      id: "rodzaj.agroturystyka",
      name: t("place_type.agrotourism", "Agroturystyka"),
      iconSrc: "/svg/house-chimney-floor.svg"
    },
    {
      id: "rodzaj.apartament-mieszkanie",
      name: t("place_type.apartment_flat", "Apartament/Mieszkanie"),
      iconSrc: "/svg/sofa.svg"
    },
    {
      id: "rodzaj.dom-pracy-twórczej",
      name: t("place_type.creative_work_house", "Dom pracy twórczej"),
      iconSrc: "/svg/house-sun.svg"
    },
    {
      id: "rodzaj.dom-wycieczkowy",
      name: t("place_type.tourist_house", "Dom wycieczkowy"),
      iconSrc: "/svg/house-chimney-blank.svg"
    },
    {
      id: "rodzaj.domek-letniskowy",
      name: t("place_type.summer_house", "Domek letniskowy"),
      iconSrc: "/svg/house-chimney-user.svg"
    },
    {
      id: "rodzaj.hotel",
      name: t("place_type.hotel", "Hotel"),
      iconSrc: "/svg/hotel.svg"
    },
    {
      id: "rodzaj.hostel-motel",
      name: t("place_type.hostel_motel", "Hostel / Motel"),
      iconSrc: "/svg/building.svg"
    },
    {
      id: "rodzaj.kemping",
      name: t("place_type.camping", "Kemping"),
      iconSrc: "/svg/campground.svg"
    },
    {
      id: "rodzaj.ośrodek-kolonijny",
      name: t("place_type.camp_center", "Ośrodek kolonijny"),
      iconSrc: "/svg/city.svg"
    },
    {
      id: "rodzaj.ośrodek-szkoleniowo-wczasowy",
      name: t(
        "place_type.training_vacation_center",
        "Ośrodek szkoleniowo-wczasowy"
      ),
      iconSrc: "/svg/building-four.svg"
    },
    {
      id: "rodzaj.ośrodek-wczasowy",
      name: t("place_type.vacation_center", "Ośrodek wczasowy"),
      iconSrc: "/svg/garage-open.svg"
    },
    {
      id: "rodzaj.pensjonat",
      name: t("place_type.guest_house", "Pensjonat"),
      iconSrc: "/svg/house.svg"
    },
    {
      id: "rodzaj.pokój-gościnny-prywatna-kwatera",
      name: t(
        "place_type.guest_room_private_accommodation",
        "Pokój gościnny/prywatna kwatera"
      ),
      iconSrc: "/svg/single-bed.svg"
    },
    {
      id: "rodzaj.pole-biwakowe",
      name: t("place_type.camping_site", "Pole biwakowe"),
      iconSrc: "/svg/camp.svg"
    },
    {
      id: "rodzaj.schronisko",
      name: t("place_type.shelter", "Schronisko"),
      iconSrc: "/svg/building-three.svg"
    },
    {
      id: "rodzaj.schronisko-młodzieżowe",
      name: t("place_type.youth_hostel", "Schronisko młodzieżowe"),
      iconSrc: "/svg/weixin-people-nearby.svg"
    },
    {
      id: "rodzaj.szkolne-schronisko-młodzieżowe",
      name: t(
        "place_type.school_youth_hostel",
        "Szkolne schronisko młodzieżowe"
      ),
      iconSrc: "/svg/school.svg"
    },
    {
      id: "rodzaj.zakład-uzdrowiskowy",
      name: t("place_type.spa_facility", "Zakład uzdrowiskowy"),
      iconSrc: "/svg/geometric-flowers.svg"
    },
    {
      id: "rodzaj.inny-obiekt-noclegowy",
      name: t("place_type.other_accommodation", "Inny obiekt noclegowy"),
      iconSrc: "/svg/figma-component.svg"
    }
  ]
}

export const styleModal: any = {
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute' as 'absolute',
	// alignItems: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: {xs: '100%', sm: '100%', md: '670px'},
	height: {xs: '100%', sm: '100%', md: '90vh'},
	overflowY: 'scroll',
	padding: '40px 48px',
	borderRadius: '8px',
	bgcolor: 'background.paper',
	boxShadow: '0px 20px 26px -6px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)',
	p: 4,
};

export const modalStudentTitle: any = {

	display: 'flex',
	fontSize: '20px',
	color: '#7E7E7E',
	flexDirection: 'column',
	padding: '0',
	'& > p': {
		fontFamily: 'Mulish',
		color: '#222222',
		fontSize: '32px',
	},
};

export const modalBtnsContainer = {
	display:'flex',
	width:'100%',
	justifyContent:'flex-end',
	'& > button' : {
		margin:{xs:'5px' , sm:'15px'},
	}

};
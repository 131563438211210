import React from "react"

export const TrashIcon = ({ fill , width="21", height="21"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7277_229010)">
        <path
          d="M9.04297 7.63672H7.79297V15.1367H9.04297V7.63672Z"
          //   fill="#0C48D7"
          fill={fill}
        />
        <path d="M12.793 7.63672H11.543V15.1367H12.793V7.63672Z" fill={fill} />
        <path
          d="M2.79297 3.88672V5.13672H4.04297V17.6367C4.04297 17.9682 4.17466 18.2862 4.40909 18.5206C4.64351 18.755 4.96145 18.8867 5.29297 18.8867H15.293C15.6245 18.8867 15.9424 18.755 16.1769 18.5206C16.4113 18.2862 16.543 17.9682 16.543 17.6367V5.13672H17.793V3.88672H2.79297ZM5.29297 17.6367V5.13672H15.293V17.6367H5.29297Z"
          fill={fill}
        />
        <path d="M12.793 1.38672H7.79297V2.63672H12.793V1.38672Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_7277_229010">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.292969 0.136719)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

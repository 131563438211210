import { FormattedDate, FormattedTime } from "react-intl"
import { ApiTable } from "../../../components/table/ApiTable"
import { usePlacesApi } from "../../organizer/places/usePlacesApi"

export const SuperAdminPlacesTable = ({
  queryParams,
  type
}: {
  queryParams?: any
  type: string
}) => {
  const api = usePlacesApi()

  var query = queryParams ? { ...queryParams } : {}
  query.type = type
  const columns = [
    {
      header: "Data dodania",
      cell: (row) => (
        <div>
          {row.created_at && (
            <>
              <div>
                <FormattedDate value={row.created_at} />
              </div>{" "}
              <div>
                <FormattedTime value={row.created_at} />
              </div>
            </>
          )}
        </div>
      ),
      field: "created_at"
    },
    {
      header: type === "accommodation" ? "Nazwa ośrodka" : "Nazwa atrakcji",
      cell: (row: any) => <div className="">{row.name}</div>,
      sort: true,
      field: "name"
    },
    {
      header: "Właściciel",
      cell: (row: any) => (
        <div className="">
          {row.scope === "company" ? "Firma" : "FajnyCzas"}
        </div>
      ),
      field: "scope"
    },
    {
      header: "Nazwa firmy",
      cell: (row: any) => <div className="">{row.company?.name}</div>,
      field: "company_name"
    },
    {
      header: "Zdjęcia",
      cell: (row: any) => (
        <div className="">{row.media?.length > 0 ? "TAK" : "NIE"}</div>
      ),
      field: "has_photos"
    },
    {
      header: "Opis",
      width: 150,
      cell: (row: any) => (
        <div className="text-left text-overflow-ellipsis overflow-ellipsis whitespace-nowrap ">
          <div className="whitespace-nowrap overflow-hidden max-w-full w-[150px] overflow-ellipsis">
            {row.description}
          </div>
        </div>
      ),
      field: "description"
    },
    {
      header: "Ilość ofert",
      cell: (row: any) => <div className="">{row.use_count ?? "-"}</div>,
      field: "use_count"
    },
    {
      header: "Lokalizacja",
      cell: (row: any) => {
        var parts = []
        if (row.street) {
          parts.push(row.street)
        }
        if (row.city) {
          parts.push(row.city)
        }
        if (row.province) {
          parts.push(row.province)
        }
        if (row.country_name) {
          parts.push(row.country_name)
        }
        return (
          <div>
            <div>{parts.join(", ")}</div>
          </div>
        )
      }
    }
  ]
  return (
    <ApiTable
      columns={columns}
      listApi={api.listPlaces}
      showExpandColumn
      rowLinkBuilder={(row: any) => `/places/${type}/${row.id}`}
      queryParams={query}
    ></ApiTable>
  )
}

import { useReducer, useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { MojeButton } from "../MojeButton"
import { WakacjeTextInput } from "../../shared/components/WakacjeTextInput"
import { useAuth } from "../../auth/AuthContext"
import { ActionCodeSettings } from "@firebase/auth"
import { LinkUtils } from "../../shared/LinkUtils"

const Actions = {
  EMAIL_SENT: "EMAIL_SENT"
}

const Step = {
  ENTER_EMAIL: "ENTER_EMAIL",
  EMAIL_SENT: "EMAIL_SENT"
}

const logInSchema = Yup.object({
  email: Yup.string()
    .email("E-mail nie jest poprawny")
    .required("Wprowadź poprawny e-mail")
  // password: Yup.string()
  //   .required("Wprowadź nowe hasło")
  //   .min(8, "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę")
  //   .matches(
  //     /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
  //     "Hasło musi mieć minimum 8 znaków, wielką i małą literę oraz cyfrę"
  //   ),
})

const reducer = (state, action) => {
  switch (action.type) {
    case Actions.EMAIL_SENT:
      return { ...state, loading: false, step: Step.EMAIL_SENT }
      //   case Actions.EMAIL_SEND_ERROR:
      //     return { ...state, loading: false, step: undefined }
      //   case Actions.SUCCESS:
      //     return { ...state, loading: false, step: Step.SUCCESS }
      //   case Actions.INVALID_PASSWORD:
      return {
        ...state,
        loading: false,
        step: Step.ENTER_EMAIL,
        errorCode: action.errorCode
      }
    default:
      return state
  }
}
export const ResetPasswordForm = () => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    step: Step.ENTER_EMAIL
  })
  const [isSaving, setIsSaving] = useState(false)

  const defaultValues = {
    email: ""
  }
  const formMethods = useForm<any>({
    resolver: yupResolver(logInSchema),
    mode: "onChange",
    defaultValues
  })
  const {
    handleSubmit,
    getValues,
    watch,
    formState: { errors, touchedFields },
    register
  } = formMethods

  const { sendPasswordResetEmail } = useAuth()

  const values = getValues()

  const onSubmit = async (values) => {
    const isValid = await formMethods.trigger()
    if (!isValid) {
      return
    }
    setIsSaving(true)
    if (state.step === Step.ENTER_EMAIL) {
      try {
        const href = LinkUtils.getAdminHref()
        const actionCodeSettings: ActionCodeSettings = {
          url: href + "/login?source=reset-password"
          // dynamicLinkDomain: 'htttps://admin.fajnyczas.pl'
        }
        var res = await sendPasswordResetEmail(values.email, actionCodeSettings)
        dispatch({ type: Actions.EMAIL_SENT })
      } catch (error) {}
    } else if (state.step === Step.EMAIL_SENT) {
    }
    setIsSaving(false)
  }
  return (
    <div>
      {state.step === Step.ENTER_EMAIL && (
        <div className="my-4 text-center">
          Wpisz adres e-mail podany podczas rejestracji konta, na który wyślemy
          instrukcje resetowania hasła.
        </div>
      )}

      <FormProvider {...{ ...formMethods }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {state.step == Step.ENTER_EMAIL && (
            <div className="mb-4">
              <WakacjeTextInput
                id="email"
                label="Email"
                fullWidth
                {...register("email")}
                errors={errors}
                touched={touchedFields?.email}
                valid={!errors.email}
                value={watch("email")}
                disabled={isSaving}
              />
            </div>
          )}

          {state.step == Step.EMAIL_SENT && (
            <>
              <div className="mb-8">
                <div className="mb-8">
                  Link do zrestartowania hasła został wysłany na wskazany adres
                </div>
                <div className="text-center font-bold">{values.email}</div>
              </div>

              {/* <div>Wprowadź nowe hasło:</div>
              <WakacjeTextInput
                id="password"
                label="Hasło"
                fullWidth
                {...register("password")}
                errors={errors}
                touched={touchedFields?.email}
                valid={!errors.email}
              /> */}
            </>
          )}
          {state.step != Step.EMAIL_SENT && (
            <div>
              <MojeButton
                type="submit"
                variant="contained"
                color="primary"
                label="Resetuj hasło"
                isSaving={isSaving}
              />
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  )
}

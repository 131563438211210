import Box from "@mui/material/Box"
import {
  curatorContainer,
  infoBlockWrapper,
  infoText,
  titleBlock
} from "./curatorStyle"
import Typography from "@mui/material/Typography"

export const CuratorSection = () => {
  return (
    <Box sx={curatorContainer}>
      <Box sx={titleBlock}>
        <span>Informacje podstawowe</span>
      </Box>

      <Box sx={infoBlockWrapper}>
        <Typography sx={infoText} component={"span"}>
          Imię
          <span>Jan</span>
        </Typography>

        <Typography sx={infoText} component={"span"}>
          Nazwisko
          <span>Kowalski</span>
        </Typography>
      </Box>

      <Box sx={titleBlock}>
        <span>Dane kontaktowe</span>
      </Box>

      <Box sx={infoBlockWrapper}>
        <Typography sx={infoText} component={"span"}>
          Telefon
          <span>512 515 231</span>
        </Typography>

        <Typography sx={infoText} component={"span"}>
          E-mail
          <span>adresojciec@mail.pl</span>
        </Typography>
      </Box>

      <Box sx={titleBlock}>
        <span>Adres zamieszkania</span>
      </Box>

      <Box sx={infoBlockWrapper}>
        <Typography sx={infoText} component={"span"}>
          Adres
          <span>Wesoła 21/4</span>
          <span>Kraków</span>
          <span>woj. mazowieckie</span>
        </Typography>

        <Typography sx={infoText} component={"span"}></Typography>
      </Box>
    </Box>
  )
}

import { Button } from "@mui/material"
import { FormHelperText, Heading3 } from "../../../../components/FormCommons"
import { useFormContext } from "react-hook-form"
import { Box } from "@mui/system"

import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import { UploadableMediaWidget } from "../../../../common/photos/UploadableMediaWidget"
import { LoadingSpinner } from "../../../../components/LoadingSpinner"

import update from "immutability-helper"
import { useDrag, useDrop } from "react-dnd"
import { DndProvider } from "react-dnd/dist/core"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useCallback, useState, useEffect } from "react"
import clsx from "clsx"

import { useTranslation } from "react-i18next"

export const LoadingPhoto = (props: any) => {
  const { loadingProgress } = props
  let alt = props.alt || "Image"

  return (
    // <PhotoStyled {...props}>
    <div style={{ position: "relative" }}>
      <img
        alt={alt}
        src={props.src}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          position: "relative"
        }}
      />

      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#ffffff90",
          height: loadingProgress + "%",
          color: "red",
          borderRadius: 10,
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          zIndex: 5
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          zIndex: 10
        }}
      >
        <div className="flex flex-row items-center justify-center h-full">
          {/* {loadingProgress < 100 ? <div>Wgrywam</div> : <div>Zapisuję</div>} */}
          <LoadingSpinner />
          {/* <FontAwesomeIcon size={36} icon={faSpinner} spin /> */}
        </div>
      </div>
    </div>
    // </PhotoStyled>
  )
}

export const UploadablePhoto = (props: any) => {
  return (
    <UploadableMediaWidget
      onPhotoUploaded={props.onPhotoUploaded}
      disabled={props.disabled}
      useGallery={props.useGallery}
    >
      {({ tmpPhoto, tmpPhotoSrc }: any) => {
        return (
          <div
            id="photoWrapper"
            style={{
              position: "relative",
              cursor: "pointer",
              height: "100%",
              width: "100%"
            }}
          >
            {props.photo && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0
                }}
              >
                <img
                  src={props.photo.url}
                  alt="Zdjęcie główne"
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%"
                  }}
                />
              </div>
            )}

            {!props.photo && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "#e6eefd"
                    // opacity: 0.5,
                  }}
                >
                  {props.children ? (
                    props.children
                  ) : (
                    <>
                      <Button
                        style={{
                          width: "100%",
                          height: "100%"
                        }}
                        onClick={
                          props.triggerValidation && props.triggerValidation
                        }
                      >
                        <div className="flex justify-center items-center flex-col align-middle w-full h-full">
                          <img
                            src="/svg/icon-photo.svg"
                            alt=""
                            width="32"
                            height="32"
                          />
                          <p>{props.label}</p>
                        </div>
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}

            {tmpPhotoSrc && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0
                }}
              >
                <LoadingPhoto
                  loadingProgress={tmpPhoto.percentUploaded}
                  src={tmpPhotoSrc}
                  width={80}
                  fullHeight
                />
              </div>
            )}
          </div>
        )
      }}
    </UploadableMediaWidget>
  )
}

export const buildPhoto = (newPhoto: any) => ({
  id: newPhoto.id,
  provider: "cloudinary",
  providerData: newPhoto.providerData,
  url: newPhoto.url,
  width: newPhoto.width,
  height: newPhoto.height
})

const SinglePhotoWrapper = ({ id, children, findPhoto, movePhoto }) => {
  const originalIndex = findPhoto(id).index
  const [{ isDragging }, drag] = useDrag(() => {
    return {
      type: "photo",
      item: { id, originalIndex },
      collect: (monitor) => {
        return {
          isDragging: (monitor.getItem() as any)?.id === id
        }
      },
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item as any
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          movePhoto(droppedId, originalIndex)
        }
      }
    }
  }, [id, originalIndex, movePhoto])

  const [, drop] = useDrop(
    () => ({
      accept: "photo",
      hover({ id: draggedId }: any) {
        if (draggedId !== id) {
          const { index: overIndex } = findPhoto(id)
          movePhoto(draggedId, overIndex)
        }
      }
    }),
    [findPhoto, movePhoto]
  )

  return (
    <Box
      ref={(node) => drag(drop(node as any))}
      width={120}
      height={120}
      mx={1}
      my={2}
      display="inline-block"
      bgcolor="#7e7e7e10"
      borderRadius="8px"
      overflow="hidden"
      className={clsx("group relative", isDragging && "opacity-50")}
    >
      {children}
    </Box>
  )
}

export const ProductSectionPhotosForm = (props) => (
  <DndProvider backend={HTML5Backend}>
    <ProductSectionPhotosFormInternal {...props} />
  </DndProvider>
)

const ProductSectionPhotosFormInternal = ({
  showMainPhoto,
  triggerValidation,
  mediaInit,
  photosNumber
}: {
  showMainPhoto?: any
  triggerValidation?: any
  mediaInit?: any
  photosNumber?: number
}) => {
  const { watch, getValues, setValue, trigger } = useFormContext<any>()
  const initMainPhoto = getValues("main_photo")
  const initMedia = getValues("media") ?? mediaInit
  const [media, setMedia] = useState(initMedia ?? [])
  const [mainPhoto, setMainPhoto] = useState(initMainPhoto ?? undefined)
  const { t } = useTranslation()

  const [, drop] = useDrop(() => ({ accept: "photo" }))
  const PHOTOS_NUMBER = photosNumber ?? 4

  const handleMainPhotoUploaded = (newPhoto: any) => {
    setMainPhoto(buildPhoto(newPhoto))
    return true
  }
  const handleAdditionalPhotoUploaded = (newPhoto: any) => {
    if (media.length >= 10) {
      return
    }
    setMedia((prev) =>
      prev ? [...prev, buildPhoto(newPhoto)] : [buildPhoto(newPhoto)]
    )
    return true
  }

  useEffect(() => {
    setValue("media", media)
  }, [media])

  useEffect(() => {
    setValue("mainPhoto", mainPhoto)
  }, [mainPhoto])

  const handleRemovePhoto = (idx) => {
    const newMedia = [...media]
    newMedia.splice(idx, 1)
    setMedia(newMedia)
  }

  const findPhoto = useCallback(
    (id: string) => {
      const index = media.findIndex((m) => `${m.id}` === id)
      return {
        item: media[index],
        index
      }
    },
    [media]
  )

  const movePhoto = useCallback(
    (id: string, atIndex: number) => {
      const { item, index } = findPhoto(id)

      const newMedia = update(media, {
        $splice: [
          [index, 1],
          [atIndex, 0, item]
        ]
      })

      setMedia(newMedia)
    },
    [findPhoto, media]
  )

  const photosWidgets = []

  if (media) {
    for (let i = 0; i < media.length; i++) {
      photosWidgets.push(
        <SinglePhotoWrapper
          id={media[i].id}
          findPhoto={findPhoto}
          movePhoto={movePhoto}
          key={i}
        >
          <div className="h-full w-full">
            <img
              src={media[i].url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              alt="Photo"
            ></img>
            <div
              className="absolute top-[calc(50%-19px)] left-[calc(50%-19px)] right-0 bottom-0 
          hidden group-hover:block bg-[#00000040]text-white 
        "
            >
              <div
                title={t("ui.photos.remove_photo", "Usuń zdjęcie")}
                className="m-auto"
              >
                <img
                  onClick={(event: any) => {
                    var s = i
                    handleRemovePhoto(s)
                  }}
                  className="cursor-pointer"
                  src="/svg/trash-icon.svg"
                  width="39"
                  height="38"
                  alt="trash"
                />
              </div>
            </div>
          </div>
        </SinglePhotoWrapper>
      )
    }
  }
  // ) : (
  //   <Box
  //     height="100%"
  //     display="flex"
  //     alignItems="center"
  //     justifyContent="center"
  //   >
  //     <InsertPhotoOutlined />
  //   </Box>
  // )}

  return (
    <>
      <Heading3 id="h3-1" className="mb-4">
        {t("product.photos", "Zdjęcia")}
      </Heading3>
      <div className="mb-4">
        <FormHelperText>
          {t("ui.photos.add_up_1", "Dodaj do")} {PHOTOS_NUMBER}{" "}
          {t(
            "ui.photos.add_up_2",
            "zdjęć. Aby zmienić kolejność przeciągaj zdjęcia"
          )}
        </FormHelperText>
      </div>

      {showMainPhoto && (
        <>
          <Box
            className="mb-4 relative"
            sx={{
              border: 1,
              borderColor: "rgba(0, 0, 0, 0.23)",
              borderRadius: 1,
              height: 300,
              width: "100%"
              // minHeight: "12rem",
            }}
          >
            <UploadablePhoto
              photo={mainPhoto}
              onPhotoUploaded={handleMainPhotoUploaded}
              label={t("ui.photos.add_main_photo", "Dodaj zdjęcie główne")}
            />
          </Box>
        </>
      )}

      <div className="flex gap-4">
        {media?.length < PHOTOS_NUMBER && (
          <Box
            width={120}
            height={120}
            my={2}
            display="inline-block"
            bgcolor="#0559E810"
            borderRadius="8px"
            overflow="hidden"
          >
            <UploadablePhoto
              onPhotoUploaded={handleAdditionalPhotoUploaded}
              label={t("ui.photos.add_photo", "Dodaj zdjęcie")}
              triggerValidation={triggerValidation && triggerValidation}
            />
          </Box>
        )}
        <Box ref={drop} mx={-1}>
          {photosWidgets}
        </Box>
      </div>
    </>
  )
}

export const ProductSectionPhotosPreview = ({ values, refetch }: any) => {
  return (
    <Box>
      {values.main_photo && (
        <Box
          className="mb-4 relative"
          sx={{
            border: 1,
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderRadius: 1,
            height: 300,
            width: "100%"
            // minHeight: "12rem",
          }}
        >
          <img
            src={values.main_photo.url}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%"
            }}
            alt="Main"
          ></img>
        </Box>
      )}
      <Box mx={-1}>
        {values.media && values.media.length > 0 ? (
          values.media.map((photo: any, index: any) => (
            <Box
              key={index}
              width={120}
              height={120}
              mx={1}
              my={2}
              display="inline-block"
              bgcolor="#7e7e7e10"
              borderRadius="8px"
              overflow="hidden"
            >
              <img
                src={photo.url}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover"
                }}
                alt="Photo"
              ></img>
            </Box>
          ))
        ) : (
          <span className="ml-2">-</span>
        )}
      </Box>
    </Box>
  )
}

export const ProductSectionPhotosView = ({ values, refetch, watch }: any) => {
  const { updateProduct } = useProductApi()
  const { t } = useTranslation()

  const saveHandler = async (data: any) => {
    var productData: any = {
      id: values.id,
      main_photo: data.main_photo,
      media: data.media
    }
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  return (
    <EditableFormSection
      title={t("product.photos", "Zdjęcia")}
      values={values}
      onSave={saveHandler}
      onSaved={handleRefresh}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <ProductSectionPhotosForm
                values={values}
                watch={watch}
                {...builderProps}
                // showMainPhoto
              />
            )}
            {!builderProps.isEditing && (
              <ProductSectionPhotosPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

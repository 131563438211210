export const XIcon = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29162 7.30052L14.7675 1.50052C14.9908 1.30052 14.9908 0.967187 14.7675 0.767187C14.5441 0.567187 14.172 0.567187 13.9487 0.767187L7.47284 6.56719L0.997004 0.767187C0.773699 0.567187 0.401525 0.567187 0.178221 0.767187C-0.045084 0.967187 -0.045084 1.30052 0.178221 1.50052L6.65405 7.30052L0.178221 13.1005C-0.045084 13.3005 -0.045084 13.6339 0.178221 13.8339C0.32709 13.9005 0.47596 13.9672 0.62483 13.9672C0.773699 13.9672 0.922569 13.9005 0.997004 13.8339L7.47284 8.03385L13.9487 13.8339C14.0231 13.9005 14.172 13.9672 14.3208 13.9672C14.4697 13.9672 14.6186 13.9005 14.693 13.8339C14.9163 13.6339 14.9163 13.3005 14.693 13.1005L8.29162 7.30052Z"
        fill={fill ?? "white"}
      />
    </svg>
  )
}

import { LinearProgress } from "@mui/material"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../contexts/auth-context"
import { PosterEditor } from "./PosterEditor"
import { PosterSectionAdmin } from "./PosterSectionAdmin"
import { PosterSectionPayment } from "./PosterSectionPayment"
import { PosterUtils } from "./PosterUtils"
import { usePosterOrder } from "./usePosterOrdersApi"

const PosterOrderPreview = ({ order }: { order: any }) => {
  console.log("order.template_code", order)
  const template = PosterUtils.getTemplateByCode(order.template_code)

  return (
    <div>
      <div className="text-xl font-bold my-2">Podgląd plakatu</div>
      {template ? (
        <div>
          <PosterEditor
            posterTemplate={template}
            values={order.data}
            onSetField={() => {}}
            editable={false}
          />
        </div>
      ) : (
        <div>Błąd - nie znaleziono szablonu </div>
      )}
    </div>
  )
}

export const PosterOrderPage = (props: any) => {
  const params = useParams()
  console.log("params", params)
  const { data, loading } = usePosterOrder(params.id)
  const authContext = useAuthContext()
  return (
    <div>
      {loading && <LinearProgress />}
      <div>
        <div className="text-4xl font-bold my-4">Zamówienie plakatów</div>
      </div>
      {/* numer : {params?.id}
      <div>{JSON.stringify(data)}</div> */}
      {data && (
        <div className="container max-w-screen-xl m-auto">
          <PosterOrderPreview order={data} />
          <PosterSectionPayment order={data} />
          <div className="mb-4">
            <div className="text-xl font-lg font-bold mb-1">
              Specjalne uwagi
            </div>
            <div>{data.special_request ?? "Brak"}</div>
          </div>
          {authContext.isSuperAdmin && <PosterSectionAdmin order={data} />}
        </div>
      )}
    </div>
  )
}

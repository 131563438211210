import React from "react"
import { Box } from "@mui/system"
import { LoginForm } from "./LoginForm"
import Fade from "react-reveal/Fade"
export const LoginPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyItems="center"
      alignContent="center"
      width="100%"
    >
      <Box margin="auto">
        <Fade top distance="20px">
          <LoginForm />
        </Fade>
      </Box>
    </Box>
  )
}

import { Box, Tab, Tabs } from "@mui/material"
import React from "react"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  selectedTab: number
}

export const FcTabPanel = (props: TabPanelProps) => {
  const { children, selectedTab, index, ...other } = props

  return (
    <div role="tabpanel" hidden={selectedTab !== index} {...other}>
      {selectedTab === index && <div>{children}</div>}
    </div>
  )
}

export const FcTabsHeader = ({ tabs, selectedTab, handleTabChange }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          {tabs.map((tab) => {
            return <Tab key={tab} label={tab} />
          })}
        </Tabs>
      </Box>
    </Box>
  )
}

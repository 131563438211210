import axios from "axios"
import { useState } from "react"

export const useOfferCommand = () => {
  const [isSaving, setIsSaving] = useState(false)

  const runCommand = async (command: any) => {
    if (!command.aggregate_id) {
      throw new Error("No command.aggregate_id")
    }
    var url =
      process.env.REACT_APP_WAKACJE_HTTP_API_URL +
      `/bookings/v2.0/bff/super/${command.aggregate_id}`
    setIsSaving(true)
    try {
      var res = await axios.post(url, { command })
      setIsSaving(false)
      return res.data
    } catch (err) {
      setIsSaving(false)
      return {
        success: false
      }
    }
  }

  return {
    runCommand,
    isSaving
  }
}

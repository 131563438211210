import { MenuItem, Select } from "@mui/material"
import { useState } from "react"

export const PosterSectionAdmin = ({ order }) => {
  const [action, setAcion] = useState<any>()
  const actions = [
    { label: "Oznacz jako przyjęte", code: "PosterOrder.Accept" },
    { label: "Oznacz jako w druku", code: "PosterOrder.StartPrinting" },
    { label: "Oznacz jako wysłane", code: "PosterOrder.MarkSent" },
  ]
  return (
    <div className="my-4 shadow rounded p-8">
      <div className="font-bold text-xl">Sekcja administratora</div>
      <div className="flex flex-row">
        <div className="mr-4">Wybierz akcje:</div>
        <div>
          <Select
            className="ml-4"
            value={action}
            inputProps={{
              "aria-labelledby": "table-page-select-label",
            }}
            onChange={(e) => setAcion(e.target.value)}
          >
            {actions.map((act: any) => (
              <MenuItem key={act.code} value={act.code}>
                {act.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  )
}

import { useState } from 'react'
import { motion } from 'framer-motion'
import { AppBar, Button, Toolbar } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { SuperAdminSelectCompany } from './nav/SuperAdminSelectCompany'
import { UserDropdownMenu } from './widgets/UserDropdownMenu'
import { useAuthContext } from '../contexts/auth-context'
import { useCompanyContext } from '../contexts/company-context'
import { LanguageDropdownMenu } from './widgets/LanguageDropdownMenu'
import { CurrencyDropdownMenu } from './widgets/CurrencyDropdown'
import { useTranslation } from 'react-i18next'
import useCurrentUrl from '../hooks/useCurrentUrl'

export const Header = () => {
  const authContext = useAuthContext()
  const companyContext = useCompanyContext()
  const location = useLocation()
  const [showNav, setShowNav] = useState(false)

  const currentUrl = useCurrentUrl()

  const { t } = useTranslation()

  const isRegisterPage =
    location.pathname === '/rejestracja' ||
    location.pathname === '/registration/USA'

  const registerNav = [
    { goTo: '#funkcje', label: t('main_header.features', 'Funkcje') },
    { goTo: '#cennik', label: t('main_header.price_list', 'Cennik') }
  ]

  return (
    <AppBar
      color='transparent'
      position={isRegisterPage ? 'fixed' : 'static'}
      sx={{ zIndex: theme => theme.zIndex.appBar, backgroundColor: 'white' }}
    >
      <Toolbar sx={{ paddingX: [1, null, 2] }} className='flex justify-between'>
        <div className='flex gap-2 items-center'>
          <Link to='/'>
            {currentUrl.includes('gofunlo') ? (
              <img
                className=''
                src='/assets/logo/go-funlo-logo.svg'
                alt=''
                width='150px'
                height='27px'
              />
            ) : (
              <img
                className=''
                src='/assets/logo/logo-with-name-h.svg'
                alt=''
                width='150px'
                height='27px'
              />
            )}
          </Link>

          <div className='text-[#FD4943] mr-4'>
            {t('main_header.for_companies', 'dla Firm')}
          </div>

          {process.env.REACT_APP_VERCEL_ENV !== 'production' && (
            <div className='bg-red-500  px-4 py-2 text-white hidden md:block'>
              {t('main_header.test_environment', 'Środowisko testowe!!')}
            </div>
          )}

          {authContext.isSuperAdmin && (
            <>
              <Link to='/super'>
                <Button style={{ color: '#213895' }}>
                  {t('main_header.super_admin', 'Super Admin')}
                </Button>
              </Link>
              <SuperAdminSelectCompany />
            </>
          )}
        </div>

        {companyContext.company?.fc_website_prefix && (
          <div className='ml-auto'>
            <a
              href={`https://www.fajnyczas.pl/${companyContext.company?.fc_website_prefix}`}
              target='_blank'
              rel='noreferrer'
            >
              <Button
                variant='outlined'
                style={{ padding: '5px 30px', borderRadius: '8px' }}
              >
                {t('main_header.my_business_card', 'Moja wizytówka')}
              </Button>
            </a>
          </div>
        )}

        {isRegisterPage && (
          <div className='hidden lg:block'>
            <ul className='flex gap-14'>
              {registerNav.map((nav, index: any) => (
                <li
                  key={index}
                  className='text-[color:var(--fc-color-primary-blue)] cursor-pointer hover:underline'
                  onClick={() => {
                    document.querySelector(nav.goTo).scrollIntoView({
                      behavior: 'smooth'
                    })
                  }}
                >
                  {nav.label}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className='hidden lg:flex gap-2 items-center'>
          {isRegisterPage && (
            <div className='flex  gap-5'>
              <LanguageDropdownMenu />

              <Link to='/login'>
                <Button variant='outlined'>
                  {t('main_header.login', 'Logowanie')}
                </Button>
              </Link>
            </div>
          )}
        </div>

        {authContext.currentUser && (
          <div className='flex flex-row items-center justify-center ml-8'>
            <LanguageDropdownMenu />
            <CurrencyDropdownMenu />
            <div className='border-l px-4'></div>
            <UserDropdownMenu />
          </div>
        )}

        {isRegisterPage && (
          <div className='block lg:hidden'>
            <img
              onClick={() => setShowNav(true)}
              src='/assets/svg/hamburger-menu.svg'
              width='24'
              height='24'
              alt=''
              className='cursor-pointer'
            />
          </div>
        )}

        {/* Mobile nav */}
        {showNav && isRegisterPage && (
          <div className='fixed lg:hidden bg-black bg-opacity-60 w-full h-full left-0 top-0'>
            <motion.div
              initial={{ x: '300px' }}
              animate={{ x: '0' }}
              transition={{ duration: 0.1, ease: 'easeInOut' }}
              className='bg-white w-[300px] h-full fixed right-0 top-0 p-5'
            >
              <div className='flex justify-end mb-8'>
                <img
                  onClick={() => setShowNav(false)}
                  src='/assets/svg/close.svg'
                  width='16'
                  height='16'
                  alt=''
                  className='cursor-pointer'
                />
              </div>
              <div>
                <ul className='flex flex-col gap-8 mb-12'>
                  {registerNav.map((nav, index: any) => (
                    <li
                      key={index}
                      className='text-[color:var(--fc-color-primary-blue)] font-bold '
                      onClick={() => {
                        document.querySelector(nav.goTo).scrollIntoView({
                          behavior: 'smooth'
                        })
                        setShowNav(false)
                      }}
                    >
                      {nav.label}
                    </li>
                  ))}
                </ul>

                <div className='flex flex-col lg:flex-row gap-5'>
                  <Link to='/rejestracja'>
                    <Button variant='contained' fullWidth size='large'>
                      {t('main_header.registration', 'Rejestracja')}
                    </Button>
                  </Link>

                  <Link to='/login'>
                    <Button variant='outlined' fullWidth size='large'>
                      {t('main_header.login', 'Logowanie')}
                    </Button>
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

import { FormSectionContainer } from "../products/forms/components/FormSectionContainer"
import { ProductSectionFoodForm } from "../products/forms/ProductSectionFood"
import { ProductSectionInsuranceForm } from "../products/forms/ProductSectionInsurance"
import { ProductSectionConditionsForm } from "../products/forms/ProductSectionConditions"
import { ProductSectionFilesForm } from "../products/ProductSectionFiles"
import { ProductSectionScheduleForm } from "../products/forms/ProductSectionSchedule"

export const IndividualSchoolTripSectionSchedule = ({
  register,
  formMethods,
  values
}: any) => {
  return (
    <FormSectionContainer>
      <ProductSectionScheduleForm {...formMethods} register={register} />
      <ProductSectionFoodForm {...formMethods} register={register} />
      <ProductSectionInsuranceForm register={register} values={values} />

      <ProductSectionConditionsForm register={register} values={values} />

      <ProductSectionFilesForm
        register={register}
        values={{}}
        showSection={false}
      />
    </FormSectionContainer>
  )
}

import { useNavigate } from "react-router-dom"
import { MojeButton } from "../ui/MojeButton"

export const CreateCompanyFirst = ({ title }: { title: string }) => {
  const navigate = useNavigate()
  return (
    <div className="text-center flex flex-col items-center pt-10">
      <div className="text-2xl my-2 text-center">{title}</div>

      <img src="/assets/illustrations/cog.svg" alt="Illustration" />

      <div className="my-8">
        <MojeButton
          label="Ustaw dane firmy"
          color="primary"
          variant="contained"
          onClick={(event: any) => {
            navigate("/company/register")
          }}
        />
      </div>
    </div>
  )
}

import { Dialog, DialogContent, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { BlueBackground } from "wakacje-web/src/shared/components/BlueBackground"
import { ProductUtils } from "wakacje-web/src/utils/ProductUtils"
import { CalendarIcon } from "../../../../components/icons/CalendarIcon"
import KidIcon from "../../../../components/icons/KidIcon"
import { ParentIcon } from "../../../../components/icons/ParentIcon"
import {
  WakacjeChip,
  WakacjeChipVariant
} from "wakacje-web/src/shared/components/WakacjeChip"

const List = ({ name, value }) => {
  return (
    <div className="mb-4">
      <p className="text-[#707070] text-xs">{name}</p>
      <p className="text-[#0B0B0B]">{value}</p>
    </div>
  )
}

const TwoColDiv = ({ children }) => {
  return (
    <div className="flex">
      <div className="w-[50%]">{children[0] && children[0]}</div>
      <div className="w-[50%]">{children[1] && children[1]}</div>
    </div>
  )
}

export const ParticipantPopup = ({
  openPopup,
  setOpenPopup,
  participant,
  booking
}) => {
  const handleClose = () => {
    setOpenPopup(false)
  }

  console.log("ParticipantPopup", participant)

  if (participant) {
    return (
      <Dialog open={openPopup} onClose={handleClose} maxWidth="xl">
        <DialogContent>
          <div className="w-[596px]">
            <div className="flex justify-end ml-auto mb-2">
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            {/* Section 1 */}
            <>
              <BlueBackground>
                <div className="flex gap-3 items-center">
                  <KidIcon />

                  <div>
                    <p className="text-lg leading-none">Dane uczestnika</p>
                    <p className="text-2xl font-bold leading-tight">
                      {participant.first_name} {participant.last_name}
                    </p>
                    <p className="text-lg">
                      {ProductUtils.calculateAge(participant.birth_date)} lat
                    </p>
                  </div>
                </div>
              </BlueBackground>
              <div className="mt-4">
                <List name="Rok urodzenia" value={participant.birth_date} />

                <TwoColDiv>
                  <List name="Kraj" value={participant.country} />
                  <List name="Województwo" value={participant.province} />
                </TwoColDiv>

                <TwoColDiv>
                  <List name="Kod pocztowy" value={participant.zip_code} />
                  <List name="Miejscowość" value={participant.city} />
                </TwoColDiv>

                <List
                  name="Ulica, nr domu i lokalu"
                  value={participant.address}
                />
                {participant.additional_info && (
                  <List
                    name="Informacje dodatkowe"
                    value={participant.additional_info}
                  />
                )}
              </div>
            </>

            {/* Section 2 */}
            <>
              <BlueBackground>
                <div className="flex gap-3 items-center">
                  <CalendarIcon />

                  <div>
                    <p className="text-2xl font-bold">Rezerwacja</p>
                  </div>
                </div>
              </BlueBackground>
              <div className="mt-4">
                <TwoColDiv>
                  <List name="Nr rezerwacji" value={booking.id} />
                  <List
                    name="Status"
                    value={
                      <WakacjeChip
                        variant={WakacjeChipVariant.GREEN}
                        label={booking.status}
                      />
                    }
                  />
                </TwoColDiv>

                <TwoColDiv>
                  <List name="Termin płatności" value={booking.due_date} />
                  <List name="Płatność" value="-" />
                </TwoColDiv>
              </div>
            </>

            {/* Section 3 */}
            <>
              <BlueBackground>
                <div className="flex gap-3 items-center">
                  <ParentIcon />

                  <div>
                    <p className="text-lg leading-none">Dane opiekuna</p>
                    <p className="text-2xl font-bold leading-tight">
                      {participant.parent.first_name}{" "}
                      {participant.parent.last_name}
                    </p>
                  </div>
                </div>
              </BlueBackground>
              <div className="mt-4">
                <TwoColDiv>
                  <List name="Telefon" value={participant.parent.phone} />
                  <List name="E-mail" value={participant.parent.email} />
                </TwoColDiv>
              </div>
            </>
          </div>
        </DialogContent>
      </Dialog>
    )
  } else {
    return
  }
}

import { Control, Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { InputField } from "../FormCommons"

export const TripDataDialogName = ({ control }: { control: Control }) => {
  const { t } = useTranslation()
  return (
    <>
      <Controller
        name={"name"}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <InputField
              onChange={onChange}
              value={value}
              label={t("ui.trips.name_required", "Nazwa*")}
              id={`name`}
            />
          )
        }}
      />
    </>
  )
}

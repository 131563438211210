import {
  DescriptionList,
  FormHelperText,
  FormSectionSeparator,
  Heading3,
  InputField
} from "../../../../components/FormCommons"
import MenuItem from "@mui/material/MenuItem"
import { EditableFormSection } from "../../../../components/forms/EditableFormSection"
import { useProductApi } from "../../events/api/useProductApi"
import {
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL,
  PRODUCT_TYPE_SCHOOL_TRIP
} from "wakacje-web/src/utils/ProductUtils"
import { useFormContext, useFormState } from "react-hook-form"
import { useCategories } from "../../../../components/useCategories"
import {
  ProductLocationsChecks,
  ProductSectionLocationPreview
} from "./ProductSectionLocation"
import { Button } from "@mui/material"
import { useEffect, useState } from "react"

import { countries } from "../countries"
import allCountries from "country-region-data/data.json"
import * as Yup from "yup"
import { FieldError } from "../../../../components/FieldError"
import { ProductSectionTransportForm } from "./ProductSectionTransport"
import { PlaceOrAccommodationModal } from "./ProductSectionPlace/PlaceOrAccommodationModal"

const pickupRouteSchema = Yup.object().shape({
  address: Yup.string().required("Miejsce wyjazdu jest wymagany"),
  price: Yup.number().required("Cena jest wymagany")
})

export const productLocationSchema = {
  country_code: Yup.string().required("Kraj jest wymagany"),
  location: Yup.object().shape({
    province: Yup.string().test(
      "country_code",
      "Województwo jest wymagane",
      function (value) {
        const country_code = this.from?.[1]?.value?.country_code
        return (
          country_code !== "PL" || (country_code === "PL" && value.length > 0)
        )
      }
    ),
    city: Yup.string().required("Miejsce docelowe jest wymagane")
  }),
  location_types: Yup.array().required("Typ lokalizacji jest wymagany"),
  transport_types: Yup.array()
    .min(1, "Dojazd jest wymagana")
    .required("Dojazd jest wymagana"),
  transport_vehicle: Yup.string().when("transport_types", {
    is: (transport_types) =>
      transport_types.some(
        (transport_type) => transport_type.type === "provided"
      ),
    then: Yup.string().nullable().required("Rodzaj transportu jest wymagany"),
    otherwise: Yup.string().nullable().notRequired()
  }),
  pickup_route: Yup.array()
    .of(pickupRouteSchema)
    .when("transport_vehicle", {
      is: (transport_vehicle) => transport_vehicle,
      then: Yup.array().min(1, "Miejsce wyjazdu jest wymagany"),
      otherwise: Yup.array().notRequired()
    })
}

export const SchoolTripSectionLocationForm = (props: any) => {
  const { watch, getValues, register, trigger } = useFormContext<any>()
  const { errors } = useFormState()
  const values = getValues()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { setValue } = useFormContext()
  const { type } = values
  const accommodation = watch("accommodation")
  // Keep this field registered
  const tripType = watch("trip_type")

  const countryCode = watch("country_code")

  useEffect(() => {
    console.log("updated country")
    if (countryCode !== "PL") {
      setValue("country_is_poland", "no")
      setValue("location.province", "", {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      })
    } else {
      setValue("country_is_poland", "yes")
      trigger("location.province")
    }
  }, [countryCode])

  let selectedCountry = allCountries.find((obj) => {
    return obj.countryShortCode === countryCode
  })

  return (
    <div className="mb-8 pt-4">
      {(type === PRODUCT_TYPE_SCHOOL_TRIP.value ||
        type === PRODUCT_TYPE_SCHOOL.value ||
        type === PRODUCT_TYPE_CAMP.value) && (
        <>
          {(tripType === "wielodniowa" || type === PRODUCT_TYPE_CAMP.value) && (
            <>
              <div className="my-4">
                <Heading3>Nocleg</Heading3>

                {accommodation && (
                  <>
                    <div className="flex flex-row items-center">
                      <div className="my-4 flex-1 text-lg">
                        {accommodation.name}
                      </div>
                      <div>
                        <div
                          className="text-red-500 font-bold cursor-pointer hover:underline"
                          onClick={() => {
                            setValue("accommodation", undefined)
                          }}
                        >
                          Usuń
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {!accommodation && (
                  <div className="flex justify-center">
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleOpen}
                    >
                      Dodaj nocleg
                    </Button>
                  </div>
                )}

                <PlaceOrAccommodationModal
                  type="accommodation"
                  open={open}
                  handleClose={handleClose}
                  disabledItems={[]}
                  onItemsSelected={(items) => {
                    console.log("onItemsSelected", items)

                    if (items && items[0].type === "accommodation") {
                      setValue("accommodation", items[0])
                      setValue("accommodation_place_id", items[0].id)
                    }

                    if (
                      items &&
                      // items[0].type === "place" &&
                      items.length > 0
                    ) {
                      setValue("place", {
                        ...items[0]
                      })
                      setValue("place_id", items[0].id)
                    }
                    handleClose()
                  }}
                  handleDeleteItem={() => {}}
                />
              </div>

              {/* <FormSectionSeparator /> */}
            </>
          )}
        </>
      )}

      {/*TODO: This needs to be checked and verified, whether it should remain, be inherited from the accommodation, or something else*/}
      {(type === PRODUCT_TYPE_DAYCAMP.value ||
        type === PRODUCT_TYPE_CAMP.value ||
        type === PRODUCT_TYPE_SCHOOL_TRIP.value) && (
        <>
          <div className="mb-3">
            <Heading3>Lokalizacja</Heading3>
          </div>

          {type === PRODUCT_TYPE_DAYCAMP.value && (
            <>
              <InputField
                label="Nazwa ośrodka2*"
                {...register("location_name", { required: true })}
              />
            </>
          )}
          <div>
            <InputField
              select
              label="Kraj*"
              SelectProps={{
                defaultValue: values?.location?.province || ""
              }}
              value={countryCode}
              {...register("country_code")}
            >
              {countries.map((c, index) => (
                <MenuItem key={index} value={c.code}>
                  {c.name_pl}
                </MenuItem>
              ))}
            </InputField>
            <FieldError label={errors?.country_code?.message} />
          </div>

          <div>
            {countryCode === "PL" && (
              <>
                <InputField
                  select
                  label="Województwo*"
                  SelectProps={{
                    defaultValue: values?.location?.province || ""
                  }}
                  value={watch("location.province")}
                  {...register("location.province", { required: false })}
                >
                  {selectedCountry.regions.map((pr, index) => (
                    <MenuItem value={pr.name}>{pr.name}</MenuItem>
                  ))}
                </InputField>
                <FieldError label={errors?.location?.province?.message} />
              </>
            )}
          </div>

          <div className="mb-4">
            {type !== PRODUCT_TYPE_DAYCAMP.value && (
              <InputField
                label="Miejsce docelowe*"
                {...register("location.city")}
              />
            )}

            {type === PRODUCT_TYPE_DAYCAMP.value && (
              <>
                {" "}
                <InputField
                  label="Kod pocztowy"
                  {...register("location.post_code", { required: true })}
                  // inputComponent={TextMaskPostCode}
                />
                <InputField label="Ulica*" {...register("location.street")} />
                <div className="flex justify-items-stretch gap-4  flex-row">
                  <div className="mr-2 w-36">
                    <InputField
                      label="Numer domu*"
                      {...register("location.house_number")}
                    />
                  </div>
                  <div className="w-36">
                    <InputField
                      label="Numer lokalu"
                      {...register("location.room_number")}
                    />
                  </div>
                </div>
              </>
            )}
            <FieldError label={errors?.location?.city?.message} />
          </div>
        </>
      )}

      <div className="mb-8" />
      <Heading3>Typ lokalizacji</Heading3>
      <FormHelperText>Zaznacz przynajmniej jedną opcję*</FormHelperText>
      <ProductLocationsChecks
        watch={watch}
        register={register}
        setValue={setValue}
      />
      <div className="mb-8" />
      {/* <FormSectionSeparator /> */}

      <ProductSectionTransportForm register={register} />
      <FieldError label={errors?.location_types?.message} />
    </div>
  )
}

export const SchoolTripSectionLocationPreview = ({ values }: any) => {
  return <ProductSectionLocationPreview values={values} />
}

export const SchoolTripSectionLocationView = ({ values, refetch }: any) => {
  const { updateProduct } = useProductApi()

  const saveHandler = async (data: any) => {
    console.log("Save handler", data)
    var productData = {
      id: values.id,
      accommodation: data.accommodation,
      country_code: data.country_code,
      country_name: data.country_name,
      location: data.location,
      location_types: data.location_types,
      location_name: data.location_name,
      place_id: data.place_id,
      accommodation_place_id: data.accommodation_place_id,
      // transport
      transport_description: data.transport_description,
      transport_vehicle: data.transport_vehicle,
      transport_type: data.transport_type,
      transport_types: data.transport_types,
      pickup_route: data.pickup_route
    }
    console.log("new product data", productData)
    return updateProduct(productData)
  }
  const handleRefresh = () => {
    refetch()
  }
  const initialValues = {
    ...values,
    location_types: values.location_types ?? [],
    country_is_poland: values.country_code === "PL",
    pickup_route: values.pickup_route ?? []
  }

  const _values = { ...values, pickup_route: values.pickup_route ?? [] }
  return (
    <EditableFormSection
      title="Lokalizacja"
      values={_values}
      onSave={saveHandler}
      onSaved={handleRefresh}
      validationSchema={productLocationSchema}
    >
      {(builderProps: any) => {
        return (
          <div>
            {builderProps.isEditing && (
              <SchoolTripSectionLocationForm
                values={initialValues}
                register={builderProps.register}
                watch={builderProps.watch}
              />
            )}
            {!builderProps.isEditing && (
              <SchoolTripSectionLocationPreview values={values} />
            )}
          </div>
        )
      }}
    </EditableFormSection>
  )
}

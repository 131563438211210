import axios from "axios"
import {
  Button,
  CircularProgress,
  TextField
} from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import { UidUtils } from "../../utils/UidUtils"
import { MessageBubble } from "./MessageBubble"
import { parseISO, format } from "date-fns"
import { uniqBy } from "lodash"
import { MojeButton } from "../../components/MojeButton"
import { useAuth } from "../../auth/AuthContext"
import { useAcknowledgeMessages } from "../messages/components/useAcknowledgeMessages"
import { AttachFilePopup } from "./AttachFilePopup"
import { useFileUploadedContext } from "../../contexts/FileUploadedContext"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import styled from "styled-components"
import { useTranslation } from "next-i18next"

export const ChatMessagesList = ({
  chatHash,
  companyId,
  mode,
  accessToken,
  userId,
  rootUrl,
  detailsRenderer,
  booking
}) => {
  const [lastPage, setLastPage] = useState<any>()
  const [messages, setMessages] = useState<any>(undefined)
  const [loading, setLoading] = useState(false)
  const { acknowledge } = useAcknowledgeMessages(rootUrl)

  const onEventBusEvent = useCallback(event => {
    console.log("BookingMessagesWidget.onEventBusEvent", event)
    loadNextPage()
  }, [])
  useEffect(() => {
    console.log("ChatMessagesList subscribe")
    if (typeof document.addEventListener !== "undefined") {
      document.addEventListener("Message", onEventBusEvent)
      document.addEventListener("Chat", onEventBusEvent)

      return () => {
        console.log("ChatMessagesList unsubscribe")
        document.removeEventListener("Message", onEventBusEvent)
      }
    }
  }, [])

  const { fileUploadedAction } = useFileUploadedContext()

  const loadNextPage = async () => {
    setLoading(true)
    console.log("loadNextPage")
    const url = rootUrl + `/messages/v1/chat/${chatHash}`
    var token =
      accessToken && typeof accessToken === "function"
        ? await accessToken()
        : accessToken
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (res.data.messages) {
      setLastPage(res.data.messages)
      mergeMessages(res.data.messages.data)
    }
    setLoading(false)
  }

  const mergeMessages = addMessages => {
    var newMessages = messages
      ? messages.reduce((a, i) => ({ ...a, [i.id]: i }), {})
      : {}
    for (var i = 0; i < addMessages.length; i++) {
      var m = addMessages[i]
      newMessages[m.id] = m
    }
    newMessages = Object.values(newMessages)
    newMessages.sort((a, b) =>
      a.sent_at ? a.sent_at.localeCompare(b.sent_at) : 0
    )
    setMessages(newMessages)
  }

  const handleSent = newMessage => {
    mergeMessages([newMessage])
  }

  const [showDateItem, setShowDateItem] = useState([])

  useEffect(() => {
    // TODO - check if there are unread (by us) messages
    acknowledge(chatHash, mode, accessToken)
  }, [])

  const dateSeparator = () => {
    const dateArr = []
    if (messages) {
      messages.forEach(item => {
        dateArr.push({
          date: format(parseISO(item.created_at), "MM/dd/yyyy"),
          id: item.id
        })
      })

      var uniqueDate = uniqBy(dateArr, function (e) {
        return e.date
      })
    }
    setShowDateItem(uniqueDate)
  }

  const lastMessageScrollRef = useRef(null)

  useEffect(() => {
    loadNextPage()
  }, [])

  useEffect(() => {
    dateSeparator()
    lastMessageScrollRef.current.scrollIntoView()
    window.scrollBy(0, -500)
    if (fileUploadedAction) {
      fileUploadedAction.updateMessages(messages)
    }
  }, [messages])

  return (
    // <StyledContainer> className="h-full flex flex-col relative text-xs md:text-base"></StyledContainer>
    <StyledContainer>
      {!messages && loading && (
        <div className="text-center p-10">
          <CircularProgress />
        </div>
      )}

      <div
        className="messages-container flex-1 w-full pt-5 overflow-y-auto px-1 md:pr-5 md:max-h-[100%] lg:max-h-[1000px] pb-10 md:text-base"
        style={{
          minHeight: 0
        }}
      >
        {messages &&
          messages.map(msg => {
            return (
              <MessageBubble
                booking={booking}
                key={msg.id}
                msg={msg}
                mode={mode}
                showDate={
                  showDateItem &&
                    showDateItem.some(item => item["id"] === msg.id)
                    ? true
                    : false
                }
                detailsRenderer={detailsRenderer}
              />
            )
          })}
        <div className="py-2" ref={lastMessageScrollRef}></div>
      </div>
      {!loading && (
        <>
          <NewMessageWidget
            userId={userId}
            chatHash={chatHash}
            companyId={companyId}
            onSent={handleSent}
            accessToken={accessToken}
            mode={mode}
            rootUrl={rootUrl}
          />
        </>
      )}
    </StyledContainer>
  )
}

const NewMessageWidget = ({
  chatHash,
  onSent,
  companyId,
  userId,
  mode,
  accessToken,
  rootUrl
}) => {
  const [newMessage, setNewMessage] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [id, setId] = useState(UidUtils.generateUid("msg"))
  const [showAddFilePopup, setShowAddFilePopup] = useState(false)
  const [showSendButton, setShowSendButton] = useState(false)
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const { t } = useTranslation("common")

  const sendInner = async msg => {
    setIsSending(true)

    console.log("sendInner", msg)

    try {
      if (!accessToken) {
        console.error("BookingMessages - Not Logged In")
        throw new Error("Nie zalogowany")
      }

      const url = rootUrl + `/messages/v1/chat/${chatHash}/message/${id}`

      const message = {
        direction: mode === "user" ? "FROM_USER" : "TO_USER",
        user_id: userId,
        company_id: companyId,
        chat_hash: chatHash,
        ...msg
      }

      var token =
        accessToken && typeof accessToken === "function"
          ? await accessToken()
          : accessToken
      var res = await axios.post(url, message, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      setId(UidUtils.generateUid("msg"))
      setNewMessage("")
      onSent(res.data.data)
      setIsSending(false)
    } catch (err) {
      console.error("Error send message")
      setIsSending(false)
    }
  }
  const handleSend = async () => {
    await sendInner({
      content_text: newMessage
    })
  }
  const handleKeyPress = event => {
    if (event.key === "Enter" && !event.shiftKey && md) {
      handleSend()
    }
  }
  const handleSendAttachments = async attachments => {
    console.log("handleSendAttachments", attachments)

    var res = await sendInner({
      content: attachments,
      type: "attachment"
    })
    console.log("Send inner res", res)
    setShowAddFilePopup(false)
  }

  return (
    <div className="bg-white lg:pb-8 sticky bottom-0 left-2 right-2 md:static px-2">
      <TextField
        className="w-full "
        multiline
        maxRows={3}
        minRows={1}
        onKeyDown={handleKeyPress}
        onChange={event => {
          setShowSendButton(true)
          setNewMessage(event.target.value)
        }}
        value={newMessage}
        placeholder={t("account.write_message", "Napisz wiadomość...")}
        size="small"
        inputProps={{
          style: {
            padding: "1px 8px"
          }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'currentColor',
              borderWidth: 1,
            },
            '&:hover fieldset': {
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <div
              className="cursor-pointer"
              style={{
                padding: "0",
                width: "48px"
              }}
            >
              <img
                onClick={() => setShowAddFilePopup(true)}
                src="/svg/camera.svg"
                width="28"
                height="28"
              />
            </div>
          ),
          endAdornment: (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              style={{
                visibility: !showSendButton ? "hidden" : "visible",
                borderRadius: "50%",
                minWidth: "18px",
                minHeight: "18px",
                padding: "12px"
              }}
            >
              <img src="/svg/send.svg" alt="send icon" width="24" height="24" />
            </Button>
          )
        }}
      />

      <div className="flex flex-row justify-end items-center mt-3">
        <div className="mr-5">
          {/* <MojeButton
            onClick={() => setShowAddFilePopup(true)}
            variant="text"
            style={{
              color: "var(--fc-color-accent-4)"
            }}
            endIcon={
              <img
                src="/svg/attachment.svg"
                alt="attachment icon"
                width="17"
                height="17"
              />
            }
            className="h-[38px] w-[auto] md:w-[154px]  text-base"
          >
            <p>Dodaj plik</p>
          </MojeButton> */}
        </div>
      </div>
      <AttachFilePopup
        onClose={() => setShowAddFilePopup(false)}
        open={showAddFilePopup}
        chatHash={chatHash}
        onSend={handleSendAttachments}
      />
    </div>
  )
}

export const BookingsMessagesWidgetCompany = ({
  booking,
  mode,
  accessToken,
  detailsRenderer,
  chatHash
}) => {
  return (
    <ChatMessagesList
      userId={booking.user_id}
      accessToken={accessToken}
      chatHash={chatHash}
      companyId={booking.company.id}
      mode={mode}
      rootUrl={process.env.REACT_APP_WAKACJE_HTTP_API_URL}
      detailsRenderer={detailsRenderer}
      booking={booking}
    />
  )
}
export const BookingsMessagesWidgetNext = ({
  booking,
  mode,
  company,
  chatHash
}) => {
  const { auth, user } = useAuth()
  const userId = (user as any)?.id
  console.log("BookingsMessagesWidgetNext", company, booking, userId)

  return (
    <ChatMessagesList
      rootUrl={process.env.NEXT_PUBLIC_WAKACJE_HTTP_API_URL}
      userId={userId}
      accessToken={auth?.currentUser?.accessToken}
      chatHash={chatHash}
      companyId={company.id}
      mode={mode}
      detailsRenderer={undefined}
      booking={booking}
    />
  )
}

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.75rem;

  .messages-container {
    ::-webkit-scrollbar {
      width: 4px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      background: #0559e8;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: gray;
    }
  }
`